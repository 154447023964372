import React, { useState, useEffect, useRef } from "react";
import { PATH } from "../../../globals/app-constants";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import FooterContact from "../../../component/FooterContact";
import InsightsCard from "../../../component/InsightsCard";
import ManufacturingVideo from "../../../asset/video/manufacturing.mp4";


const Manufacturing = () => {
    const mobileSectionsRefs = {
        qualitycontrolMd: useRef(null),
        objectproductcountingMd: useRef(null),
        losspreventionMd: useRef(null),
        safetysecurityMd: useRef(null),
        employeetrackingMd: useRef(null),
        assettrackingMd: useRef(null),
    };
    const webSectionsRefs = {
        qualitycontrol: useRef(null),
        objectproductcounting: useRef(null),
        lossprevention: useRef(null),
        safetysecurity: useRef(null),
        employeetracking: useRef(null),
        assettracking: useRef(null),
    };
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [tab, setTab] = useState(isMobileView ? 'qualitycontrolMd' : 'qualitycontrol');
    const [sectionsRefs, setSectionsRefs] = useState(isMobileView ? mobileSectionsRefs : webSectionsRefs)

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 3,
        slidesToScroll: 1,
    }
    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setIsMobileView(isMobile);
            setSectionsRefs(isMobile ? mobileSectionsRefs : webSectionsRefs);

            // Determine the currently active section
            const currentActiveTab = Object.keys(sectionsRefs).find((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    return top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2;
                }
                return false;
            });

            // Set the tab dynamically based on the active section
            if (currentActiveTab) {
                setTab(isMobile ? `${currentActiveTab}Md` : currentActiveTab.replace('Md', ''));
            } else {
                // Fallback to the first section if no active section is found
                setTab(isMobile ? 'qualitycontrolMd' : 'qualitycontrol');
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Iterate through sections to find the one currently in view
            Object.keys(sectionsRefs).forEach((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                        setTab(key);
                    }
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sectionsRefs]);

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('scroll', handleHorizontalScroll(tab));
        }, 1000);
    }, [tab])

    const handleScrollView = (refName) => {
        const pos = refName.style.position;
        const top = refName.style.top;
        refName.style.position = 'relative';
        refName.style.top = '-100px';
        refName.scrollIntoView({ behavior: 'smooth', block: 'start' });
        refName.style.top = top;
        refName.style.position = pos;
    }
    const handleScrollViewMd = (refName, currentTab) => {
        if (refName) {
            // Adjust the position temporarily for smooth scroll
            const pos = refName.style.position;
            const top = refName.style.top;
            refName.style.position = 'relative';
            refName.style.top = '-170px';

            refName.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });

            // Reset the position after scroll
            refName.style.top = top;
            refName.style.position = pos;

            // Once the vertical scroll is done, trigger the horizontal scroll
            refName.addEventListener('transitionend', () => {
                handleHorizontalScroll(currentTab);
            }, { once: true });
        }
    };
    const handleHorizontalScroll = (currentTab) => {
        const container = document.querySelector('.manufacturParentTabs-container'); // Adjust the selector to match your container
        const subContainer = document.querySelector(`.manufacturSubTabsr-${currentTab}`); // Adjust the selector to match your container
        if (container) {
            const subContainerRect = subContainer?.getBoundingClientRect();
            container.scrollBy({
                left: subContainerRect?.x > 0 ? 100 : -200,
                behavior: 'smooth'
            });
        }
    }
    return (
        <div className="mt-24 lg:mt-32">
            <div className="container max-auto">
                <h1 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center capitalize">AI/ML Solutions for Manufacturing - From Flaw Detection to Flawless Production</h1>
                <p className="text-base fR text-start lg:text-center text-pColor my-1 max-w-3xl mx-auto">Envision new horizons for your manufacturing business with our advanced, secure solutions that expand what’s achievable.</p>
                <div className="my-6 lg:my-12 flex justify-center ">
                    <video className="lg:max-w-screen-md rounded-md" playsInline autoPlay={true} muted loop>
                        <source src={ManufacturingVideo} type="video/mp4"></source>
                    </video>
                </div>
            </div>
            <div className="container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor fB mb-3 capitalize">Unveil the invisible and optimize the visible with computer vision</h2>
                <p className="text-base fR text-start text-pColor my-1">Simplify hidden tasks and drive production forward.</p>
                <div className="block md:hidden">
                    <div className="d-flex w-full">
                        <div className={`flex w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40 bg-white w-full overflow-x-auto scroll-smooth manufacturParentTabs-container`}>
                            <button className={`text-sm px-3 py-3 rounded-md manufacturSubTabsr-qualitycontrolMd ${tab == 'qualitycontrolMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('qualitycontrolMd');
                                    handleScrollViewMd(sectionsRefs.qualitycontrolMd.current, 'qualitycontrolMd')
                                    //sectionsRefs.qualitycontrolMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Quality Control</button>
                            <button className={`text-sm px-3 py-3 rounded-md manufacturSubTabsr-objectproductcountingMd ${tab == 'objectproductcountingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('objectproductcountingMd');
                                    handleScrollViewMd(sectionsRefs.objectproductcountingMd.current, 'objectproductcountingMd')
                                    //sectionsRefs.objectproductcountingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Object/Product Counting</button>
                            <button className={`text-sm px-3 py-3 rounded-md manufacturSubTabsr-safetysecurityMd ${tab == 'safetysecurityMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('safetysecurityMd');
                                    handleScrollViewMd(sectionsRefs.safetysecurityMd.current, 'safetysecurityMd')
                                    //sectionsRefs.safetysecurityMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Safety and Security</button>
                            <button className={`text-sm px-3 py-3 rounded-md manufacturSubTabsr-losspreventionMd ${tab == 'losspreventionMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('losspreventionMd');
                                    handleScrollViewMd(sectionsRefs.losspreventionMd.current, 'losspreventionMd')
                                    //sectionsRefs.losspreventionMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Loss Prevention</button>
                            <button className={`text-sm px-3 py-3 rounded-md manufacturSubTabsr-employeetrackingMd ${tab == 'employeetrackingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('employeetrackingMd');
                                    handleScrollViewMd(sectionsRefs.employeetrackingMd.current, 'employeetrackingMd')
                                    //sectionsRefs.employeetrackingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Employee Tracking</button>
                            <button className={`text-sm px-3 py-3 rounded-md manufacturSubTabsr-assettrackingMd ${tab == 'assettrackingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('assettrackingMd');
                                    handleScrollViewMd(sectionsRefs.assettrackingMd.current, 'assettrackingMd')
                                    //sectionsRefs.assettrackingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Asset Tracking</button>
                            {/* <div className=" flex gap-3 overflow-auto py-6 text-nowrap">
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'qualitycontrol' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('qualitycontrol')}>Quality Control</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'objectproductcounting' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('objectproductcounting')}>Object/Product Counting</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'safetysecurity' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('safetysecurity')}>Safety and Security</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'lossprevention' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('lossprevention')}>Loss Prevention</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'employeetracking' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('employeetracking')}>Employee Tracking</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'assettracking' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('assettracking')}>Asset Tracking</button>
                            </div> */}
                        </div>
                        <div>
                            <div ref={sectionsRefs.qualitycontrolMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Manufacturing</p>
                                        <h2 className="text-hColor text-base fB">Quality Control</h2>
                                        <p className="text-pColor text-sm py-2 fM">AI-driven quality control transforms manufacturing, turning every product inspection into a flawless pursuit of perfection. Embrace next-gen machine vision to meticulously analyze every item on rapid production lines, detecting even the most elusive defects. With advanced cameras and sensors, this innovative technology guarantees flawless products and extraordinary accuracy at every step.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.agileIcon}
                                        heading={'Defect Detection'}
                                        subtext={'AI technology reveals even the most elusive flaws, ensuring each product meets the highest quality standards.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.design}
                                        heading={'Waste Reduction'}
                                        subtext={'Minimize material and resource wastage through early defect identification and prevention.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.imageAnalytics}
                                        heading={'Shape and Size Accuracy'}
                                        subtext={'Utilize advanced AI to calculate production parts’ dimensions against reference models, ensuring precise quality control.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.quality}
                                        heading={'Live Quality Check'}
                                        subtext={'AI provides continuous, real-time analysis of production lines, allowing for immediate adjustments and maintaining consistent quality.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.objectproductcountingMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Manufacturing</p>
                                        <h2 className="text-hColor text-base fB">Object/Product Counting</h2>
                                        <p className="text-pColor text-sm py-2 fM">Precision in tracking is the cornerstone of efficient manufacturing.Real-time tracking solutions utilize data-driven insights to identify and resolve bottlenecks, while sophisticated anomaly detection algorithms ensure quality control by flagging missing items and deviations, while predictive analytics optimize inventory management to mitigate risks of stock imbalances.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Inventory Tracking'}
                                        subtext={'Accurately counting products as they move through the assembly line provides real-time insights into production rates, identifying bottlenecks and inefficiencies.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.communicationSkils}
                                        heading={'Quality Control and Anomaly Detection'}
                                        subtext={'Detect and report defective or missing products on the assembly line, ensuring consistent quality.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.retailsecurityicon}
                                        heading={'Waste Reduction'}
                                        subtext={'Tracking the number of products and by-products can help identify areas of waste generation and implement strategies to reduce it.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.stockicon}
                                        heading={'Prevent Stockouts and Overstocking'}
                                        subtext={'Precise product counting ensures accurate inventory levels, preventing stockouts and overstocking.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.safetysecurityMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Manufacturing</p>
                                        <h2 className="text-hColor text-base fB">Safety and Security</h2>
                                        <p className="text-pColor text-sm py-2 fM">Upgrade your manufacturing safety with touchless AI for immediate defect detection and compliance. Our state-of-the-art system uses advanced computer vision to identify production flaws, ensure safety gear is worn, and provide real-time insights - all through a sleek, intuitive web app. Elevate your manufacturing environment with unmatched safety and precision!</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.dataAnalytics}
                                        heading={'Defect Detection'}
                                        subtext={'Employs sophisticated imaging technology and algorithms to pinpoint and categorize production defects with precision.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.safetyverificationicon}
                                        heading={'Safety Gear Verification'}
                                        subtext={'Employs visual recognition technology to ensure compliance with safety gear requirements, such as helmets, masks, and safety glasses.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.annotateIcon}
                                        heading={'Weapon Detection'}
                                        subtext={'Spot and classify potential threats like firearms to prevent safety breaches and ensure a secure manufacturing environment.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Incident Tracking'}
                                        subtext={'Records precise timestamps and details of defects and safety compliance checks, storing evidence and metadata for thorough analysis.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.losspreventionMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Manufacturing</p>
                                        <h2 className="text-hColor text-base fB">Loss Prevention</h2>
                                        <p className="text-pColor text-sm py-2 fM">Catch Issues Before They Escalate. Utilize breakthrough data analysis to spot and stop potential theft and errors before they impact your bottom line, enhancing efficiency and security.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.feasibility}
                                        heading={'Theft Takedown'}
                                        subtext={'Cutting-edge surveillance systems deter theft, safeguard critical manufacturing assets and protect your bottom line.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.uiux}
                                        heading={'AI-Powered Visual Guard'}
                                        subtext={'AI video analysis identifies anomalies in machine operation, potentially indicating malfunctions or misuse, allowing for preventative maintenance and improved efficiency.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.vision}
                                        heading={'Smart Surveillance'}
                                        subtext={'Instantly identify discrepancies in production processes, flagging defects and unauthorized actions to prevent material losses.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.scale}
                                        heading={'Quick Install, Crystal Clarity'}
                                        subtext={'The effortless setup of top-tier cameras ensures precise monitoring of product details and production quantities, enhancing manufacturing efficiency and accuracy.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.employeetrackingMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Manufacturing</p>
                                        <h2 className="text-hColor text-base fB">Employee Tracking</h2>
                                        <p className="text-pColor text-sm py-2 fM">Unify efficiency and security on your manufacturing floor with our revolutionary AI vision solution. Smart cameras provide real-time staff location, ensuring the right personnel are available for critical tasks. Facial recognition acts as a vigilant guardian, securing sensitive areas, automating attendance tracking, and empowering you to achieve peak manufacturing success.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Effortless Shift Attendance'}
                                        subtext={'Facial recognition streamlines shift attendance tracking, eliminating manual punch-ins and reducing administrative tasks on the factory floor.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.streamline}
                                        heading={'Unauthorized Access Prevention'}
                                        subtext={'Facial recognition algorithms block unauthorized entry to restricted manufacturing areas, sending instant alerts to security teams for immediate action.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.imageAnalytics}
                                        heading={'Smart Task Routing'}
                                        subtext={'Integrate with task management systems to dynamically assign and route tasks based on staff location and expertise, optimizing production floor efficiency.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Real-Time Location Insights'}
                                        subtext={'Track manufacturing staff in real-time for seamless workflow coordination, improved assembly line efficiency, and optimized production processes.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.assettrackingMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Manufacturing</p>
                                        <h2 className="text-hColor text-base fB">Asset Tracking</h2>
                                        <p className="text-pColor text-sm py-2 fM">Forget blind spots and production delays in manufacturing! Keep your operations flawless with real-time insights and precise tracking, while AI-driven facial recognition secures your high-priority zones. Transform every facet of your manufacturing process with cutting-edge AI!</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.product}
                                        heading={'Inventory Mastery'}
                                        subtext={'Elevate your inventory game with intelligent automation and instant alerts for stock levels and expiration, ensuring your production line never misses a beat.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.materialdemandicon}
                                        heading={'Predictive Raw Material Demand'}
                                        subtext={'Utilize advanced forecasting to predict raw material needs, optimizing supply chain management and preventing production delays.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.productList}
                                        heading={'Product Placement'}
                                        subtext={'Leverage state-of-the-art computer vision to ensure precise placement and identification of components, maintaining perfect alignment with production plans.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.dashboardicon}
                                        heading={'Insight-Powered Dashboard'}
                                        subtext={'Unleash tailored analytics with bespoke reports on asset usage and production flow, optimizing resource allocation and driving unparalleled manufacturing efficiency.'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex gap-20">
                    <div className="">
                        <div className="flex flex-col w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40">
                            <div className={`flex gap-2 items-center py-3 ${tab === 'qualitycontrol' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'qualitycontrol' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('qualitycontrol');
                                        sectionsRefs.qualitycontrol.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Quality Control
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'qualitycontrol' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'qualitycontrol' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'objectproductcounting' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'objectproductcounting' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('objectproductcounting');
                                        handleScrollView(sectionsRefs.objectproductcounting.current)
                                        //sectionsRefs.objectproductcounting.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Object/Product Counting
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'objectproductcounting' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'objectproductcounting' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'safetysecurity' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'safetysecurity' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('safetysecurity');
                                        handleScrollView(sectionsRefs.safetysecurity.current)
                                        //sectionsRefs.safetysecurity.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Safety and Security
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'safetysecurity' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'safetysecurity' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'lossprevention' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'lossprevention' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('lossprevention');
                                        handleScrollView(sectionsRefs.lossprevention.current)
                                        //sectionsRefs.objectproductcounting.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Loss Prevention
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'lossprevention' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'lossprevention' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'employeetracking' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'employeetracking' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('employeetracking');
                                        handleScrollView(sectionsRefs.employeetracking.current)
                                        //sectionsRefs.employeetracking.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Employee Tracking
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'employeetracking' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'employeetracking' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'assettracking' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'assettracking' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('assettracking');
                                        handleScrollView(sectionsRefs.assettracking.current)
                                        //sectionsRefs.qualitycontrol.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Asset Tracking
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>



                        </div>
                    </div>
                    <div>
                        <div ref={sectionsRefs.qualitycontrol} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Manufacturing</p>
                                            <h2 className="text-hColor text-xl underline fB">Quality Control</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/ai-for-quality-control')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">AI-driven quality control transforms manufacturing, turning every product inspection into a flawless pursuit of perfection. Embrace next-gen machine vision to meticulously analyze every item on rapid production lines, detecting even the most elusive defects. With advanced cameras and sensors, this innovative technology guarantees flawless products and extraordinary accuracy at every step.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.agileIcon}
                                        heading={'Defect Detection'}
                                        subtext={'AI technology reveals even the most elusive flaws, ensuring each product meets the highest quality standards.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.design}
                                        heading={'Waste Reduction'}
                                        subtext={'Minimize material and resource wastage through early defect identification and prevention.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.imageAnalytics}
                                        heading={'Shape and Size Accuracy'}
                                        subtext={'Utilize advanced AI to calculate production parts’ dimensions against reference models, ensuring precise quality control.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.quality}
                                        heading={'Live Quality Check'}
                                        subtext={'AI provides continuous, real-time analysis of production lines, allowing for immediate adjustments and maintaining consistent quality.'}
                                    />
                                </div>

                            </div>
                        </div>
                        <div ref={sectionsRefs.objectproductcounting} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Manufacturing</p>
                                            <h2 className="text-hColor text-xl underline fB">Object/Product Counting</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/asset-tracking')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Precision in tracking is the cornerstone of efficient manufacturing.Real-time tracking solutions utilize data-driven insights to identify and resolve bottlenecks, while sophisticated anomaly detection algorithms ensure quality control by flagging missing items and deviations, while predictive analytics optimize inventory management to mitigate risks of stock imbalances.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Inventory Tracking'}
                                        subtext={'Accurately counting products as they move through the assembly line provides real-time insights into production rates, identifying bottlenecks and inefficiencies.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.communicationSkils}
                                        heading={'Quality Control and Anomaly Detection'}
                                        subtext={'Detect and report defective or missing products on the assembly line, ensuring consistent quality.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.retailsecurityicon}
                                        heading={'Waste Reduction'}
                                        subtext={'Tracking the number of products and by-products can help identify areas of waste generation and implement strategies to reduce it.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.stockicon}
                                        heading={'Prevent Stockouts and Overstocking'}
                                        subtext={'Precise product counting ensures accurate inventory levels, preventing stockouts and overstocking.'}
                                    />
                                </div>

                            </div>
                        </div>

                        <div ref={sectionsRefs.safetysecurity} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Manufacturing</p>
                                            <h2 className="text-hColor text-xl underline fB">Safety and Security</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/safety-security')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Upgrade your manufacturing safety with touchless AI for immediate defect detection and compliance. Our state-of-the-art system uses advanced computer vision to identify production flaws, ensure safety gear is worn, and provide real-time insights - all through a sleek, intuitive web app. Elevate your manufacturing environment with unmatched safety and precision!</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.dataAnalytics}
                                        heading={'Defect Detection'}
                                        subtext={'Employs sophisticated imaging technology and algorithms to pinpoint and categorize production defects with precision.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.safetyverificationicon}
                                        heading={'Safety Gear Verification'}
                                        subtext={'Employs visual recognition technology to ensure compliance with safety gear requirements, such as helmets, masks, and safety glasses.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.annotateIcon}
                                        heading={'Weapon Detection'}
                                        subtext={'Spot and classify potential threats like firearms to prevent safety breaches and ensure a secure manufacturing environment.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Incident Tracking'}
                                        subtext={'Records precise timestamps and details of defects and safety compliance checks, storing evidence and metadata for thorough analysis.'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div ref={sectionsRefs.lossprevention} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Manufacturing</p>
                                            <h2 className="text-hColor text-xl underline fB">Loss Prevention</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/loss-prevention')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Catch Issues Before They Escalate. Utilize breakthrough data analysis to spot and stop potential theft and errors before they impact your bottom line, enhancing efficiency and security.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.feasibility}
                                        heading={'Theft Takedown'}
                                        subtext={'Cutting-edge surveillance systems deter theft, safeguard critical manufacturing assets and protect your bottom line.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.uiux}
                                        heading={'AI-Powered Visual Guard'}
                                        subtext={'AI video analysis identifies anomalies in machine operation, potentially indicating malfunctions or misuse, allowing for preventative maintenance and improved efficiency.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.vision}
                                        heading={'Smart Surveillance'}
                                        subtext={'Instantly identify discrepancies in production processes, flagging defects and unauthorized actions to prevent material losses.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.scale}
                                        heading={'Quick Install, Crystal Clarity'}
                                        subtext={'The effortless setup of top-tier cameras ensures precise monitoring of product details and production quantities, enhancing manufacturing efficiency and accuracy.'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div ref={sectionsRefs.employeetracking} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Manufacturing</p>
                                            <h2 className="text-hColor text-xl underline fB">Employee Tracking</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/ai-attendance')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Unify efficiency and security on your manufacturing floor with our revolutionary AI vision solution. Smart cameras provide real-time staff location, ensuring the right personnel are available for critical tasks. Facial recognition acts as a vigilant guardian, securing sensitive areas, automating attendance tracking, and empowering you to achieve peak manufacturing success.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Effortless Shift Attendance'}
                                        subtext={'Facial recognition streamlines shift attendance tracking, eliminating manual punch-ins and reducing administrative tasks on the factory floor.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.streamline}
                                        heading={'Unauthorized Access Prevention'}
                                        subtext={'Facial recognition algorithms block unauthorized entry to restricted manufacturing areas, sending instant alerts to security teams for immediate action.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.imageAnalytics}
                                        heading={'Smart Task Routing'}
                                        subtext={'Integrate with task management systems to dynamically assign and route tasks based on staff location and expertise, optimizing production floor efficiency.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Real-Time Location Insights'}
                                        subtext={'Track manufacturing staff in real-time for seamless workflow coordination, improved assembly line efficiency, and optimized production processes.'}
                                    />
                                </div>

                            </div>
                        </div>
                        <div ref={sectionsRefs.assettracking} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Manufacturing</p>
                                            <h2 className="text-hColor text-xl underline fB">Asset Tracking</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/asset-tracking')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Forget blind spots and production delays in manufacturing! Keep your operations flawless with real-time insights and precise tracking, while AI-driven facial recognition secures your high-priority zones. Transform every facet of your manufacturing process with cutting-edge AI!</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.product}
                                        heading={'Inventory Mastery'}
                                        subtext={'Elevate your inventory game with intelligent automation and instant alerts for stock levels and expiration, ensuring your production line never misses a beat.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.materialdemandicon}
                                        heading={'Predictive Raw Material Demand'}
                                        subtext={'Utilize advanced forecasting to predict raw material needs, optimizing supply chain management and preventing production delays.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.productList}
                                        heading={'Product Placement'}
                                        subtext={'Leverage state-of-the-art computer vision to ensure precise placement and identification of components, maintaining perfect alignment with production plans.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.dashboardicon}
                                        heading={'Insight-Powered Dashboard'}
                                        subtext={'Unleash tailored analytics with bespoke reports on asset usage and production flow, optimizing resource allocation and driving unparalleled manufacturing efficiency.'}
                                    />
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <section className="bg-[#F8F8F8] py-16">
                <div className="container mx-auto flex flex-col gap-12">
                    <div className="title block lg:flex justify-between">
                        <div>
                            <div className="mb-10">
                                <h2 className="text-2xl fB mb-1">Automate, Streamline, and Propel Your Production with AI.</h2>
                                <h2 className="text-3xl fB text-[#C7C7C7] max-w-lg">From sweat to smart, Modernize your operations for peak efficiency.</h2>
                            </div>
                        </div>
                        <p className="fR text-sm max-w-xl mt-6 lg:mt-0">Proglint leads manufacturing innovation by integrating profound industry insight with state-of-the-art computer vision and digital tools.</p>
                    </div>
                    <div>
                        <p className="fR mb-5">Research Highlights</p>
                        <div className="section4 bg-background">
                            <div className="w-full gap-3">
                                <Slider {...settings}>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">AI <br /> Attendance</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/ai-attendance')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col flex-grow gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Simplify attendance tracking with facial recognition for fast, accurate recording. Your face is your pass- just walk in, verify, and move on.</p>
                                                <img src={PATH.img.Featuresfacialrecognition} alt="facial-recognition" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Asset <br /> Tracking</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/asset-tracking')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Track Your Assets, Inside and Out. Transform Your Inventory with 360° Robot Retail Asset Management.</p>
                                                <img src={PATH.img.SolutionAssettracking} alt="asset-tracking" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">AI for <br /> Quality Control</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/ai-for-quality-control')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Quality is not just an outcome, it’s a commitment ensure yours with AI precision.</p>
                                                <img src={PATH.img.SolutionQualityControl} alt="quality-control" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Loss <br /> Prevention </p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/loss-prevention')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col flex-grow gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">See It, Stop It, Save It!  Redefine Retail security with our AI-driven Self-checkout, halting fraud in its tracks. No more lines, no more delays - just grab, scan, and let the customers go with total peace of mind.</p>
                                                <img src={PATH.img.SolutionLossprevention} className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div> */}

                                </Slider>
                            </div>
                        </div>
                        {/* <SolutionCarousel settings={settings} /> */}
                    </div>
                </div>
            </section>
            <FooterContact />
        </div>
    )
}
export default Manufacturing;