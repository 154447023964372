import React from "react";

const BlogCard = ({ ImgPath,Date,Heading, ParaContent, profileContent}) => {

    return (
        <div className="h-full" >
            <div className="h-full flex flex-col" >
                <img className="rounded-md w-full h-96 object-cover" src={ImgPath} alt="card_img" />
                <div className="pt-5 flex flex-col grow">
                    <p className="text-sm text-start font-medium fR uppercase text-primary">{Date}</p>
                    <h3 className="text-lg fSB text-start capitalize text-hColor font-medium mb-2">{Heading}</h3>
                    <p className="text-sm fR text-start text-[#5d5d5d]">{ParaContent}</p>
                </div>
                    {profileContent}
            </div>
        </div>
    );
}
export default BlogCard