import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import { TagIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
const Loss_Prevention = () => {


    const [showAccordion, setShowAccordion] = useState({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
    const handleAccordion = (accordion) => {

        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false })
                break;
            case 6:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6 })
                break;
        }

    }
    return (
        <div>
            <div className="home_banner relative">
                <div className="w-full h-full">
                    <img src={PATH.img.solution_healthcare} alt="banner" className="h-full w-full object-cover slide active" />
                </div>
                <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                <div className="w-full z-[2] absolute top-[30%] lg:top-[40%]">
                    <div className=" container mx-auto">
                        <div className="mb-14">
                            <div className="flex gap-1 items-center mb-2">
                                <TagIcon className="text-[#7A7A7A] w-5 h-5" />
                                <p className="text-xs fB text-[#7A7A7A]">LOSS PREVENTION</p>
                            </div>
                            <h1 className="text-2xl lg:text-5xl fR text-white">AI-driven Loss Prevention in Healthcare</h1>
                            <p className="text-base max-w-2xl fR text-white mt-4">AI Secures Healthcare Inventory. Enhance healthcare security with our AI-powered system, halting fraud effectively. No more errors or delays - just trust, verify, and ensure peace of mind for patients and staff.</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <h2 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center">AI-Powered Healthcare Innovation- Superior Loss Prevention Meets Accelerated Patient Flow</h2>
                <p className="text-sm fR text-start lg:text-center text-pColor my-1 mx-auto">Proglint delivers a breakthrough in healthcare efficiency with our advanced self-checkout system, powered by computer vision. Patients benefit from a swift and intuitive checkout experience, while intelligent systems minimize wait times in the waiting room. Robust loss prevention features safeguard critical medications, allowing your staff to focus more on providing exceptional care. Enjoy a more efficient and secure healthcare environment, where technology enhances both patient experience and operational excellence.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Benefits</h2>
                        <p className="fR text-sm my-3">Our innovative self-checkout system leverages computer vision to ensure a smooth patient experience while safeguarding your inventory with advanced security measures.  This is the future of efficient and secure healthcare operations.</p>
                        <div className="my-6">
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Lowered inventory losses</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(1)}>
                                        {showAccordion.accordion1 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Protect valuable healthcare resources and reduce financial setbacks with robust surveillance measures.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Stop Theft, Prioritize Care</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(2)}>
                                        {showAccordion.accordion2 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Prevent the theft of vital medications and equipment, giving you peace of mind and protecting your valuable assets.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Improved workflow efficiency</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Enhance processes and support long-term growth in healthcare facilities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Patient Monitoring</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">By monitoring patient wait times, healthcare providers can optimize appointment scheduling, reducing long wait times and enhancing patient satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Resource Allocation</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Real-time data on patient flow allows doctors to adjust their schedules and prioritize urgent cases, optimizing resource utilization and improving overall clinic efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Maximized revenue</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(4)}>
                                        {showAccordion.accordion4s ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Minimized losses + optimized operations = unstoppable growth. Fuel your retail dominance!</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div>
                        <img src={PATH.img.solution_healthcare_img1} alt="healthcare-img" />
                    </div>
                </div>
            </section>
            <div className="solution-industry-banner">
                <div className="container mx-auto grid grid-cols-2 lg:grid-cols-3 py-20">
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.IndustryIcon} alt="industry-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Industry</h2>
                            <p className="fR text-xs text-white">Retail & Healthcare</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.DeliveryModalIcon} alt="delivery-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Delivery Model</h2>
                            <p className="fR text-xs text-white">ODC</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.TechnologyIcon} alt="technology-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Technologies</h2>
                            <p className="fR text-xs text-white">Open CV,</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.DomainbannerIcon} alt="domain-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Domain</h2>
                            <p className="fR text-xs text-white">Media & Telcos</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.EffortIcon} alt="effort-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Effort and Duration</h2>
                            <p className="fR text-xs text-white">Ongoing</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <img src={PATH.img.solution_healthcare_img2} alt="healthcare-img" />
                    </div>
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Features</h2>
                        <p className="text-sm mt-2 fR">AI securely guards a world where patients whiz through checkout and medications. Upgrade your healthcare checkout process with our advanced computer vision-powered self-checkout, ensuring top-notch security and effortless patient transactions.</p>
                        <div className="relative">
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Smart Algorithms</h2>
                                    <p className="text-sm fR mt-1">Pinpoints inconsistencies between scanned items and patient records, immediately flagging suspicious activity to deter theft and loss.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Insight Central</h2>
                                    <p className="text-sm fR mt-1">Go beyond basic reports. Dive deep with interactive insights & custom dashboards.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Seamless Integration</h2>
                                    <p className="text-sm fR mt-1">Customize detection algorithms using the Visual No-Code Editor for seamless integration.</p>
                                </div>
                            </div>
                            {/* <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Fast Setup, Sharp Vision</h2>
                                    <p className="text-sm fR mt-1">Easy Installation & Top-Tier Cameras Deliver Precise Product Details & Quantities.</p>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
            </section>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Challenge</h2>
                    <div>
                        <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Healthcare data is susceptible. Robust security measures must be in place to ensure patient information remains confidential and complies with data privacy regulations. Implementing an AI-based system requires an initial investment in software and hardware. Additionally, integrating it seamlessly with existing workflows can be complex and require staff training. </p>
                        {/* <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p> */}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Success</h2>
                    <p className="text-sm fR text-start text-pColor my-1 mx-auto">Proglint ushers in a new era of healthcare with our AI-driven loss prevention system. Preventing medication theft and inventory shrinkage, we've slashed costs and boosted staff efficiency, allowing more focus on patient care. Our AI doesn't just stop theft and financial losses, it analyzes data to identify patterns, ensuring ongoing improvements in security and resource management.
                    </p>
                </div>
            </div>
            <FooterContact />
        </div>
    )
}
export default Loss_Prevention