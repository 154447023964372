import React from "react";
import Clientresult from "../../component/ClientrResult";
import { PATH } from "../../globals/app-constants";
const Projects1 = () => {
    const story = [
        {
            heading:'At a Glance',
            description1:'A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashiers counter are compared with the customers computer vision cart. Any items that are not billed at the cashiers counter will be treated as stolen. This will help detect theft and reduce loss for shops.',
            description2:'A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashiers counter are compared with the customers computer vision cart. Any items that are not billed at the cashiers counter will be treated as stolen. This will help detect theft and reduce loss for shops.'
        },
        {
            heading:'The Story',
            description1:'A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashiers counter are compared with the customers computer vision cart. Any items that are not billed at the cashiers counter will be treated as stolen. This will help detect theft and reduce loss for shops.',
            description2:'A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashiers counter are compared with the customers computer vision cart. Any items that are not billed at the cashiers counter will be treated as stolen. This will help detect theft and reduce loss for shops.',
            description2:'A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashiers counter are compared with the customers computer vision cart. Any items that are not billed at the cashiers counter will be treated as stolen. This will help detect theft and reduce loss for shops.'
        }
    ]
    return (
        <div>
            <Clientresult
            title={'A loss prevention solution for stores, where the items picked'}
            titleDescription={'A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashier counter are compared with the customer computer vision cart. Any items that are not billed at the cashier counter will be treated as stolen. This will help detect theft and reduce loss for shops.'}
            casestudyThumbnail={PATH.img.walkin}
            businessGoalImg={PATH.img.walkin}
            story={story}
            />
        </div>
    );
};
export default Projects1;