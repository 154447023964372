import React, { useState } from "react";
import { PATH } from "../globals/app-constants";
import FooterContact from "./FooterContact";
import Btn from "../component/Btn";
import Industries from "./Industries";
import ContentBanner2 from "./ContentBanner2";
import Faqs from "./Faqs";
const WebDevelopment = () => {
    const techStack = ["React JS", "Angular", "Flutter",]
    const projectOverview = [
        {
            heading: "Paul’s Fashion",
            discription: "Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.",
            img: PATH.img.paulsReact,
            view_path: '/case-studies/pauls-fashion'
        },
        {
            heading: " Intel Vision Checkout",
            discription: "Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.",
            img: PATH.img.intelAngular,
            view_path: '/case-studies/intel-vision-checkout'
        },
        {
            heading: "Treux",
            discription: "Treux simplifies medical records for patients and doctors. Our user-friendly app enables patients to manage their medical history, while doctors access records securely with patient consent. Proglint ensured a seamless, secure platform, enhancing patient-doctor interactions and delivering an intuitive app for efficient medical record management.",
            img: PATH.img.treuxFlutter,
            view_path: '/case-studies/treux-app'
        },
    ]
    const [tab, setTab] = useState(1)
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-[10] absolute top-[35%] lg:top-[25%] lg:bottom-[25%]">
                        <h1 className="banner_title font-semibold text-white w-auto lg:max-w-2xl">Web Development Company</h1>
                        {/* <p className="my-4 font-normal text-white text-base lg:text-lg w-auto lg:max-w-2xl">Unleash the Power of Web Applications, Redefining Digital Excellence!</p> */}
                    </div>
                </div>
                <div className="absolute right-0 w-full lg:w-4/6">
                    <img src={PATH.img.webBanner} alt="banner" className="w-full h-full object-contain" />
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Front End Web Development Service</h2>
                <p className="text-base text-start text-pColor my-1">Our expertise in web application development ensures excellence in web and mobile app development services, making us a top app development company in India.</p>
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.webComputer} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Front-end Architecture &
                            Design</h2>
                        <p className="text-pColor text-sm py-2">Constructing the foundation and look of your web and mobile apps for a top-notch user experience. Proglint, your trusted front-end architecture and design partner.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.development} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">SPA Application
                            & Development</h2>
                        <p className="text-pColor text-sm py-2">Build cutting-edge Single Page Applications (SPAs) that captivate your users. We specialize in SPA application development for web and mobile, delivering top-quality experiences.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.uiux} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">UI/UX App
                            Development</h2>
                        <p className="text-pColor text-sm py-2">Craft user-centric interfaces and seamless experiences with our UI/UX App Development services. We are a leading app development company dedicated to enhancing user satisfaction.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.appdev} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">PWA App
                            Development</h2>
                        <p className="text-pColor text-sm py-2">Expand your online presence with our PWA App Development. We are a leading app development company specializing in creating progressive web apps for enhanced mobile experiences.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.ampdev} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">AMP App
                            Development</h2>
                        <p className="text-pColor text-sm py-2">Experience lightning-fast loading and seamless user interactions with our AMP App Development services. Our expert app development company crafts accelerated mobile pages for superior web and mobile experiences.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.html} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">HTML5 / CSS
                            Development</h2>
                        <p className="text-pColor text-sm py-2">Bring life to your web and mobile applications with our HTML5/CSS development expertise. As a leading web development company, we create visually stunning and high-performing digital solutions to enhance user experiences.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto my-10 lg:my-16">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3 max-w-xl">Unlock Exclusive Perks with Your Web App Development Partner</h2>
                    <p className="text-base text-start text-pColor mb-3">Our team, comprised of experts in Python development, React Native development, Java development, and more, ensures that your project receives the highest standards of quality and performance. With a focus on technology and innovation, we stand out among software development companies in India, providing IT staffing services, staff augmentation, and a range of solutions for a diverse clientele.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.User} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Maestro Team</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Experts who orchestrate your success with unparalleled app and web development expertise.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.design} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Compelling Designs</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Transforming visions into visually captivating and user-friendly digital experiences that inspire engagement and joy.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.BusinessService} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Business-Oriented Service</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our services are tailored to drive business growth, delivering solutions that align with your goals and objectives.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.growth} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Growth Hacking</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Unlocking Growth Potential - Our growth hacking strategies are designed to propel your business forward, leveraging data-driven insights and innovative approaches.</p>
                    </div>
                </div>
            </div >
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start lg:text-center text-hColor font-bold mb-3">Leading Front-End Web Development Expertise</h2>
                    <p className="text-base text-start lg:text-center text-pColor mb-1">Our full-stack front-end web development expertise services, including app development, deliver responsive applications that meet your business requirements and keep you ahead in the competitive landscape.</p>
                </div>
                <div className="flex gap-4 justify-center items-center my-5 lg:my-8">
                    {
                        techStack.map((text, i) => (
                            <button className={`font-semibold text-hColor py-2 px-2 ${i + 1 === tab ? "border-b-2 border-gray-900" : "border-b-2 border-gray-100"} `} onClick={() => setTab(i + 1)}>{text}</button>
                        ))
                    }
                </div>
                {
                    projectOverview.map((obj, i) => (
                        <div className={i + 1 === tab ? "" : "hidden"}>
                            <div className="lg:flex lg:gap-10 my-6 lg:my-10">
                                <div className="w-full lg:w-2/5">
                                    <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-bold mb-3">{obj.heading}</h2>
                                    <p className="text-sm md:text-base text-start text-pColor mb-6">{obj.discription}</p>
                                    <Btn LinkPath={(obj.view_path)} value={'View All'} bgColor={'white'} />
                                    {/* <Button LinkPath={(obj.view_path)} value={'View All'} className={'bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none px-5 text-hColor font-semibold my-4'} /> */}
                                </div>
                                <div className="w-full lg:w-3/5 mt-6 lg:mt-0 h-[420px]">
                                    <img src={obj.img} alt="project" className="w-full h-full object-fill" />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="development bg-background py-12">
                <div className="container mx-auto lg:flex gap-10 items-center">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 text-start text-hColor font-bold lg:text-3xl lg:leading-normal mb-4 lg:mb-0 ">Your trusted IT staffing company
                            providing skilled professionals
                            for your projects</h2>
                    </div>
                    <div className="w-full lg:w-3/5 grid grid-cols-2 md:grid-cols-3">

                        <div className="development_item my-4 md:my-2">
                            <h2 className="text-lg font-semibold ml-3 pb-3">Languages</h2>
                            <div className="development_item_content text-lg font-normal text-pColor">Java</div>
                            <div className="development_item_content text-lg font-normal text-pColor">.Net</div>
                            <div className="development_item_content text-lg font-normal text-pColor">PHP</div>
                            <div className="development_item_content text-lg font-normal text-pColor">MEAN</div>
                            <div className="development_item_content text-lg font-normal text-pColor">MERN</div>
                        </div>
                        <div className="development_item my-4 md:my-2">
                            <h2 className="text-lg font-semibold ml-3 pb-3">Frameworks</h2>
                            <div className="development_item_content text-lg font-normal text-pColor">Angular Js</div>
                            <div className="development_item_content text-lg font-normal text-pColor">React Js</div>
                            <div className="development_item_content text-lg font-normal text-pColor">Node Js</div>
                            <div className="development_item_content text-lg font-normal text-pColor">ASP.net</div>
                            <div className="development_item_content text-lg font-normal text-pColor">Spring</div>
                        </div>
                        <div className="development_item my-4 md:my-2">
                            <h2 className="text-lg font-semibold ml-3 pb-3">Database</h2>
                            <div className="development_item_content text-lg font-normal text-pColor">MySQL</div>
                            <div className="development_item_content text-lg font-normal text-pColor">Mongo DB</div>
                            <div className="development_item_content text-lg font-normal text-pColor">Node Js</div>
                            <div className="development_item_content text-lg font-normal text-pColor">PostgreSQL</div>
                            <div className="development_item_content text-lg font-normal text-pColor">Oracle</div>
                        </div>

                    </div>
                </div>
            </div>
            <Industries />
            <ContentBanner2 />
            <Faqs
                Question1={`Will I have my source code if I opt for application development from your mobile app development agency?`}
                Answer1={`Yes, when you choose Proglint for application development, you will have access to your source code. We believe in transparency and provide our clients with the source code, ensuring you have full control over your app's development and future modifications.`}
                Question2={`Why choose Proglint as a mobile app development company?`}
                Answer2={`Choose Proglint as your mobile app development company because we offer a team of skilled professionals with expertise in various technologies, including React Native, Java, Unity3D, and more. We focus on creating high-quality, user-friendly apps tailored to your specific needs, ensuring your project's success. Moreover, our proven track record of success with our global clientele speaks volumes about our journey and client's trust.`}
                Question3={`How much will it cost to build a mobile app?`}
                Answer3={`The cost of building a mobile app varies widely depending on the app's complexity, features, and platform. At Proglint, we offer competitive pricing, and our team works closely with you to provide a customized quote based on your project requirements. Please feel free to contact us for a detailed estimate today!
`}
                Question4={`Which technologies does your mobile app development team work on?`}
                Answer4={`Our mobile app development team works with a range of technologies, including React Native, Java, Unity3D, and more. We adapt the technology stack to match the specific needs of your project, ensuring the best possible performance and user experience.`}
                Question5={`What are the different types of mobile apps?`}
                Answer5={`There are different types of mobile apps, including native, hybrid, and progressive web apps. Native apps are platform-specific and offer the best performance. Hybrid apps work on multiple platforms but may sacrifice some performance. Progressive web apps are web-based and offer cross-platform functionality but with limitations. Proglint can help you choose the right type based on your project goals and target audience.`}
            />
            <FooterContact />
        </div>
    )
}
export default WebDevelopment