import React from "react";
import { PATH } from "../globals/app-constants";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";
import Btn from "./Btn";

const BlogSection = () => {

    return (
        <div className="blog py-14">
            <h1 className="fR text-center text-xl lg:text-3xl">Our Latest Insights</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto py-6 md:py-10">
            <div className="md:border-r md:border-gray-300 md:pr-6">
            <BlogCard 
                
                ImgPath={PATH.img.blogImg} 
                Date={'23 JUN 2024'}
                Heading={'Harnessing the Power of Progressive Web Apps(PWAs) for Enhanced User Experience?'} 
                ParaContent={'In the present-day digital environment, competing for the attention of users is more challenging than ever. The web development of technology has changed not only how we access data and services but also how we use our time. As we explore the online world...'}
                profileContent={
                    <>
                        <button className="card_find_btn my-5  underline text-xs fR text-hColor text-start" onClick={() => window.location.reload()}><Link to={('/blog/harnessing-the-power-of-progressive-web-apps')}>Find out more</Link></button>
                    </>
                } />
            </div>
                <div className="md:pl-6">
                <BlogCard 
                ImgPath={PATH.img.blogImg2} 
                Date={'23 JUN 2024'}
                Heading={'The innovation of computer vision with AI and ML'} 
                ParaContent={'The concept of computer vision refers to the capability of a computer to read and understand virtual images, a capability made possible through the improvement of artificial intelligence (AI) and system-gaining knowledge of (ML). '}
                profileContent={
                    <>
                        <button className="card_find_btn my-5  underline text-xs fR text-hColor text-start" onClick={() => window.location.reload()}><Link to={('/blog/the-innovation-of-computer-vision-with-ai-ml')}>Find out more</Link></button>
                    </>
                } />
                </div>
            </div>
            <div className="flex justify-center">
            <Btn LinkPath={('/blog')} value={'See All Insights'} bgColor={'white'} />
            </div>
        </div>

    );
}
export default BlogSection