import React, { useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link, useNavigate } from "react-router-dom";
import Faqs from "../../component/Faqs";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

const ShapeSizeEstimation = () => {
    const [tab, setTab] = useState('Manufacturing');
    const navigate = useNavigate()
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 2,
        slidesToScroll: 1,
    }
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-10 absolute bottom-6 lg:top-[30%]">
                        <h1 className="banner_title fSB text-white w-auto md:w-auto lg:max-w-xl mb-4">Shape and Size Estimation</h1>
                        <p className="text-sm text-white lg:max-w-2xl">A machine vision breakthrough for identifying and measuring object dimensions, enabling systems to see and understand the world like humans.</p>
                    </div>
                </div>
                <div className="absolute">
                    <img src={PATH.img.FeaturesShapeEstimation} alt="banner" className="w-screen h-screen lg:h-full object-cover" />
                    <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 left-0 h-full w-full object-cover" />
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-xl">Use cases of Shape and Size Estimation</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3">Extracting precise measurements and geometric features from visual data, this technology unlocks endless possibilities across industries. From manufacturing floors to medical imaging suites, the ability to accurately quantify shape and size drives innovation, efficiency, and quality. Whether ensuring product consistency, optimizing production lines, or aiding in medical diagnosis, shape and size estimation is a game-changer for transformative applications.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.medicineicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Pill Counting and Inspection</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Accurate shape and size estimation can be used for high-speed pill counting and inspection, ensuring product quality and preventing medication errors.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.qualitycontrolicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Tablet Quality Control</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Ensure top-notch pharmaceutical quality by scrutinizing tablet shape and size, catching inconsistencies before they reach consumers and maintaining high standards.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.product} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Conveyor Belt Product Monitoring</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Real-time shape and size estimation can be used to inspect products on conveyor belts, identifying defects, and ensuring product consistency. By comparing product shapes and sizes to reference models, manufacturing processes can be monitored for anomalies, leading to improved product quality.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.design} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Flaw Detection in Retail</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Shape and size estimation can be used to automatically categorize retail products, such as clothing or shoes, based on their dimensions, facilitating inventory management and online shopping experiences. Identifying defects in retail products, such as tears or missing components, can be achieved through shape and size analysis, ensuring customer satisfaction.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.easyIntegration} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Fruit and Vegetable Sorting</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Shape and size estimation can be used to sort fruits and vegetables based on their dimensions, optimizing packing and distribution processes. Identifying defects in agricultural products, such as blemishes or deformities, can be achieved through shape and size analysis, improving product quality control.</p>
                    </div>
                </div>
            </div >
            <div className="bg-black pb-4 lg:pb-20">
                <div className="flex container mx-auto gap-4 flex-row overflow-auto pt-28">
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Manufacturing')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Manufacturing' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.ManufacturingtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Manufacturing' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Manufacturing</p>
                    </div>
                    {/* <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Education')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Education' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.EducationtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Education' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Education</p>
                    </div> */}
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Healthcare')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Healthcare' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.HealthcaretabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Healthcare' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Healthcare</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Retail')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Retail' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.RetailtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Retail' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Retail</p>
                    </div>
                </div>
                {tab == 'Manufacturing' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Identify shape and size flaws to ensure only high-quality products pass through.</li>
                                    <li className="text-base fR mb-3 text-white">Classify products based on shape and size for better inventory management.</li>
                                    <li className="text-base fR mb-3 text-white">Collecting detailed data on product dimensions for statistical process control.</li>
                                    <li className="text-base fR mb-3 text-white">Identify shape and size flaws to ensure only high-quality products pass through.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Asset Tracking</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/asset-tracking')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">AI for Quality Control</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-for-quality-control')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {/* {tab == 'Education' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Key Features</h1>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Elevate athletic performance with precise speed measurements.</li>
                                    <li className="text-base fR mb-3 text-white">Enhance campus safety by monitoring vehicle speeds.</li>
                                    <li className="text-base fR mb-3 text-white">Promote safer driving with detailed habit analysis.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize racing strategies with vehicle speed data for car and bike training programs.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Proglint AI Solution</h1>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4" >
                                    <h1 className="fR text-base text-white">AI Attendance</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h1 className="fR text-base text-white">Safety and Security</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions-safety-security')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                } */}
                {tab == 'Healthcare' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Automate accurate pill counting by analyzing tablet shapes and sizes.</li>
                                    <li className="text-base fR mb-3 text-white">Assessing the shape and size of drug particles for formulation and delivery optimization.</li>
                                    <li className="text-base fR mb-3 text-white">Visualizing and measuring surgical targets for improved procedure accuracy.</li>
                                    <li className="text-base fR mb-3 text-white">Ensure pharmaceutical quality by verifying tablet shapes and sizes.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Pill Counting</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/pill-counting')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Medical Image Analysis</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/medical-image-analysis')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Retail' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Categorize products by shape and size for a well-organized store.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize space by evaluating the shape and size of equipment and furniture.</li>
                                    <li className="text-base fR mb-3 text-white">Providing accurate size recommendations for online shoppers.</li>
                                    <li className="text-base fR mb-3 text-white">Identify shape and size defects in retail products to ensure only high-quality items are displayed.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Vision Analytics</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/vision-analytics')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Loss Prevention</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/loss-prevention')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Don't Just Digitize, Be Intelligent</h2>
                <p className="text-base text-start fR text-pColor my-1">Proglint Makes AI Work for You. Digitize every aspect of your business and uncover opportunities for enhancement at every stage using our advanced AI and computer vision solutions.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.playplugicon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Plug-and-Play Perfection</h2>
                        <p className="text-pColor text-sm py-2">Seamless AI integration with instant, hassle-free setup.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.costSaving} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Smart Savings</h2>
                        <p className="text-pColor text-sm py-2">Slash operating costs by up to 40% with our AI-driven solutions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.security} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Data Security</h2>
                        <p className="text-pColor text-sm py-2">Safeguard your data with top-tier privacy and security protocols.</p>
                    </div>
                </div>
            </div>
            <Faqs
                Question1={`What is shape and size estimation?`}
                Answer1={`Shape and size estimation is a computer vision technique that involves extracting precise measurements and geometric features from images or videos. By analyzing visual data, it allows systems to accurately determine the dimensions, contours, and overall shape of objects.`}
                Question2={`How does AI enhance shape and size estimation in computer vision?`}
                Answer2={`AI enhances shape and size estimation by leveraging advanced algorithms that process visual data with high precision. Machine learning models are trained to recognize and measure geometric features, ensuring accurate and reliable estimations across various applications, from manufacturing to healthcare.`}
                Question3={`What industries benefit most from AI-based shape and size estimation?`}
                Answer3={`Industries such as manufacturing, healthcare, retail, and logistics benefit significantly from AI-based shape and size estimation. It enhances operational efficiency, improves accuracy in measurements, and supports critical functions like defect detection, inventory management, and patient diagnostics.`}
                Question4={`Can AI shape and size estimation handle varying object shapes and sizes?`}
                Answer4={`Yes, AI shape and size estimation can handle a wide range of object shapes and sizes. Advanced algorithms and machine learning models are designed to adapt to different geometries and deformable objects, ensuring precise measurements even with complex and irregular forms.`}
                Question5={`How does shape and size estimation contribute to enhanced product quality?`}
                Answer5={`Shape and size estimation ensures product quality by detecting deviations from design specifications. AI technology identifies and measures defects or inconsistencies in real-time, enabling proactive quality control and reducing the likelihood of defective products reaching customers.`}
            />
            <FooterContact />
        </div>
    )
}
export default ShapeSizeEstimation