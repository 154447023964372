import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const CVShield = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Prevents Attendance Falsification: Robust facial recognition using OpenCV minimizes the chances of students manipulating attendance records.",
        point2: "Real-time Notifications: Instant attendance updates empower students with transparent information about their attendance status.",
        point3: "User-Friendly Admin Application: A user-centric admin interface simplifies the management of biometric data, enhancing system usability."
    },
    {
        title: 'Business challenge',
        point1: "Privacy Concerns: Addressing privacy worries related to facial data capturing and processing using advanced encryption and secure protocols.",
        point2: "Accuracy and Reliability: Ensuring precise attendance tracking despite diverse lighting conditions or demographic variability.",
        point3: "Initial Implementation Costs: Overcoming expenses associated with software development, hardware setup, and system configuration.",
        point4: "User Acceptance and Maintenance: Promoting user adoption and providing continuous support for system maintenance."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Privacy Measures Implementation: Implementing stringent privacy protocols and educating stakeholders about the encryption techniques used to protect facial data instilled trust and compliance with data protection regulations.",
        point2: "Algorithm Refinement for Accuracy: Constant refinement and testing of the face recognition algorithms were conducted to overcome lighting discrepancies, diverse facial expressions, and demographic variations, enhancing the system's precision.",
        point3: "Cost Optimization Strategies: Employing phased implementation and exploring cost-effective hardware and software alternatives helped manage initial expenses and reduce financial strain.",
        point4: "User Training and Support: Conducting comprehensive user training sessions and providing ongoing technical support ensured smoother adoption and reduced resistance to change, addressing user acceptance and support concerns effectively."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "The Face Recognition-based Attendance System stands tall as a testament to technological innovation meeting real-world needs. Its seamless automation, fortified security, and unwavering accuracy symbolize a future where pioneering solutions streamline processes, safeguard privacy, and inspire a new era of efficiency and trust. This system exemplifies the power of merging cutting-edge technology with a commitment to precision, privacy, and progress.",
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.CVShieldBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Automated Face Recognition Attendance System'}
                domainText={'Education, Biometrics, Automation'}
                durationText={'6 months, 24 man-months'}
                technologyText={'OpenCV, Python, ArcFace Facial Recognition Algorithms'}
                overviewText={'Proglint led the development of an advanced Face Recognition-based Attendance System, transforming traditional attendance tracking methods. Utilizing sophisticated facial recognition algorithms such as OpenCV and ArcFace, the system efficiently captures, analyzes, and verifies individuals faces, ensuring precise attendance recording.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.IntelThumbnail} RetailHeading={'Payment Processing'} SubHead={'Intel Vision Checkout'} ParaContent={`Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/intel-vision-checkout')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.cosfymartThumbnail} RetailHeading={'E-commerce'} SubHead={`Cosfymart`} ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cosfymart-app')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/jedidahs')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default CVShield;