import React from "react";
import FooterContact from "./FooterContact";
import { PATH } from "../globals/app-constants";
import ContentBanner1 from "./ContentBanner1";
import { Link } from "react-router-dom";
const Android = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <h1 className="white_banner_title font-semibold text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">Android App Development Company</h1>
                    <div className="w-full h-1/2 lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.androidLogo} alt="logo" className="block mx-auto w-40 lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="device my-5 lg:my-10 container mx-auto">
                <div>
                    <p className="text-xl md:px-0 lg:text-3xl text-center text-hColor font-bold mb-3">Transform your ideas into reality</p>
                    <p className="text-base text-center text-pColor mb-1">We have crafted over 120 high-performing Android apps, showcasing versatility and excellence across diverse industries and functionalities.</p>
                </div>
                <img src={PATH.img.androidDevice} alt="device-img" className="block m-auto py-6 lg:py-12" />
            </div>
            <div className="service_section container mx-auto  mt-3 mb-10 lg:mt-14 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3 max-w-xl">Fueling business growth with avant-garde Android app solutions</h2>
                    <p className="text-base text-start text-pColor mb-3">We recognize the indispensable role of mobile applications in the contemporary digital milieu. Our proficient team of Android app developers stands at the forefront, adept at crafting solutions that redefine user experiences—characterized by robust code, captivating UI/UX, responsiveness, scalability, and impeccable functionality. Backed by expertise in the latest Android technology stack, we leverage proven app development software to deliver intuitive solutions. The cumulative experience of our Android Mobile App Development Team positions us as a paragon of excellence, consistently achieving success and garnering accolades for our clients.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.settingIdea} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Customer-Centric Approach</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our development ethos revolves around you, ensuring our Android apps align seamlessly with your vision and user expectations.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.delivering} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Delivering Quality for Success</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Excellence is our hallmark; each Android app we craft is a testament to our commitment to delivering high-quality solutions that guarantee success.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.webLaptop} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Play Store Visibility Boost</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our products not only meet but exceed industry standards, ensuring your Android apps stand out on the Play Store, driving visibility and downloads.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.endUser} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">User-Centric Design</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">In-depth user research guides our development process, enabling us to create Android apps that resonate with end-users, ensuring satisfaction and engagement.</p>
                    </div>
                </div>
            </div >
            <div className="service_projects container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-6 lg:mb-12 max-w-xl">Illuminating the path to success for our visionary clients</h2>
                <div className="lg:flex gap-10">
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-[#6F1A07]  relative overflow-hidden rounded-xl">
                        <img src={PATH.img.shopAppWeb} alt="cover-pic" className="absolute object-cover w-full h-full lg:w-auto lg:h-auto lg:top-0 -right-32 lg:right-0" />
                        <div className="bg_gradient_brown absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">Shop Box Project</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Optimize shop operations with our innovative inventory management system. Proglint's tech-driven collaboration brings precision to stock control and customer interactions, ensuring efficient store management and lasting cost-effectiveness.</p>
                            <h2 className="text-white text-lg font-normal underline cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/shopapp-app')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-2/3 bg-[#646E78] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.NNBweb} alt="cover-pic" className="absolute top-0 -right-40 lg:right-0" />
                        <div className="bg_gradient_gray absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">NNB Project</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Transform your inventory control and e-commerce strategy with our innovative solutions. Proglint's custom applications for Nourish & Bloom incorporate comprehensive web and mobile functionalities, seamlessly integrating with SAP ERP. Discover streamlined operations and cost-efficient solutions for your business growth.</p>
                            <h2 className="text-white text-lg font-normal underline cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/nnb')}>Case Study</Link></h2>
                        </div>
                    </div>
                </div>
                <div className="lg:flex lg:gap-10 lg:mt-10">
                    <div className="w-full mb-6 lg:w-2/3 bg-secondary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.cvsCoverimg} alt="cover-pic" className="absolute top-0 -right-20 lg:right-0" />
                        <div className="bg_gradient_red absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">CVS Pharmacy</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Revolutionizing Retail with Smart Fridge Technology. Proglint's innovative Smart Fridge Management System enhances shopping experiences, streamlines inventory, and offers valuable insights. Witness the future of retail automation.</p>
                            <h2 className="text-white text-lg font-normal underline cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/cvs')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-primary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.humanCoverimg1} alt="cover-pic" className="absolute bottom-12 right-0" />
                        <div className="px-6 cursor-pointer py-10 absolute bottom-0" onClick={() => window.location.reload()}>
                            <Link to={('/contact')}>
                                <div className="flex flex-row gap-1">
                                    <h2 className="text-white text-lg font-normal underline">Say Hi</h2>
                                    <img src={PATH.img.icons.hello} alt="hello" className="w-8 h-8" />
                                </div>
                                <h2 className="text-white text-lg font-normal underline">to Discuss a Project</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  my-10 lg:my-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3 max-w-xl">How Proglint Boosts Your Business with Android App Development</h2>
                    <p className="text-base text-start text-pColor my-3">Discover growth with Proglint's Android app development. We blend expertise, strategy, and flawless execution to create powerful digital solutions. From education to food and healthcare, our tailored apps guarantee user engagement and business success. Harness Android's popularity for maximum benefits. Empower your business with our Android app solutions—seamless, feature-packed, and crafted for diverse industries. Elevate user experiences with Proglint's expertise.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.mobile} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Low Investment</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Minimize costs without sacrificing app quality. Proglint's Android app development optimizes resources, employing efficient coding practices and cost-effective technologies for an economical yet robust solution.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.designTool} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Easy Customization</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Mold your Android app effortlessly. Leveraging the versatility of Java and Kotlin, our development team crafts highly customizable apps tailored to your business specifics, ensuring a seamless and personalized user experience.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.security} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Security & Distribution</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Ensure app integrity and expansive reach. Proglint integrates advanced security protocols, including SSL encryption, while optimizing app distribution through the Google Play Store, guaranteeing a secure and widely accessible app presence.</p>
                    </div>
                </div>
            </div >
            <ContentBanner1 />
            <FooterContact />
        </div>
    )
}
export default Android