import React from "react";
import Btn from "./Btn";
const FooterContact = () => {
    return (
        <div className="py-10 md:py-28 bg-primary text-white">
            <div className="grid grid-cols-l md:grid-cols-2 gap-4 container mx-auto">
                <div className="md:border-r">
                    <h1 className="text-sm lg:text-3xl text-center lg:text-start mb-6 lg:mb-10 fB flex justify-center footer-contact-text">Quickstart Your AI journey</h1>
                    <div className="flex justify-center">
                    <Btn LinkPath={('/contact')} value={'Partner with Us'} bgColor={'black'} />
                    </div>
                </div>
                <div>
                    <h1 className="text-sm lg:text-3xl text-center lg:text-start mb-6 lg:mb-10 fB flex justify-center footer-contact-text">Achieve Your Goals with Us</h1>
                    <div className="flex justify-center">
                    <Btn LinkPath={('/career')} value={'Let’s work together'} bgColor={'black'} />
                    </div>
                </div>
            </div>
            {/* <div>
                <div className="pb-5 md:pb-8">
                    <h1 className="text-xl md:text-3xl font-bold text-center text-hColor mb-3">Let’s Connect</h1>
                    <p className="text-base text-center text-pColor">Let’s Build Your Dream Product. Connect with our associates today!</p>
                </div>
                <div className="flex justify-center">
                    <div onClick={() => window.location.reload()}>
                        <Button LinkPath={('/contact')} value={'Get a Proposal'} className={''} />
                    </div>
                </div>
            </div> */}
        </div>
    )
}
export default FooterContact