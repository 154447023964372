import React from "react";

const SideBarIcons = ({ IconPath }) => {
    return (
        <div>
            <div className="flex items-center flex-row">
                <div className='w-10 h-10 bg-[#323131] rounded-full flex items-center justify-center'>
                    <img src={IconPath} width={20} alt='facebook' />
                </div>
            </div>
                
        </div>
    )
}

export default SideBarIcons
