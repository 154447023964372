import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const CVS = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Customer-centric Cart Creation: Leveraging computer vision and deep learning, the system intuitively creates virtual shopping carts based on customer behavior.",
        point2: "Unified Fridge Access: Enabling access to multiple smart fridges with a single authentication for customer convenience.",
        point3: "Efficient Inventory and Planogram Management: Strategically managing products within fridges for visibility and sales optimization.",
        point4: "Streamlined Vendor Onboarding: Simplifying the process for vendors to manage products and inventory seamlessly.",
        point5: "Insightful Reports: Generating detailed reports on sales trends, popular products, and inventory levels for user and vendor insights."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Technology Reliability: Ensuring the robustness of computer vision and automated purchasing to avoid user dissatisfaction.",
        point2: "Integration with Existing Systems: Addressing compatibility issues while integrating with various vending machines and retail platforms.",
        point3: "Cost of Advanced Technologies: Managing initial expenses while implementing cutting-edge tech like computer vision."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Enhanced System Reliability: Implementing rigorous testing and maintenance schedules.",
        point2: "Seamless Integration: Employing standardized communication protocols and flexible integration approaches.",
        point3: "Cost-Effective Implementation: Conducting cost-benefit analyses and phased implementations to manage expenses."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Proglint's adept implementation of the Smart Fridge Management System at the CVS lab showcased an evolution in retail tech. Their rigorous testing ensured technological reliability, while seamless integrations with diverse platforms showcased their adeptness. Proglint's strategic cost analyses enabled the deployment of an advanced solution, cementing their role as pioneers in retail automation."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.CVSBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Smart Fridge Management System'}
                domainText={'Retail, Automation, Inventory Management'}
                durationText={'6 months, 20 man-months'}
                technologyText={'Computer Vision, Deep Learning, Automated Purchasing'}
                overviewText={'The Smart Fridge Management System revolutionizes retail with computer vision and deep learning. It enables automatic cart creation, planogram management, and seamless vendor onboarding, delivering an enhanced shopping experience.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.IntelThumbnail} RetailHeading={'Payment Processing'} SubHead={'Intel Vision Checkout'} ParaContent={`Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/intel-vision-checkout')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.cosfymartThumbnail} RetailHeading={'E-commerce'} SubHead={`Cosfymart`} ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cosfymart-app')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/jedidahs')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default CVS;