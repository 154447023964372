import React from "react";
import Btn from "../component/Btn";
import { ArrowLongLeftIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
const Job2 = () => {
    return (
        <div className="container mx-auto mt-20">
            <div className="flex gap-1 items-center my-10" onClick={() => window.location.reload()}>
                <Link to={('/career')} className="flex gap-1 items-center">
                    <ArrowLongLeftIcon className="w-6 h-6 text-secondary cursor-pointer" />
                    <p className="text-secondary text-base fSB cursor-pointer">Back to Career</p>
                </Link>
            </div>
            <div className="mt-10 mb-6 lg:mb-10">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center  text-hColor fB mb-3">CV Engineer</h1>
                {/* <p className="text-sm text-center text-pColor my-1 mb-3 px-2 lg:px-28">September 20, 2023</p> */}
                {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 w-32 text-center text-sm px-6 block m-auto bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                <div className="flex justify-center">
                    <a href="mailto:hr@proglint.com?subject=Applying for CV Engineer">
                        <Btn value={'Apply Now'} bgColor={'white'} />
                        {/* <button className='block mx-auto py-3 rounded-full my-4 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'> Apply Now</button> */}
                    </a>
                </div>
            </div>
            <div className="md:flex my-14">
                <div className="w-full md:w-2/6 lg:w-1/6">
                    <h2 className="text-hColor text-base fSB">Job Information</h2>
                    <ul>
                        <li className="text-sm text-pColor my-3 fR">Bachelor’s degree in computer science or equivalent</li>
                        <li className="text-sm text-pColor my-3 fR">3 - 4 years</li>
                        <li className="text-sm text-pColor my-3 fR"> Thiruvanmiyur, Chennai</li>
                        <li className="text-sm text-pColor my-3 fR">Full Time</li>
                    </ul>
                </div>
                <div className="w-full md:w-4/6 lg:w-5/6">
                    <div className="description">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Profile</h2>
                        <p className="text-pColor fR text-sm my-3">You will be responsible for developing robust, scalable and efficient Python
                            applications mainly in relation to Computer Vision, AI and Deep Learning solutions.</p>

                    </div>
                    <div className="description mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Responsibilities</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Develop Python applications mainly for Deep Learning / Computer Vision solutions</li>
                            <li className="text-pColor fR text-sm my-3"> Train deep learning models for use with computer vision solutions</li>
                            <li className="text-pColor fR text-sm my-3"> Need 100% commitment and adherence to the deadlines</li>
                            <li className="text-pColor fR text-sm my-3"> Present innovative ideas and improvements to increase the efficiency of existing solutions</li>
                            <li className="text-pColor fR text-sm my-3"> Explore and present cutting-edge, state-of-the-art solutions that directly affect our
                                company's revenue</li>

                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Technical Skills</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Very good knowledge specifically in Object identification, by tracking, Rest net,
                                Mobile net, Person and Object Tracking, Face recognition, Zero- or One-Shot
                                Learning, Gesture deduction and behavior identification.
                            </li>
                            <li className="text-pColor fR text-sm my-3">Strong in Python programming (min 3 Years) and Django framework</li>
                            <li className="text-pColor fR text-sm my-3">Experience with user interface design, database structures, and statistical analyses.</li>
                            <li className="text-pColor fR text-sm my-3">Knowledge in Data Structures and Algorithms</li>
                            <li className="text-pColor fR text-sm my-3">Knowledge in Computer Vision / OpenCV</li>
                            <li className="text-pColor fR text-sm my-3">Experience working with machine learning and deep learning, libraries like tensor flow
                                and PyTorch</li>
                            <li className="text-pColor fR text-sm my-3">Knowledge with Version Control (git) and distributed software development</li>
                        </ol>
                    </div>
                    <div className="description mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Soft Skills</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Excellent communication skills (verbal and written)</li>
                            <li className="text-pColor fR text-sm my-3"> Analytical mindset and good problem solving skills</li>
                            <li className="text-pColor fR text-sm my-3"> Ability to work as a team player</li>
                            <li className="text-pColor fR text-sm my-3"> Rigid adherence to schedule and delivering solutions on time</li>
                        </ol>
                    </div>
                    <h2 className="text-sm fSB text-hColor my-4">Please note that we have requirements for this role in Chennai.</h2>
                    {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                </div>
            </div>
        </div>
    );
};
export default Job2