import React from "react";
import { PATH } from "../../globals/app-constants";
import Button from "../../component/Button";


const PageNotFound = () => {
    return (
        <div>
            <div className="banner_error relative overflow-hidden">
                <div className="w-full lg:w-full left-[20%]">
                    <img src={PATH.img.error_404} alt="banner" className="w-6/12 error_img" />
                    <h1 className="text-4xl fSB absolute left-[35%] mt-14 error_text">There's NOTHING here...</h1>
                    <div onClick={() => window.location.reload()}>
                        <Button LinkPath={('/')} value={'Back to Home'} className={'px-6 absolute left-[45%] error_button mt-28 bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;