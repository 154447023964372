import React, { useState } from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import Button from "../../component/Button";
import Industries from "../../component/Industries";
import ContentBanner2 from "../../component/ContentBanner2";
import Faqs from "../../component/Faqs";
import Modal from 'react-modal';
import { Link, useNavigate } from "react-router-dom";

import { ArrowLongRightIcon, ArrowLongLeftIcon, } from "@heroicons/react/24/solid";
import END_POINTS from "../../service/Endpoints";
var nospecial = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
const SolutionPage = () => {
    const [fields, setFields] = useState({})
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [modalIsOpen, setModelIsOpen] = useState(false)
    const [contacts, setContacts] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        email: false,
        phone: false,
        message: false
    });
    const handleFieldChange = (field, value) => {
        setContacts((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const isValidEmail = (email) => {
        const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
        return validEmailRegex.test(email);
    }

    const onlyNameRegex = new RegExp(`^[a-zA-Z ]+$`);
    const onlyCharactersRegex = new RegExp(`^[a-zA-Z0-9_ -()+]+$`);
    const onlyNumberRegex = new RegExp(`^[0-9.]+$`);
    const handleValidation = (currentField, value) => {
        switch (currentField) {
            case 'name':
                if (!value) {
                    return 'Please enter the valid name';
                } else if (!onlyNameRegex.test(value)) {
                    return 'Special characters and numbers are not allowed'
                }
                break;
            case 'email':
                if (!value) {
                    return 'Please enter the valid email address';
                } else if (!isValidEmail(value)) {
                    return 'Please enter the valid email address'
                }
                break;
            case 'phone':
                if (!value) {
                    return 'Please enter the valid phone number';
                } else if (!onlyNumberRegex.test(value)) {
                    return 'Numbers field only accepted.'
                } else if (value.length !== 10) {
                    return 'Please enter the valid phone number'
                }
                break;
            case 'message':
                if (!value) {
                    return 'Please enter the valid message';
                } else if (!onlyCharactersRegex.test(value)) {
                    return 'Special characters are not allowed'
                }
                break;
            default:
                return null
        }
        return '';
    }

    // useEffect(() => {
    //     console.log("chk")
    //     handleValidation()
    // }, [fields])


    const handleEmailSuccess = (data) => {
        setContacts({
            name: '',
            email: '',
            phone: '',
            message: ''
        })
        setEmailSuccess(data?.success)
    }

    const handleSubmitValidation = (e) => {
        e.preventDefault();
        // Validate all fields before submission
        setEmailError('')
        setEmailSuccess('')
        const newErrors = {};
        let hasErrors = false;
        for (const field in contacts) {
            if (handleValidation(field, contacts?.[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }

        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            const data = { ...contacts };
            fetch(`${END_POINTS.API_BASE_URL}/proglint/mail/trigger`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic cHJvZ2xpbnQ6dG5pbGdvcnAzMjE=',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => handleEmailSuccess(data))
                .catch(() => setEmailError(true));
        }
    }
    const handleNumberInput = (event) => {
        var txt = nospecial.test(event.target.value);
        if (!txt) {
            if (event.target.value == "" && fields?.phone == "") {
                event.target.value = event.target.value;
            } else if (event.target.value == "" && fields?.phone !== "") {
                if (fields?.phone?.length > 1) {
                    event.target.value = fields?.phone ?? "";
                } else if (fields?.phone?.length == 1 && event.target.value !== "") {
                    event.target.value = fields?.phone;
                } else if (fields?.phone !== "") {
                    if (event.target.value !== "") {
                        event.target.value = fields?.phone;
                    } else {
                        event.target.value = "";
                    }
                } else {
                    event.target.value = "";
                }
            } else {
                event.target.value = fields?.phone;
            }
        }
        else {
            event.target.value = event.target.value;
        }
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            padding: '0',
        },
    };
    const closeModal = () => {
        setModelIsOpen(false)
    }

    const openModal = () => {
        setModelIsOpen(true)
    }

    const navigate = useNavigate()
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Diverse Product Selection: Aldi boasts a wide array of grocery items, including weight-based and bistro products, offering a comprehensive selection for users.",
        point2: "QR Code Technology: Implementing QR code scanning, Aldi enables effortless cart transfer between devices, allowing users flexibility in managing their shopping progress across various platforms.",
        point3: "Payment & Promotional Features: Aldi's application includes seamless payment modes and promotional features, enhancing user convenience and offering exclusive benefits.",
        point4: "Real-time Hardware Monitoring: Empowering users with hardware monitoring capabilities within the application ensures real-time device status updates, enabling efficient device management and issue troubleshooting."
    },
    {
        title: 'Business challenge',
        challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
    },
    {
        title: 'Overcoming Challenges',
        point1: "Seamless Checkout Integration: Proglint successfully integrated varied checkout options, ensuring a smooth transition between devices and maintaining shopping continuity.",
        point2: "Efficient Hardware Monitoring: By implementing real-time device status updates, Proglint provided users with effective device management and troubleshooting capabilities."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "The Aldi E-commerce application, developed and deployed by Proglint, redefined shopping experiences by offering versatile checkout options, simplified cart transfers, and real-time device monitoring. This innovative platform guarantees an efficient and reliable shopping environment for users."
    }
    ]

    return (
        <div>
            <div className="solution_banner bg-cover bg-black bg-center relative">
                <div className="w-full h-full backdrop-brightness-75">
                    <div className="z-[10] container mx-auto">
                        <div className=" absolute top-[35%] md:top-[25%] md:bottom-[25%]">
                            <h1 className="banner_title fSB text-white w-auto lg:max-w-xl">Computer Vision Solutions</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-2xl">Pioneering digital transformation with advanced software solutions</h2>
                <p className="text-base text-start text-pColor mb-4 fR">Harnessing the latest in computer vision and machine learning, our solutions empower retailers to make data-driven decisions, drive customer engagement, and stay ahead in today's competitive market landscape. With the ability to optimize store performance, curb losses, enhance operational precision, and maximize the impact of retail media, our technology provides comprehensive support for retailers aiming to thrive in an ever-evolving industry.</p>
            </div>
            <div className="container mx-auto flex lg:flex-row flex-col items-center lg:gap-10 gap-6 my-6 lg:my-20">
                <div className="w-full lg:w-5/7 mt-4 lg:mt-0">
                    <img src={PATH.img.LP_solution} alt="project" className="w-full h-full" />
                </div>
                <div className="w-full lg:w-2/7">
                    <p className="text-base text-start text-pColor lg:mb-4 mb-2 fR">Retail</p>
                    <h2 className="text-xl md:px-0 lg:text-4xl text-start text-hColor fSB mb-3 w-80">Loss Prevention</h2>
                    <p className="text-base text-start text-pColor mb-4 fR">Empower your retail operations with our cutting-edge solution, redefining Automated Checkout Systems for unprecedented efficiency and security.</p>
                    <div className="flex items-center gap-4">
                        <div className="" onClick={() => window.location.reload()}>
                            <Button LinkPath={('/solution-loss-prevention')} value={'Read more'} className={'px-8 py-3 text-sm font-semibold bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none'} />
                        </div>
                        <button className="flex gap-2 items-center fR">Enquiry<img src={PATH.img.Enquiry_icon} /></button>
                    </div>
                </div>
            </div>
            <div className="container mx-auto flex lg:flex-row flex-col items-center lg:gap-10 gap-6 my-6 lg:my-20">
                <div className="w-full lg:w-2/7 lg:order-1 order-2">
                    <p className="text-base text-start text-pColor lg:mb-4 mb-2">Retail</p>
                    <h2 className="text-xl md:px-0 lg:text-4xl text-start text-hColor font-semibold mb-3 w-80">Retail Media</h2>
                    <p className="text-base text-start text-pColor mb-4">Bridge the Digital Transformation gap in Retail, empowering media planners and buyers with detailed customer behavior data for strategic allocation of advertising budgets across various platforms.</p>
                    <div className="flex items-center gap-4">
                        <div className="" onClick={() => window.location.reload()}>
                            <Button LinkPath={('/solutions/loss-prevention')} value={'Read more'} className={'px-8 py-3 text-sm font-semibold bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none'} />
                        </div>
                        <button className="flex gap-2 items-center">Enquiry<img src={PATH.img.Enquiry_icon} /></button>
                    </div>
                </div>
                <div className="w-full lg:w-5/7 mt-4 lg:mt-0 lg:order-2 order-1">
                    <img src={PATH.img.RM_solution} alt="project" className="w-full h-full" />
                </div>
            </div>
            <div className="container mx-auto flex lg:flex-row flex-col items-center lg:gap-10 gap-6 my-6 lg:my-20">
                <div className="w-full lg:w-5/7 mt-4 lg:mt-0">
                    <img src={PATH.img.UR_solution} alt="project" className="w-full h-full" />
                </div>
                <div className="w-full lg:w-2/7">
                    <p className="text-base text-start text-pColor lg:mb-4 mb-2">Retail</p>
                    <h2 className="text-xl md:px-0 lg:text-4xl text-start text-hColor font-semibold mb-3 w-80">Unattended Retail</h2>
                    <p className="text-base text-start text-pColor mb-4">Transform retail spaces with innovative, automated, and scalable solutions, reshaping customer interactions and transactions with streamlined processes, integrated smart technologies, and efficient checkout systems.</p>
                    <div className="flex items-center gap-4">
                        <div className="" onClick={() => window.location.reload()}>
                            <Button LinkPath={('/solution-unattended-retail')} value={'Read more'} className={'px-8 py-3 text-sm font-semibold bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none'} />
                        </div>
                        <button className="flex gap-2 items-center">Enquiry<img src={PATH.img.Enquiry_icon} /></button>
                    </div>
                </div>
            </div>
            <div className="container mx-auto flex lg:flex-row flex-col items-center lg:gap-10 gap-6 my-6 lg:my-20">
                <div className="w-full lg:w-2/7 lg:order-1 order-2">
                    <p className="text-base text-start text-pColor lg:mb-4 mb-2">Retail</p>
                    <h2 className="text-xl md:px-0 lg:text-4xl text-start text-hColor font-semibold mb-3 w-80">Operational Excellence</h2>
                    <p className="text-base text-start text-pColor mb-4">Replace hunches and guesswork with data-driven insights! Experience an all-in-one solution designed to slash operational costs and elevate operational excellence in physical stores.</p>
                    <div className="flex items-center gap-4">
                        <div className="" onClick={() => window.location.reload()}>
                            <Button LinkPath={('/solution-operational-excellence')} value={'Read more'} className={'px-8 py-3 text-sm font-semibold bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none'} />
                        </div>
                        <button onClick={openModal} className="flex gap-2 items-center">Enquiry<img src={PATH.img.Enquiry_icon} /></button>
                    </div>
                </div>
                <div className="w-full lg:w-5/7 mt-4 lg:mt-0 lg:order-2 order-1">
                    <img src={PATH.img.OE_solution} alt="project" className="w-full h-full" />
                </div>
            </div>
            <Industries />
            <ContentBanner2 />
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-6 md:p-10 lg:p-14 shadow-[0_3px_10px_rgb(0,0,0,0.2)] relative">
                    <div className="w-8 h-8 bg-black rounded-full absolute top-3 right-3 flex justify-center align-center" style={{alignItems:'center'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <h1 className="text-xl md:text-3xl text-center font-bold text-hColor mb-3">Get a Quote</h1>
                    <p className="text-base text-center md:text-base text-pColor mb-8 lg:max-w-xl">Please fill in the form and our representative will get back to you.</p>
                    <div className="mb-4">
                        <label for="name" className="block mb-2 text-base font-normal text-pColor">Your Name</label>
                        <input
                            type="text"
                            value={contacts?.name}
                            id="name"
                            onChange={(e) => handleFieldChange('name', e.target.value)}
                            name="name"
                            className="bg-white border-b-2 border-[#D2D2D2] text-base text-pColor outline-0 block w-full py-2.5 placeholder:text-sm placeholder:font-medium placeholder-gray-500 placeholder-opacity-25"
                            placeholder="Please Enter your name" />
                        {fieldChangedStatus?.name && handleValidation('name', contacts?.name) && (
                            <p className="text-red-500 text-sm">{handleValidation('name', contacts?.name)}</p>)
                        }
                    </div>
                    <div className="mb-4">
                        <label for="email" className="block mb-2 text-base font-normal text-pColor">Email</label>
                        <input
                            type="email"
                            value={contacts?.email}
                            id="email"
                            onChange={(e) => handleFieldChange('email', e.target.value)}
                            name="email"
                            className="bg-white border-b-2 border-[#D2D2D2] text-base text-pColor outline-0 block w-full py-2.5 placeholder:text-sm placeholder:font-medium placeholder-gray-500 placeholder-opacity-25"
                            placeholder="Please Enter your email" />
                        {fieldChangedStatus?.email && handleValidation('email', contacts?.email) && (
                            <p className="text-red-500 text-sm">{handleValidation('email', contacts?.email)}</p>)
                        }
                    </div>
                    <div className="mb-4">
                        <label for="phone" className="block mb-2 text-base font-normal text-pColor">Phone</label>
                        <input
                            type="text"
                            value={contacts?.phone}
                            id="phone"
                            onChange={(e) => handleFieldChange('phone', e.target.value)}
                            onInput={(e) => handleNumberInput(e)}
                            name="phone"
                            maxLength={10}
                            className="bg-white border-b-2 border-[#D2D2D2] text-base text-pColor outline-0 block w-full py-2.5 placeholder:text-sm placeholder:font-medium placeholder-gray-500 placeholder-opacity-25"
                            placeholder="Please Enter your contact number" />
                        {fieldChangedStatus?.phone && handleValidation('phone', contacts?.phone) && (
                            <p className="text-red-500 text-sm">{handleValidation('phone', contacts?.phone)}</p>)
                        }
                    </div>
                    <div className="mb-4">
                        <label for="message" className="block mb-2 text-base font-normal text-pColor">Message</label>
                        <textarea
                            placeholder="Please Enter your message here"
                            id="message"
                            value={contacts?.message}
                            rows="4"
                            name="message"
                            onChange={(e) => handleFieldChange('message', e.target.value)}
                            className="bg-white w-full border-b-2 border-[#D2D2D2] text-base outline-0 placeholder:text-sm placeholder:font-medium placeholder-gray-500 placeholder-opacity-25"></textarea>
                        {fieldChangedStatus?.message && handleValidation('message', contacts?.message) && (
                            <p className="text-red-500 text-sm">{handleValidation('message', contacts?.message)}</p>)
                        }
                    </div>
                    <div className="flex justify-center">

                        <button className={'mx-4 px-6 py-3 mb-0 rounded-full text-sm font-semibold bg-slate-200 text-black'}>Cancel</button>
                        <button className={'px-6 py-3 rounded-full text-sm font-semibold bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none hover:bg-gradient-to-br hover:from-secondary from-30% hover:to-primary to-90%'} onClick={(e) => handleSubmitValidation(e)}> Send </button>
                    </div>
                    {emailSuccess && <div className='py-3 px-4 bg-[#00890026] rounded-md mt-8'>
                        <p className='text-sm'>Thank you! We will get back to you shortly.</p>
                    </div>}
                    {
                        emailError &&
                        <div className='py-3 px-4 bg-[#fe8a8a] rounded-md mt-8'>
                            <p className='text-sm'>Opps! Something went wrong.</p>
                        </div>
                    }
                </div>
            </Modal>
            <Faqs
                Question1={`How can computer vision enhance retail media strategies?`}
                Answer1={`Computer vision enables retailers to analyze customer behavior, demographics, and preferences in-store. This data helps tailor advertising content and placements for maximum impact, leading to more effective retail media strategies.`}
                Question2={`How does computer vision contribute to loss prevention?`}
                Answer2={`Computer vision enables real-time monitoring of retail spaces to detect and prevent theft, shoplifting, and other instances of shrinkage. By employing advanced algorithms, computer vision systems can identify suspicious behavior and alert store staff, helping to reduce inventory losses and improve overall security.`}
                Question3={`How does an AI-based video analytics system redefine theft prevention?`}
                Answer3={`The AI-based video analytics system employs advanced algorithms to detect suspicious behavior at point-of-sale and self-checkout areas in retail environments. By analyzing customer behavior in real-time, we can identify potential instances of theft and alert staff promptly, enhancing theft prevention measures.`}
                Question4={`How does your analytics engine leverage computer vision in unattended retail environments?`}
                Answer4={`Our analytics engine utilizes computer vision technology to analyze the rich data captured by our algorithms. We extract actionable insights regarding customer behavior patterns, product preferences, dwell times, and traffic flow within unattended retail settings by processing video footage. This enables us to provide granular visibility into various aspects of store operations, facilitating informed decision-making and continuous improvement.`}
                Question5={`What role does computer vision play in achieving operational excellence in retail?`}
                Answer5={`Computer vision technology helps retailers optimize various operational processes, such as inventory management, store layout optimization, and queue management. By providing valuable insights into customer behavior and store performance, computer vision enables retailers to make data-driven decisions that enhance efficiency and productivity.`}
            />
            <FooterContact />
        </div>
    );
};
export default SolutionPage;