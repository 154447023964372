import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const ALDIVisionCheckout = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Diverse Product Selection: Aldi boasts a wide array of grocery items, including weight-based and bistro products, offering a comprehensive selection for users.",
        point2: "QR Code Technology: Implementing QR code scanning, Aldi enables effortless cart transfer between devices, allowing users flexibility in managing their shopping progress across various platforms.",
        point3: "Payment & Promotional Features: Aldi's application includes seamless payment modes and promotional features, enhancing user convenience and offering exclusive benefits.",
        point4: "Real-time Hardware Monitoring: Empowering users with hardware monitoring capabilities within the application ensures real-time device status updates, enabling efficient device management and issue troubleshooting."
    },
    {
        title: 'Business challenge',
        challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
    },
    {
        title: 'Overcoming Challenges',
        point1: "Seamless Checkout Integration: Proglint successfully integrated varied checkout options, ensuring a smooth transition between devices and maintaining shopping continuity.",
        point2: "Efficient Hardware Monitoring: By implementing real-time device status updates, Proglint provided users with effective device management and troubleshooting capabilities."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "The Aldi E-commerce application, developed and deployed by Proglint, redefined shopping experiences by offering versatile checkout options, simplified cart transfers, and real-time device monitoring. This innovative platform guarantees an efficient and reliable shopping environment for users."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.portfolioDetailBanner1}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Multi-Platform E-commerce Checkout Engine'}
                domainText={'E-commerce, Retail, Technology'}
                durationText={'4 months, 12 man-months'}
                technologyText={'JavaScript, ReactJS, Node.js, React Native, QR Code Scanning, Device Monitoring APIs'}
                overviewText={'Proglint developed Aldi, an advanced E-commerce application offering streamlined checkout options to elevate user experiences. Leveraging web, mobile, and vision-based checkouts, Aldi ensures a seamless shopping journey for users, especially for groceries and bistro products, catering to both guests and registered customers.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.IntelThumbnail} RetailHeading={'Payment Processing'} SubHead={'Intel Vision Checkout'} ParaContent={`Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/intel-vision-checkout')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.cosfymartThumbnail} RetailHeading={'E-commerce'} SubHead={`Cosfymart`} ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cosfymart-app')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/jedidahs')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default ALDIVisionCheckout;