import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const Canteen = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Seamless Entry: Streamlined customer entry into the store through a QR code generated post-card registration.",
        point2: "Preauthorization & Refund: Automated preauthorization of amounts and precise refund calculations after purchases.",
        point3: "Integrated Payment Portal: Seamlessly integrated with a secure payment portal for swift transactions and refunds."
    },
    {
        title: 'Business challenge',
        point1: "Developing core logic for card registration, QR code generation, and integrating the system with payment portals.",
        point2: "Cost-efficiently implementing advanced logic and ensuring an effective, user-friendly solution."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Core Logic Development: The challenge lay in crafting a robust system for card registration and QR code generation to facilitate customer access. Proglint tackled this by conducting exhaustive research and employing Python and Django, ensuring a secure and efficient process from card registration to QR code generation.",
        point2: "Integration with Payment Portals: Integrating the system with payment gateways presented complexities. Proglint mitigated this by meticulously integrating RESTful APIs, ensuring seamless transactions and accurate refund processing in harmony with the canteen system."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Proglint pioneered an innovative solution for Canteen Tap & Go, introducing a streamlined billing experience. This encompassed deploying a user-friendly mobile app for card registration and QR code generation, ensuring effortless canteen access. The system accurately preauthorized and refunded amounts based on customer-picked items, incorporating secure payment gateway integration for encrypted transactions and precise billing. The overall cost-effective approach optimized the process without compromising efficiency, elevating the customer experience in the canteen ecosystem.",
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.CanteenBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Autonomous Billing and Automation'}
                domainText={'Food Services, Autonomous Billing, Retail'}
                durationText={'4 months, 12 man-months'}
                technologyText={`Python, Django , RESTful APIs, Cloudpick's API`}
                overviewText={`The Canteen Tap & Go app provides a frictionless customer experience by enabling autonomous billing for selected products. Customers register their cards via a mobile app, generating a QR code for canteen access. Upon scanning, a preauthorized amount is deducted, reconciled, and refunded based on the items taken using Cloudpick's invoice data.`}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.shopAppThumbnail} RetailHeading={'E-commerce'} SubHead={'Shop App'} ParaContent={`The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/shopapp-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default Canteen;