import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const PaulFashion = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Customization Portal: The app provides a user-friendly interface for fabric selection, style choices, and personalized measurements.",
        point2: "Tailored Experience: Customers enjoy a tailor-made approach to fashion, creating unique garments suiting their preferences."
    },
    {
        title: 'Business challenge',
        challengeDescription: "Meeting customer expectations for an intuitive online tailoring experience while ensuring accurate customizations and timely delivery posed significant challenges.",
    },
    {
        title: 'Overcoming Challenges',
        point1: "Enhanced User Interface: The app's frontend was designed for easy navigation and precise customization, enhancing the user experience.",
        point2: "Seamless Integration: Integration with a secure payment gateway ensured smooth and safe transactions for personalized orders."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Proglint leveraged cutting-edge technologies to architect and develop a robust e-commerce platform. Paul's Fashion E-commerce app revolutionized the tailor shop's offerings, providing customers with an intuitive platform to personalize and order bespoke clothing, marking a new era in tailored online shopping experiences.",
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.PaulsfashionBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Custom ‘Tailoring E-commerce’ App'}
                domainText={'Fashion, Tailoring, E-commerce'}
                durationText={'4 months, 12 man-months'}
                technologyText={'React Native, Backend (Node.js), Database (MySQL), Payment Gateway Integration (Stripe)'}
                overviewText={`Paul's Fashion, a renowned Thai tailor, expanded its services by launching an e-commerce platform. The app empowers customers to customize clothing items, from shirts to suits, offering a seamless tailoring experience.`}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.Tap_and_go_thumbnail} RetailHeading={'Payment Solutions'} SubHead={'Tap and Go'} ParaContent={`Tap and Go app transformed retail by enabling contactless store access through NFC technology, React Native, Node.js, and MongoDB integration. Ensuring secure transactions, it streamlined checkout by swiftly generating carts and adjusting payments at WIWO stores. Proglint's role was pivotal, orchestrating NFC integration and backend development. Their expertise in MongoDB and robust backend systems solidified app reliability. This success set a new standard for contactless retail, showcasing Proglint's commitment to client objectives in partnership with WIWO.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/tap-and-go')}>Read more</Link>
                                </>
                            } />
                         <SliderCards ImgPath={PATH.img.shopAppThumbnail} RetailHeading={'E-commerce'} SubHead={'Shop App'} ParaContent={`The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/shopapp-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default PaulFashion;