import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import { TagIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
const AI_Attecndance = () => {
    const [showAccordion, setShowAccordion] = useState({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
    const handleAccordion = (accordion) => {

        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false })
                break;
            case 6:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6 })
                break;
        }

    }
    return (
        <div>
            <div className="home_banner relative">
                <div className="w-full h-full">
                    <img src={PATH.img.AiattendanceImg} alt="banner" className="h-full w-full object-cover slide active" />
                </div>
                <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                <div className="w-full z-[2] absolute top-[30%] lg:top-[40%]">
                    <div className=" container mx-auto">
                        <div className="mb-14">
                            <div className="flex gap-1 items-center mb-2">
                                <TagIcon className="text-[#7A7A7A] w-5 h-5" />
                                <p className="text-xs fB text-[#7A7A7A]">Attendance Tracking</p>
                            </div>
                            <h1 className="text-2xl lg:text-5xl fR text-white">AI Face Recognition Attendance System</h1>
                            <p className="text-base max-w-2xl fR text-white mt-4">Simplify attendance tracking with facial recognition for fast, accurate recording. Your face is your pass- just walk in, verify, and move on.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <h2 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center">AI-Powered Facial Recognition for Modern Attendance with Transparent and Efficient Tracking</h2>
                <p className="text-sm fR text-start lg:text-center text-pColor my-1 mx-auto">The Face Recognition Attendance System transforms attendance tracking by eliminating falsified records with real-time updates, keeping students and employees informed, and streamlining data management with an intuitive admin interface. This advanced solution enhances precision, ensures privacy with robust security, and allows educators and employers to focus on what truly matters.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Benefits</h2>
                        <p className="fR text-sm my-3">Streamline attendance tracking with advanced facial recognition system for fast, accurate recording and automated processes, eliminating manual data entry and administrative tasks.</p>
                        <div className="my-6">
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Precision Perfected</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(1)}>
                                        {showAccordion.accordion1 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Achieve precise attendance tracking with advanced facial recognition algorithms that reduce errors and false positives.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Instant Attendance Updates</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(2)}>
                                        {showAccordion.accordion2 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Receive instant notifications for attendance marking, keeping students and employees informed immediately.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Streamlined Operations</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Automate attendance processes, eliminating manual data entry and saving valuable time for educators and administrators.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black"> Fortified Privacy</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(4)}>
                                        {showAccordion.accordion4 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Guard biometric data with state-of-the-art encryption and access controls, ensuring top-notch security and compliance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Seamless Management</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(5)}>
                                        {showAccordion.accordion5 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion5 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Navigate biometric data effortlessly with a sleek, user-friendly admin interface that simplifies oversight and administration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={PATH.img.AiAttendance1} alt="AiAttendance-img" />
                    </div>
                </div>
            </section>
            <div className="solution-industry-banner">
                <div className="container mx-auto grid grid-cols-2 lg:grid-cols-3 py-20">
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.IndustryIcon} alt="industry-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Industry</h2>
                            <p className="fR text-xs text-white">Education, Manufacturing</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.DeliveryModalIcon} alt="delivery-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Resource Allocation</h2>
                            <p className="fR text-xs text-white">Image Sensors, Hardware Setup, Software Integration</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.TechnologyIcon} alt="technology-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Tech Frameworks</h2>
                            <p className="fR text-xs text-white">Computer Vision, AI/ML</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.DomainbannerIcon} alt="domain-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Support</h2>
                            <p className="fR text-xs text-white">Platform-Wide Reach</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.EffortIcon} alt="effort-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Project Duration</h2>
                            <p className="fR text-xs text-white">4-8 Weeks, Flexible</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <img src={PATH.img.AiAttendance2} alt="AiAttendance-img" />
                    </div>
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Features</h2>
                        {/* <p className="text-sm mt-2 fR">AI securely guards a world where patients whiz through checkout and medications. Upgrade your healthcare checkout process with our advanced computer vision-powered self-checkout, ensuring top-notch security and effortless patient transactions.</p> */}
                        <div className="relative">
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Next-Gen Face Recognition</h2>
                                    <p className="text-sm fR mt-1">Harness the power of advanced algorithms like OpenCV and ArcFace for fast, accurate facial recognition in real-time.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Touch-Free Technology</h2>
                                    <p className="text-sm fR mt-1">Implement touchless systems for smooth and hygienic attendance recording, suitable for various devices.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Real-Time Updates</h2>
                                    <p className="text-sm fR mt-1">Get instant updates with automated notifications, keeping everyone informed and on track with their attendance.
                                    </p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base"> Privacy-First Approach</h2>
                                    <p className="text-sm fR mt-1">Incorporates robust encryption and access controls to safeguard facial data and uphold individual privacy.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Intuitive Admin Dashboard</h2>
                                    <p className="text-sm fR mt-1">Manage biometric data and attendance records with ease using a streamlined, user-friendly interface designed for simplicity.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Challenge</h2>
                    <div>
                        <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Navigating the future of attendance tracking isn't without its hurdles: managing sensitive facial data stirs privacy concerns, while accuracy can falter due to lighting and diverse demographics.High setup costs and user resistance add to the complexity, making seamless integration a challenge.</p>
                        {/* <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p> */}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Success</h2>
                   <div>
                   <p className="text-sm fR text-start text-pColor my-1 mx-auto">Proglint’s advanced facial recognition system automates attendance with ease, cutting down manual data entry and administrative tasks.. Enjoy streamlined operations and let educators and managers focus on impactful work rather than tedious paperwork.
                    </p>
                    <p className="text-sm fR text-start text-pColor my-2 mx-auto">Harness the power of advanced algorithms like OpenCV and ArcFace for flawless real-time face detection and recognition. Coupled with top-notch encryption and privacy controls, our solution ensures exceptional accuracy and robust data protection, addressing all your concerns while setting new standards in security.</p>
                   </div>
                </div>
            </div>
            <FooterContact />
        </div>
    )
}
export default AI_Attecndance;