import React from "react";
import FooterContact from "./FooterContact";
import { PATH } from "../globals/app-constants";
import ContentBanner1 from "./ContentBanner1";

const MEAN = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <p className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">MEAN Stack Development Company</p>
                    <div className="w-full h-1/2 lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.MEANlogo} alt="logo" className="block mx-auto w-44 object-contain lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  mt-10 mb-10 lg:mt-14 lg:mb-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h1 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-3xl">Unlock Business Potential with Proglint's MEAN Stack Expertise</h1>
                    <p className="text-base text-start text-pColor mb-3 fR">Collaborate with the premier MEAN Stack Development Company to harness cutting-edge technologies, shaping your digital growth.</p>
                    <p className="text-base text-start text-pColor my-3 fR">Hiring skilled developers is pivotal, especially those versed in the MEAN stack. They wield the ability to craft robust web applications more efficiently than others, making them indispensable for businesses seeking online expansion. MEAN stack development companies lead the industry in offering holistic technology solutions for web and mobile applications.</p>
                    <p className="text-base text-start text-pColor my-3 fR">At Proglint, a globally-renowned MEAN Stack development company, we harness the power of the MEAN (MongoDB, Express JS, Angular JS, NodeJS) stack. Our team amalgamates extensive technical expertise with this stack to craft highly functional and feature-rich web solutions tailored to diverse business requirements. With a team of over 25 MEAN specialists, we deliver interactive and market-leading products across various domains.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon1} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">End-to-end MEAN Solutions</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Our comprehensive MEAN Stack solutions cover the entire development spectrum, integrating MongoDB, ExpressJS, AngularJS, and NodeJS for seamless functionality and tailored outcomes.

                            Leveraging the MEAN Stack, we deliver end-to-end solutions, ensuring robust, scalable, and customized applications from ideation to deployment.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon2} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Enterprises App Development</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Tailored for enterprises, our MEAN Stack-driven applications are designed to meet diverse business needs, delivering scalable, intuitive, and high-performance solutions.

                            With our MEAN Stack expertise, we craft enterprise-grade applications that optimize workflows, enhance productivity, and adapt to evolving business requirements.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon3} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Migration to MEAN Stack</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Seamlessly transition your applications to the MEAN Stack environment with our migration services, ensuring minimal disruption and maximizing scalability.

                            Our migration expertise ensures a smooth shift to the MEAN Stack, enhancing application performance, flexibility, and future scalability.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon4} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">MEAN Stack Consulting</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Trust our MEAN Stack experts for strategic guidance, providing clear insights and proficient strategies to optimize technology resources and development processes.

                            Our consulting services offer in-depth MEAN Stack insights, empowering businesses to navigate the technology landscape for efficient and effective implementation strategies.</p>
                    </div>
                </div>
            </div >
            <div className="service_section container mx-auto  my-10 lg:my-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-3xl">Discover Why Proglint Stands Out as Your MEAN Stack Partner</h2>
                    <p className="text-base text-start text-pColor mb-3 fR">At Proglint, we're the leading MEAN stack development experts, fueled by a talented team well-versed in MEAN technologies. We craft scalable, robust applications in significantly less time compared to other frameworks. Embracing our MEAN stack services means unlocking benefits like rapid release cycles, agile engineering, cost-effective development, and swift project launches. We stand at the forefront, ensuring your success by catering to diverse database models—be it relational, document-oriented, or graph-oriented—to shape your data model effectively.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.webLaptop} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Unparalleled Quality Web Solutions</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Our development cycle ensures top-tier web solutions by rigorously testing code and implementing user-centric design, elevating industry standards.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.desktopDesign} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Security at Its Core</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We employ robust encryption and advanced security protocols to safeguard data integrity, ensuring user privacy in all our solutions.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.lessTime} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Efficient MEAN Solutions</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Our agile development methodology expedites MEAN stack solutions without compromising precision, driven by streamlined workflows and iterative processes.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.productList} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Power of MEAN Tech-Stack</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Harness the MEAN stack's capabilities through seamless integration, enabling cutting-edge functionalities and technologies to elevate your business.</p>
                    </div>
                </div>
            </div >
            <div className="development bg-background py-8 mb-10">
                <div className="container mx-auto lg:flex gap-10 items-center">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">MEAN Tech-Stack Mastery</h2>
                        <p className="text-base text-start text-pColor mb-3 fR">Maximize your business potential with
                            our expertise in MEAN web and
                            app development, leveraging this
                            comprehensive toolset.
                        </p>
                    </div>
                    <div className="w-full lg:w-3/5 grid grid-cols-2 lg:grid-cols-3">
                        <div className="development_item my-4">
                            <h2 className="text-lg fSB ml-3 pb-3">Tools</h2>
                            <div className="development_item_content text-lg fR text-pColor">Redis</div>
                            <div className="development_item_content text-lg fR text-pColor">.Net</div>
                            <div className="development_item_content text-lg fR text-pColor">Fabric</div>
                            <div className="development_item_content text-lg fR text-pColor">GIT</div>
                            <div className="development_item_content text-lg fR text-pColor">JSON</div>
                            <div className="development_item_content text-lg fR text-pColor">Fabric</div>
                        </div>
                        <div className="development_item my-4">
                            <h2 className="text-lg fSB ml-3 pb-3">Skills</h2>
                            <div className="development_item_content text-lg fR text-pColor">Angular Js</div>
                            <div className="development_item_content text-lg fR text-pColor">React</div>
                            <div className="development_item_content text-lg fR text-pColor">HTML5</div>
                            <div className="development_item_content text-lg fR text-pColor">Express</div>
                            <div className="development_item_content text-lg fR text-pColor">CSS</div>
                        </div>
                        <div className="development_item my-4">
                            <h2 className="text-lg fSB ml-3 pb-3">Database</h2>
                            <div className="development_item_content text-lg fR text-pColor">MySQL</div>
                            <div className="development_item_content text-lg fR text-pColor">Mongo DB</div>
                            <div className="development_item_content text-lg fR text-pColor">Node Js</div>
                            <div className="development_item_content text-lg fR text-pColor">PostgreSQL</div>
                            <div className="development_item_content text-lg fR text-pColor">Oracle</div>
                        </div>

                    </div>
                </div>
            </div>
            <ContentBanner1 />
            <FooterContact />
        </div>
    )
}
export default MEAN