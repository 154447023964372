import React from "react";
import FooterContact from "./FooterContact";
import { PATH } from "../globals/app-constants";
import ContentBanner2 from "./ContentBanner2";

const StaffAugmentation = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <p className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">IT Staff Augmentation services
                    </p>
                    <div className="w-full lg:w-1/2">
                        <img src={PATH.img.icons.staffAugmentation_logo} alt="logo" className="block mx-auto w-full lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h1 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Empower Your Projects with IT Staff Augmentation Solutions</h1>
                <p className="text-base text-start text-pColor my-1 fR">At Proglint, we understand the evolving needs of businesses. Our IT staff augmentation services offer flexibility, enabling seamless integration of skilled professionals into your existing team, precisely tailored to your project requirements.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.feasibility} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Domain-Specific Expertise</h2>
                        <p className="text-pColor text-sm py-2 fR">Proglint ensures that the augmented staff possesses not only the required technical skills but also domain-specific expertise, allowing seamless integration into your projects and contributing effectively to your business goals.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.Bot} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Direct Communication</h2>
                        <p className="text-pColor text-sm py-2 fR">We prioritize clear and direct communication channels, ensuring a smooth workflow and effective collaboration between your team and our augmented staff.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.User} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB  ">No Team Retention Headache</h2>
                        <p className="text-pColor text-sm py-2 fR">Forget the stress of team retention; our flexible augmentation allows you to scale your team effortlessly, without long-term commitments.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.predictableCost} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB  ">Predictable Cost</h2>
                        <p className="text-pColor text-sm py-2 fR">Benefit from cost predictability with our augmentation services, offering transparent pricing models that align with your budget constraints.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.scalling} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB  ">Fast Scaling</h2>
                        <p className="text-pColor text-sm py-2 fR">Quickly adapt to changing project demands by swiftly scaling your team, empowering your business with agility in resource management.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.efficiency} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB    ">Efficiency</h2>
                        <p className="text-pColor text-sm py-2 fR">Augment your team with top-tier professionals, boosting your project's efficiency and productivity while meeting deadlines with precision.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  my-10 lg:my-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Right Fitment for the Right Project, How?</h2>
                    <p className="text-base text-start text-pColor my-3 fR">Enhance project success with Proglint's strategic staff augmentation. Tailor your team for each project, ensuring the right skills at the right time, boosting efficiency and delivering exceptional results.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.webLaptop} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Technical Skills</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our team excels in diverse technical competencies, ensuring the precise alignment of skills required for your project's success.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.puzzle} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Cultural Fit</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Beyond technical prowess, our professionals integrate seamlessly into your work culture, fostering collaboration and synergy.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.communicationSkils} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Communication Skills</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Clear, effective communication is integral. Our team ensures seamless interactions, enhancing project efficiency and understanding.</p>
                    </div>
                </div>
            </div >
            <div className="mt-10 mb-20 flex justify-center items-center px-14">
                <img src={PATH.img.icons.StaffAugmentationProcess} alt="staff_augmentation" />
            </div>
            <ContentBanner2 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default StaffAugmentation