import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const LossPrevention = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Enhanced Scanning Precision: The algorithm ensures accurate barcode matching, reducing errors in product identification during the scanning process.",
        point2: "Basket Completion Alerts: Notifying customers to scan all items moved to the checkout basket, ensuring a comprehensive checkout process.",
        point3: "Fake Scan Prevention: Preventing fraudulent activities by detecting and blocking attempts to fake product scans or bypass payments.",
        point4: "Suspicious Activity Monitoring: Alerting store managers in case of abandoned checkouts or suspicious behavior within the kiosk area, enhancing overall security measures."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores,",
        point1: "Product Training Complexity: Training products for accurate identification posed a significant challenge. To circumvent this, Proglint devised an anonymous product identification method to streamline the training process.",
        point2: "Market Solution Limitations: Existing market solutions lacked comprehensive product identification and quantity estimation capabilities, which Proglint aimed to address."
        // point3: "A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores,",
        // point4: "A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores,"
    },
    {
        title: 'Overcoming Challenges',
        point1: "Anonymous Product Identification: By innovating with an anonymous product identification strategy, Proglint simplified the training process, enabling efficient product recognition.",
        point2: "Precision in Product Details: The developed algorithm excelled in providing precise product details and accurate quantity estimations, overcoming the limitations of existing solutions.",
        point3: "Customized Security Measures: The tailored solution significantly minimized the risk of theft or fraudulent activities, ensuring a secure and reliable self-checkout process."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Successfully deployed in Charlotte and UST labs in Poland, Proglint's Loss-Prevention Algorithm revolutionized the retail sector's self-checkout security measures.",
        deliveredParagraph2: "The solution, designed to prevent pilferage and guarantee seamless transactions, significantly enhanced overall retail efficiency and security, setting new industry standards in automated checkout systems.",
        // deliveredParagraph3: "A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores,",
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.losspreventionBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Automated Loss-Prevention Solution'}
                domainText={'Automation, Retail, Security'}
                durationText={'3 months, 10 man-months'}
                technologyText={'Machine Learning, Computer Vision, Python'}
                overviewText={'Proglint spearheaded the development of an innovative Loss-Prevention Algorithm catering to the automation sector within the retail domain. The algorithm was designed to augment the security of self-checkout kiosks in supermarkets, mitigating the risk of fraudulent activities. By implementing a robust and efficient system, customers could confidently control their purchasing process while preventing potential losses due to unauthorized actions.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                       <SliderCards ImgPath={PATH.img.shopAppThumbnail} RetailHeading={'E-commerce'} SubHead={'Shop App'} ParaContent={`The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/shopapp-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default LossPrevention;