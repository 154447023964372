import React, { useEffect } from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import Button from "../../component/Button";
import { Link } from "react-router-dom";
import Faqs from "../../component/Faqs";

const Apps = () => {
    useEffect(() => {
        const counter = document.querySelectorAll(".counter-second span")
        const container = document.querySelector(".counter-second")
        let activated = false;

        const handleScroll = () => {
            const contentDiv = document.getElementById('content-div-second');
            if (!contentDiv) return;

            const contentRect = contentDiv.getBoundingClientRect();
            const contentTop = contentRect.top;
            console.log(contentTop, "contentTopcontentTop")
            if ((contentTop >= -420 && contentTop <= 400) && activated === false) {
                counter.forEach(c => {
                    c.innerText = 0;
                    let count = 0;
                    function updateCount() {
                        const target = parseInt(c.dataset.count)
                        if (count < target) {
                            count++
                            c.innerText = count
                            setTimeout(updateCount, 3)
                        } else {
                            c.innerText = target
                        }
                    }
                    updateCount()
                    activated = true
                })
            } else {
                if (contentTop > 190) {
                    counter.forEach(c => c.innerText = 0)
                    activated = false
                }
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            // clearInterval(timer)
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className="banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="absolute bottom-6 top-[35%] md:top-[25%] md:bottom-[25%] z-10">
                        <h1 className="banner_title fSB text-white w-auto lg:max-w-xl">Custom App Development Company</h1>
                        {/* <p className="my-4 font-normal text-white texFt-base lg:text-lg md:w-auto lg:max-w-2xl">Welcome to Proglint, where we bring your digital dreams to life. Transforming visions into remarkable, user-centric applications, boasting a track record of 120+ successful apps across diverse platforms.</p> */}
                    </div>
                </div>
                <div className="absolute right-0 w-full lg:w-4/6">
                    <img src={PATH.img.appsBanner} alt="banner" className="w-full h-full object-contain" />
                </div>
            </div>
            <div className="apps_device my-5 lg:my-10  container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Crafting Your Digital Vision into Reality</h2>
                    <p className="text-base text-center text-pColor mb-1 fR">Your dream, irrespective of the landscape, is within our capabilities to turn into a digital masterpiece.</p>
                </div>
                <img src={PATH.img.appsdevice} alt="device-img" className="block m-auto py-6 lg:py-12" />
            </div>
            <div className="service_section container mx-auto">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">Strategic Solutions Powering Business Evolution</h2>
                    <p className="text-base text-start text-pColor mb-3 fR">Proglint isn't just about solutions; it's about crafting success stories. Our team's expertise extends beyond delivering services — we're committed to understanding your vision and translating it into impactful, future-proof solutions. With a client-centric approach, we ensure every project, whether it's AI integration, app development, or web solutions, embodies innovation and excellence, shaping the way industries operate and thrive.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <div className="service_hover" onClick={() => window.location.reload()}>
                            <Link to={('/ai-development-services')}>
                                <h3 className="service_item_content cursor-pointer">
                                    <img src={PATH.img.icons.aiml} alt="icon" className="w-8 h-8" />
                                    <span className="text-xl fSB">AI & ML Integration</span>
                                </h3>
                                <p className="text-base text-pColor cursor-pointer max-w-4xl my-5 fR">Deploying robust AI and Machine Learning frameworks to optimize business intelligence and drive data-driven decisions.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="service_item_box">
                        <div className="service_hover" onClick={() => window.location.reload()}>
                            <Link to={('/app-development')}>
                                <h3 className="service_item_content cursor-pointer">
                                    <img src={PATH.img.icons.mobile} alt="icon" className="w-8 h-8" />
                                    <span className="text-xl fSB">Custom App Development</span>
                                </h3>
                                <p className="text-base text-pColor cursor-pointer max-w-4xl my-5 fR">Crafting tailored applications using agile methodologies and cutting-edge frameworks for optimal performance and user engagement.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="service_item_box">
                        <div className="service_hover" onClick={() => window.location.reload()}>
                            <Link to={('/web-development-services')}>
                                <h3 className="service_item_content cursor-pointer">
                                    <img src={PATH.img.icons.web} alt="icon" className="w-8 h-8" />
                                    <span className="text-xl fSB">Web Development Expertise</span>
                                </h3>
                                <p className="text-base text-pColor cursor-pointer max-w-4xl my-5 fR">Creating dynamic web solutions, utilizing the latest frontend and backend technologies to ensure scalability and seamless user experiences.</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div >
            <div className="choose_proglint bg-background my-10 " id="content-div-second">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-14 py-12 container mx-auto">
                    <div>
                        <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">Why Proglint?</h2>
                        <p className="text-base text-start text-pColor mb-4 fR">Proglint's legacy speaks volumes: a history of excellence reflected in 225+ successful projects, and a seasoned team boasting 6+ years of expertise. Our commitment goes beyond numbers; it's about fostering lasting partnerships and adapting to ever-evolving tech landscapes. With an unwavering dedication to innovation, we continuously raise the bar, shaping industries and exceeding expectations.</p>
                        <div onClick={() => window.location.reload()}>
                            <Link to={('/contact')}>
                                <div className="flex gap-2 items-center cursor-pointer">
                                    <p className="text-xl underline text-pColor fR">Let’s Talk About Your</p>
                                    <img src={PATH.img.icons.hello} alt="hello" />
                                </div>
                                <p className="text-xl underline text-pColor cursor-pointer fR">New Project</p>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-2">
                            <div className="border-r-2 border-b-2">
                                <div className="counter-second px-0 lg:px-10 py-4 lg:py-8">
                                    <h3 className="text-base text-[#A0A0A0]">We have</h3>
                                    <h2 className="text-4xl my-2 text-hColor fB"><span data-count="6"></span>+</h2>
                                    <p className="text-base fM  text-pColor">Years of experience with experts</p>
                                </div>
                            </div>
                            <div className="border-b-2">
                                <div className="counter-second px-4 lg:px-10 py-4 lg:py-8">
                                    <h3 className="text-base text-[#A0A0A0]">Generated</h3>
                                    <h2 className="text-4xl my-2 text-hColor fB"><span data-count="50"></span>+</h2>
                                    <p className="text-base fM text-pColor">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="border-r-2">
                                <div className="counter-second px-0 lg:px-10 py-4 lg:py-8">
                                    <h3 className="text-base text-[#A0A0A0]">Successfully</h3>
                                    <h2 className="text-4xl my-2 text-hColor fB"><span data-count="225"></span>+</h2>
                                    <p className="text-base fM text-pColor">Projects Delivered</p>
                                </div>
                            </div>
                            <div>
                                <div className="counter-second px-4 lg:px-10 py-4 lg:py-8">
                                    <h3 className="text-base text-[#A0A0A0]">Experts</h3>
                                    <h2 className="text-4xl my-2 text-hColor fB"><span data-count="100"></span>+</h2>
                                    <p className="text-base fM text-pColor">Team members</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="industries container mx-auto">
                <div className="my-4">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">Our Focused Sectors</h2>
                    <p className="text-base text-start text-pColor mb-3">Proglint transforms industries through tailored tech solutions, empowering retail, healthcare, education, finance sectors with innovation-driven digital advancements and strategies.</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-10 my-4 lg:my-10">
                    <div className="relative">
                        <img src={PATH.img.Iretail} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/cosfymart-app')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IretailIcon} alt="retail" />
                                    <p className="text-2xl fSB text-white hover:underline mt-3 cursor-pointer">Retail & Shopping</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ihealth} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/treux-app')}>
                                <main className="absolute bottom-8 px-6 w-52 ">
                                    <img src={PATH.img.icons.IhealthIcon} alt="retail" />
                                    <p className="text-2xl fSB text-white hover:underline mt-3 cursor-pointer">Health Care & Wellness</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ieduction} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/cv-shield')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IeducationIcon} alt="retail" />
                                    <p className="text-2xl fSB  cursor-pointer text-white hover:underline mt-3">Education & e-Learning</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ifinancial} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/qlm-app')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IfinancialIcon} alt="retail" />
                                    <p className="text-2xl cursor-pointer fSB text-white hover:underline mt-3">Financial & Technology</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  my-10 lg:my-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">Leading App Development Services for Digital Empowerment</h2>
                    <p className="text-base text-start text-pColor mb-3">At our core, Proglint embodies a commitment to redefine the digital experience through our high-class app development services. We specialize in crafting bespoke applications that transcend expectations, merging cutting-edge technology with intuitive design. With a focus on user empowerment, innovation, and seamless functionality, our solutions stand as pillars of excellence in the ever-evolving digital realm. Whether it's creating immersive user interfaces or architecting robust backend systems, we strive to empower your digital journey with sophistication and ingenuity.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.quality} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Top-Standard Quality Assurance</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Upholding rigorous quality benchmarks, our apps assure seamless performance, reliability, and user satisfaction at every touchpoint</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.onTime} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">On-Time and Efficient Solutions</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">With agile methodologies, we ensure prompt deliveries without compromising precision, optimizing the development cycle for swift results.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.performance} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">High-Performance</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our focus lies in crafting apps that redefine excellence—speed, stability, and user engagement converge to elevate performance standards.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.puzzle} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Future-Proof Solutions</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Embracing innovation, our solutions are built to adapt and thrive, foreseeing technological shifts and ensuring sustained relevance.</p>
                    </div>
                </div>
            </div >
            <div className="content_banner my-8 relative">
                <img src={PATH.img.contentBanner1} className="h-64 lg:h-full w-full object-cover" alt="banner" />
                <div className="flex flex-col gap-4 absolute top-[10%] lg:top-[30%] lg:bottom-[50%] lg:right-20 px-6">
                    <h2 className="text-white leading-relaxed font-light text-xl lg:text-2xl lg:max-w-xl mb-3">Crafted a top-ranking streaming app in half the expected time, setting a <span className="fSB">new industry benchmark for speed and quality.</span></h2>
                    <div onClick={() => window.location.reload()} className="">
                        <Button LinkPath={('/contact')} value={'Lets Talk'} className={'bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none px-5 text-hColor fSB my-4 lg:my-8'} />
                    </div>
                </div>
            </div>
            <div className="agile my-5 lg:my-12 container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Development Methodology</h2>
                    <p className="text-base text-center text-pColor mb-1">Our streamlined development process ensures clients experience transparent and collaborative milestones, fostering trust and innovation at every stage.</p>
                </div>
                <img src={PATH.img.agile} alt="device-img" className="block m-auto py-6 lg:py-12" />
            </div>
            <Faqs
                Question1={`Will I have my source code if I opt for application development from your mobile app development agency?`}
                Answer1={`Yes, when you choose Proglint for application development, you will have access to your source code. We believe in transparency and provide our clients with the source code, ensuring you have full control over your app's development and future modifications.`}
                Question2={`Why choose Proglint as a mobile app development company?`}
                Answer2={`Choose Proglint as your mobile app development company because we offer a team of skilled professionals with expertise in various technologies, including React Native, Java, Unity3D, and more. We focus on creating high-quality, user-friendly apps tailored to your specific needs, ensuring your project's success. Moreover, our proven track record of success with our global clientele speaks volumes about our journey and client's trust.`}
                Question3={`How much will it cost to build a mobile app?`}
                Answer3={`The cost of building a mobile app varies widely depending on the app's complexity, features, and platform. At Proglint, we offer competitive pricing, and our team works closely with you to provide a customized quote based on your project requirements. Please feel free to contact us for a detailed estimate today!
`}
                Question4={`Which technologies does your mobile app development team work on?`}
                Answer4={`Our mobile app development team works with a range of technologies, including React Native, Java, Unity3D, and more. We adapt the technology stack to match the specific needs of your project, ensuring the best possible performance and user experience.`}
                Question5={`What are the different types of mobile apps?`}
                Answer5={`There are different types of mobile apps, including native, hybrid, and progressive web apps. Native apps are platform-specific and offer the best performance. Hybrid apps work on multiple platforms but may sacrifice some performance. Progressive web apps are web-based and offer cross-platform functionality but with limitations. Proglint can help you choose the right type based on your project goals and target audience.`}
            />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div >
    );
}
export default Apps;