import React from "react";
import FooterContact from "./FooterContact";
import Btn from "../component/Btn";
import { PATH } from "../globals/app-constants";
import { Link} from "react-router-dom";
import ContentBanner2 from "./ContentBanner2";

const Python = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-4">
                    <h1 className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">Python Development Services
                    </h1>
                    <div className="w-full lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.pythoLogo} alt="logo" className="block mx-auto w-32  object-contain lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Python-Powered Solutions Simplified By Our Experts</h2>
                <p className="text-base text-start text-pColor my-1 fR">Boost your business with clever Python solutions designed for smoother processes and better results. Get the benefits of Python for smarter, custom-made applications that suit your needs.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.pythonComputer} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Python Web Application Development</h2>
                        <p className="text-pColor text-sm py-2 fR">Creating powerful and user-friendly websites using Python's strong tools like Django and Flask. We build websites that are easy to use and do exactly what you need.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.pythonDev} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Futuristic Python Development</h2>
                        <p className="text-pColor text-sm py-2 fR">Using Python for advanced things like predicting trends, making smart decisions, and creating future-ready software that stays ahead of the curve.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.systemSettings} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Integration & Migration</h2>
                        <p className="text-pColor text-sm py-2 fR">Helping to smoothly move your existing systems to better ones and making sure all your tools work well together using Python's help.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.pythonAPI} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Flawless API Integrations</h2>
                        <p className="text-pColor text-sm py-2 fR">Making different software talk to each other smoothly by building connections using Python, ensuring they share information easily and work together seamlessly.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.smartThinking} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Smart Thinking Skills</h2>
                        <p className="text-pColor text-sm py-2 fR">Using Python's clever abilities to solve tricky problems and find smart solutions, making processes more efficient and effective.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.bulb} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSBow ">Problem Solving Strategies</h2>
                        <p className="text-pColor text-sm py-2 fR">Creating plans and solutions using Python's smart tools to tackle complex problems, finding effective ways to overcome challenges.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  my-10 lg:my-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Selecting Our Python Developers: Why Us?</h2>
                    <p className="text-base text-start text-pColor my-3 fR">Python stands as a reliable language ensuring maximum security for building effective web applications. Our skilled developers possess diverse industry experience, guaranteeing professionalism in their work. Proglint offers proficient Python developers adept at streamlining work and delivering quality results. Hire our offshore Python developers based on your project requirements.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.integrity} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Honesty & Clarity</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Our commitment to transparency ensures you're informed at every stage, fostering trust and clarity throughout the development journey.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.User} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Seasoned Professionals</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Backed by extensive industry expertise, our seasoned professionals bring innovative solutions tailored to your unique business challenges.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.zeroCost} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Free Project Quote</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We provide cost estimates at no charge, enabling you to plan and allocate resources efficiently without initial financial commitment.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.rocket_icon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Stress-free Project Management</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Our project management approach prioritizes efficiency, ensuring smooth execution and timely delivery, reducing your project management burdens.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.magnifying} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Transparent Work Approach</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We maintain an open line of communication, allowing you to track progress, provide feedback, and make informed decisions at every step.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.onTime} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Flexible Engagement Models</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Choose from a variety of engagement models that suit your project scope, ensuring optimal flexibility and alignment with your business goals.</p>
                    </div>
                </div>
            </div >
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start  text-hColor fB mb-3">Success Stories</h2>
                    <p className="text-base text-start text-pColor my-1 fR">If you are looking for emerging AI and ML solutions, you can choose from our pool of dedicated AI experts. Our experience and tech stack allows us to develop custom AI and ML solutions. Here are our success stories!</p>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-14">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor fB mb-3">Loss Prevention</h2>
                        <p className="text-base text-start text-pColor my-6 fR"> Implemented a robust loss-prevention algorithm for self-checkout kiosks in supermarkets. Prevents barcode mismatches, fake scans, and alerts on suspicious activities, enhancing security without the need for extensive product training. Deployed successfully in labs.</p>
                        <div onClick={() => window.location.reload()}>
                        <Btn LinkPath={('/case-studies/loss-prevention')} value={'View All'} bgColor={'white'} />
                        {/* <Button LinkPath={('/case-studies/loss-prevention')} value={'View All'} className={'bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none px-5 text-hColor fSB my-4'} /> */}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 mt-6 lg:mt-0">
                        <img src={PATH.img.lossPreventionWeb} alt="project" className="w-full h-full" />
                    </div>
                </div>
            </div>
            <div className="process container mx-auto my-4 lg:my-8">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start lg:text-center text-hColor fB mb-3">The Process</h2>
                <p className="text-base text-start lg:text-center text-pColor my-1 lg:px-32 fR">Embracing a comprehensive Agile methodology, our Python development company ensures the delivery of exceptionally satisfying Python solutions.</p>
                <div className="grid grid-cols-2 lg:flex justify-center items-center gap-4 lg:gap-8 my-8 lg:my-20">
                    <div className="circle mt-2 w-40 h-40 lg:w-52 lg:h-52 bg-white border-2 border-gray-300 rounded-full relative flex justify-center items-center">
                        <div className="w-8 h-8 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"><span className="fB text-lg text-hColor flex justify-center items-center">1</span></div>
                        <p className="text-md lg:text-xl text-pColor flex justify-center items-center text-center w-32 fSB">Submit Your Requirement</p>
                    </div>
                    <div className="circle mt-2 w-40 h-40 lg:w-52 lg:h-52 bg-white border-2 border-primary rounded-full relative flex justify-center items-center">
                        <div className="w-8 h-8 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-300 rounded-full"><span className="fB text-lg text-hColor flex justify-center items-center">2</span></div>
                        <p className="texr-md lg:text-xl text-pColor flex justify-center items-center text-center w-32 fSB">Feasibility Discussion</p>
                    </div>
                    <div className="circle mt-2 w-40 h-40 lg:w-52 lg:h-52 bg-white border-2 border-gray-300 rounded-full relative flex justify-center items-center">
                        <div className="w-8 h-8 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-secondary rounded-full"><span className="fB text-lg text-hColor flex justify-center items-center">3</span></div>
                        <p className="text-md lg:text-xl text-pColor flex justify-center items-center text-center w-32 fSB">Select Engagement Model</p>
                    </div>
                    <div className="circle mt-2 w-40 h-40 lg:w-52 lg:h-52 bg-white border-2 border-primary rounded-full relative flex justify-center items-center">
                        <div className="w-8 h-8 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-300 rounded-full"><span className="fB text-lg text-hColor flex justify-center items-center">4</span></div>
                        <p className="text-md lg:text-xl text-pColor flex justify-center items-center text-center w-32 fSB">Deployment of Expert Team</p>
                    </div>
                    <div className="circle mt-2 w-40 h-40 lg:w-52 lg:h-52 bg-white border-2 border-gray-300 rounded-full relative flex justify-center items-center">
                        <div className="w-8 h-8 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"><span className="fB text-lg text-hColor flex justify-center items-center">5</span></div>
                        <p className="text-md lg:text-xl text-pColor flex justify-center items-center text-center w-32 fSB">Continuous Iterations and Support</p>
                    </div>
                </div>
            </div>
            <div className="industries container mx-auto mt-4 lg:mt-14">
                <div className="my-4">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">Industries We Cater</h2>
                    <p className="text-base text-start text-pColor my-3 fR">From retail solutions to cutting-edge technology, we traverse the spectrum of product development, tailored specifically through our Python development lens for our clients</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-10 my-4 lg:my-10">
                    <div className="relative">
                        <img src={PATH.img.Iretail} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/cosfymart-app')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IretailIcon} alt="retail" />
                                    <p className="text-2xl fSB text-white hover:underline mt-3 cursor-pointer">Retail & Shopping</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ihealth} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/treux-app')}>
                                <main className="absolute bottom-8 px-6 w-52 ">
                                    <img src={PATH.img.icons.IhealthIcon} alt="retail" />
                                    <p className="text-2xl fSB text-white hover:underline mt-3 cursor-pointer">Health Care & Wellness</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ieduction} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/cv-shield')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IeducationIcon} alt="retail" />
                                    <p className="text-2xl fSB  cursor-pointer text-white hover:underline mt-3">Education & e-Learning</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ifinancial} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/qlm-app')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IfinancialIcon} alt="retail" />
                                    <p className="text-2xl cursor-pointer fSB text-white hover:underline mt-3">Financial & Technology</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBanner2 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Python