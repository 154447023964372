import React from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link } from "react-router-dom";
import { ArrowLongRightIcon, } from "@heroicons/react/24/solid";

const Career = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="lg:flex container mx-auto lg:items-center">
                    <div className="w-full lg:w-1/2 mt-40">
                        <h1 className="white_banner_title fSB text-hColor w-auto lg:max-w-xl">Career Overview</h1>
                        {/* <p className="my-4 fR text-pColor text-base lg:text-lg md:w-auto lg:max-w-md">Explore opportunities with Hidden Brains that are both challenging and rewarding. Experience collaborative and rewarding environment.</p> */}
                    </div>
                    {/* <div className="w-full lg:w-1/2">
                        <img src={PATH.img.careerLogo} alt="logo" className="block mx-auto w-full lg:w-auto" />
                    </div> */}
                </div>
            </div>
            <div className="container mx-auto my-8 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-3xl">Join Proglint </h2>
                <p className="text-base text-start text-pColor my-3 lg:max-w-3xl fR">We are always looking to work with talented people with an entrepreneurial outlook, a drive to succeed and the confidence to change the world with technology.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10">
                    <div className="group bg-[#f6f6f6] p-8 cursor-pointer hover:shadow-lg" onClick={() => window.location.reload()}>
                        <Link to={('/applying-for-role-java-developer')}>
                            <div className="flex flex-col flex-grow h-full">
                                <h2 className="uppercase text-lg fSB mb-2 text-hColor flex-grow-0 ">Java Developer</h2>
                                <p className="text-pColor text-base mb-4 grow fR">Proglint is looking for skilled programmers with experience building web applications in Java.
                                    Java Developers are responsible for analyzing user requirements and business objectives,
                                    determining application features and functionality, and recommending changes to existing
                                    Java-based applications, among other duties.</p>
                                <div className="flex justify-between">
                                    <h2 className="text-secondary text-base hover:underline flex-grow fR">Full time</h2>
                                    <ArrowLongRightIcon className="w-6 hidden group-hover:block" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="group bg-[#f6f6f6] p-8 cursor-pointer hover:shadow-lg" onClick={() => window.location.reload()}>
                        <Link to={('/applying-for-role-cv-engineer')}>
                            <div className="flex flex-col flex-grow h-full">
                                <h2 className="uppercase text-lg fSB mb-2 text-hColor flex-grow-0 ">CV Engineer</h2>
                                <p className="text-pColor text-base mb-4 grow fR">You will be responsible for developing robust, scalable and efficient Python
                                    applications mainly in relation to Computer Vision, AI and Deep Learning solutions.
                                </p>
                                <div className="flex justify-between">
                                    <h2 className="text-secondary text-base hover:underline flex-grow fR">Full time</h2>
                                    <ArrowLongRightIcon className="w-6 hidden group-hover:block" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="group bg-[#f6f6f6] p-8 cursor-pointer hover:shadow-lg" onClick={() => window.location.reload()}>
                        <Link to={('/applying-for-role-devops-engineer')}>
                            <div className="flex flex-col flex-grow h-full">
                                <h2 className="uppercase text-lg fSB mb-2 text-hColor flex-grow-0">DevOps Engineer</h2>
                                <p className="text-pColor text-base mb-4 grow fR">We’re looking for a DevOps engineer who can be responsible for deploying
                                    product updates, identifying production issues, and implementing integrations that meet our
                                    customers needs.</p>
                                <div className="flex justify-between">
                                    <h2 className="text-secondary text-base hover:underline flex-grow fR">Full time</h2>
                                    <ArrowLongRightIcon className="w-6 hidden group-hover:block" />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="container mx-auto my-8 lg:my-14">
                {/* <h1 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-3xl">Join Proglint </h1>
                <p className="text-sm md:text-base text-start text-pColor my-3 lg:max-w-3xl">We are always looking to work with talented people with an entrepreneurial outlook, a drive to succeed and the confidence to change the world with technology.</p> */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10">
                    <div className="group bg-[#f6f6f6] p-8 cursor-pointer hover:shadow-lg" onClick={() => window.location.reload()}>
                        <Link to={('/applying-for-role-javascript-developer-experience')}>
                            <div className="flex flex-col flex-grow h-full">
                                <h2 className="uppercase text-lg fSB mb-2 text-hColor flex-grow-0 ">JavaScript Developer - Experience</h2>
                                <p className="text-pColor text-base mb-4 grow fR">We are seeking a talented and experienced React Developer to join our dynamic
                                    team. The ideal candidate should have a strong background in front-end development with a focus
                                    on React, Angular. As a JavaScript Developer, you will be responsible for designing and
                                    implementing user interfaces, collaborating with cross-functional teams, and ensuring the
                                    performance and responsiveness of our web applications.</p>
                                <div className="flex justify-between">
                                    <h2 className="text-secondary text-base hover:underline flex-grow fR">Full time</h2>
                                    <ArrowLongRightIcon className="w-6 hidden group-hover:block" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="group bg-[#f6f6f6] p-8 cursor-pointer hover:shadow-lg" onClick={() => window.location.reload()}>
                        <Link to={('/applying-for-role-javascript-developer-fresher')}>
                            <div className="flex flex-col flex-grow h-full">
                                <h2 className="uppercase text-lg fSB mb-2 text-hColor flex-grow-0 "> JavaScript Developer - Freshers</h2>
                                <p className="text-pColor text-base mb-4 grow fR">Proglint is seeking enthusiastic and talented individuals who are passionate about
                                    JavaScript development to join our team as Junior JavaScript Developers. This is an excellent
                                    opportunity for recent graduates or individuals with limited professional experience to kick-start
                                    their career in web development.</p>
                                <div className="flex justify-between">
                                    <h2 className="text-secondary text-base hover:underline flex-grow fR">Full time</h2>
                                    <ArrowLongRightIcon className="w-6 hidden group-hover:block" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="group bg-[#f6f6f6] p-8 cursor-pointer hover:shadow-lg" onClick={() => window.location.reload()}>
                        <Link to={('/applying-for-role-mobile-developer-react-native-and-iOS-developer')}>
                            <div className="flex flex-col flex-grow h-full">
                                <h2 className="uppercase text-lg fSB mb-2 text-hColor flex-grow-0"> Mobile Developer (React Native + iOS Developer)</h2>
                                <p className="text-pColor text-base mb-4 grow fR">At Proglint, We specialize in developing AI-powered infrastructure for retail stores and
                                    constantly strive to remain at the forefront of the latest designs and technologies. With a focus
                                    on next-generation solutions, we are poised to become a global leader in various domains,
                                    including Computer Vision, AI, Web development, Android & iOS development, Machine
                                    Learning, and Kiosk development. Our mission at Proglint is to leverage the potential of
                                    technology and human expertise to optimize organizational processes and enhance efficiency</p>
                                <div className="flex justify-between">
                                    <h2 className="text-secondary text-base hover:underline flex-grow fR">Full time</h2>
                                    <ArrowLongRightIcon className="w-6 hidden group-hover:block" />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-20 mb-14">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Our Tech Hiring Process</h2>
                    <p className="text-base text-center text-pColor my-1 fR">Come discover Hidden Brains - the best platform to take your career to new heights.</p>
                </div>
                <img src={PATH.img.hiringProcess} alt="process" className="block mx-auto my-3" />
            </div>
            <div className="heights container mx-auto">
                <div className="mb-6 lg:mb-10">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Best Platform to Take Your Career to New Heights.</h2>
                    <p className="text-base text-start text-pColor my-1 fR">An intensity to challenge limits, acting with sensitivity and working with unyielding integrity, experience the Spirit of Proglint.</p>
                </div>
                <div className="block md:flex gap-6">
                    <div className="w-full lg:w-3/7 flex flex-col">
                        <div className="relative my-3 w-full h-1/2">
                            <img src={PATH.img.height1} alt="retail" className="w-full h-full object-cover " />
                            <div className="w-full h-full  flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900">
                                <main className="absolute bottom-8 px-6 w-52">
                                    <p className="text-2xl fSB text-white  mt-3 ">Diversity & Inclusion</p>
                                </main>
                            </div>
                        </div>
                        <div className="relative my-3 w-full h-1/2">
                            <img src={PATH.img.height2} alt="retail" className="w-full h-full object-cover" />
                            <div className="w-full h-full  flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900">
                                <main className="absolute bottom-8 px-6 w-52">
                                    <p className="text-2xl fSB text-white  mt-3 ">Innovation & Excellence</p>
                                </main>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-2/7 flex flex-col">
                        <div className="relative my-3">
                            <img src={PATH.img.height3} alt="retail" className="w-full h-full" />
                            <div className="w-full h-full flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900">
                                <main className="absolute bottom-8 px-6 w-52">
                                    <p className="text-2xl fSB text-white  mt-3 ">Training Opportunity</p>
                                </main>
                            </div>
                        </div>
                        <div className="relative my-3">
                            <img src={PATH.img.height4} alt="retail" className="w-full h-full" />
                            <div className="w-full h-full  flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900">
                                <main className="absolute bottom-8 px-6 w-52">
                                    <p className="text-2xl fSB text-white  mt-3 ">Career Development</p>
                                </main>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/7 flex flex-col">
                        <div className="relative my-3 w-full h-1/2">
                            <img src={PATH.img.height5} alt="retail" className="w-full h-full object-cover" />
                            <div className="w-full h-full  flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900">
                                <main className="absolute bottom-8 px-6 w-52">
                                    <p className="text-2xl fSB text-white  mt-3 ">Integrity & Transparency</p>
                                </main>
                            </div>
                        </div>
                        <div className="relative my-3 w-full h-1/2">
                            <img src={PATH.img.height6} alt="retail" className="w-full h-full" />
                            <div className="w-full h-full  flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900">
                                <main className="absolute bottom-8 px-6 w-52">
                                    <p className="text-2xl fSB text-white  mt-3 ">Recognition & Feedback</p>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContact />
        </div >
    )
}
export default Career