import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const IntelVisionCheckout = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Versatile Payment Options: Supports both card payments and cash transactions through an integrated cash machine, ensuring convenience for users with various payment preferences.",
        point2: "Real-time Data Sync: Flat file sync functionality ensures synchronized product information across different platforms, reducing manual entry efforts and ensuring on-premises database accuracy.",
        point3: "Exchange and Refund Facilitation: Intel Vision incorporates exchange and refund functionalities, enhancing customer flexibility for returns and exchanges, thereby contributing to a positive shopping experience."
    },
    {
        title: 'Business challenge',
        challengeDescription: "Ensuring seamless payment processing, synchronized data, and flexible exchange/refund functionalities while maintaining security standards posed initial integration and data consistency challenges."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Synchronized Data Management: Leveraging WinSCP, the application streamlined data synchronization, ensuring real-time updates across platforms and reducing manual intervention.",
        point2: "Secure Payment Integration: Integrated payment gateway APIs ensured secure and hassle-free transactions for users, enhancing overall transactional reliability."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Proglint played a pivotal role in conceptualizing, developing, and implementing the Intel Vision Checkout application, leveraging cutting-edge technologies and strategic integration methods. Through meticulous planning and execution, Proglint seamlessly integrated WinSCP for secure file transfers, implemented robust database synchronization mechanisms, orchestrated the integration of the cash machine interface, and ensured the seamless operation of the Payment Gateway API.",
        deliveredParagraph2: "Additionally, Proglint's expertise facilitated the efficient deployment of exchange and refund functionalities, empowering customers with flexible return options.",
        deliveredParagraph3: "Proglint's comprehensive involvement resulted in the successful delivery of a sophisticated, user-centric application that elevated the shopping experience for Intel enthusiasts while optimizing backend operations for enhanced efficiency."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.intelBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Intel Vision Checkout: Enhanced Shopping Experience'}
                domainText={'E-commerce, Payment Processing, Data Synchronization'}
                durationText={'6 months, 18 man-months'}
                technologyText={'Secure File Transfer Protocol (SFTP) using WinSCP, Real-time Database Synchronization, Embedded Hardware Interface for Cash Machine Integration, Encrypted Payment Gateway API Integration'}
                overviewText={'The Intel Vision Checkout application serves as the dedicated interface for the Vision Checkout kiosk E-commerce platform, providing a specialized shopping experience centered around Intel products. The application offers an intuitive user interface designed to cater specifically to Intel enthusiasts, enabling a seamless and secure shopping journey.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.EquionxThumbnail} RetailHeading={'Business Solutions'} SubHead={'Equinox'} ParaContent={'Explore Equinox, a revolutionary employee management app by Proglint. Streamline HR services, approval processes, and learning opportunities in one user-friendly platform. Witness enhanced efficiency and quick-turnaround solutions for your workforce. Delivered successfully to QICG Tawasul in Qatar.'}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/equinux')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.CVSThumbnail} RetailHeading={'Inventory Management'} SubHead={'CVS'} ParaContent={`Revolutionizing Retail with Smart Fridge Technology. Proglint's innovative Smart Fridge Management System enhances shopping experiences, streamlines inventory, and offers valuable insights. Witness the future of retail automation.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cvs')}> Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.aladdinThumbnail} RetailHeading={'Home Maintenance'} SubHead={'Aladdin'} ParaContent={'Enhancing home services, our mobile-based solution adeptly connects users with service professionals. With dedicated apps for customers and administrators, our platform assures an efficient experience. The Aladdin app stands as a testament to our expertise at Proglint in crafting user-centric home service solutions.'}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/aladdin-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default IntelVisionCheckout;