import React from "react";
import { PATH } from "../globals/app-constants";
import Btn from "../component/Btn";
const ContentBanner2 = () => {
    return (
        <div>
            <div className="content_banner my-8 bg-black h-auto lg:h-96 w-full">
                <div className="container mx-auto relative">
                    <img src={PATH.img.contentBanner2} className="w-full lg:h-full h-80 object-cover lg:w-auto" alt="banner" />
                    <div className="flex flex-col gap-4 absolute top-[25%] lg:top-[30%] lg:bottom-[50%] lg:right-20 px-6">
                        <p className="text-white lg:leading-relaxed fR text-sm lg:text-xl">Our dedicated team is here to make it happen</p>
                        <h1 className="text-white lg:leading-relaxed fB text-base lg:text-xl">Ready to bring your dream project to life?</h1>
                        <div className="mt-3" onClick={() => window.location.reload()}>
                        <Btn LinkPath={('/contact')} value={'Lets Talk'} bgColor={'black'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContentBanner2;