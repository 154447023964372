import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const ShopApp = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Inventory Management & Bistro Configuration: Proglint simplifies inventory tasks, including ordering, stocking, and bistro product configuration within the system.",
        point2: "Customer E-Commerce Platform: A user-friendly interface for ordering products and bistro dishes through web and mobile applications.",
        point3: "Comprehensive Store Manager Tools: Equipped with various roles for effective order management, including Admin, Store manager, Store associate, Bistro manager, and kitchen roles.",
        point4: "Planogram & Reports: The system enables Planogram configuration, stock monitoring, and provides detailed reports on sales trends and inventory insights.",
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Inventory Logic: Developed intricate logics for inventory operations, ensuring effective management.",
        point2: "Promotions Handling: Configured various promotions with complex calculations, managing different combinations.",
        point3: "Planogram Implementation: Detected shelf products via Computer Vision and Machine Learning, ensuring accuracy.",
        point4: "Integration Challenges: Seamlessly integrated Inventory Management with Customer and Store Manager apps."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Inventory Logic Management: Proglint addressed intricate inventory logic by designing detailed processes for onboarding, purchase orders, and inventory adjustments, ensuring precise stock management and reporting.",
        point2: "Varied Promotions Handling: Extensive tested and ensured flawless management of diverse promotions, guaranteeing accurate cost calculations for different offers within the system.",
        point3: "Precise Planogram via Computer Vision: Through rigorous training and testing, Proglint achieved accurate product detection in shelves using Computer Vision, enabling smooth Planogram management.",
        point4: "System Integration Complexity: Meticulously conducted multiple testing phases, ensuring seamless integration among Product Inventory, Customer, and Store Manager applications, facilitating efficient data flow and operations."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.",
        deliveredParagraph2: "This cost-effective solution promised substantial long-term savings for the client while reshaping shop management and elevating customer satisfaction."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.ShopAppBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Integrated Shop Management Solution'}
                domainText={'Inventory Management, E-commerce, Retail'}
                durationText={' 6 months, 20 man-months'}
                technologyText={`Python, Django , RESTful APIs, Cloudpick's API`}
                overviewText={'Shop Management Solution offers an advanced Inventory Management system for L&T + Bistro, WIWO, and E-commerce sections. The platform includes a Customer E-commerce Application for seamless product and bistro dish orders, a versatile Store Manager Application with multiple roles, and a Planogram feature for efficient stock monitoring.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.Tap_and_go_thumbnail} RetailHeading={'Payment Solutions'} SubHead={'Tap and Go'} ParaContent={`Tap and Go app transformed retail by enabling contactless store access through NFC technology, React Native, Node.js, and MongoDB integration. Ensuring secure transactions, it streamlined checkout by swiftly generating carts and adjusting payments at WIWO stores. Proglint's role was pivotal, orchestrating NFC integration and backend development. Their expertise in MongoDB and robust backend systems solidified app reliability. This success set a new standard for contactless retail, showcasing Proglint's commitment to client objectives in partnership with WIWO.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/tap-and-go')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default ShopApp;