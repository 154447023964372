import React, { useState } from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
const Research = () => {
    const [tab, setTab] = useState('computervision');
    const navigate = useNavigate()
    return (
        <div>
            <div className="industry_banner bg-cover bg-center">
                <div className="w-full h-full flex flex-col lg:flex-row items-end container mx-auto mt-20 lg:mt-0">
                    <div className="w-full lg:w-2/3 mb-8 lg:mb-14">
                        <p className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">Research</p>
                        <p className="fR text-base text-black mt-4">Welcome to Proglint Research – Get ahead with our latest insights on AI and machine learning, featuring fresh trends and compelling narratives in visual deep learning and computer vision.</p>
                    </div>
                    <div className="w-full h-1/2 lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.ResearchBook} alt="logo" className="block mx-auto w-44 object-contain lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-11">
                <div className=" flex gap-3 overflow-auto py-6 text-nowrap">
                    <button className={`text-sm fSB  px-3 py-3 rounded-md ${tab == 'computervision' ? 'bg-primary text-white' : 'bg-[#F0F0F0] text-gray-500'}`} onClick={() => setTab('computervision')}>Computer Vision</button>
                    <button className={`text-sm fSB  px-3 py-3 rounded-md ${tab == 'edgeai' ? 'bg-primary text-white' : 'bg-[#F0F0F0] text-gray-500'}`} onClick={() => setTab('edgeai')}>Edge Ai</button>
                    {/* <button className="text-sm fR bg-[#F0F0F0] text-gray-500 px-3 py-3 rounded-md">Deep Learning</button> */}
                </div>
                <div className="grid grid-cols-1 shadow-lg my-4">
                    <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 items-center">
                        <div className="w-full h-full">
                            <img src={PATH.img.research_detail_img3} alt="research-img" className="rounded-t-2xl lg:rounded-tr-none lg:rounded-l-2xl object-cover" />
                        </div>
                        <div className="flex flex-col gap-4 rounded-r-2xl px-2 pb-4 lg:pe-6">
                            <h1 className="fB text-lg md:text-xl lg:text-2xl text-black">AI Models to Power Your Vision AI Applications (2024)</h1>
                            <p className="fR text-base">Unlock how intelligent imaging will transform industries with unmatched accuracy and insight through advanced AI models.</p>
                            <button onClick={() => navigate('/research/ai-models-to-power-ai-applications')} LinkPath={('/research/ai-models-to-power-ai-applications')} className="fR text-xs flex flex-row gap-2 mt-4 lg:mt-6"><p>Learn More</p><ArrowRightIcon className="w-4 h-4" /></button>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 my-10">
                    {tab == 'computervision' &&
                        <div className="shadow-lg ">
                            <div className="flex flex-col gap-4 items-center">
                                <div className="relative ">
                                    <img src={PATH.img.NVIDIADeepStream_img1} alt="img" className="rounded-t-2xl object-cover h-80" />
                                    <div className="absolute top-0 mx-6 my-4 text-white border border-white rounded-full px-3 py-1 ">Computer Vision</div>
                                </div>
                                <div className="flex flex-col gap-4 flex-grow rounded-b-2xl px-4 pb-6">
                                    <h1 className="fB text-lg md:text-xl lg:text-2xl text-black">Real-Time AI with NVIDIA DeepStream, Shaping the Edge of Tomorrow (2024).</h1>
                                    <p className="fR text-base">Experience the future of AI with NVIDIA DeepStream. Unleash the power of real-time video analytics and transform your applications across industries.</p>
                                    <button onClick={() => navigate('/research/NVIDIA_DeepStream')} 
                                   className="fR text-xs flex flex-row gap-2 mt-4 lg:mt-6"><p>Learn More</p><ArrowRightIcon className="w-4 h-4" /></button>
                                </div>
                            </div>
                        </div>
                    }
                    {tab == 'computervision' &&
                        <div className="shadow-lg ">
                            <div className="flex flex-col gap-4 items-center">
                                <div className="relative">
                                    <img src={PATH.img.IntelDeepLearningStreamer_img1} alt="img" className="rounded-t-2xl object-cover h-80 w-full" />
                                    <div className="absolute top-0 mx-6 my-4 text-white border border-white rounded-full px-3 py-1 ">Computer Vision</div>
                                </div>
                                <div className="flex flex-col flex-grow gap-4 rounded-b-2xl px-4 pb-6">
                                    <h1 className="fB text-lg md:text-xl lg:text-2xl text-black">Real-Time Video Analytics with Intel Deep Learning Streamer (Intel DL Streamer),2024</h1>
                                    <p className="fR text-base">Intel-Discover Intel DL Streamer 2024, pioneering the next wave of real-time video analytics and AI innovation.</p>
                                    <button onClick={() => navigate('/research/intel_Deep_Learning_Streamer')} 
                                   className="fR text-xs flex flex-row gap-2 mt-4 lg:mt-6"><p>Learn More</p><ArrowRightIcon className="w-4 h-4" /></button>
                                </div>
                            </div>
                        </div>
                    }
                    {tab == 'edgeai' &&
                        <div className="shadow-lg ">
                            <div className="flex flex-col gap-4 items-center">
                                <div className="relative">
                                    <img src={PATH.img.EdgePlatformImg1} alt="img" className="rounded-t-2xl object-cover h-80" />
                                    <div className="absolute top-0 mx-6 my-4 text-white border border-white rounded-full px-3 py-1 ">Edge Ai</div>
                                </div>
                                <div className="flex flex-col flex-grow gap-4 rounded-b-2xl px-4 pb-6">
                                    <h1 className="fB text-lg md:text-xl lg:text-2xl text-black">Intel® Tiber™ Edge Platform for Unmatched AI and Edge Computing Excellence, 2024</h1>
                                    <p className="fR text-base">Unleash the power of AI and edge computing with Intel® Tiber™ Edge Platform in 2024, delivering unmatched performance and scalability for the next wave of innovation.</p>
                                    <button onClick={() => navigate('Intel-tiber-edge-platform')} 
                                   className="fR text-xs flex flex-row gap-2 mt-4 lg:mt-6"><p>Learn More</p><ArrowRightIcon className="w-4 h-4" /></button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <FooterContact />
        </div>
    )
}
export default Research