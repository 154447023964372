import React from "react";
import { PATH } from "../globals/app-constants";
import Btn from "../component/Btn";
const ContentBanner1 = () => {
    return (
        <div>
            <div className="content_banner relative w-full before:absolute before:left-0 before:right-0 before:top-0 before:z-10 before:h-full before:w-full before:bg-gradient-to-r from-gray-800 to-[#F89B60] before:opacity-70  ">
                <img src={PATH.img.contentBanner3} alt="banner" className="relative z-0 h-80 md:h-96 w-full object-cover" />
                <div className="flex flex-col gap-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 container mx-auto z-20">
                    <p className="text-white leading-relaxed fR text-xl lg:text-4xl">Ready to start your dream project?</p>
                    <h1 className="text-white leading-relaxed my-1 lg:my-4 fB text-xl lg:text-4xl">We have a TEAM to get you there.</h1>
                    <div className="mt-4" onClick={() => window.location.reload()}>
                    <Btn LinkPath={('/contact')} value={'Lets Talk'} bgColor={'black'} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContentBanner1;