import React from "react";
import FooterContact from "./FooterContact";
import { PATH } from "../globals/app-constants";
import ContentBanner1 from "./ContentBanner1";

const MERN = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <p className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">MERN Stack Development Company
                    </p>
                    <div className="w-full h-1/2 lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.MERNlogo} alt="logo" className="block mx-auto w-60 lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  mt-10 mb-10 lg:mt-14 lg:mb-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h1 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-3xl">Revolutionizing Your Business with our MERN Stack Expertise</h1>
                    <p className="text-base text-start text-pColor my-3 fR">Partnering with us for MERN Stack Development ensures cutting-edge prowess across MongoDB, Express.js, React, and Node.js. Renowned for top-tier development services, we boast extensive expertise in the web domain</p>
                    <p className="text-base text-start text-pColor my-3 fR">Our strategic MERN stack framework implementation unlocks a plethora of technical possibilities, fostering innovative solutions. We excel in crafting products that guarantee immersive UX/UI and seamless interfaces, leveraging our proficiency in these frameworks.</p>
                    <p className="text-base text-start text-pColor my-3 fR">With an adept understanding of MERN stack intricacies, we deliver secure, advanced APIs and applications, driving high-tech web resources. Our solutions are precision-engineered to address intricate business challenges, tailored to specific user needs, and comply with the latest web development standards.</p>
                    <p className="text-base text-start text-pColor my-3 fR">Backed by a versatile team fluent in multiple languages and frameworks, our approach ensures unparalleled excellence across every project.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon1} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">All-inclusive end-to-end MERN solutions</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Our comprehensive MERN solutions encompass every stage of development, from conceptualization to deployment. We specialize in the seamless integration of MongoDB, Express.js, React, and Node.js, delivering cohesive, full-stack solutions tailored to your requirements.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon2} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Innovative work labs and streamlined processes</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Within our innovative labs, we foster a culture of creativity and continuous improvement. Our streamlined processes optimize efficiency, ensuring agile development cycles and the swift execution of projects while adhering to industry best practices.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon3} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Highly experienced MERN development team</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Backed by a seasoned team proficient in the MERN stack, we bring unparalleled expertise to every project. Our developers' extensive experience across MongoDB, Express.js, React, and Node.js ensures robust, scalable, and high-performance applications.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.MEANicon4} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Flexible MERN development solutions in your budget</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We offer flexible MERN development solutions customized to suit your budgetary constraints without compromising quality. Our adaptable approach accommodates varying project scopes, delivering value-driven solutions aligned with your financial parameters.</p>
                    </div>
                </div>
            </div >
            <div className="service_section container mx-auto  my-10 lg:my-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-3xl">Proglint: Your Trusted MERN Stack Partner</h2>
                    <p className="text-base text-start text-pColor my-3 fR">At Proglint, reliability in MERN Stack web development is more than just a commitment; it's an ethos ingrained in every facet of our operations. Our adept team, well-versed in MongoDB, Express.js, React, and Node.js, transcends conventional development norms. They craft solutions not merely through coding but by infusing architectural brilliance, enhancing user experiences, and fortifying backends for superior performance.</p>
                    <p className="text-base text-start text-pColor my-3 fR">Our reliability shines through our innovative work labs and streamlined processes. These spaces foster the convergence of cutting-edge technologies and efficient workflows, enabling agile development cycles that ensure prompt project deliveries. Proglint's hallmark reliability extends to our approach - we tailor MERN solutions to diverse budgets without compromising quality, nurturing enduring partnerships built on trust, transparency, and shared success visions.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.webLaptop} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Enterprise Application Development</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We specialize in crafting robust enterprise-level applications leveraging the MERN Stack, ensuring scalability, security, and seamless integration with existing systems. Our solutions cater to diverse business needs and complexities, fostering efficiency and growth.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.desktopDesign} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Web Services and API Development</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Proglint excels in developing powerful web services and APIs using the MERN Stack, facilitating seamless communication between various platforms and systems. Our API solutions are designed to enhance interoperability, connectivity, and data accessibility.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.lessTime} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">SPA and Real-Time App Development</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">With expertise in Single Page Applications (SPAs) and real-time app development using MERN, we engineer dynamic, interactive, and responsive applications. Leveraging cutting-edge technologies, we deliver immersive user experiences and real-time data processing.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.productList} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Support & Maintenance Services</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Beyond development, our commitment extends to providing reliable support and maintenance services for MERN-based applications. We ensure continuous functionality, performance optimization, and timely updates, assuring sustained operational excellence.</p>
                    </div>
                </div>
            </div >
            <div className="development bg-background py-8 mb-10">
                <div className="container mx-auto lg:flex gap-10 items-center">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">MERN Tech-Stack Expertise</h2>
                        <p className="text-base text-start text-pColor my-3 fR">Enhance your business with powerful MERN
                            (MongoDB, Express.js, React, Node.js)
                            development solutions. Harness the prowess
                            of this toolset to craft dynamic web and app
                            solutions, revolutionizing your digital presence
                            and user experience.
                        </p>
                    </div>
                    <div className="w-full lg:w-3/5 grid grid-cols-2  lg:grid-cols-3">

                        <div className="development_item my-4">
                            <h2 className="text-lg fSB ml-3 pb-3">Tools</h2>
                            <div className="development_item_content text-lg fR text-pColor">Redis</div>
                            <div className="development_item_content text-lg fR text-pColor">.Net</div>
                            <div className="development_item_content text-lg fR text-pColor">Fabric</div>
                            <div className="development_item_content text-lg fR text-pColor">GIT</div>
                            <div className="development_item_content text-lg fR text-pColor">JSON</div>
                            <div className="development_item_content text-lg fR text-pColor">Fabric</div>
                        </div>
                        <div className="development_item my-4">
                            <h2 className="text-lg fSB ml-3 pb-3">Skills</h2>
                            <div className="development_item_content text-lg fR text-pColor">Angular Js</div>
                            <div className="development_item_content text-lg fR text-pColor">React</div>
                            <div className="development_item_content text-lg fR text-pColor">HTML5</div>
                            <div className="development_item_content text-lg fR text-pColor">Express</div>
                            <div className="development_item_content text-lg fR text-pColor">CSS</div>
                        </div>
                        <div className="development_item my-4">
                            <h2 className="text-lg fSB ml-3 pb-3">Database</h2>
                            <div className="development_item_content text-lg fR text-pColor">MySQL</div>
                            <div className="development_item_content text-lg fR text-pColor">Mongo DB</div>
                            <div className="development_item_content text-lg fR text-pColor">Node Js</div>
                            <div className="development_item_content text-lg fR text-pColor">PostgreSQL</div>
                            <div className="development_item_content text-lg fR text-pColor">Oracle</div>
                        </div>

                    </div>
                </div>
            </div>
            <ContentBanner1 />
            <FooterContact />
        </div>
    )
}
export default MERN