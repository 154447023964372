import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const Cosfymart = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Enhanced Customer Experience: Intuitive E-commerce app enables customers to place orders, track product status, and subscribe to daily-use items like milk and vegetables via a subscription model.",
        point2: "Robust Inventory Management: Streamlined inventory control within Cosfymart ensures accurate tracking and management of products.",
        point3: "Streamlined Onboarding: A simplified onboarding process for vendors, including a user-friendly interface for product and inventory management, elevating their ease of use.",
        point4: "Comprehensive Reporting: Detailed sales trends, popular product insights, and inventory level reports provide actionable insights for vendors to optimize strategies.",
        point5: "Subscription Module: Empowering vendors to efficiently manage and cater to customer subscriptions for daily-use items, ensuring seamless service."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Vendor Training: Simplifying the onboarding process and offering multilingual accessibility in the app mitigated the challenge of training vendors for product onboarding.",
        point2: "Cost Efficiency: Conducting a thorough cost-benefit analysis, coupled with an innovative approach to process both subscription and regular orders within a single platform and app, led to substantial long-term cost savings."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Streamlined Vendor Onboarding: Simplifying the onboarding process with step-by-step guidance and local language accessibility reduced training complexities and accelerated vendor integration.",
        point2: "Cost-Effective Logic Integration: Efficiently combining daily subscription and regular orders within a unified platform minimized development costs, ensuring a seamless user experience and long-term savings."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.cosfymartBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Seamless E-Commerce Bridge'}
                domainText={'E-commerce, Retail, Mobile & Web Development'}
                durationText={'8 months, 24 man-months'}
                technologyText={'React Native, Node.js, MongoDB, HTML5, CSS3, JavaScript'}
                overviewText={'Proglint spearheaded the development of Cosfymart, an integrated mobile and web-based E-commerce Platform facilitating swift transactions between retail customers and vendors. This solution harmonizes the shopping experience, empowering registered users to effortlessly purchase a myriad of products from various vendors with just a few taps or clicks.'}
                overviewText1={'The project entails the creation of two distinct mobile applications catering to customers and vendors, accompanied by web applications for enhanced accessibility and efficient product and order management.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.EquionxThumbnail} RetailHeading={'Business Solutions'} SubHead={'Equinox'} ParaContent={'Explore Equinox, a revolutionary employee management app by Proglint. Streamline HR services, approval processes, and learning opportunities in one user-friendly platform. Witness enhanced efficiency and quick-turnaround solutions for your workforce. Delivered successfully to QICG Tawasul in Qatar.'}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/equinux')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.CVSThumbnail} RetailHeading={'Inventory Management'} SubHead={'CVS'} ParaContent={`Revolutionizing Retail with Smart Fridge Technology. Proglint's innovative Smart Fridge Management System enhances shopping experiences, streamlines inventory, and offers valuable insights. Witness the future of retail automation.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cvs')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.aladdinThumbnail} RetailHeading={'Home Maintenance'} SubHead={'Aladdin'} ParaContent={'Enhancing home services, our mobile-based solution adeptly connects users with service professionals. With dedicated apps for customers and administrators, our platform assures an efficient experience. The Aladdin app stands as a testament to our expertise at Proglint in crafting user-centric home service solutions.'}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/aladdin-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default Cosfymart;