import React from "react";
import FooterContact from "./FooterContact";
import { PATH } from "../globals/app-constants";
import ContentBanner1 from "./ContentBanner1";
import { Link} from "react-router-dom";

const ReactNative = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <p className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">React Native App Development Company
                    </p>
                    <div className="w-full h-1/2 lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.reactNativeLogo} alt="logo" className="block mx-auto w-40 lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="device my-5 lg:my-10 container mx-auto">
                <div>
                    <h1 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Crafting Powerful Tech Solutions Seamlessly with React Native</h1>
                    <p className="text-base text-center text-pColor mb-1 fR">With 120+ apps across platforms, our React Native expertise soars</p>
                </div>
                <img src={PATH.img.reactNativeDevice} alt="device-img" className="block m-auto py-6 lg:py-12" />
            </div>
            <div className="service_section container mx-auto  mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">Optimize React Native's Advantages for App Development with Proglint</h2>
                    <p className="text-base text-start text-pColor my-3 fR">Harness the power of React Native to expedite mobile app realization within an optimized timeframe. Our cross-platform solution guarantees seamless performance across both Android and iOS, emulating native app experiences. At RipenApps, access impeccable cross-platform development, maximizing React Native's potential with innovative UI/UX designs for superior app outcomes that rival native offerings.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.trendIcon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Cross-Platform Adaptability</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Leverage React Native's flexibility for seamless app deployment across various platforms, ensuring optimal user experience throughout.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.shortTdev} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Shorter Time Development</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Utilize React Native's framework for faster app development, reducing time-to-market and ensuring efficient project delivery.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.thirdParty} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Third-party Integrations</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Effortlessly integrate third-party functionalities into your React Native apps, expanding capabilities and enhancing user experiences.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.dartBoard} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Live Reload</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Utilize the live reload feature in React Native for real-time code changes and instant visual updates, streamlining development processes.</p>
                    </div>
                </div>
            </div >
            <div className="service_projects container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-6 lg:mb-12 max-w-md">Building Strong Bond</h2>
                <div className="lg:flex gap-10">
                    <div className="flex w-full mb-6 lg:w-3/5 h-80 bg-[#6F1A07] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.aladdinWeb} alt="cover-pic" className="absolute object-cover w-full h-full absolute object-cover w-full h-full lg:w-auto lg:h-auto lg:top-0 -right-32 lg:right-0" />
                        <div className="bg_gradient_brown absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl fB mb-3">Aladdin</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10 fR">Enhancing home services, our mobile-based solution adeptly connects users with service professionals. With dedicated apps for customers and administrators, our platform assures an efficient experience. The Aladdin app stands as a testament to our expertise at Proglint in crafting user-centric home service solutions.</p>
                            <h2 className="text-white text-lg fR cursor-pointer underline" onClick={() => window.location.reload()}><Link to={('/case-studies/aladdin-app')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="flex w-full mb-6 lg:w-2/3  bg-primary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.cosfyCoverimg} alt="cover-pic" className="absolute object-cover w-full h-full absolute object-cover w-full h-full lg:w-auto lg:h-auto top-0 -right-48 lg:right-0" />
                        <div className="bg_gradient_orange absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl fB mb-3">Cosfymart</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10 fR">Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.</p>
                            <h2 className="text-white text-lg fR underline cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/cosfymart-app')}>Case Study</Link></h2>
                        </div>
                    </div>
                </div>
                <div className="lg:flex lg:gap-10 lg:mt-10">
                    <div className="flex w-full mb-6 lg:w-2/3 bg-secondary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.NNBweb} alt="cover-pic" className="absolute object-cover w-full h-full absolute object-cover w-full h-full lg:w-auto lg:h-auto top-0 -right-48 lg:right-0" />
                        <div className="bg_gradient_red absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl fB mb-3">Nourish & Bloom</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10 fR">Transform your inventory control and e-commerce strategy with our innovative solutions. Proglint's custom applications for Nourish & Bloom incorporate comprehensive web and mobile functionalities, seamlessly integrating with SAP ERP. Discover streamlined operations and cost-efficient solutions for your business growth.</p>
                            <h2 className="text-white cursor-pointer text-lg fR underline" onClick={() => window.location.reload()}><Link to={('/case-studies/nnb ')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="flex w-full mb-6 lg:w-3/5 h-80 bg-[#26557D] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.humanCoverimg1} alt="cover-pic" className="absolute bottom-12 right-0" />
                        <div className="px-6 py-10 absolute bottom-0 cursor-pointer" onClick={() => window.location.reload()}>
                            <Link to={('/contact')} >
                                <div className="flex flex-row gap-1">
                                    <h2 className="text-white text-lg fR underline">Say Hi</h2>
                                    <img src={PATH.img.icons.hello} alt="hello" className="w-8 h-8" />
                                </div>
                                <h2 className="text-white text-lg fR underline">to Discuss a Project</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-3xl">Hire a versatile team for React Native app development across platforms</h2>
                    <p className="text-base text-start text-pColor my-3 fR">In the world of technology, there's always something new brewing. Flutter, backed by Google, is a flexible framework, allowing the creation of Android & iOS apps using a single codebase swiftly. It offers a native-like experience across different platforms, be it mobile, laptop, desktop, TV, or wearables. Our experts love crafting apps with Flutter due to its myriad benefits.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.bestReactNative} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Premier React Native Development Services</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our developers leverage React Native's modular architecture to build scalable apps, ensuring seamless integration of complex functionalities and third-party APIs.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.shareReactNative} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Proficient in Harnessing React Native's Potential</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We optimize app performance by implementing React Native's native modules for intricate functionalities, ensuring app efficiency and smooth operations. Utilizing React Native's reusable components and Hot Reload feature, we ensure rapid development cycles, enabling quick iterations and bug fixes.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.costEstimate} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Transparent Development Costs</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We provide detailed cost breakdowns, considering aspects like third-party integrations, complex functionalities, and scalability requirements. Our estimates cover development, testing, deployment, and maintenance costs, ensuring transparency and predictability in your project budget.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.productList} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Future-Proofed Products</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We ensure future readiness by adopting React Native's forward-compatible elements, enabling easy adaptability to future OS updates and device advancements. With a focus on scalability and code maintainability, our solutions are built to accommodate future enhancements and technological advancements.</p>
                    </div>
                </div>
            </div >
            <ContentBanner1 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default ReactNative