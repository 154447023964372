import React from "react";
import { PATH } from "../globals/app-constants";
const PortfolioDetail = ({ imgSrc, bannerTitle, bannerSubtitle, durationText, technologyText, domainText, overviewText, overviewText1, Portfoliodetails }) => {
    return (
        <div>
            <div className="bg-cover bg-center relative mt-20">
                <img src={imgSrc} alt="banner" className="h-80 object-cover lg:w-full lg:h-full" />
                <div className="absolute top-0 backdrop-brightness-75 w-full h-full">
                    <div className="absolute top-[30%] container mx-auto">
                        <p className="mb-3 fR text-white text-base lg:text-lg w-auto lg:max-w-2xl uppercase">{bannerSubtitle}</p>
                        <h1 className="banner_title fSB text-white w-auto lg:max-w-2xl">{bannerTitle}</h1>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 container mx-auto lg:px-28 py-10 ">
                <div>
                    <div className="flex gap-4 mb-2 md:mb-0">
                        <div>
                            <img src={PATH.img.icons.domainIcon} alt="icon" className="w-12 h-12 object-contain" />
                        </div>
                        <div className="flex flex-col flex-grow z-10">
                            <h4 className="text-lg text-hColor fM">Domain</h4>
                            <p className="text-sm text-pColor fR flex-grow max-w-xs">{domainText}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex gap-4 mb-2 md:mb-0">
                        <div>
                            <img src={PATH.img.icons.duraionIcon} alt="icon" className="w-8 h-8 lg:w-10 lg:h-10 object-contain" />
                        </div>
                        <div className="flex flex-col flex-grow z-10">
                            <h4 className="text-lg text-hColor fM">Effort and Duration</h4>
                            <p className="text-sm text-pColor fR flex-grow max-w-xs">{durationText}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex gap-4 mb-2 md:mb-0">
                        <div>
                            <img src={PATH.img.icons.technologyIcon} alt="icon" className="lg:w-12 h-12 object-contain" />
                        </div>
                        <div className="flex flex-col flex-grow z-10">
                            <h4 className="text-lg text-hColor fM">Technologies</h4>
                            <p className="text-sm text-pColor fR flex-grow max-w-xs">{technologyText}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pb-10">
                <div className="project_overview">
                    <h2 className="text-xl text-hColor fM mb-3">Project overview</h2>
                    <p className="text-base text-pColor fR pb-2">{overviewText}</p>
                    <p className="text-base text-pColor fR pb-2">{overviewText1}</p>
                </div>
                {Portfoliodetails.map((detail) =>
                    <div className="case_highlight mt-4">
                        <h2 className="text-xl text-hColor fM mb-3">{detail.title}</h2>
                        <p className="my-4">{detail.challengeDescription}</p>
                        {detail.title == 'Delivered solution' ? '' :
                            <ul className="px-4 list-disc">
                                {detail.point1 ?
                                    <li className="py-1 fR">{detail.point1}</li> : ''}
                                {detail.point2 ?
                                    <li className="py-1 fR">{detail.point2}</li> : ''}
                                {detail.point3 ?
                                    <li className="py-1 fR">{detail.point3}</li> : ''}
                                {detail.point4 ?
                                    <li className="py-1 fR">{detail.point4}</li> : ''}
                            </ul>}
                        {detail.title != 'Delivered solution' ? '' :
                            <div className="delivered_solution mt-4">
                                <p className="text-base text-pColor fR my-2">{detail.deliveredParagraph1}</p>
                                <p className="text-base text-pColor fR my-2">{detail.deliveredParagraph2}</p>
                                <p className="text-base text-pColor fR my-2">{detail.deliveredParagraph3}</p>
                            </div>}

                    </div>)}
            </div>
        </div>
    );
};
export default PortfolioDetail;