import React from "react";
import Btn from "../component/Btn";
import { ArrowLongLeftIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
const Job4 = () => {
    return (
        <div className="container mx-auto mt-20">
            <div className="flex gap-1 items-center my-10" onClick={() => window.location.reload()}>
                <Link to={('/career')} className="flex gap-1 items-center">
                    <ArrowLongLeftIcon className="w-6 h-6 text-secondary cursor-pointer" />
                    <p className="text-secondary text-base fSB cursor-pointer">Back to Career</p>
                </Link>
            </div>
            <div className="mt-10 mb-6 lg:mb-10">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center  text-hColor fB mb-3">JavaScript Developer Experience</h1>
                {/* <p className="text-sm text-center text-pColor my-1 mb-3 px-2 lg:px-28">September 20, 2023</p> */}
                {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 w-32 text-center text-sm px-6 block m-auto bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                <div className="flex justify-center">
                    <a href="mailto:hr@proglint.com?subject=Applying for JavaScript Developer Experience">
                        <Btn value={'Apply Now'} bgColor={'white'} />
                        {/* <button className='block mx-auto py-3 rounded-full my-4 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'> Apply Now</button> */}
                    </a>
                </div>
            </div>
            <div className="md:flex my-14">
                <div className="w-full md:w-2/6 lg:w-1/6">
                    <h2 className="text-hColor text-base fSB">Job Information</h2>
                    <ul>
                        <li className="text-sm text-pColor my-3 fR">2 to 3 Years</li>
                        <li className="text-sm text-pColor my-3 fR"> Thiruvanmiyur, Chennai</li>
                        <li className="text-sm text-pColor my-3 fR">Full Time</li>
                    </ul>
                </div>
                <div className="w-full md:w-4/6 lg:w-5/6">
                    <div className="description">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Profile</h2>
                        <p className="text-pColor fR text-sm my-3">PWe are seeking a talented and experienced React Developer to join our dynamic
                            team. The ideal candidate should have a strong background in front-end development with a focus
                            on React, Angular. As a JavaScript Developer, you will be responsible for designing and
                            implementing user interfaces, collaborating with cross-functional teams, and ensuring the
                            performance and responsiveness of our web applications.</p>

                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Front-end Development</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Develop new user-facing features using React / Angular.</li>
                            <li className="text-pColor fR text-sm my-3"> Build reusable components and front-end libraries for future use.</li>
                            <li className="text-pColor fR text-sm my-3"> Ensure the technical feasibility of UI/UX designs.</li>
                            <li className="text-pColor fR text-sm my-3"> Optimize applications for maximum speed and scalability.</li>
                        </ol>
                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Collaboration</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Collaborate with other team members and stakeholders to define, design, and ship new
                                features</li>
                            <li className="text-pColor fR text-sm my-3"> Work closely with designers to implement and maintain a consistent user experience.</li>
                            <li className="text-pColor fR text-sm my-3">Collaborate with back-end developers to integrate user-facing elements with server-side
                                logic.</li>
                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl b-3">Code Quality</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Write clean, efficient, and well-documented code.</li>
                            <li className="text-pColor fR text-sm my-3">Conduct code reviews to ensure code quality and adherence to coding standards.</li>
                            <li className="text-pColor fR text-sm my-3">Debug and resolve issues in a timely manner.
                            </li>

                        </ol>
                    </div>
                    <div className="responsibilities">
                        <h2 className="text-hColor fSB text-xl">Performance Optimization</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Identify and address performance bottlenecks.</li>
                            <li className="text-pColor fR text-sm my-3">Continuously work on improving the front-end development process</li>

                        </ol>
                    </div>
                    <div className="responsibilities">
                        <h2 className="text-hColor fSB text-xl">Testing</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Implement and maintain unit tests</li>
                            <li className="text-pColor fR text-sm my-3">Collaborate with quality assurance (QA) team to ensure high-quality software</li>

                        </ol>
                    </div>
                    <div className="responsibilities">
                        <h2 className="text-hColor fSB text-xl">Learning and Growth:</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Stay updated on emerging trends and technologies in front-end development.
                            </li>
                            <li className="text-pColor fR text-sm my-3">Share knowledge and mentor junior developers.</li>

                        </ol>
                    </div>
                    <div className="description">

                        <h2 className="text-hColor fSB text-xl">Requirements</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> 2 to 3 years of professional experience in front-end development with a focus on React,
                                Angular</li>
                            <li className="text-pColor fR text-sm my-3">  Strong proficiency in JavaScript and its advanced concepts.</li>
                            <li className="text-pColor fR text-sm my-3"> Experience with state management libraries/frameworks (e.g., Redux).</li>
                            <li className="text-pColor fR text-sm my-3"> Knowledge of HTML5, CSS3, and responsive web design principles.
                            </li>
                            <li className="text-pColor fR text-sm my-3"> Familiarity with RESTful APIs and asynchronous programming.</li>
                            <li className="text-pColor fR text-sm my-3"> Experience with version control systems, preferably Git.</li>
                            <li className="text-pColor fR text-sm my-3"> Excellent problem-solving and communication skills
                            </li>
                        </ol>
                    </div>
                    <div className="responsibilities">
                        <h2 className="text-hColor fSB text-xl">Nice to Have
                        </h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Experience with JavaScript build tools (e.g., Webpack).</li>
                            <li className="text-pColor fR text-sm my-3"> Familiarity with server-side rendering and Node.js.</li>
                            <li className="text-pColor fR text-sm my-3">Exposure to other JavaScript frameworks (e.g., Vue.js).
                            </li>

                        </ol>
                    </div>
                    <h2 className="text-sm fSB text-hColor my-4">Please note that we have requirements for this role in Chennai.</h2>
                    {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                </div>
            </div>
        </div>
    );
};
export default Job4