import React from "react";
import Btn from "../component/Btn";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
const Job6 = () => {
    return (
        <div className="container mx-auto mt-20">
            <div className="flex gap-1 items-center my-10" onClick={() => window.location.reload()}>
                <Link to={('/career')} className="flex gap-1 items-center">
                    <ArrowLongLeftIcon className="w-6 h-6 text-secondary cursor-pointer" />
                    <p className="text-secondary text-base fSB cursor-pointer">Back to Career</p>
                </Link>
            </div>
            <div className="mt-10 mb-6 lg:mb-10">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center  text-hColor fB mb-3">Mobile Developer (React Native + iOS Developer)</h1>
                {/* <p className="text-sm text-center text-pColor my-1 mb-3 px-2 lg:px-28">September 20, 2023</p> */}
                {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 w-32 text-center text-sm px-6 block m-auto bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                <div className="flex justify-center">
                    <a href="mailto:hr@proglint.com?subject=Applying for Mobile Developer (React Native and iOS Developer)">
                        <Btn value={'Apply Now'} bgColor={'white'} />
                        {/* <button className='block mx-auto py-3 rounded-full my-4 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'> Apply Now</button> */}
                    </a>
                </div>
            </div>
            <div className="md:flex my-14">
                <div className="w-full md:w-2/6 lg:w-1/6">
                    <h2 className="text-hColor text-base fSB">Job Information</h2>
                    <ul>
                        <li className="text-sm text-pColor my-3 fR"> 3 years</li>
                        <li className="text-sm text-pColor my-3 fR"> Thiruvanmiyur, Chennai</li>
                        <li className="text-sm text-pColor my-3 fR">Full Time</li>
                    </ul>
                </div>
                <div className="w-full md:w-4/6 lg:w-5/6">
                    <div className="description">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Profile</h2>
                        <p className="text-pColor fR text-sm my-3">At Proglint, We specialize in developing AI-powered infrastructure for retail stores and
                            constantly strive to remain at the forefront of the latest designs and technologies. With a focus
                            on next-generation solutions, we are poised to become a global leader in various domains,
                            including Computer Vision, AI, Web development, Android & iOS development, Machine
                            Learning, and Kiosk development. Our mission at Proglint is to leverage the potential of
                            technology and human expertise to optimize organizational processes and enhance efficiency
                        </p>

                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Mobile App Development</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Design, develop, and maintain high-quality mobile applications for iOS and Android
                                platforms using React Native</li>
                            <li className="text-pColor fR text-sm my-3"> Collaborate with cross-functional teams to define, design, and ship new features</li>

                        </ol>
                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Code Development and Maintenance</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Write clean and maintainable code, ensuring the best performance, quality, and
                                responsiveness of applications.

                            </li>
                            <li className="text-pColor fR text-sm my-3"> Identify and address bottlenecks, bugs, and other issues in the codebase.</li>

                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Integration</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Integrate third-party APIs, libraries, and services to enhance app functionality.</li>

                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Testing</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Implement unit testing to ensure the reliability and stability of mobile applications.</li>


                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Performance Optimization</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Continuously optimize application performance, keeping up-to-date with the latest
                                industry trends and best practices
                            </li>


                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Experience</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Proven experience in React Native development for iOS and android platforms.
                            </li>
                            <li className="text-pColor fR text-sm my-3">Solid understanding of iOS development using Swift and/or Objective-C.</li>
                            <li className="text-pColor fR text-sm my-3">Previous experience with mobile app development and deployment on the App Store
                                and PlayStore.</li>

                        </ol>
                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Skills</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Proficient in React Native, JavaScript, Swift, and/or Objective-C</li>
                            <li className="text-pColor fR text-sm my-3"> Familiarity with native iOS development tools and frameworks.</li>
                            <li className="text-pColor fR text-sm my-3"> Strong problem-solving and debugging skills.</li>
                        </ol>
                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Communication</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Excellent communication skills (written and verbal).
                            </li>
                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Adaptability
                        </h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Ability to adapt to new technologies and frameworks quickly</li>

                            <li className="text-pColor fR text-sm my-3"> Ability to work collaboratively in a team environment.</li>
                        </ol>
                    </div>
                    <h2 className="text-sm fSB text-hColor my-4">Please note that we have requirements for this role in Chennai.</h2>
                    {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                </div>
            </div>
        </div>
    );
};
export default Job6