import React, { useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link, useNavigate } from "react-router-dom";
import Faqs from "../../component/Faqs";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

const PersonObjectTracking = () => {
    const [tab, setTab] = useState('Manufacturing');
    const navigate = useNavigate()
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 2,
        slidesToScroll: 1,
    }
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-10 absolute bottom-6 lg:top-[30%]">
                        <h1 className="banner_title fSB text-white w-auto md:w-auto lg:max-w-xl mb-4">Person and Object Tracking</h1>
                        <p className="text-sm text-white lg:max-w-2xl">Effortlessly track and classify objects and people in real time, boosting your efficiency with every image and video. Navigate the future with precision with real-time tracking at your fingertips.</p>
                    </div>
                </div>
                <div className="absolute">
                    <img src={PATH.img.FeaturesPersontracking} alt="banner" className="w-screen h-screen lg:h-full object-cover" />
                    <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 left-0 h-full w-full object-cover" />
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-xl">Use cases of Person and Object Tracking</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3 fR">Elevate your operations with next-gen Person and Object Tracking and Pose Estimation—unlock unparalleled productivity and security like never before. Our AI-powered solutions provide real-time monitoring of people, objects, and assets across diverse industries, from manufacturing floors to bustling campuses. Gain unprecedented visibility, optimize workflows, and make data-driven decisions to enhance efficiency and security with precise posture analysis and movement tracking.
                    </p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.streamline} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Smart Object Management</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Accurately identify and tally objects, inventory, and products to ensure optimal availability and strategic placement. Streamline operations with automated inventory updates.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.BusinessService} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Livestock Monitoring</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Automate the detection and counting of livestock, including predator threat alerts and comprehensive terrain mapping. Monitor livestock health and movement with precision.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.securityLaptop} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Security and Surveillance</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Spot anomalies in crowded areas and receive real-time alerts about potential threats through advanced object detection. Enhance security protocols with intelligent threat analysis.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.qualitycontrolicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Quality Control</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Identify and flag asset defects, ensuring proactive management and prevention of potential issues. Maintain high standards with continuous quality monitoring.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.healthcare} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Weapon Detection</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl fR my-5">Enhance public safety by identifying dangerous objects in real-time at locations such as malls, schools, and hospitals. Implement swift security measures with instant threat alerts.</p>
                    </div>
                </div>
            </div >
            <div className="bg-black pb-4 lg:pb-20">
                <div className="flex container mx-auto gap-4 flex-row overflow-auto pt-28">
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Manufacturing')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Manufacturing' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.ManufacturingtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Manufacturing' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Manufacturing</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Education')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Education' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.EducationtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Education' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Education</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Healthcare')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Healthcare' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.HealthcaretabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Healthcare' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Healthcare</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Retail')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Retail' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.RetailtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Retail' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Retail</p>
                    </div>
                </div>
                {tab == 'Manufacturing' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fR text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Track product movement on the assembly line, identify bottlenecks and improve efficiency.</li>
                                    <li className="text-base fR mb-3 text-white">Detect defects and anomalies in real-time for improved product quality.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize stock levels and prevent shortages or overstocking.</li>
                                    <li className="text-base fR mb-3 text-white">Monitor worker movements to ensure compliance with safety protocols and prevent accidents.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Asset Tracking</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/asset-tracking')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">AI for Quality Control</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-for-quality-control')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Education' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Monitor campus areas to detect unauthorized access and ensure student safety.</li>
                                    <li className="text-base fR mb-3 text-white">Automate attendance tracking and reduce administrative burden.</li>
                                    <li className="text-base fR mb-3 text-white">Monitor campus activity for potential threats and improve emergency response.</li>
                                    <li className="text-base fR mb-3 text-white">Track the usage of educational resources like library books and lab equipment to optimize their availability and usage.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4" >
                                    <h2 className="fR text-base text-white">AI Attendance</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-attendance')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Asset Tracking</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/asset-tracking')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Healthcare' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Locate and track medical equipment for efficient workflow and cost reduction.</li>
                                    <li className="text-base fR mb-3 text-white">Track patient movement, identify potential falls, and optimize staff allocation.</li>
                                    <li className="text-base fR mb-3 text-white">Track medical staff to improve coordination and response times during emergencies.</li>
                                    <li className="text-base fR mb-3 text-white">Enhance security by tracking and controlling visitor access to sensitive areas within the healthcare facility.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Pill Counting</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/pill-counting')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Medical Image Analysis</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/medical-image-analysis')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Retail' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Track product movement, prevent stockouts, and optimize shelf space.</li>
                                    <li className="text-base fR mb-3 text-white">Detect suspicious activity and theft attempts for improved security.</li>
                                    <li className="text-base fR mb-3 text-white">Monitor customer movements to understand shopping patterns and improve store layout.</li>
                                    <li className="text-base fR mb-3 text-white">Identify areas with high and low product sales or foot traffic to boost productivity.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Vision Analytics</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/vision-analytics')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Loss Prevention</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/loss-prevention')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Asset Tracking</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/asset-tracking')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Don't Just Digitize, Be Intelligent</h2>
                <p className="text-base text-start text-pColor my-1">Proglint Makes AI Work for You. Digitize every aspect of your business and uncover opportunities for enhancement at every stage using our advanced AI and computer vision solutions.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.playplugicon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Plug-and-Play Perfection</h2>
                        <p className="text-pColor text-sm py-2">Seamless AI integration with instant, hassle-free setup.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.costSaving} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Smart Savings</h2>
                        <p className="text-pColor text-sm py-2">Slash operating costs by up to 40% with our AI-driven solutions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.security} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Data Security</h2>
                        <p className="text-pColor text-sm py-2">Safeguard your data with top-tier privacy and security protocols.</p>
                    </div>
                </div>
            </div>
            <Faqs
                Question1={`How does real-time tracking and analysis work?`}
                Answer1={`Real-time tracking and analysis systems use advanced algorithms and sensors to monitor and process data from various sources, such as cameras, RFID tags, or GPS devices. This data is then analyzed to provide insights into an object or person's movement, behavior, and interactions.
                `}
                Question2={`Can real-time tracking and analysis protect privacy?`}
                Answer2={`Yes, privacy is a top concern. Advanced systems employ data encryption and anonymization techniques to protect sensitive information while providing valuable insights.`}
                Question3={`How can real-time person/object tracking enhance productivity in the retail industry?`}
                Answer3={`By identifying areas with high and low product sales or foot traffic, businesses can optimize product placement, adjust marketing strategies, and boost overall productivity.`}
                Question4={`How does real-time tracking improve the education sector?`}
                Answer4={`In education, real-time tracking can automate attendance, monitor student and staff locations for better security, optimize classroom and facility usage, and provide insights for enhancing the learning environment.`}
                Question5={`How does real-time tracking contribute to security in various industries?`}
                Answer5={`Real-time tracking enhances security by detecting unauthorized access, identifying suspicious objects/weapons, providing instant alerts, and allowing for proactive threat management across different industry settings.`}
            />
            <FooterContact />
        </div>
    )
}
export default PersonObjectTracking