import React, { useState } from "react";
import FooterContact from "./FooterContact";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import Button from "./Button";
import { PATH } from "../globals/app-constants";
import { Link } from "react-router-dom";
import Industries from "./Industries";
import Faqs from "./Faqs";
const AppDevelopment = () => {
    const [showAccordion, setShowAccordion] = useState({ accordion1: true, accordion2: false, accordion3: false, accordion4: false, accordion5: false })

    const handleAccordion = (accordion) => {

        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5 })
                break;
        }

    }
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="z-[2] absolute top-[35%] lg:top-[25%] container mx-auto">
                    <h1 className="banner_title font-semibold text-white w-auto lg:max-w-xl">Mobile App Development Services</h1>
                </div>
                <div className="absolute right-0">
                    <img src={PATH.img.mobileBanner} alt="banner" className="w-full h-auto lg:h-full object-contain" />
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Unlock the Full Potential of Mobile Apps</h2>
                <p className="text-base text-start text-pColor mb-1">Elevate user experiences, craft intuitive interfaces, and deliver top-tier performance with cutting-edge front-end technologies.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 my-6 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.mobileApp} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Mobile App Design</h2>
                        <p className="text-pColor text-sm py-2">Craft captivating and responsive mobile app interfaces with our expert mobile app design services. Tailored for user engagement.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.nativeApp} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Native App Development</h2>
                        <p className="text-pColor text-sm py-2">Expert Native App Development - Enhancing mobile solutions with top-tier technology, ensuring a seamless user experience across platforms.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.crossApp} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Cross-platform App
                            Development</h2>
                        <p className="text-pColor text-sm py-2">Efficiently craft cross-platform apps with our app development expertise, ensuring compatibility and seamless performance across various devices and platforms.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.appSupport} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Maintenance and
                            Support
                        </h2>
                        <p className="text-pColor text-sm py-2">Comprehensive app development support and maintenance, ensuring smooth operation and optimal performance for your mobile applications.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.appSecurity} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Mobile App
                            Security
                        </h2>
                        <p className="text-pColor text-sm py-2">Ensuring Robust Mobile App Security: Our expert team integrates cutting-edge security measures for reliable and protected applications.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.appQuality} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Quality Assurance
                            and Testing
                        </h2>
                        <p className="text-pColor text-sm py-2">Ensure flawless performance with rigorous testing. Our expertise guarantees top-notch quality for your app development projects.</p>
                    </div>
                </div>
            </div>
            <div className="project container mx-auto  mt-6 lg:mt-16">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start lg:text-center text-hColor font-bold mb-3">Mobile App Development</h2>
                    <p className="text-base text-start lg:text-center text-pColor my-1">We excel in crafting versatile mobile apps that seamlessly run on various platforms, ensuring scalability and robust performance.</p>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-20">
                    <div className="w-full lg:w-2/7">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-semibold mb-3 w-80">Android App Development Solutions</h2>
                        <p className="text-base text-start text-pColor mb-4">Elevate your business with high-performing Android apps that outshine your competition. Our comprehensive Android app development services cover a wide range of devices and ecosystems. As a top-rated mobile app development company, we possess in-depth knowledge of the Android platform, from its core architecture to various device types.</p>
                        <p className="text-base text-start text-pColor my-4">With a global portfolio of Android app projects, we navigate the intricacies of device testing, platform updates, screen resolutions, and more, ensuring reliable products that cater to Android users. Choose us as your Android app development partner to unlock the full potential of the Android ecosystem.</p>
                        <button className="text-xl text-secondary flex gap-2 items-center  align-middle hover:underline" onClick={() => window.location.reload()}><Link to={("/android-app-development-services")} className="flex gap-2"><p>Explore more</p><ArrowLongRightIcon className="w-8 h-8" /></Link> </button>

                    </div>
                    <div className="w-full lg:w-5/7 mt-4 lg:mt-0">
                        <img src={PATH.img.mobileDevice1} alt="project" className="w-full h-full" />
                    </div>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-20">
                    <div className="w-full lg:w-5/7 mt-4 lg:mt-0 hidden lg:block">
                        <img src={PATH.img.mobileDevice2} alt="project" className="w-full h-full" />
                    </div>
                    <div className="w-full lg:w-2/7">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-semibold mb-3 w-80">iOS App Development Services</h2>
                        <p className="text-base text-start text-pColor mb-4">When it comes to creating feature-rich, high-performance iOS apps, our expertise is unmatched. We offer comprehensive iOS application development for a range of Apple devices, including iPhone, iPad, Apple Watch, and Apple TV. As a top-tier iOS app development company, we guide you through the entire development cycle, from concept to deployment.</p>
                        <p className="text-base text-start text-pColor my-4">From wireframes and prototypes to release planning, we provide end-to-end support.</p>
                        <button className="text-xl text-secondary flex gap-2 items-center  align-middle hover:underline" onClick={() => window.location.reload()}><Link to={("/iOS-app-development-company")} className="flex gap-2"><p>Explore more</p><ArrowLongRightIcon className="w-8 h-8" /></Link> </button>
                    </div>
                    <div className="w-full lg:w-5/7 mt-4 lg:mt-0 block lg:hidden">
                        <img src={PATH.img.mobileDevice2} alt="project" className="w-full h-full" />
                    </div>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-20">
                    <div className="w-full lg:w-2/7">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-semibold mb-3 w-80">Hybrid Platform</h2>
                        <p className="text-base text-start text-pColor mb-4">Revolutionize your digital presence with our Hybrid Platform solutions. Seamlessly blending native and cross-platform app development expertise, we craft performance-driven applications that guarantee exceptional user experiences. As a trusted mobile app development company, we prioritize bug-free, secure, and feature-rich solutions. Our team ensures your app resonates with every user, delivering responsiveness and innovation.</p>
                        <p className="text-base text-start text-pColor my-4">Explore the future of digital interaction with our expert hybrid app development services.</p>
                        <button className="text-xl text-secondary flex gap-2 items-center  align-middle hover:underline" onClick={() => window.location.reload()}><Link to={("/hybrid-app-development-services")} className="flex gap-2"><p>Explore more</p><ArrowLongRightIcon className="w-8 h-8" /></Link> </button>
                    </div>
                    <div className="w-full lg:w-5/7 mt-4 lg:mt-0">
                        <img src={PATH.img.mobileDevice3} alt="project" className="w-full h-full" />
                    </div>
                </div>
            </div>
            <div className="content_banner relative w-full before:absolute before:left-0 before:right-0 before:top-0 before:z-10 before:h-full before:w-full before:bg-gradient-to-r from-gray-800 to-[#F89B60] before:opacity-70">
                <img src={PATH.img.contentBanner3} alt="banner" className="relative z-0 h-80 md:h-96 w-full w-full object-cover" />
                <div className="flex flex-col gap-5 absolute top-8 lg:top-20 z-20 container mx-auto">
                    <p className="text-white leading-relaxed font-light text-xl lg:text-4xl">Let's build something extraordinary together.</p>
                    <h2 className="text-white leading-relaxed font-bold text-xl lg:text-4xl">We are here to transform your dreams into cutting-edge mobile applications.</h2>
                    <div className="mt-4" onClick={() => window.location.reload()}>
                        <Button LinkPath={('/contact')} value={'Let’s Connect'} className={'bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none px-5 text-hColor font-semibold my-6 lg:my-8'} />
                    </div>
                </div>
            </div>
            <Industries />
            <Faqs
                Question1={`Will I have my source code if I opt for application development from your mobile app development agency?`}
                Answer1={`Yes, when you choose Proglint for application development, you will have access to your source code. We believe in transparency and provide our clients with the source code, ensuring you have full control over your app's development and future modifications.`}
                Question2={`Why choose Proglint as a mobile app development company?`}
                Answer2={`Choose Proglint as your mobile app development company because we offer a team of skilled professionals with expertise in various technologies, including React Native, Java, Unity3D, and more. We focus on creating high-quality, user-friendly apps tailored to your specific needs, ensuring your project's success. Moreover, our proven track record of success with our global clientele speaks volumes about our journey and client's trust.`}
                Question3={`How much will it cost to build a mobile app?`}
                Answer3={`The cost of building a mobile app varies widely depending on the app's complexity, features, and platform. At Proglint, we offer competitive pricing, and our team works closely with you to provide a customized quote based on your project requirements. Please feel free to contact us for a detailed estimate today!
`}
                Question4={`Which technologies does your mobile app development team work on?`}
                Answer4={`Our mobile app development team works with a range of technologies, including React Native, Java, Unity3D, and more. We adapt the technology stack to match the specific needs of your project, ensuring the best possible performance and user experience.`}
                Question5={`What are the different types of mobile apps?`}
                Answer5={`There are different types of mobile apps, including native, hybrid, and progressive web apps. Native apps are platform-specific and offer the best performance. Hybrid apps work on multiple platforms but may sacrifice some performance. Progressive web apps are web-based and offer cross-platform functionality but with limitations. Proglint can help you choose the right type based on your project goals and target audience.`}
            />
            <FooterContact />
        </div>
    )
}
export default AppDevelopment