import React from "react";
import { Link } from "react-router-dom";
const Btn = ({ value, LinkPath, bgColor, onClick }) => {
    return (
        <div className="wrapper">
            {/* <button className={`fR text-xs border   rounded-full ${bgColor=== 'black' ? `border-white text-white` : `border-gray-950 text-[#F26D55]`} px-4 py-2`} onClick={() => window.location.reload()}><Link className="flex gap-2 " to={LinkPath}><p>{value}</p><ArrowUpRightIcon className="w-4 h-4" style={{ color: `{${bgColor=== 'black' ? `#fff` : `#F26D55`}` }} /></Link></button> */}
            <button className={`fR text-xs border rounded-full ${bgColor === 'black' ? `border-white text-white` : `border-gray-950 text-[#F26D55]`} px-4 py-1`} onClick={() => window.location.reload()}><Link className="flex gap-2 items-center " to={LinkPath}><p>{value}</p><span className={`${bgColor === 'black' ? `white-arrow` : `arrow`}`}></span></Link>
            </button>
        </div>
    )
}
export default Btn