import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import { TagIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
const Retail = () => {


    const [showAccordion, setShowAccordion] = useState({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
    const handleAccordion = (accordion) => {

        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false })
                break;
            case 6:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6 })
                break;
        }

    }
    return (
        <div>
            <div className="home_banner relative">
                <div className="w-full h-full">
                    <img src={PATH.img.SolutionLossprevention} alt="banner" className="h-full w-full object-cover slide active" />
                </div>
                <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                <div className="w-full z-[2] absolute top-[30%] lg:top-[40%]">
                    <div className=" container mx-auto">
                        <div className="mb-14">
                            <div className="flex gap-1 items-center mb-2">
                                <TagIcon className="text-[#7A7A7A] w-5 h-5" />
                                <p className="text-xs fB text-[#7A7A7A]">RETAIL AI</p>
                            </div>
                            <h1 className="text-2xl lg:text-5xl fR text-white">Advanced Loss Prevention in Retail</h1>
                            <p className="text-base max-w-2xl fR text-white mt-4">See It, Stop It, Save It!  Redefine Retail security with our AI-driven Self-checkout, halting fraud in its tracks. No more lines, no more delays - just grab, scan, and let the customers go with total peace of mind.</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <h2 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center">Loss Prevention in Retail with Secure POS and Self-Checkout</h2>
                <p className="text-sm fR text-start lg:text-center text-pColor my-1 mx-auto">Skip the Checkout Line, Not Security. Detect basket mismatches, fake-scanning, and abandoned transactions, our advanced Loss Prevention solution ensures swift action with real-time alerts to the support desk during checkout. Additionally, we expertly manage store flow, analyze shopper behavior, and uncover invaluable product insights.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Benefits</h2>
                        <p className="fR text-sm my-3">Secure every transaction, safeguard every sale—our AI solutions redefine retail loss prevention. Experience unparalleled accuracy and efficiency in fraud detection, and turn security into a competitive advantage.</p>
                        <div className="my-6">
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Shrinkage Reduction</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(1)}>
                                        {showAccordion.accordion1 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Minimize inventory loss through advanced surveillance, analytics, and real-time alerts.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Suspicious Behavior Detection</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(2)}>
                                        {showAccordion.accordion2 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Proactively identify potential theft or fraud with AI-powered behavior analysis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Enhanced Security</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Create a secure shopping environment for customers and employees with comprehensive loss prevention measures.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Profit Maximization</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(4)}>
                                        {showAccordion.accordion4 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Protect your bottom line by reducing shrinkages and optimizing operational efficiency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={PATH.img.SolutionLoss1} alt="retail-img" />
                    </div>
                </div>
            </section>
            <div className="solution-industry-banner">
                <div className="container mx-auto grid grid-cols-2 lg:grid-cols-3 py-20">
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.IndustryIcon} alt="Industry-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Industry</h2>
                            <p className="fR text-xs text-white">Retail, Manufacturing, Healthcare</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.DeliveryModalIcon} alt="Delivery-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Resource Allocation</h2>
                            <p className="fR text-xs text-white">Camera Systems, Hardware Setup, Software Integration</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.TechnologyIcon} alt="Technology-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Tech Frameworks</h2>
                            <p className="fR text-xs text-white">Computer Vision, AI/ML</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.DomainbannerIcon} alt="Domain-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Support</h2>
                            <p className="fR text-xs text-white">Platform-Wide Reach</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.EffortIcon} alt="Effort-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Project Duration</h2>
                            <p className="fR text-xs text-white">3-6 Months, Ongoing</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <img src={PATH.img.SolutionLoss2} alt="retail-img" />
                    </div>
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Core Highlights</h2>
                        <p className="text-sm mt-2 fR">Loss Prevention Made Easy. We use data-driven strategies to optimize store flow, decode shopper behavior, and predict trends for a thriving retail experience.</p>
                        <div className="relative">
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Next-Gen Visual Analysis</h2>
                                    <p className="text-sm fR mt-1">AI video analysis secures point-of-sale and self-checkout by spotting fake scans and abandoned transactions, with instant alerts for theft.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Smart Surveillance Algorithms</h2>
                                    <p className="text-sm fR mt-1">Instantly detect basket-item discrepancies, flagging fake or skip-scans to prevent theft.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Holistic BI Data Solutions</h2>
                                    <p className="text-sm fR mt-1">Enhance decision-making with custom dashboards, interactive reports, and ready-made charts for actionable insights.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Fast Setup, Sharp Vision</h2>
                                    <p className="text-sm fR mt-1">Easy Installation & Top-Tier Cameras Deliver Precise Product Details & Quantities.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Challenge</h2>
                    <div>
                        <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Traditional AI training relies heavily on pre-labeled data, making it time-consuming and limiting the scope of recognizable products.  For accurate product recognition, they often involve extensive manual labeling, creating a significant barrier to adoption. Proglint's anonymous product identification method breaks free from these constraints, enabling faster, more comprehensive training that surpasses the limitations of existing market solutions. </p>
                        {/* <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p> */}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Success</h2>
                    <p className="text-sm fR text-start text-pColor my-1 mx-auto">Proglint's groundbreaking Loss Prevention Algorithm represents a paradigm shift in global retail security. This innovative solution revolutionizes self-checkout experiences with unparalleled theft prevention while ensuring a seamless experience. This innovation sets a new standard for security and efficiency in automated retail.
                        This translates to a win-win situation for both retailers and customers: reduced losses and a smoother, faster checkout experience for everyone.
                    </p>
                </div>
            </div>
            <FooterContact />
        </div>
    )
}
export default Retail