import React from "react";
import FooterContact from "../../component/FooterContact";

const PrivacyPolicy = () => {


    return (
        <div>
            <div className="container mx-auto mt-32">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Privacy Policy</h1>
                <p className="text-sm md:text-base text-start text-pColor fR">Welcome to Proglint Software Solutions Private Limited.</p>
                <p className="text-sm md:text-base text-start text-pColor fR pt-5">Protecting your privacy is a priority, and this Privacy Policy is designed to provide detailed information on how we collect, use, disclose, and protect your personal information. By engaging with our software and services, you agree to the terms outlined in this privacy policy.</p>
                <p className="text-sm md:text-base text-start text-pColor fR pt-5">This Privacy Policy outlines the data collection storage, and processing practices of Proglint Software Solutions (referred to as We, Our, or Us). It elucidates your rights regarding your data and provides on how to reach out to us with any queries.</p>
                <h3 className="text-xl fSB pt-5 mb-3">Information We collect:</h3>
                <p className="text-base fR text-pColor">When you visit our site without registering, we respect your privacy and do not require any personal information. You are free to navigate our site without providing any details.</p>
                <p className="text-base fR text-pColor pt-5">If you choose to use our software or services, we may collect personal information such as your name, email address, contact details, and payment information.</p>
                <p className="text-base fR text-pColor pt-5 fSB mb-3">Usage Data:</p>
                <p className="text-base fR text-pColor">As you navigate our site, we automatically collect the following information about your interactions.</p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">Log files (A record of activities and interactions during your visit to our site, which includes the pages you access and the actions you take)</li>
                    <li className="text-base fR text-pColor">IP Addresses (The geographic locations of our users and enhanced security measures)</li>
                    <li className="text-base fR text-pColor">Browser Type</li>
                    <li className="text-base fR text-pColor">Pages visited</li>
                </div>
                <p className="text-base text-pColor pt-5">Rest assured, we will not utilize the information, including personal details, in any manner.</p>
                <h3 className="text-xl fSB pt-5 mb-3">How We Use Your Information:</h3>
                <p className="text-base fR text-pColor">The collected information is utilized for the following purposes:</p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">To provide and maintain our software and services.</li>
                    <li className="text-base fR text-pColor">To personalize and enhance your user experience.</li>
                    <li className="text-base fR text-pColor">To communicate with you, respond to inquiries, and provide support</li>
                    <li className="text-base fR text-pColor">To analyze usage patterns and trends for informed decision-making</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">Cookies and Analytics:</h3>
                <p className="text-base text-pColor">A small file, known as a cookie, is placed on your computer when you first visit our website. This cookie aids in analyzing web traffic and tracking your activities across the web. We collect this data only with your consent, assuming agreement to our terms of usage. You have the option to avoid this by adjusting your browser settings to reject cookies or by browsing as an anonymous user in incognito mode.</p>
                <p className="text-base fR text-pColor pt-5">In our commitment to enhance the functionality of our website, we are affiliated with several authentic third-party services. It is important to note that their privacy policies may differ from ours, and we do not take responsibility for their agreements</p>
                <p className="text-base fR text-pColor pt-5">For Analytics and business decision-making, we analyze user patterns and behavior. The data collected includes information related to device/browser, IP addresses, and on-site activities. This analysis helps us make informed decisions to enhance our services and tailor them to user preferences.</p>
                <p className="text-base fR text-pColor pt-5">We value your privacy, and we need to clarify that we do not collect personally identifiable information through these means. The data we gather is used solely for statistical purposes and to improve the overall user experience.</p>
                <h3 className="text-xl fSB pt-5 mb-3">Security:</h3>
                <p className="text-base fR text-pColor">We employ industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We regularly review and enhance our security practices.</p>
                <h3 className="text-xl fSB pt-5 mb-3">Caution Regarding Scams:</h3>
                <p className="text-base fR text-pColor">This serves as the official global website of Proglint Software Solutions. Please be aware that we do not operate under any other website domain, and we do not request payments, especially those related to recruitment unless it is directly associated with an official client project.</p>
                <p className="text-base fR text-pColor pt-5">Exercise caution to avoid falling victim to scammers who may impersonate our brand. Proglint Software Solutions cannot be held responsible for any losses incurred if you become a target of such scams, whether knowingly or unknowingly. If you come across any suspicious activity, we encourage you to report it directly to us using the provided contact details. Alternatively, you can also report phishing to Google.</p>
                <h3 className="text-xl fSB pt-5 mb-3">Changes to this Privacy Policy:</h3>
                <p className="text-base fR text-pColor">We may update our Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted on this page, and the effective date will be updated accordingly.</p>
                <h3 className="text-xl fSB pt-5 mb-3">Contact Us:</h3>
                <p className="text-base fR text-pColor">If you have any queries or concerns about our Privacy Policy, please contact us at </p>
                <div className="pl-5">
                    <li className="text-base fR text-pColor">Email: <a className="underline cursor-pointer fSB" href="mailto:info@proglint.com">info@proglint.com</a></li>
                    <li className="text-base fR text-pColor">Phone No: <a className="underline cursor-pointer fSB" href="tel:+919597010401">+91 95970-10401</a></li>
                </div>
                <p className="text-base fR text-pColor my-5 pt-8 mb-11">Thank you for taking the time to understand how Proglint Software Solutions Private Limited handles your information. Your privacy is important to us, and we are committed to maintaining a secure and transparent relationship.</p>
            </div>
            <FooterContact />
        </div>
    );
};
export default PrivacyPolicy;