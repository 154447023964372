import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const QLM = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Benefit Access & Certificate Retrieval: Employees effortlessly access their benefit limits and download health certificates via the mobile applications. The interface allows for quick retrieval and understanding of coverage.",
        point2: "Claim Submission & Tracking: Seamless claim submission and tracking are enabled through intuitive interfaces on the mobile app, ensuring efficient processing and real-time updates for users on their claims' status.",
        point3: "Healthcare Provider Locator: Integration with Google Maps allows users to find information about hospitals and doctors easily. The feature enhances user experience by providing geolocation-based information.",
        point4: "Health & Wellness Features: Employees can set medication reminders, check BMI, access health tips, and find emergency contact numbers. These features promote overall well-being and health awareness."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Hospitals & Doctors Integration: Integrating hospital and doctor information accurately on the Google Maps platform posed challenges due to data standardization and API complexities.",
        point2: "Efficient Claim Tracking: Ensuring seamless communication and tracking of claims between employees and managers required robust claim management protocols and interfaces."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Custom API Integration for Location Mapping: Proglint engineered a customized API integration, enabling accurate and reliable mapping of hospitals and doctor locations. This allowed for precise geolocation services.",
        point2: "Streamlined Claim Management Interface: A dedicated claim management interface was developed, enhancing transparency and accessibility in claim tracking, ensuring smoother interactions between employees and managers."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Successfully implemented at QLM and OQIC in Doha, Qatar, these applications provide an intuitive and comprehensive insurance management platform for both companies. Proglint played a pivotal role in architecting the applications, ensuring seamless integration of functionalities, and providing a user-friendly interface for efficient insurance service access."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.QLMBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Empowering Insurance Accessibility for QLM and OQIC'}
                domainText={'Insurance, Healthcare, Technology'}
                durationText={'6 months, 18 man-months'}
                technologyText={'Mobile Development (iOS, Android), Google Maps API, Claim Management Software'}
                overviewText={'Proglint collaborated with Qatar Life & Medical Insurance Company (QLM) and Oman Qatar Insurance Company (OQIC) to develop cutting-edge mobile applications facilitating insurance services for employees. The applications enable users to access benefits, track claims, download health certificates, locate healthcare providers via Google Maps, set medication reminders, and access essential health-related information seamlessly.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                       <SliderCards ImgPath={PATH.img.shopAppThumbnail} RetailHeading={'E-commerce'} SubHead={'Shop App'} ParaContent={`The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/shopapp-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default QLM;