import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import { TagIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
const QualityControl = () => {


    const [showAccordion, setShowAccordion] = useState({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
    const handleAccordion = (accordion) => {

        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false })
                break;
            case 6:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6 })
                break;
        }

    }
    return (
        <div>
            <div className="home_banner relative">
                <div className="w-full h-full">
                    <img src={PATH.img.SolutionQualityControl} alt="banner" className="h-full w-full object-cover slide active" />
                </div>
                <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                <div className="w-full z-[2] absolute top-[30%] lg:top-[40%]">
                    <div className=" container mx-auto">
                        <div className="mb-14">
                            <div className="flex gap-1 items-center mb-2">
                                <TagIcon className="text-[#7A7A7A] w-5 h-5" />
                                <p className="text-xs fB text-[#7A7A7A]">Quality Control</p>
                            </div>
                            <h1 className="text-2xl lg:text-5xl fR text-white">AI for Quality Control</h1>
                            <p className="text-base max-w-2xl fR text-white mt-4">Automated inspections deliver instantaneous, precise evaluations, dramatically reducing manual tasks and eliminating human errors.</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <h2 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center">AI-Driven Quality Control in Manufacturing—Achieve Flawless Products Every Time.</h2>
                <p className="text-sm fR text-start lg:text-center text-pColor my-1 mx-auto">Quality control in manufacturing gets a high-tech upgrade with AI, revolutionizing the inspection process. This sophisticated technology uses machine vision to meticulously analyze every product on fast-moving lines, detecting elusive flaws that human eyes might miss. With cameras and sensors in play, AI guarantees impeccable product quality and unparalleled accuracy.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Benefits</h2>
                        <p className="fR text-sm my-3">In manufacturing, quality is not just an outcome, it’s a commitment-ensure yours with AI precision. With sophisticated machine vision, AI ensures every product meets the highest standards, improving efficiency and reducing waste.</p>
                        <div className="my-6">
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Uncover Hidden Defects</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(1)}>
                                        {showAccordion.accordion1 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Detect anomalies and imperfections that human eyes might miss, ensuring product excellence.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Enhance Brand Reputation</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(2)}>
                                        {showAccordion.accordion2 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">AI can catch defects before products reach consumers, preventing dissatisfaction and negative reviews.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black"> Reduces Waste</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Machine Learning (ML) prevents defective inputs from entering production, cutting down on product rejections and minimizing waste.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black"> Preserves Product Viability</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(4)}>
                                        {showAccordion.accordion4 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">AI monitors storage and transportation conditions, such as temperature, to ensure product safety and prevent spoilage.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Ensures Regulatory Compliance</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(5)}>
                                        {showAccordion.accordion5 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion5 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">AI helps companies meet strict regulations by identifying compliance issues and ensuring products meet required standards.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={PATH.img.SolutionQualitycontrol1} alt="Quality-control-img" />
                    </div>
                </div>
            </section>
            <div className="solution-industry-banner">
                <div className="container mx-auto grid grid-cols-2 lg:grid-cols-3 py-20">
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.IndustryIcon} alt="Industry-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Industry</h2>
                            <p className="fR text-xs text-white">Manufacturing</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.DeliveryModalIcon} alt="Delivery-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Resource Allocation</h2>
                            <p className="fR text-xs text-white">Hardware Setup, Software Integration</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.TechnologyIcon} alt="Technology-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Tech Frameworks</h2>
                            <p className="fR text-xs text-white">Open CV, Deep Learning</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.DomainbannerIcon} alt="Domain-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Support</h2>
                            <p className="fR text-xs text-white">Platform-Wide Reach</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.EffortIcon} alt="Effort-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Project Duration</h2>
                            <p className="fR text-xs text-white">3-6 Weeks, Iterative</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <img src={PATH.img.SolutionQualitycontrol2} alt="Quality-control-img" />
                    </div>
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Features</h2>
                        {/* <p className="text-sm mt-2 fR">AI securely guards a world where patients whiz through checkout and medications. Upgrade your healthcare checkout process with our advanced computer vision-powered self-checkout, ensuring top-notch security and effortless patient transactions.</p> */}
                        <div className="relative">
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Maintains Quality Uniformity</h2>
                                    <p className="text-sm fR mt-1">Ensure consistent product quality by analyzing data and spotting patterns that affect input standards.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Reveals Hidden Trends</h2>
                                    <p className="text-sm fR mt-1">Uncovers patterns in manufacturing errors, helping to tackle root causes and improve processes.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Predicts Market Needs</h2>
                                    <p className="text-sm fR mt-1">AI forecasts supply and demand, preventing shortages and ensuring timely product availability.
                                    </p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Boosts Automation Efficiency</h2>
                                    <p className="text-sm fR mt-1">Integrates with QC systems and robotics to streamline inspections and speed up decision-making.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Challenge</h2>
                    <div>
                        <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Ensuring uniform quality across production lines can be a daunting task due to variations in equipment, processes, and human oversight. These inconsistencies often lead to unpredictable product quality and missed defects, requiring vigilant and continuous quality checks to maintain standards.</p>
                        {/* <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p> */}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Success</h2>
                   <div>
                   <p className="text-sm fR text-start text-pColor my-1 mx-auto">Proglint transforms quality control with its cutting-edge AI technology, ensuring flawless consistency across all production stages. By automating inspections and standardizing quality checks, Proglint delivers unmatched precision and reliability, making quality issues a thing of the past.</p>
                    <p className="text-sm fR text-start text-pColor my-2 mx-auto">We turns data chaos into clarity by seamlessly integrating and analyzing diverse data streams. Its powerful analytics engine provides actionable insights in real time, enabling manufacturers to swiftly identify and resolve quality issues. This streamlined approach enhances decision-making and fortifies quality control, setting new industry standards.</p>
                   </div>
                </div>
            </div>
            <FooterContact />
        </div>
    )
}
export default QualityControl;