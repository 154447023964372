import React, { useEffect } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import Slider from 'react-infinite-logo-slider'
import { Link  } from "react-router-dom";
import SliderCards from "../../component/SliderCards";

const Company = () => {

    useEffect(() => {
        const counter = document.querySelectorAll(".counter-third span")
        const container = document.querySelector(".counter-third")
        let activated = false;

        const handleScroll = () => {
            const contentDiv = document.getElementById('content-div-third');
            if (!contentDiv) return;

            const contentRect = contentDiv.getBoundingClientRect();
            const contentTop = contentRect.top;
            console.log(contentTop, "contentTop contentTop")
            if ((contentTop >= -420 && contentTop <= 340) && activated === false) {
                counter.forEach(c => {
                    c.innerText = 0;
                    let count = 0;
                    function updateCount() {
                        const target = parseInt(c.dataset.count)
                        if (count < target) {
                            count++
                            c.innerText = count
                            setTimeout(updateCount, 3)
                        } else {
                            c.innerText = target
                        }
                    }
                    updateCount()
                    activated = true
                })
            } else {
                if (contentTop > 190) {
                    counter.forEach(c => c.innerText = 0)
                    activated = false
                }
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className="banner_image bg-cover bg-black bg-center relative">
                <div className="w-full h-full backdrop-brightness-75">
                    <div className="z-[10] container mx-auto">
                        <div className=" absolute top-[35%] md:top-[25%] md:bottom-[25%]">
                            <h1 className="banner_title fSB text-white w-auto lg:max-w-2xl">Transforming Businesses With Innovation</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="promises container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Empowering Your Success</h2>
                <p className="text-base text-start text-pColor">At Proglint, we pledge three key assurances to every client, embodying our commitment to excellence:</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.promisesIcon1} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">World-Class Products</h2>
                        <p className="text-pColor text-sm py-2">Improve your digital presence with high-end mobile app development and design, tailored for startups and brands seeking success.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.promisesIcon2} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Delightful Experiences</h2>
                        <p className="text-pColor text-sm py-2">Crafting user-centric solutions that exceed expectations, ensuring seamless and enjoyable interactions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.promisesIcon3} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Exceeding Expectations</h2>
                        <p className="text-pColor text-sm py-2">Our passion lies in surpassing your envisioned outcomes, delivering beyond what's expected at every turn.</p>
                    </div>
                </div>
            </div>
            <div className="about_section mt-8 lg:mt-20 mb-6 lg:mb-14" id="content-div-third">
                <div className="container mx-auto">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Passion for Your Success</h2>
                    <p className="text-base text-center text-pColor">Welcome to the world's most innovative lab, where cutting-edge technology drives groundbreaking Computer Vision solutions alongside App development and Design. With 50+ apps deployed and 500k+ users engaged, we're defining the future.</p>
                    <div className="flex justify-center items-center">
                        <div className="grid grid-cols-2 md:grid-cols-3 md:flex gap-4 md:gap-12 py-8 md:py-12">
                            <div className="counter-third">
                                <h2 className="text-3xl md:text-4xl fB text-hColor text-center"><span data-count="6"></span>+</h2>
                                <p className="text-hColor fR text-md py-1 md:py-3 text-center">Years of Corporate Excellence</p>
                            </div>
                            <div className="counter-third">
                                <h2 className="text-3xl md:text-4xl fB text-hColor text-center"><span data-count="50"></span>+</h2>
                                <p className="text-hColor fR text-md py-1 md:py-3 text-center">Apps deployed on stores</p>
                            </div>
                            <div className="counter-third">
                                <h2 className="text-3xl md:text-4xl fB text-hColor text-center"><span data-count="300"></span>+</h2>
                                <p className="text-hColor fR text-md py-1 md:py-3 text-center">Entrepreneurs consulted worldwide</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={PATH.img.proglintCartoonImg} alt="aboutImg" className="w-full" />
            </div>
            <div className="future container mx-auto my-10 lg:my-20">
                <div className="gap-10">
                    <div>
                        <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Innovating Tomorrow, Today</h2>
                        <p className="text-base mt-4 fR text-pColor">At Proglint, we embody a relentless pursuit of innovation, envisioning tomorrow's technological landscapes. Our forward-thinking approach drives us to pioneer groundbreaking solutions that redefine industries and revolutionize digital experiences. We are committed to staying at the forefront of technological advancements, shaping a future where innovation converges with user-centric design, propelling businesses and brands toward unparalleled success.</p>
                        <p className="text-base mt-4 fR text-pColor">The future isn't just a concept; it's a reality we shape daily.</p>
                    </div>

                </div>
            </div>
            <div className="story bg-background">
                <div className="lg:flex flex-col gap-8 container mx-auto py-6 lg:py-12">
                    <div className="w-full lg:w-3/5">
                        <div className="mb-6">
                            <p className="text-sm text-start text-pColor mb-3">Our Story</p>
                            <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Catalyzing Innovation: Unveiling the Proglint Journey</h2>
                        </div>

                        <div className="">
                            <p className="text-base text-pColor my-3">Building Proglint has been a labor of love for over a decade. Witnessing over 500 businesses, diverse in size and ambition, unlock their full potential through our solutions has been incredibly rewarding. Every time we see our technology touch lives across the globe, it fuels our passion to keep innovating, and keep pushing the limits.</p>
                        </div>
                    </div>
                    <div className="w-full flex lg:flex-row flex-col gap-6">
                        <div className="flex gap-5 mb-6">
                            <div className="bg-white h-14 w-20 p-3 shadow-xl flex justify-center items-center rounded-md">
                                <img src={PATH.img.icons.bulb} alt="icon" className="w-16 h-16" />
                            </div>
                            <div>
                                <h4 className="text-base text-hColor font-medium">Start</h4>
                                <p className="text-sm text-pColor my-1"> Initiating your digital journey with Proglint's expert guidance and innovative solutions tailored for startups aiming for success.</p>
                            </div>
                        </div>
                        <div className="flex gap-5 mb-6">
                            <div className="bg-white h-14 w-20 p-3 shadow-xl flex justify-center items-center rounded-md">
                                <img src={PATH.img.icons.scale} alt="icon" className="w-16 h-16" />
                            </div>
                            <div>
                                <h4 className="text-base text-hColor font-medium">Scale</h4>
                                <p className="text-sm text-pColor my-1"> Empowering businesses to expand and thrive with Proglint's scalable technologies and strategies designed for growth and sustainability.</p>
                            </div>
                        </div>
                        <div className="flex gap-5 mb-6">
                            <div className="bg-white h-14 w-20 p-3 shadow-xl flex justify-center items-center rounded-md">
                                <img src={PATH.img.icons.streamline} alt="icon" className="w-16 h-16" />
                            </div>
                            <div>
                                <h4 className="text-base text-hColor font-medium">Streamline</h4>
                                <p className="text-sm text-pColor my-1">Streamlining operations and enhancing efficiency through Proglint's transformative solutions, ensuring seamless digital experiences for businesses.</p>
                            </div>
                        </div>
                    </div>
                    <h2 className="text-2xl text-secondary inline-block flex gap-3" onClick={() => window.location.reload()}><Link to={('/contact')} className="cursor-pointer flex gap-3"><span>Get in touch</span><ArrowLongRightIcon className="w-10" /></Link></h2>
                </div>
            </div>
            <div className="globe container mx-auto mt-8 lg:mt-20">
                <div className="lg:flex justify-between items-center">
                    <div className="w-full lg:w-2/5 mb-8 lg:mb-0">
                        <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Global Reach, Local Impact</h2>
                        <p className="text-base text-start text-pColor my-1 max-w-sm">With a robust global presence spanning the USA, Poland, and Thailand, Proglint has delivered exceptional solutions to 50+ clients, including Fortune 500 and Forbes 2000 corporations. Our track record boasts 225+ successful deliveries, solidifying our commitment to global excellence with local impact.</p>
                        <div className="flex gap-6 my-6">
                            <div className="w-16 md:w-20">
                                <img src={PATH.img.USA} className="rounded-md" alt="USA" />
                                <p className="text-pColor text-center my-1 text-sm">USA</p>
                            </div>
                            <div className="w-16 md:w-20">
                                <img src={PATH.img.Europe} className="rounded-md" alt="USA" />
                                <p className="text-pColor text-center my-1 text-sm">Europe</p>
                            </div>
                            <div className="w-16 md:w-20">
                                <img src={PATH.img.thailand} className="rounded-md" alt="USA" />
                                <p className="text-pColor text-center my-1 text-sm">Thailand</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5">
                        <img src={PATH.img.globe} alt="globe" className="" />
                    </div>
                </div>
                <Slider
                    width="250px"
                    duration={40}
                    pauseOnHover={true}
                    blurBorders={false}
                    blurBoderColor={'#fff'}
                >
                    <Slider.Slide>
                        <img src={PATH.img.b1} alt="any" className='w-36' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={PATH.img.b2} alt="any2" className='w-36' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={PATH.img.b3} alt="any3" className='w-36' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={PATH.img.b4} alt="any3" className='w-36' />
                    </Slider.Slide><Slider.Slide>
                        <img src={PATH.img.b5} alt="any3" className='w-36' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={PATH.img.b6} alt="any3" className='w-36' />
                    </Slider.Slide>
                </Slider>
            </div>
            {/* <div className="team_img container mx-auto my-4 lg:my-12">
                <h1 className="text-xl px-4 md:px-0 lg:text-3xl text-center text-hColor fB mb-10">Discover Our Talent</h1>
                <div className="w-full flex gap-5 overflow-auto">
                    <img src={PATH.img.teamimg.bharathi} alt="team" className="max-w-sm max-h-56 object-cover" />
                    <img src={PATH.img.teamimg.kabil} alt="team" className="max-w-sm max-h-56 object-cover" />
                    <img src={PATH.img.teamimg.jagadish} alt="team" className="max-w-sm max-h-56 object-cover" />
                    <img src={PATH.img.teamimg.bharathi} alt="team" className="max-w-sm max-h-56 object-cover" />
                    <img src={PATH.img.teamimg.kabil} alt="team" className="max-w-sm max-h-56 object-cover" />
                </div>
            </div> */}
            <div className="section4 bg-background mt-8 md:pt-6 md:pb-12 py-4">
                <div className="container mx-auto">
                    <div className="pt-6">
                        <p className="text-sm text-start text-pColor fR mb-3">Pioneering in Expertise</p>
                        <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Explore Our Latest Insights and Innovations</h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 mt-6">
                        <SliderCards
                            ImgPath={PATH.img.PaulFashionThumbnail}
                            RetailHeading={'FASHION RETAIL'}
                            SubHead={'Pauls Fashion'}
                            ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <button className="card_find_btn my-5 underline text-xs fB text-hColor text-start text-secondary" onClick={() => window.location.reload()}><Link to={('/case-studies/pauls-fashion')}>Read More</Link></button>
                                </>
                            } />
                        <SliderCards
                            ImgPath={PATH.img.cosfymartThumbnail}
                            RetailHeading={'E-commerce'}
                            SubHead={'Cosfymart'}
                            ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <button className="card_find_btn my-5 underline text-xs fB text-hColor text-start text-secondary" onClick={() => window.location.reload()}><Link to={('/case-studies/cosfymart-app')}>Read More</Link></button>
                                </>
                            } />
                        <SliderCards
                            ImgPath={PATH.img.NNBThumbnail}
                            RetailHeading={'RETAIL'}
                            SubHead={'Nourish & Bloom'}
                            ParaContent={`Transform your inventory control and e-commerce strategy with our innovative solutions. Proglint's custom applications for Nourish & Bloom incorporate comprehensive web and mobile functionalities, seamlessly integrating with SAP ERP. Discover streamlined operations and cost-efficient solutions for your business growth.`}
                            profileContent={
                                <>
                                    <button className="card_find_btn my-5 underline text-xs fB text-hColor text-start text-secondary" onClick={() => window.location.reload()}><Link to={('/case-studies/nnb')}>Read More</Link></button>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <div className="section1 container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-14 mt-20 mb-4">
                    <div>
                        <img src={PATH.img.careerimg} alt="career_img" className="h-80 rounded-xl w-full object-cover shadow-2xl" />
                        <h2 className="text-base fSB text-hColor mt-3">Career at Proglint</h2>
                        <button className="text-secondary cursor-pointer text-sm" onClick={() => window.location.reload()}><Link to={('/career')}>Join Us</Link></button>
                    </div>
                    <div>
                        <img src={PATH.img.contactimg} alt="career_img" className="h-80 rounded-xl w-full object-cover shadow-2xl" />
                        <h2 className="text-base fSB text-hColor mt-3">Contact Us</h2>
                        <button className="text-secondary cursor-pointer text-sm" onClick={() => window.location.reload()}><Link to={('/contact')}>Say Hello</Link></button>
                    </div>
                </div>
            </div>
            <div className="office_activity mt-10 lg:mt-20">
                <div className="flex w-full">
                    <div className="w-full overflow-hidden cursor-pointer">
                        <img
                            alt="gallery"
                            className="block h-full w-full object-cover object-center hover:scale-125 hover:skew-y-3 hover:transition-all hover:duration-300"
                            src={PATH.img.activity1} />
                    </div>
                    <div className="w-3/5 overflow-hidden cursor-pointer">
                        <img
                            alt="gallery"
                            className="block h-full w-full object-cover object-center  hover:scale-125 hover:skew-x-3 hover:transition-all hover:duration-300"
                            src={PATH.img.activity2} />
                    </div>
                    <div className="w-2/5 overflow-hidden cursor-pointer">
                        <img
                            alt="gallery"
                            className="block h-full w-full object-cover object-center  hover:scale-125 hover:skew-y-3 hover:transition-all hover:duration-300"
                            src={PATH.img.activity3} />
                    </div>
                </div>
                <div className="flex w-full">
                    <div className="w-3/5">
                        <div className="h-[50%] overflow-hidden cursor-pointer">
                            <img
                                alt="gallery"
                                className="block h-full w-full object-cover object-center  hover:scale-125 hover:skew-x-3 hover:transition-all hover:duration-300"
                                src={PATH.img.activity4} />
                        </div>
                        <div className="h-[50%] overflow-hidden cursor-pointer">
                            <img
                                alt="gallery"
                                className="block h-full w-full object-cover object-center  hover:scale-125  hover:skew-y-3 hover:transition-all hover:duration-300"
                                src={PATH.img.activity5} />
                        </div>
                    </div>
                    <div className="w-3/5 overflow-hidden cursor-pointer">
                        <img
                            alt="gallery"
                            className="block h-full w-full object-cover object-center  hover:scale-125 hover:transition-all hover:duration-300"
                            src={PATH.img.activity6} />
                    </div>
                    <div className="w-full">
                        <div className="flex">
                            <div className="w-[50%] overflow-hidden cursor-pointer">
                                <img
                                    alt="gallery"
                                    className="block h-full w-full object-cover object-center  hover:scale-125  hover:skew-x-3 hover:transition-all hover:duration-300"
                                    src={PATH.img.activity7} />
                            </div>
                            <div className="w-[50%] overflow-hidden cursor-pointer">
                                <img
                                    alt="gallery"
                                    className="block h-full w-full object-cover object-center  hover:scale-125  hover:skew-y-3 hover:transition-all hover:duration-300"
                                    src={PATH.img.activity8} />
                            </div>
                        </div>
                        <div className="overflow-hidden cursor-pointer">
                            <img
                                alt="gallery"
                                className="block h-full w-full object-cover object-center  hover:scale-125 hover:transition-all hover:duration-300"
                                src={PATH.img.activity9} />
                        </div>
                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
}
export default Company