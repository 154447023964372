import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import ContentBanner2 from "../../../component/ContentBanner2";

const Unattended = () => {
    
    return (
        <div>
            <div className="white_banner relative overflow-hidden bg-cover bg-center ">
                <div className="container mx-auto">
                    <div className="absolute lg-top-[35%] md:bottom-[25%] md:top-[25%] z-10 ">
                        <h1 className="white_banner_title font-semibold text-hColor w-full lg:w-1/2 lg-mb-6 lg:mb-0">Unattended Retail</h1>
                        <p className="text-base text-start text-pColor my-1 w-full lg:w-1/2">Driving retail evolution, our steadfast dedication to innovation empowers retail spaces with streamlined, automated, and adaptable solutions, reshaping the customer experience and transactional processes within retail environments.</p>
                    </div>
                </div>
                <div className="absolute right-0 bottom-0 md:h-full">
                    <img src={PATH.img.Solution_Unattended_Retail} alt="banner" className="w-full h-full object-contain" />
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Embrace Effortless Shopping Experiences with Unattended Retail Solutions</h2>
                <p className="text-base text-start text-pColor my-1">Revolutionize your retail experience with our unattended retail solutions, designed to create seamless and frictionless shopping experiences for customers.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon1} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Smart Fridges and Display Units</h2>
                        <p className="text-pColor text-sm py-2">Offering Real-Time Inventory Tracking, Automated Replenishment, and Demand-Based Pricing to Ensure Product Freshness and Accessibility, Enhancing Customer Experiences.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon2} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Advanced Checkout Solutions</h2>
                        <p className="text-pColor text-sm py-2">Facilitating Contactless Payments, Seamless Transactions, and Minimal Wait Times for Customers. Enjoy Swift, Secure, and Convenient Checkout Experiences Around the Clock.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon3} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Interactive Product Discovery</h2>
                        <p className="text-pColor text-sm py-2">Elevating Customer Engagement with Dynamic Displays, Intuitive Experiences, and Personalized Recommendations, Simplifying Product Understanding and Encouraging Exploration.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-2 max-w-2xl">Proglint's Tech Advancements in the Retail Business Sector</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3">Unlock the potential of your retail business with Proglint's latest tech advancements, empowering businesses to optimize operations, elevate customer experiences, and stay ahead in today's competitive market.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon1} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Enhancing Retail Security</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our advanced solutions employ AI anomaly detection, video surveillance, and access control, ensuring swift detection of suspicious behavior and minimizing losses.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon2} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Securing Customer Buy-In</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">We prioritize ease of adoption with intuitive interfaces and comprehensive support. From tailored training to accessible features, we ensure confidence and comfort in the new shopping experience.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon3} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Data Privacy Assurance</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">We prioritize transparency and control, keeping you informed about data usage while offering full control over personalized features. Our stringent standards ensure privacy and security at all times.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon4} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Simplified System Integration</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Effortlessly fuse our technology with your existing infrastructure, covering POS systems, security cameras, and inventory management software, backed by tailored integration solutions and expert support for seamless implementation.</p>
                    </div>
                </div>
            </div >
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start  text-hColor font-bold mb-3">Seize the Future of Unattended Retail Innovation with Proglint</h2>
                    <p className="text-base text-start text-pColor my-1">Experience the forefront of unattended retail innovation with Proglint, pioneering the future of effortless shopping solutions.</p>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-14">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-bold mb-3">Algorithm</h2>
                        <p className="text-base text-start text-pColor mb-6">Trained on vast datasets of real-world scenarios, our advanced algorithms accurately detect and track customer movements, product interactions, and checkout processes. With seamless differentiation between individuals and products, they enable autonomous transactions while maintaining precision and security. Continuously evolving to match store conditions and customer behaviors, these algorithms ensure optimal performance and a seamless shopping journey.</p>
                    </div>
                    <div className="w-full lg:w-3/5 mt-10 lg:mt-0 h-[420px]">
                        <img src={PATH.img.shopAppThumbnail} alt="project" className="w-full h-full rounded-2xl object-cover" />
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10 pb-4 md:pb-8 relative z-10">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Tackle Key Implementation Challenges in Unattended Retail Solutions</h2>
                <p className="text-sm md:text-base text-start text-pColor mb-3">Optimize labor expenses, extend operational hours, and harness advanced retail technology for enhanced efficiency and profitability.</p>
                <div className="grid grid-cols-1 md:grid-cols-3 my-10 md:my-14 gap-8 lg:gap-16">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Efficient Labor Management</h2>
                        <p className="border-t-2 border-primary text-pColor pt-2 text-base flex-grow">Achieve Up to 75% Labor Cost Reductions with Automation and Staff Optimization</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">24/7 Accessibility</h2>
                        <p className="border-t-2 border-[#26557D] text-pColor pt-2 text-base flex-grow">Expand Operational Hours for Increased Sales and Enhanced Customer Convenience</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Enhancing Customer Delight</h2>
                        <p className="border-t-2 border-secondary text-pColor pt-2 text-base flex-grow">Accelerate Checkouts, Tailored Services, and Minimized Wait Times for Elevated Satisfaction</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 my-10 md:my-14 gap-8 lg:gap-16">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Driving Revenue Growth</h2>
                        <p className="border-t-2 border-primary text-pColor pt-2 text-base flex-grow">Amplify Sales with Enhanced Product Visibility, Targeted Marketing, and Stimulated Impulse Purchases</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Maximizing Insights</h2>
                        <p className="border-t-2 border-[#26557D] text-pColor pt-2 text-base flex-grow">Utilize Valuable Data to Fine-Tune Store Layouts, Optimize Inventory Control, and Refine Marketing Strategies</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Innovative Retail Solutions</h2>
                        <p className="border-t-2 border-secondary text-pColor pt-2 text-base flex-grow">Stay Ahead with Advanced Technology Tailored to Evolve Alongside Dynamic Consumer Trends</p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10 pb-4 md:pb-8 ">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Empower unattended retail with Proglint's data-driven strategy</h2>
                <p className="text-sm md:text-base text-start text-pColor mb-3">Leverage data-driven insights to transform your retail operation with Proglint, optimizing store layout, marketing strategies, and operational efficiency for unparalleled success.</p> 
                <p className="text-sm md:text-base text-start text-pColor mb-3">Trust Proglint to pave the way for cost reduction and enhanced efficiency across every aspect of your retail business.</p>
                <div>
                    <img src={PATH.img.LP_solution_map} alt="map" className="w-full h-full mt-10"/>
                </div>
            </div>
            <ContentBanner2 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Unattended