import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const SliderCards = ({ ImgPath, RetailHeading, SubHead, ParaContent, profileContent }) => {

    return (
        <div className="lg:px-5 h-full" >
            <div className="carousel_card bg-white border border-gray-200 rounded-t-2xl hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] h-full flex flex-col" >
                <img className="rounded-t-2xl w-full h-56 object-cover" src={ImgPath} alt="card_img" />
                <div className="p-5 flex flex-col grow">
                    <p className="text-xs text-start fSB uppercase text-pColor mb-2">{RetailHeading}</p>
                    <h3 className="line-clamp-2 text-lg text-start capitalize text-hColor fSB mb-2">{SubHead}</h3>
                    <p className="line-clamp-5 text-sm text-start text-[#4A4A4A] fR">{ParaContent}</p>
                    {profileContent}
                </div>
            </div>
        </div>
    );
}
export default SliderCards