import React, { useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link } from "react-router-dom"
import Btn from "../../component/Btn";


const Blog = () => {
    let array = [1, 2, 3]
    const [page, setPage] = useState(1)
    const blogs = [
        {
            title: 'Image Annotation for Computer Vision Applications (2024)',
            link: '/blog/image-annotation-for-computer-vision-applications',
            img: PATH.img.blogDetailImg7,
            description: 'Explore how image annotation drives smarter computer vision applications, powering AI accuracy and innovation in 2024.'
        },
        {
            title: 'YOLOv9: The Next Gen of Real-Time Object Detection (2024)',
            link: '/blog/next-gen-of-real-time-object-detection',
            img: PATH.img.blogDetailImg6,
            description: 'Discover how YOLOv9 is redefining real-time object detection in 2024 with unmatched speed, accuracy, and versatility.'
        },
        {
            title: 'Smart Retail with Computer Vision: From Shelf to Checkout (2024)',
            link: '/blog/smart-retail-with-computer-vision',
            img: PATH.img.blogDetailImg5,
            description: `Explore how computer vision is revolutionizing retail with smarter insights and efficiency.`
        },
        {
            title: 'Video Analytics for Predictive AI Insights, 2024',
            link: '/blog/video-analytics-for-predictive-ai-insights',
            img: PATH.img.blogDetailImg4,
            description: 'Discover how video analytics is transforming predictive AI in 2024, turning raw footage into actionable insights for smarter decision-making.'
        },
        {
            title: 'Emerging Trends in Computer Vision for 2024: A Glimpse into the Future',
            link: '/blog/emerging-trends-in-computer-vision',
            img: PATH.img.blogDetailImg3,
            description: 'Explore the advanced trends shaping the future of computer vision in 2024, where innovation meets real-world impact.'
        },
        {
            title: 'The innovation of computer vision with AI and ML',
            link: '/blog/the-innovation-of-computer-vision-with-ai-ml',
            img: PATH.img.blogDetailImg2,
            description: 'Discover how the fusion of computer vision with AI and ML is driving groundbreaking innovations and transforming industries.'
        },
        {
            title: 'Harnessing the Power of Progressive Web Apps(PWAs) for Enhanced User Experience?',
            link: '/blog/harnessing-the-power-of-progressive-web-apps',
            img: PATH.img.blogDetailImg,
            description: 'Uncover how Progressive Web Apps (PWAs) are revolutionizing user experience with speed, reliability, and seamless functionality.'
        }, 
    ];

    const [currentPage, setCurrentPage] = useState(0);
    const blogsPerPage = blogs.length;

    const totalPages = Math.ceil(blogs.length / blogsPerPage);

    const handleNext = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
    };

    const handlePrevious = () => {
        setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
    };

    const currentBlogs = blogs.slice(currentPage * blogsPerPage, (currentPage + 1) * blogsPerPage);

    function renderPage() {
        return <>
            <div className="mb-8 mt-28 md:mt-16">
                <h1 className="banner_title fSB text-hColor w-auto lg:max-w-2xl mb-3">Insights with Blog</h1>
                <p className="mb-4 font-normal text-pColor text-sm md:text-base lg:text-lg w-auto lg:max-w-2xl">Discover fresh business horizons, ride the tech wave, and dive into Digital Innovations. Explore the latest frontiers of tech with our Blog!
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-4 mb-7">
                {currentBlogs.map((blog, index) => (
                    <div className="blog-hover h-100" key={index}>
                        <div className=" blog mb-10 blog-hover-div">
                            
                            <div onClick={() => window.location.reload()}>
                                <Link to={blog.link}>
                                    <img src={blog.img} className="w-full h-full object-cover cursor-pointer blog-img" alt="blog_thumbnail" />
                                </Link>
                            </div>
                            <div onClick={() => window.location.reload()}>
                                <Link to={blog.link}>
                                    <h2 className="text-hColor fB text-lg my-4 cursor-pointer hover:underline">{blog.title}</h2>
                                </Link>
                            </div>
                            <p className="text-pColor fR text-sm md:text-base my-3">{blog.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* <div className="flex gap-5 flex-col pb-3">
                <div className="pagination-controls w-100 flex gap-4 justify-center cursor-pointer">
                    <div className="blog-btn flex rounded-full py-1 px-4" onClick={handlePrevious} disabled={currentPage === 0}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#F36D55" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                        </svg>
                        <button className="fs-12 fR blog-text-color" >Previous</button>
                    </div>

                    <div className="blog-btn flex rounded-full py-1 px-4 cursor-pointer" onClick={handleNext} disabled={currentPage + 1 >= totalPages}>
                        <button className="fs-12 fR blog-text-color" >Next</button>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#F36D55" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                        </svg>
                    </div>
                </div>
                <div className="total-count text-hColor fR fs-12 mb-4 flex justify-center">
                    Showing {currentPage * blogsPerPage + 1}-{Math.min((currentPage + 1) * blogsPerPage, blogs.length)} of {blogs.length} blogs
                </div>
            </div>

            <div className="flex items-center justify-between bg-white px-4 pt-2 pb-7 sm:px-6">
                
                <div className="flex flex-1 items-center justify-between mobile-pagination">
                    <div className="mobile-pagination-text">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">1</span> to <span className="font-medium">4</span> of{' '}
                            <span className="font-medium">4</span> results
                        </p>
                    </div>
                    <div>
                        <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
                            <a
                                href="#"
                                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-[#F89B60] hover:text-[#ffff] focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Previous</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                </svg>
                            </a>
                            <a
                                href="#"
                                aria-current="page"
                                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-[#F89B60] hover:text-[#ffff] focus:z-20 focus:outline-offset-0"
                            >
                                1
                            </a>
                            <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-[#F89B60] hover:text-[#ffff] focus:z-20 focus:outline-offset-0"
                            >
                                2
                            </a>
                            <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-[#F89B60] hover:text-[#ffff] focus:z-20 focus:outline-offset-0"
                            >
                                3
                            </a>
                            
                            <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-[#F89B60] hover:text-[#ffff] focus:z-20 focus:outline-offset-0"
                            >
                                4
                            </a>

                            <a
                                href="#"
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-[#F89B60] hover:text-[#ffff] focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Next</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                            </a>
                        </nav>
                    </div>
                </div>
            </div> */}
        </>
    }
    function renderBlog() {
        if (page === 1) {
            return renderPage()
        } else if (page === 2) {
            return renderPage()
        } else if (page === 3) {
            return <div>
                Hello world
            </div>
        }
    }

    const handlePrev = () => {
        setPage(p => {
            if (p !== 1) {
                return p - 1
            } else {
                return p
            }
        })
    }

    // const handleNext = () => {
    //     setPage(p => {
    //         if (p !== array.length) {
    //             return p + 1
    //         } else {
    //             return p
    //         }
    //     })
    // }
    // console.log(page, 'page')
    return (
        <div>
            <div className="container mx-auto mt-20">
                <div className="md:flex gap-10 mt-6">
                    <div className="w-full md:w-8/12">
                        {
                            renderBlog()
                        }
                    </div>
                    <div className="w-full md:w-4/12 mob-padd">
                        <div className="border border-gray-200 mt-0 relative pb-10 overflow-hidden">
                            <img src={PATH.img.blogPoster} alt="blog_poster" className="w-full absolute -top-12 md:-top-18 left-0" />
                            <div className="relative z-30">
                                <p className="fSB text-lg p-8 text-center">Ready to build apps that are secure, robust, & scalable?</p>
                                <p className="fSB text-lg p-4 text-center text-secondary mt-4 lg:mt-10">Let us be your partner</p>
                                <ul className="list-disc px-8 flex flex-col gap-3">
                                    <li className="text-pColor text-base fR">100+ App Developers</li>
                                    <li className="text-pColor text-base fR">50+ Happy Clients</li>
                                    <li className="text-pColor text-base fR">225+ Successful Projects</li>
                                    <li className="text-pColor text-base fR">100% Code Security</li>
                                    <li className="text-pColor text-base fR">6+ Years of Experience</li>
                                </ul>
                            </div>
                        </div>
                        <div className="border border-gray-200 p-6 mt-6">
                            <h2 className="text-hColor text-lg fM mb-3">Crafting top-notch, efficient, & user-friendly apps for professional use.</h2>
                            <ul className="border-t border-gray-400 py-2">
                                <div onClick={() => window.location.reload()}>
                                    <Link to={('/android-app-development-services')}>
                                        <li className="py-1 fR">Android</li>
                                    </Link>
                                    <Link to={('/iOS-app-development-company')}>
                                        <li className="py-1 fR">iOS</li>
                                    </Link>
                                    <Link to={('hybrid-app-development-services')}>
                                        <li className="py-1 fR">Hybrid</li>
                                    </Link>
                                    <Link to={('/web-development-services')}>
                                        <li className="py-1 fR">Web Development</li>
                                    </Link>
                                </div>
                            </ul>
                        </div>
                        <div className="border border-gray-200 p-6 mt-6">
                            <h2 className="text-hColor text-lg fM mb-3">Categories</h2>
                            <ul className="border-t border-gray-400 py-2">
                                <div onClick={() => window.location.reload()}>
                                    <Link to={('/blog/harnessing-the-power-of-progressive-web-apps')}>
                                        <li className="py-1 fR">Web Development</li>
                                    </Link>
                                    <Link to={('/blog/harnessing-the-power-of-progressive-web-apps')}>
                                        <li className="py-1 fR">Mobile Development</li>
                                    </Link>
                                    <Link to={('/blog/harnessing-the-power-of-progressive-web-apps')}>
                                        <li className="py-1 fR">App Development</li>
                                    </Link>
                                    <Link to={('/blog/harnessing-the-power-of-progressive-web-apps')}>
                                        <li className="py-1 fR">Application Trends</li>
                                    </Link>
                                    <Link to={('/blog/the-innovation-of-computer-vision-with-ai-ml')}>
                                        <li className="py-1 fR">Artificial Intelligence</li>
                                    </Link>
                                    <Link to={('/blog/the-innovation-of-computer-vision-with-ai-ml')}>
                                        <li className="py-1 fR">Machine Learning</li>
                                    </Link>
                                    <Link to={('/blog/the-innovation-of-computer-vision-with-ai-ml')}>
                                        <li className="py-1 fR">Computer vision</li>
                                    </Link>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default Blog;