import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const TapAndGo = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Contactless Entry: Customers effortlessly gain store access via card tap, simplifying the shopping experience.",
        point2: "Seamless Shopping: The app generates a shopping cart upon exit, enabling convenient, accurate payments based on items selected."
    },
    {
        title: 'Business challenge',
        challengeDescription: "Implementing secure, efficient contactless transactions while ensuring accurate payment adjustments for purchases made during store visits posed significant challenges.",
    },
    {
        title: 'Overcoming Challenges',
        point1: "Streamlined Payment Process: The app's backend was optimized for instant cart generation and precise payment adjustments.",
        point2: "NFC Integration: Seamless integration of NFC technology facilitated quick, secure card-based access and payments."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Tap and Go app transformed retail by enabling contactless store access through NFC technology, React Native, Node.js, and MongoDB integration. Ensuring secure transactions, it streamlined checkout by swiftly generating carts and adjusting payments at WIWO stores. Proglint's role was pivotal, orchestrating NFC integration and backend development. Their expertise in MongoDB and robust backend systems solidified app reliability. This success set a new standard for contactless retail, showcasing Proglint's commitment to client objectives in partnership with WIWO.",
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.Tap_and_go_banner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Contactless Store Access App'}
                domainText={'Retail, Payment Solutions, Contactless Technology'}
                durationText={'6 months, 22 man-months'}
                technologyText={'NFC (Near Field Communication), Payment APIs (Stripe, PayPal), React Native, Backend (Node.js), Database (MongoDB)'}
                overviewText={'The Tap and Go application revolutionizes WIWO store access, offering a seamless, contactless experience. Customers use their debit or credit cards to pre-authorize entry, shop hassle-free, and conveniently pay upon exit.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.shopAppThumbnail} RetailHeading={'E-commerce'} SubHead={'Shop App'} ParaContent={`The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/shopapp-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default TapAndGo;