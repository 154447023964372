import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const LeelaKitchen = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Seamless Customer Ordering: A simplified journey for customers to select and place orders seamlessly through the website.",
        point2: "Bulk Order Enquiry: A comprehensive form facilitating inquiries for listed and new dishes.",
        point3: "Dynamic Dish Configuration: Configuration of dishes based on weekly schedules, enabling orders 24 hours in advance.",
        point4: "Real-time Dish Display: Displaying dishes on the customer website in line with configurations and stock availability.",
        point5: "Efficient Inventory Management: Tracking and managing inventory of dishes within the Leela Kitchen application",
        point6: "Detailed Reporting: Generating comprehensive reports on sales trends, popular products, and inventory levels, offering valuable insights for vendors."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Stock Availability Logic: Designing logic for dish configuration and customer ordering while restricting order timings.",
        point2: "Payment Portal Integration: Integrating the system with a payment portal for seamless transactions.",
        point3: "Cost-Effective Implementation: Implementing advanced logics in a cost-effective four-page customer journey application."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Simplified Logic Implementation: Proglint devised a straightforward yet effective logic for dish configuration and customer ordering, ensuring ease of use.",
        point2: "Rigorous Testing & Integration: Comprehensive sandbox testing and seamless integration with the payment portal ensured smooth functionality.",
        point3: "Cost-Optimized Solution: By focusing on a concise yet functional customer journey application, Proglint delivered a cost-effective solution without compromising on essential functionalities."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Successfully implemented and operational at Leela Kitchen, Proglint's system revolutionized the ordering process, simplifying dish configuration, inventory management, and customer ordering while maintaining cost efficiency."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.leelakitchenBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Streamlined Ordering System for Leela Kitchen'}
                domainText={'Food & Catering technology'}
                durationText={'3 months, 12 man-months'}
                technologyText={'React.js, Node.js, MySQL, MongoDB'}
                overviewText={'Proglint pioneered the development of a user-friendly system for Leela Kitchen, enabling customers to conveniently order dishes through a website. The system incorporated a bulk ordering feature, dish configurations, and real-time inventory management, optimizing the ordering process.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={'/case-studies/jedidahs'}>Read more</Link>
                                </>
                            } />

                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.shopAppThumbnail} RetailHeading={'E-commerce'} SubHead={'Shop App'} ParaContent={`The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/shopapp-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default LeelaKitchen;