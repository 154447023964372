import React from "react";
import Navbar from "./navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
const Layout = () => {
    return (
        <div>
            <Navbar/>
            <div className="wrapper">
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
}
export default Layout;