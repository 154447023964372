import React from "react";
import { Link } from "react-router-dom";

const Button = ({ value, className, LinkPath, onClick }) => {
    return (
        <div className="">
            <Link to={LinkPath} className={`px-6 py-3 rounded-full text-sm font-semibold bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none hover:bg-gradient-to-br hover:from-secondary from-30% hover:to-primary to-90% ${className}`} onClick={onClick}>{value}</Link>
        </div>
    )
}
export default Button