import React, { useEffect, useState } from "react";
import { PATH } from "../../globals/app-constants";
import Button from "../../component/Button";
import { CheckIcon } from '@heroicons/react/24/solid'
import FooterContact from "../../component/FooterContact";
import Industries from "../../component/Industries";
import ContentBanner2 from "../../component/ContentBanner2";
import Faqs from "../../component/Faqs";

const Product = () => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  
    return (
        <div>

            <div className="service_banner_h relative overflow-hidden ">
                <div className="container mx-auto">
                    <div className="absolute top-[35%] md:bottom-[25%] md:top-[25%] z-10 ">
                        <h1 className="banner_title fSB text-white w-auto lg:max-w-2xl">Software Product Development Services
                        </h1>
                    </div>
                </div>
                <div className="absolute right-0 bottom-0 md:h-full">
                    <img src={PATH.img.productBanner1} alt="banner" className="w-full h-full object-contain" />
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Product Development</h2>
                <p className="text-base text-start text-pColor fR">Proglint redefines innovation through advanced Product Development. From concept to launch, our agile methodologies and cutting-edge technologies craft transformative solutions that redefine industry standards, ensuring your visionary ideas reach their full potential.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.planning} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Budgeting & planning</h2>
                        <p className="text-pColor text-sm py-2 fR">Effective budgeting and meticulous planning ensure streamlined resource allocation and project progression, fostering cost-efficiency and project success.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.bulb} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Have An Idea?</h2>
                        <p className="text-pColor text-sm py-2 fR">We transform your raw ideas into viable, actionable plans, infusing innovation and strategy to nurture and materialize your vision effectively.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.promisesIcon3} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Startup MVP</h2>
                        <p className="text-pColor text-sm py-2 fR">Crafting a Minimum Viable Product (MVP) sets the foundation for your startup's success, allowing for iterative development and early user feedback to refine your product.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.approach} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Correct Approach</h2>
                        <p className="text-pColor text-sm py-2 fR">Adopting the right approach aligns your project with strategic goals, ensuring the right methodologies and technologies are employed for optimal outcomes.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.feasibility} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Feasibility Check</h2>
                        <p className="text-pColor text-sm py-2 fR">Conducting thorough feasibility checks scrutinizes project viability, considering technical, economic, and operational aspects to ensure sustainable development.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.feedback} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Feedback Mechanism</h2>
                        <p className="text-pColor text-sm py-2 fR">A robust feedback mechanism acts as a compass, guiding iterative improvements and enhancements based on user insights and market responses.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  my-10 lg:my-16 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Our Product Strategies & Discovery Process</h2>
                    <p className="text-base text-start text-pColor fR">At Proglint, we propel your digital aspirations into reality through a robust product strategy and discovery process. Our approach amalgamates cutting-edge technology, market analysis, and user-centric methodologies to craft products that resonate with your audience.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.onTime} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Defining Objectives</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We delve deep into your objectives, understanding your business, goals, and user needs to define a clear roadmap for your product's success.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.memory} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Market Research & Analysis</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Our team conducts comprehensive market research and competitor analysis to identify gaps and opportunities, ensuring your product stands out.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.aiml} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">User-Centric Design Thinking</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">We leverage design thinking principles, placing your users at the core to ideate and conceptualize intuitive and engaging experiences.</p>
                    </div>
                </div>
            </div >
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start lg:text-center  text-hColor fB mb-3">Holistic Development Procedures</h2>
                    <p className="text-base text-start lg:text-center text-pColor px-2 lg:px-28 fR">Craft your ambitions into reality leveraging our diverse Software Development methodologies. Tailor your approach to suit your specific requirements, optimizing resources, timelines, and budgets for seamless project execution.</p>
                </div>
                <div className="lg:flex lg:gap-16 my-6 lg:my-14 align-middle items-center">
                    <div className="w-full lg:w-3/7">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor fB mb-3 max-w-md">Tailored Software Development Methodologies</h2>
                        <p className="text-base text-start text-pColor mb-4 fR">Integrating the strengths of various industry-standard development models, we've engineered a streamlined and comprehensible framework for custom software development. Our adept professionals adeptly navigate methodologies such as Agile, Waterfall, Prototyping, and Scrum.</p>
                        <p className="text-base text-start text-pColor my-4 fR">Our focal point remains establishing a transparent, agile process allowing for iterative refinement, adaptability, and comprehensive progress assessment at each stage.</p>
                    </div>
                    <div className="w-full lg:w-4/7 mt-4 md:mt-14 lg:mt-0">
                        <img src={PATH.img.devMethodology} alt="project" className="w-full h-full" />
                    </div>
                </div>
            </div>
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start  text-hColor fB mb-3">Success Stories</h2>
                    <p className="text-base text-start text-pColor my-1 fR">If you are looking for emerging AI and ML solutions, you can choose from our pool of dedicated AI experts. Our experience and tech stack allows us to develop custom AI and ML solutions. Here are our success stories!</p>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-14">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor fB mb-3">Shop App</h2>
                        <p className="text-base text-start text-pColor mb-6 fR">Optimize shop operations with our innovative inventory management system. Proglint's tech-driven collaboration brings precision to stock control and customer interactions, ensuring efficient store management and lasting cost-effectiveness.</p>
                        <div onClick={() => window.location.reload()}>
                            <Button LinkPath={('/case-studies/shopapp-app')} value={'View All'} className={'bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none px-5 text-hColor font-semibold my-4'} />
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 mt-10 lg:mt-0 h-[420px]">
                        <img src={PATH.img.shopAppThumbnail} alt="project" className="w-full h-full rounded-2xl object-cover" />
                    </div>
                </div>
            </div>
            <div className="work_card container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-6 md:mb-10 mt-4">Choose Your Options</h2>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div className="card flex flex-col rounded-md px-6 py-6 border-t-8   border-primary shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
                        <h3 className="text-hColor fM text-xl mb-8">Static Prototyping</h3>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Define functionalities and features</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Visually map out user journey</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Interactive, shareable prototype</p>
                        </div>
                    </div>
                    <div className="card flex flex-col rounded-md px-6 py-6 border-t-8   border-secondary shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
                        <h3 className="text-hColor fM text-xl mb-8">Proof of Concept</h3>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Define functionalities and features</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Real functionality & development</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Deployed staging model</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Functionality feasibility check</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">API/3rd Party Integration check</p>
                        </div>
                    </div>
                    <div className="card flex flex-col rounded-md px-6 py-6 border-t-8   border-primary shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
                        <h3 className="text-hColor fM text-xl mb-8">Functional Prototyping</h3>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Define functionalities and features</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Visually map out user journey</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Interactive, shareable prototype</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Visual and creative graphic designs</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Deployed staging model</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Functionality feasibility check</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">API/3rd Party Integration check</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">QAT and UAT Cycle</p>
                        </div>
                        <div className="flex gap-2 items-center mb-4">
                            <CheckIcon className="w-6 h-6 text-pColor" />
                            <p className="text-pColor text-md fR">Deployment and launch of finished MVP</p>
                        </div>
                    </div>
                </div>
            </div>
            <Industries />
            <ContentBanner2 />
            <Faqs
                Question1={`What services do you offer in Product Development?`}
                Answer1={`Proglint specializes in a range of services including ideation, design, prototyping, software development, testing, and deployment across various industries. The entire product development lifecycle is followed diligently by our experts.`}
                Question2={`How does Proglint ensure the security and confidentiality of the project?`}
                Answer2={`We prioritize data security and confidentiality. Proglint implements robust security measures, and access controls, and follows stringent data protection protocols to safeguard your project's integrity and confidentiality.`}
                Question3={`What is your approach to handling complex projects?`}
                Answer3={`Our approach involves a thorough understanding of your requirements, iterative development cycles, agile methodologies, and a collaborative process. We break down complex projects into manageable stages for efficient execution.`}
                Question4={`Can you work with existing teams or integrate with ongoing projects?`}
                Answer4={`Absolutely, Proglint offers flexible engagement models. We can seamlessly integrate with your existing teams, supplement ongoing projects, or provide end-to-end solutions based on your specific needs.`}
                Question5={`How do you ensure the quality and reliability of the developed products?`}
                Answer5={`Quality assurance is integral to our process. We follow industry best practices, rigorous testing methodologies, and continuous refinement to ensure high-quality, reliable, and scalable products that meet your standards.`}
            />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Product