import React from "react";
import { Carousel } from "react-responsive-carousel";
import { PATH } from "../globals/app-constants";
import FooterContact from "../component/FooterContact";
const Clientresult = ({ title, titleDescription, casestudyThumbnail, businessGoalImg, story, }) => {
    return (
        <div>
            <div className="container mx-auto mt-20">
                <div className="lg:flex gap-12 pt-6">
                    <div className="w-full lg:w-5/8 mb-6">
                        <div>
                            <p className="text-secondary text-base font-medium my-4">Case Study</p>
                            <h1 className="text-hColor text-xl lg:text-2xl font-bold">{title}</h1>
                            <p className="text-pColor text-sm font-normal my-4">{titleDescription}</p>
                        </div>

                    </div>
                    <div className="w-full lg:w-3/8">
                        <div>
                            <img src={casestudyThumbnail} alt="casestudy-Img" className="w-full h-full object-cover" />
                        </div>
                    </div>
                </div>
                <div className="story mt-4">
                    {
                        story.map((content) =>
                            <div className="md:flex gap-6 mt-4 md:mt-8">
                                <div className="w-full md:w-1/5">
                                    <h2 className="text-hColor font-bold text-xl lg:text-2xl mb-2">{content.heading}</h2>
                                </div>
                                <div className="w-full md:w-4/5">
                                    <p className="text-pColor text-sm font-normal mb-3">{content.description1}</p>
                                    <p className="text-pColor text-sm font-normal mb-3">{content.description2}</p>
                                </div>
                            </div>
                        )
                    }
                    {/* <div className="md:flex gap-6 mt-4 md:mt-8">
                    <div className="w-full md:w-1/5">
                        <h1 className="text-hColor font-bold text-xl lg:text-2xl mb-2">The Story</h1>
                    </div>
                    <div className="w-full md:w-4/5">
                        <p className="text-pColor text-sm font-normal mb-3">A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashier's counter are compared with the customer's computer vision cart. Any items that are not billed at the cashier's counter will be treated as stolen. This will help detect theft and reduce loss for shops.</p>
                        <p className="text-pColor text-sm font-normal mb-3">A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashier's counter are compared with the customer's computer vision cart. Any items that are not billed at the cashier's counter will be treated as stolen. This will help detect theft and reduce loss for shops.</p>
                    </div>
                </div> */}
                </div>
                <div className="business_goal mt-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 my-3 md:my-0 md:gap-12">
                        <div>
                            <img src={businessGoalImg} alt="business_goal_img" className="w-full h-full object-cover" />
                        </div>
                        <div>
                            <h2 className="text-xl font-bold text-hColor py-3">Business Goal</h2>
                            <p className="text-sm font-normal text-pColor pb-6">A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashier's counter are compared with the customer's computer vision cart. Any items that are not billed at the cashier's counter will be treated as stolen. This will help detect theft and reduce loss for shops.</p>
                            <ul className="list-disc list-inside">
                                <h2 className="text-base font-medium text-hColor mb-6">Security solutions targeted for:</h2>
                                <li className="text-sm text-pColor mb-2">Hotels</li>
                                <li className="text-sm text-pColor mb-2">Shopping Malls</li>
                                <li className="text-sm text-pColor mb-2">Office</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section6 pt-4 md:pt-20 pb-2 md:pb-4 lg:pb-8 relative">
                    <div>
                        <h2 className="text-xl md:text-3xl font-bold text-hColor">Proglint Approach</h2>
                        <p className="text-sm md:text-base text-pColor my-2">A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented at the cashier's counter are compared with the customer's computer vision cart. Any items that are not billed at the cashier's counter will be treated as stolen. This will help detect theft and reduce loss for shops.</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 lg:gap-20  my-5 md:my-10">
                        <div>
                            <h2 className="text-6xl font-bold text-hColor">01</h2>
                            <h2 className="text-xl font-bold text-hColor mb-3 mt-5">Discover</h2>
                            <p className="text-base text-pColor ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum </p>
                        </div>
                        <div>
                            <h2 className="text-6xl font-bold text-secondary">02</h2>
                            <h2 className="text-xl font-bold text-secondary mb-3 mt-5">Solve</h2>
                            <p className="text-base text-pColor ">Lorem Ipsum is simply dummy text of the <sapn className="text-secondary font-medium">printing</sapn> and <sapn className="text-secondary font-medium">typesetting</sapn> industry. Lorem Ipsum </p>
                        </div>
                        <div>
                            <h2 className="text-6xl font-bold text-hColor">03</h2>
                            <h2 className="text-xl font-bold text-hColor mb-3 mt-5">Simplify</h2>
                            <p className="text-base text-pColor ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum </p>
                        </div>
                        <div>
                            <h2 className="text-6xl font-bold text-secondary">04</h2>
                            <h2 className="text-xl font-bold text-secondary mb-3 mt-5">Sustain</h2>
                            <p className="text-base text-pColor ">Lorem Ipsum is simply dummy text of the <sapn className="text-secondary font-medium">printing</sapn> and <sapn className="text-secondary font-medium">typesetting</sapn> industry. Lorem Ipsum </p>
                        </div>
                        <div>
                            <h2 className="text-6xl font-bold text-hColor">05</h2>
                            <h2 className="text-xl font-bold text-hColor mb-3 mt-5">Flexible</h2>
                            <p className="text-base text-pColor ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className='feedback my-12'>
                <div className='w-full bg-[#F9F9F9] flex items-center justify-center relative py-8 lg:py-12'>
                    <div className='w-full lg:w-1/2 px-8 md:px-3 relative'>
                                <img src={PATH.img.icons.quoteIcon} className="w-12 h-12" alt="quote"/>
                        <Carousel infiniteLoop={true} showArrows={false} autoPlay={true} showStatus={true} showIndicators={false}>
                                <div className='mt-3'>
                                    <p className='text-pColor text-center text-base pt-0 max-w-xl font-display'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum </p>
                                    <div className='mt-5 px-8 flex justify-center'>
                                        <div className="flex gap-2 items-center my-6">
                                            <div className="w-10 h-10">
                                                <img src={PATH.img.profile} alt="profile" className="rounded-full w-10 h-10 object-fill align-middle" />
                                            </div>
                                            <div>
                                                <p className="uppercase text-start text-pColor font-semibold text-sm">THOMAS SATHISH</p>
                                                <p className="text-pColor text-start text-xs capitalize">Business Development Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <p className='text-pColor text-center text-base pt-0 max-w-xl'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum </p>
                                    <div className='mt-5 px-8 flex justify-center'>
                                        <div className="flex gap-2 items-center my-6">
                                            <div className="w-10 h-10">
                                                <img src={PATH.img.profile} alt="profile" className="rounded-full w-10 h-10 object-fill align-middle" />
                                            </div>
                                            <div>
                                                <p className="uppercase text-start text-pColor font-semibold text-sm">SATHISH</p>
                                                <p className="text-pColor text-start text-xs capitalize">Business Development Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           
                        </Carousel>
                       <div className="absolute bottom-5 right-6 lg:right-0">
                       <img src={PATH.img.icons.quoteIcon} className="w-12 h-12 rotate-180 mt-2" alt="quote"/>
                       </div>
                    </div>
                </div>
            </section>
            <FooterContact/>
        </div>
    );
};
export default Clientresult;