import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import { TagIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
const Action_Recognition = () => {


    const [showAccordion, setShowAccordion] = useState({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
    const handleAccordion = (accordion) => {

        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false })
                break;
            case 6:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6 })
                break;
        }

    }
    return (
        <div>
            <div className="home_banner relative">
                <div className="w-full h-full">
                    <img src={PATH.img.solution_action_recognition_img1} alt="banner" className="h-full w-full object-cover slide active" />
                </div>
                <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                <div className="w-full z-[2] absolute top-[30%] lg:top-[40%]">
                    <div className=" container mx-auto">
                        <div className="mb-14">
                            <div className="flex gap-1 items-center mb-2">
                                <TagIcon className="text-[#7A7A7A] w-5 h-5" />
                                <p className="text-xs fB text-[#7A7A7A]">Action Recognition</p>
                            </div>
                            <h1 className="text-2xl lg:text-5xl fR text-white">Action Recognition</h1>
                            <p className="text-base max-w-2xl fR text-white mt-4">AI-Enhanced Behavior Recognition for Real-Time Insights. Transform your approach to tracking and understanding human behavior with precise, immediate analysis.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <h2 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center">Computer Vision Solutions for Human Behavior Analysis in a Smart Environment</h2>
                <p className="text-sm fR text-start lg:text-center text-pColor my-1 mx-auto">Master the market by truly knowing your customers." Our advanced behavior analysis technology offers profound insights into buying patterns, preferences, and interactions, empowering businesses to refine their strategies, enhance engagement, and drive success across various sectors.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Benefits</h2>
                        <p className="fR text-sm my-3">Unveil the power of behavior insights for exceptional results. Human behavior analysis offers a wealth of benefits, from enhancing security to optimizing operations and improving customer experiences.</p>
                        <div className="my-6">
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Boost Retail Security</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(1)}>
                                        {showAccordion.accordion1 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Stop theft in its tracks with real-time behavior analysis, reducing shrinkage and safeguarding your inventory.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Elevate Customer Satisfaction</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(2)}>
                                        {showAccordion.accordion2 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Speed up service and enhance the customer experience by optimizing staffing and cutting wait times with insightful behavior data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">volutionize Workplace Safety</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Prevent accidents and ensure safety compliance by monitoring employee movements and detecting risky behaviors.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black"> Enhance Patient Care</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(4)}>
                                        {showAccordion.accordion4 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Detect falls and unusual activity patterns promptly to ensure timely intervention and superior healthcare outcomes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Optimize Resource Allocation</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(5)}>
                                        {showAccordion.accordion5 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion5 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Leverage behavioral insights to tailor care plans and allocate resources efficiently, maximizing patient care effectiveness.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={PATH.img.solution_action_recognition_img2} alt="solution_action_recognition-img" />
                    </div>
                </div>
            </section>
            <div className="solution-industry-banner">
                <div className="container mx-auto grid grid-cols-2 lg:grid-cols-3 py-20">
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.IndustryIcon} alt="industry-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Industry</h2>
                            <p className="fR text-xs text-white">Healthcare, Diagnostic research</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.DeliveryModalIcon} alt="delivery-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Resource Allocation</h2>
                            <p className="fR text-xs text-white">Video Streams,  Integration Software</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.TechnologyIcon} alt="technology-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Tech Frameworks</h2>
                            <p className="fR text-xs text-white">Open CV, AI/ML</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.DomainbannerIcon} alt="domain-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Support</h2>
                            <p className="fR text-xs text-white">Multi-Channel Support</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.EffortIcon} alt="effort-icons" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Project Duration</h2>
                            <p className="fR text-xs text-white">4-8 Weeks, Scalable</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <img src={PATH.img.solution_action_recognition_img3} alt="solution_action_recognition-img" />
                    </div>
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Features</h2>
                        {/* <p className="text-sm mt-2 fR">AI securely guards a world where patients whiz through checkout and medications. Upgrade your healthcare checkout process with our advanced computer vision-powered self-checkout, ensuring top-notch security and effortless patient transactions.</p> */}
                        <div className="relative">
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Instant Surveillance</h2>
                                    <p className="text-sm fR mt-1">Catch suspicious behavior in the act with cutting-edge real-time monitoring, enabling swift and effective responses.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Deep Behavioral Insights</h2>
                                    <p className="text-sm fR mt-1">Uncover actionable insights into buying patterns and preferences, driving strategic decisions and boosting customer engagement.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Streamlined Workflows</h2>
                                    <p className="text-sm fR mt-1">Boost manufacturing efficiency by refining worker movements and equipment use with advanced behavior analysis.
                                    </p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Continuous Patient Monitoring</h2>
                                    <p className="text-sm fR mt-1">Track patient behavior seamlessly to ensure timely interventions and continuous care, even in the absence of doctors.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Personalized Care Solutions</h2>
                                    <p className="text-sm fR mt-1">Craft tailored care plans and allocate resources based on detailed behavior analysis, enhancing patient outcomes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Challenge</h2>
                    <div>
                        <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Navigating behavior analysis can be complex. In retail, balancing effective monitoring with customer privacy concerns poses a challenge, as constant surveillance can sometimes lead to discomfort. Additionally, interpreting diverse human behaviors accurately requires overcoming environmental and contextual limitations.</p>
                        <p className="text-sm fR text-start text-pColor my-2 mx-auto">In healthcare, integrating behavior analysis with existing systems can be intricate, demanding seamless integration with traditional practices. Ensuring data security and delivering timely interventions in a busy medical environment adds another layer of complexity.</p>

                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Success</h2>
                   <div>
                   <p className="text-sm fR text-start text-pColor my-1 mx-auto">Proglint has revolutionized behavior analysis with groundbreaking solutions that drive real results. In retail, our technology has slashed theft rates and significantly boosted customer satisfaction by enhancing service efficiency and reducing wait times.
                    </p>
                    <p className="text-sm fR text-start text-pColor my-2 mx-auto">In healthcare, our advanced monitoring systems have improved patient care and optimized resource allocation. By leveraging precise behavior analysis, we’ve set a new benchmark for success, delivering innovation and exceptional results across various industries.</p>
                   </div>
                </div>
            </div>
            <FooterContact />
        </div>
    )
}
export default Action_Recognition;