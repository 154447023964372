import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import { Link } from "react-router-dom";
import SliderCards from "../../component/SliderCards";

const Truex = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Timeline View for Patient Medical History: Patients access a comprehensive timeline view within the app, presenting their medical records chronologically. This feature allows for easy tracking and reference of medical data, facilitating a better understanding of health histories",
        point2: "Hospital-Specific Patient Profiles by Doctors: Doctors, upon registration and hospital setup, create dedicated patient profiles within the app for seamless management and access to patient data specific to each hospital. This enhances patient care and management efficiency.",
        point3: "Doctor's Access to Patient History with Consent:Doctors request access to patient medical records through consent requests. Once approved by the patient, doctors gain secure access to view and analyze the patient's medical history, ensuring data privacy and patient control.",
        point4: "Patient's Consent Management for Data Access: Patients manage access to their medical records by accepting or revoking consent requests from doctors. This level of control empowers patients to safeguard their data and control who can view their medical history."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Consent Tracking Between Doctor and Patient: Implementing a robust system to track and manage consent requests between doctors and patients, ensuring transparency and adherence to privacy regulations.",
        point2: "Implementing a Comprehensive Timeline View: Developing a user-friendly, comprehensive timeline view for patients to efficiently navigate and understand their medical history.",
        point3: "Aligning Data of Registered and Unregistered Patients: Efficiently managing and integrating data from both registered and unregistered patients to ensure a unified and organized medical database within the application.",
        point4: "Integration of Data from Synced Email IDs:Synchronizing and securely uploading patient files and medical data from synced email IDs into the application's database for seamless data access and management"
    },
    {
        title: 'Overcoming Challenges',
        point1: "Streamlined Consent Tracking System: Implemented a robust consent tracking mechanism ensuring efficient management and monitoring of consent requests between doctors and patients.",
        point2: "Enhanced Timeline View: Developed an intuitive and comprehensive timeline view interface, enabling patients to effortlessly navigate and comprehend their medical history.",
        point3: "Unified Data Management: Successfully integrated data from diverse sources, aligning information from registered and unregistered patients for a cohesive and well-organized medical data repository.",
        point4: "Secure Data Integration from Synced Email IDs: Implemented secure protocols to sync and upload patient files and data from synced email IDs, ensuring data integrity and privacy compliance."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Stringent testing and phased implementation ensured seamless functionality across systems, addressing the challenges effectively. Successfully delivered the Doctor and Patient Application to the Treux Team, simplifying medical record management and enabling secure data sharing between patients and healthcare providers. Proglint ensured a user-friendly and secure platform, enhancing patient-doctor interactions and medical record accessibility."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.TreuxBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Medical Records Simplified'}
                domainText={'Healthcare, Medical Records, Patient Management'}
                durationText={'6 months, 24 man-months'}
                technologyText={'Swift (iOS), Kotlin (Android), Node.js, MongoDB, Selenium, Jest Framework'}
                overviewText={'The Equinox app redefines employee management, offering a centralized platform for quick and efficient daily operations. Simplifying tasks with its user-friendly interface, it allows seamless tracking of requests, approvals, and HR services, empowering users with on-the-go actions.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.IntelThumbnail} RetailHeading={'Payment Processing'} SubHead={'Intel Vision Checkout'} ParaContent={`Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/intel-vision-checkout')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.cosfymartThumbnail} RetailHeading={'E-commerce'} SubHead={`Cosfymart`} ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cosfymart-app')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/jedidahs')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default Truex;