import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import ContentBanner2 from "../../../component/ContentBanner2";

const Operational = () => {
   
    return (
        <div>
            <div className="white_banner relative overflow-hidden bg-cover bg-center ">
                <div className="container mx-auto">
                    <div className="absolute lg-top-[35%] md:bottom-[25%] md:top-[25%] z-10 ">
                        <h1 className="white_banner_title font-semibold text-hColor w-full lg:w-1/2 lg-mb-6 lg:mb-0">Operational Excellence</h1>
                        <p className="text-base text-start text-pColor my-1 w-full lg:w-1/2">Step into the future with Proglint! No more guessing; leverage data-driven insights to transform your store operations. Unlock valuable customer behavior data and seize control of your retail destiny.</p>
                    </div>
                </div>
                <div className="absolute right-0 bottom-0 md:h-full">
                    <img src={PATH.img.Solution_Operational} alt="banner" className="w-full h-full object-contain" />
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Optimize Your Business: A Gateway to Lower Operational Costs</h2>
                <p className="text-base text-start text-pColor my-1">Streamline operations and cut costs with our tailored solutions for maximizing efficiency and productivity.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon1} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Enhance Inventory Oversight</h2>
                        <p className="text-pColor text-sm py-2">Keep a constant watch on shelf levels and forecast inventory demands, preventing surplus stock and potential revenue loss while ensuring timely replenishment for seamless sales operations.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon2} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Planogram Adherence</h2>
                        <p className="text-pColor text-sm py-2">Guarantee adherence to planograms for strategic product placement, maximizing shelf space utility and customer contentment, thereby enhancing store layout efficiency.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon3} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Ensuring Operational Compliance</h2>
                        <p className="text-pColor text-sm py-2">Simplify adherence to safety standards and internal protocols, mitigating risks and penalties. Guaranteeing operational protocol compliance.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-2 max-w-2xl">Proglint enhances retail operations, ensuring operational excellence and efficiency</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3">Proglint elevates retail standards by fostering operational excellence through its innovative solutions and streamlined processes..</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon1} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Streamlining Operations</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Eradicate Manual Inefficiencies with Automation. Liberate staff for customer interactions and elevate the in-store experience, as Automation manages inventory and scheduling tasks covertly.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon2} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Overcoming Data Blind Spots</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Shedding Light on Hidden Inefficiencies. Our solution illuminates the path for data-driven decision-making, facilitating targeted interventions with maximum impact.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon3} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Workforce Efficiency</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Drive Profitability with Proactive Labor Management. Our solution analyzes staff performance for optimal scheduling and training, boosting productivity and profitability.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon4} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Minimize Stock Loss</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Anticipate Customer Demand with Precision. Our advanced algorithms analyze sales history, foot traffic, and market trends to forecast inventory needs accurately, eliminating lost sales and excess stock.</p>
                    </div>
                </div>
            </div >
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start  text-hColor font-bold mb-3">Elevating Operational Efficiency in Physical Stores with All-In-One Solutions with Proglint</h2>
                    <p className="text-base text-start text-pColor my-1">Achieve peak operational efficiency and cost-effectiveness in your physical retail stores with Proglint's integrated solution, encompassing inventory management, staff scheduling, and more, to elevate performance and drive success.</p>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-14">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-bold mb-3">Algorithm</h2>
                        <p className="text-base text-start text-pColor mb-6">Our advanced algorithms, fueled by real-time data, meticulously analyze every aspect of store operations, from inventory levels to staff activities, generating actionable insights that optimize efficiency and drive profitability.</p>
                    </div>
                    <div className="w-full lg:w-3/5 mt-10 lg:mt-0 h-[420px]">
                        <img src={PATH.img.shopAppThumbnail} alt="project" className="w-full h-full rounded-2xl object-cover" />
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10 pb-4 md:pb-8 relative z-10">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Revolutionizing Store Operations for Enhanced Efficiency</h2>
                <p className="text-sm md:text-base text-start text-pColor mb-3">Empowering retail operations to cost-optimize, enhance profitability, and achieve operational excellence through our innovative solutions.</p>
                <div className="grid grid-cols-1 md:grid-cols-3 my-10 md:my-14 gap-8 lg:gap-16">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Optimized Inventory Control</h2>
                        <p className="border-t-2 border-primary text-pColor pt-2 text-base flex-grow">Real-time insights drive efficient restocking strategies, reducing stockouts and enhancing inventory turnover for streamlined operations</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Optimized Store Designs</h2>
                        <p className="border-t-2 border-[#26557D] text-pColor pt-2 text-base flex-grow">Customized insights inform layout enhancements, elevating customer experiences. Strategic product placement maximizes visibility and accessibility, amplifying sales and satisfaction</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Operational Compliance</h2>
                        <p className="border-t-2 border-secondary text-pColor pt-2 text-base flex-grow">Employ real-time tracking and monitoring to uphold strict operational protocols, mitigating errors and ensuring seamless customer experiences while maintaining compliance standards</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 my-10 md:my-14 gap-8 lg:gap-16">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Cost Efficiency</h2>
                        <p className="border-t-2 border-primary text-pColor pt-2 text-base flex-grow">Streamlining Operations to Reduce Expenses without Compromising Excellence</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Instant Data Access</h2>
                        <p className="border-t-2 border-[#26557D] text-pColor pt-2 text-base flex-grow">Rapidly Obtain Actionable Insights for Prompt Decision-Making. Identify and Resolve Operational Challenges in Real Time</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Financial Performance</h2>
                        <p className="border-t-2 border-secondary text-pColor pt-2 text-base flex-grow">Leveraging Operational Excellence to Boost EBITA and Foster Long-Term Profitability</p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10 pb-4 md:pb-8 ">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Proglint's Data-Driven Innovation Elevates Operational Excellence</h2>
                <p className="text-sm md:text-base text-start text-pColor mb-3">Efficiently streamline operations with automated inventory management, scheduling, compliance monitoring, and reporting, empowering staff for more valuable tasks. With minimized errors and delays, our solution elevates customer service and enhances store performance, facilitated by an intuitive interface accessible to every team member, regardless of technical expertise.</p> 
                <p className="text-sm md:text-base text-start text-pColor mb-3">Enhance retail efficiency with Proglint's smart inventory management and automated scheduling for seamless operations.</p>
                <div>
                    <img src={PATH.img.LP_solution_map} alt="map" className="w-full h-full mt-10"/>
                </div>
            </div>
            <ContentBanner2 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Operational