import React, { useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link } from "react-router-dom";
import Faqs from "../../component/Faqs";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

const DistanceCalculation = () => {
    const [tab, setTab] = useState('Manufacturing');
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-10 absolute bottom-6 lg:top-[30%]">
                        <h1 className="banner_title fSB text-white w-auto md:w-auto lg:max-w-xl mb-4">Distance calculation</h1>
                        <p className="text-sm text-white lg:max-w-2xl fR">Transform video streams into flawless distance metrics, effortlessly decoding spatial relationships with advanced AI flair.</p>
                    </div>
                </div>
                <div className="absolute">
                    <img src={PATH.img.FeaturesDistanceCalculation} alt="banner" className="w-screen h-screen lg:h-full object-cover" />
                    <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 left-0 h-full w-full object-cover" />
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-xl">Use cases of Distance Calculation</h2>
                    <p className="text-sm md:text-base text-start fR text-pColor my-3">Bridging the gap between the seen and the measured.Our AI-powered computer vision meticulously extracts precise Distance metrics from video streams, transforming raw footage into actionable intelligence.Experience a new dimension of spatial analysis as we redefine the possibilities of computer vision.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.stockicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Warehouse Safety</span>
                        </h3>
                        <p className="text-base fR text-pColor max-w-4xl my-5">Monitor distances between moving containers and personnel or products, triggering alerts to prevent accidents and ensure a safe working environment.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.parkingicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Parking Management</span>
                        </h3>
                        <p className="text-base fR text-pColor max-w-4xl my-5">Self-driving vehicles heavily rely on accurate distance calculations for safe navigation, collision avoidance, and maintaining appropriate distances from other vehicles. This technology also enables autonomous parking systems to find suitable parking spaces and avoid obstacles.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.vehiclealerticon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Vehicle Proximity Alerts</span>
                        </h3>
                        <p className="text-base fR text-pColor max-w-4xl my-5">Measure and analyze vehicle distances in parking areas and loading zones, sending notifications when vehicles come too close to valuable infrastructure or restricted areas.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.efficiency} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Conveyor Belt Efficiency</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Monitor product distances on conveyor belts to streamline flow and spacing, minimizing bottlenecks. Precise distance measurements between items in warehouses and during transportation enhance storage efficiency, loading accuracy, and delivery processes.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.retailsecurityicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Retail Security</span>
                        </h3>
                        <p className="text-base fR text-pColor max-w-4xl my-5">Measure the distance between shoppers and high-value items, such as jewelry, generating alerts if individuals get too close, enhancing security and preventing theft.</p>
                    </div>
                </div>
            </div >
            <div className="bg-black pb-4 lg:pb-20">
                <div className="flex container mx-auto gap-4 flex-row overflow-auto pt-28">
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Manufacturing')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Manufacturing' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.ManufacturingtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Manufacturing' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Manufacturing</p>
                    </div>
                    {/* <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Education')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Education' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.EducationtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Education' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Education</p>
                    </div> */}
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Healthcare')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Healthcare' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.HealthcaretabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Healthcare' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Healthcare</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Retail')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Retail' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.RetailtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Retail' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Retail</p>
                    </div>
                </div>
                {tab == 'Manufacturing' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Optimize product spacing on conveyor belts, reducing bottlenecks.</li>
                                    <li className="text-base fR mb-3 text-white">Monitor container distances to alert for potential accidents, ensuring a secure workspace.</li>
                                    <li className="text-base fR mb-3 text-white">Ensure exact component alignment with accurate distance measurements.</li>
                                    <li className="text-base fR mb-3 text-white">Improve space utilization and logistics with precise distance tracking between items.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">AI for Quality Control</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-for-quality-control')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {/* {tab == 'Education' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Key Features</h1>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Elevate athletic performance with precise speed measurements.</li>
                                    <li className="text-base fR mb-3 text-white">Enhance campus safety by monitoring vehicle speeds.</li>
                                    <li className="text-base fR mb-3 text-white">Promote safer driving with detailed habit analysis.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize racing strategies with vehicle speed data for car and bike training programs.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Proglint AI Solution</h1>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4" >
                                    <h1 className="fR text-base text-white">AI Attendance</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h1 className="fR text-base text-white">Safety and Security</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions-safety-security')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                } */}
                {tab == 'Healthcare' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Monitor high-risk areas to prevent accidents and uphold safety standards.</li>
                                    <li className="text-base fR mb-3 text-white">Track patient progress by measuring distances covered during physical therapy exercises.</li>
                                    <li className="text-base fR mb-3 text-white">Refine ambulance distances to accelerate response times and boost coordination.</li>
                                    <li className="text-base fR mb-3 text-white">Track distances during pharmacy checkouts to boost security and accuracy.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Pill Counting</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/pill-counting')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Medical Image Analysis</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/medical-image-analysis')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Retail' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Detect theft and shoplifting by monitoring distances between customers and merchandise.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize shelf stocking and prevent out-of-stocks by tracking product distances.</li>
                                    <li className="text-base fR mb-3 text-white">Ensure proper parking spacing to avoid collisions and manage vehicles effectively.</li>
                                    <li className="text-base fR mb-3 text-white">Track shopper distances from high-value items like jewelry to enhance security.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Vision Analytics</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/vision-analytics')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Loss Prevention</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/loss-prevention')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Don't Just Digitize, Be Intelligent</h2>
                <p className="text-base text-start fR text-pColor my-1">Proglint Makes AI Work for You. Digitize every aspect of your business and uncover opportunities for enhancement at every stage using our advanced AI and computer vision solutions.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.playplugicon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Plug-and-Play Perfection</h2>
                        <p className="text-pColor text-sm fR py-2">Seamless AI integration with instant, hassle-free setup.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.costSaving} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Smart Savings</h2>
                        <p className="text-pColor text-sm fR py-2">Slash operating costs by up to 40% with our AI-driven solutions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.security} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Data Security</h2>
                        <p className="text-pColor text-sm fR py-2">Safeguard your data with top-tier privacy and security protocols.</p>
                    </div>
                </div>
            </div>
            <Faqs
                Question1={`What is distance calculation using AI computer vision?`}
                Answer1={`Distance calculation using AI computer vision involves utilizing artificial intelligence to analyze visual data and determine the precise distance between objects or points within an image or video. This technology employs advanced algorithms to extract spatial information and convert it into quantitative measurements.`}
                Question2={`How accurate is distance calculation using AI computer vision?`}
                Answer2={`The accuracy of distance calculation depends on factors such as image quality, camera calibration, and the complexity of the scene. However, with advancements in AI and computer vision, modern systems can achieve high levels of accuracy, often within a margin of error of a few millimeters.`}
                Question3={`What are some common applications of distance calculation using AI computer vision?`}
                Answer3={`Distance calculation technology is a game-changer across industries, from revolutionizing manufacturing with conveyor belt management to enhancing healthcare with precise patient monitoring. It’s also a retail powerhouse for optimizing store layouts and a key player in educational safety and resource management.`}
                Question4={`How can distance calculation improve warehouse operations?`}
                Answer4={`Distance calculation can significantly enhance warehouse operations by optimizing storage layout, improving picking efficiency, and preventing accidents. By accurately measuring distances between shelves, products, and employees, warehouses can implement automated guided vehicles (AGVs) and robotic systems for efficient material handling. For instance, Proglint's AI-powered warehouse management system utilizes distance calculation to optimize inventory placement and retrieval times.`}
                Question5={`What sets Proglint’s distance calculation apart from the rest?`}
                Answer5={`Proglint’s distance calculation stands out with its advanced neural network algorithms and sophisticated integration capabilities. Utilizing deep learning techniques and real-time image processing, Proglint delivers pinpoint accuracy and immediate data insights. Its seamless fusion of computer vision and edge analytics transforms video data into actionable metrics, driving operational excellence and efficiency across multiple industries.`}
            />
            <FooterContact />
        </div>
    )
}
export default DistanceCalculation