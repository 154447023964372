import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const SmartFridge = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Customer-Centric Cart Creation: Utilizing computer vision and deep learning, the fridge tracks user behavior to auto-add selected items to the virtual cart, optimizing the shopping experience.",
        point2: "Efficient Inventory Management: The system seamlessly manages product inventory within the smart fridge, ensuring optimal stock levels and timely restocking.",
        point3: "Strategic Planogram Management: Products are strategically arranged for visibility, enhancing the user experience and promoting increased sales.",
        point4: "Streamlined Vendor Onboarding: Vendors easily onboard their products via a user-friendly interface, managing inventory efficiently.",
        point5: "Insightful Reporting: Detailed reports on sales trends, popular products, and inventory levels offer valuable insights to users and vendors."
    },
    {
        title: 'Business challenge',
        point1: "Technology Reliability: Ensuring reliability in computer vision and automated purchasing was crucial to avoid disruptions in user experience.",
        point2: "Integration Complexity: Integrating the fridge with existing systems posed compatibility challenges.",
        point3: "Cost of Implementation: Developing and implementing advanced technologies incurred initial expenses."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Robust Maintenance Protocols: Proglint implemented stringent testing and maintenance schedules, ensuring technology reliability.",
        point2: "Standardized Integration Protocols: Invested in open communication protocols for seamless integration with existing systems.",
        point3: "Strategic Cost Management: Conducted cost-benefit analyses and phased implementations to manage initial expenses."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Successfully deployed and running in the Aliso lab in Poland, the Smart Fridge system, powered by cutting-edge Computer Vision and Deep Learning algorithms, revolutionized the retail landscape. It streamlined inventory management, facilitated an intuitive cart creation process based on customer behavior, and optimally arranged product placement via Planogram Management.",
        deliveredParagraph2: "Furthermore, the seamless vendor and product onboarding, coupled with comprehensive reports on sales trends and inventory, provided invaluable insights to both users and vendors."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.smartfridgeBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Innovative Smart Fridge Automation'}
                domainText={'Retail Automation, Computer Vision, Inventory Management'}
                durationText={'6 months, 25 man-months'}
                technologyText={'DeepVis Cart Creation, Computer Vision, Deep Learning Algorithms, Automated Purchasing Systems'}
                overviewText={'Proglint pioneered the development of an innovative smart fridge leveraging computer vision and deep learning algorithms. This smart fridge not only facilitated automated purchasing but also introduced advanced features like inventory and planogram management, vendor onboarding, and comprehensive sales reporting'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                       <SliderCards ImgPath={PATH.img.shopAppThumbnail} RetailHeading={'E-commerce'} SubHead={'Shop App'} ParaContent={`The Proglint-assisted Shop Management Solution introduced an innovative system integrating inventory control with customer and store management applications. Collaborating as a technology facilitator, Proglint supported the client in implementing intricate inventory algorithms and advanced technological functionalities. This collaborative effort aimed to enhance stock control precision and streamline the customer experience. The solution's success lay in its intricate promotional strategies and the implementation of Planogram via cutting-edge technology.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/shopapp-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default SmartFridge;