import React from "react";
import { PATH } from "../globals/app-constants";
import FooterContact from "./FooterContact";
import ContentBanner1 from "./ContentBanner1";
import { Link } from "react-router-dom";

const Ios = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <h1 className="white_banner_title font-semibold text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">iOS App Development Services</h1>
                    <div className="w-full h-1/2 lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.appleLogo} alt="logo" className="block mx-auto w-40 lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="device my-5 lg:my-10  container mx-auto">
                <div>
                    <p className="text-xl md:px-0 lg:text-3xl text-center text-hColor font-bold mb-3">Your dream made a reality, on any platform!</p>
                    <p className="text-base text-center text-pColor mb-1">Accelerate your business with Proglint's cutting-edge iOS App Development services, crafted for excellence and innovation.</p>
                </div>
                <img src={PATH.img.iosDevice} alt="device-img" className="block m-auto py-6 lg:py-12" />
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3 max-w-xl">Maximizing Returns: The Power of iOS App Development at Proglint</h2>
                    <p className="text-base text-start text-pColor mb-3">Opting for iOS app development provides a triple revenue boost for entrepreneurs compared to Android. The platform's focus on a premium customer segment ensures higher returns. iOS excels in data and privacy security, benefiting from Apple's constant innovations. The unbeatable user experience results from seamless hardware and software integration. If your aim is a value-oriented OS offering lucrative outcomes with reduced costs, iOS app development stands as the optimal choice.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.bulb} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Targets Premium segment users</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">iOS app development strategically targets the premium segment of users, ensuring a refined and lucrative customer base.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.deploy} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Deploys top-notch security to users</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">With top-notch security protocols, iOS apps prioritize user data privacy and protection, setting a benchmark for secure mobile experiences.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.webLaptop} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Caters the market of top developed nations</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">iOS development captures the market in top developed nations, aligning with the preferences and demands of sophisticated audiences.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.growth} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Maximum Return on investment</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Opting for iOS ensures maximum return on investment, leveraging the platform's high-paying user demographic and robust monetization potential.</p>
                    </div>
                </div>
            </div >
            <div className="service_projects  container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-6 lg:mb-12 max-w-xl">IOS App Development Solutions By
                    Proglint & their Success Stories</h2>
                <div className="lg:flex gap-10">
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-secondary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.shopAppWeb} alt="cover-pic" className="absolute object-cover w-full h-full lg:w-auto lg:h-auto -right-20 lg:top-0 lg:right-0" />
                        <div className="bg_gradient_red absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">Shop Box:</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Optimize shop operations with our innovative inventory management system. Proglint's tech-driven collaboration brings precision to stock control and customer interactions, ensuring efficient store management and lasting cost-effectiveness.</p>
                            <h2 className="text-white text-lg font-normal underline cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/shopapp-app')} >Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-2/3 bg-primary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.NNBweb} alt="cover-pic" className="absolute top-0 -right-20 lg:right-0" />
                        <div className="bg_gradient_orange absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">NNB (Nourish + Bloom):</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Transform your inventory control and e-commerce strategy with our innovative solutions. Proglint's custom applications for Nourish & Bloom incorporate comprehensive web and mobile functionalities, seamlessly integrating with SAP ERP. Discover streamlined operations and cost-efficient solutions for your business growth.</p>
                            <h2 className="text-white text-lg font-normal underline cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/nnb')} >Case Study</Link></h2>
                        </div>
                    </div>
                </div>
                <div className="lg:flex lg:gap-10 lg:mt-10">
                    <div className="w-full mb-6 lg:w-2/3 bg-[#646E78] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.cvsCoverimg} alt="cover-pic" className="absolute top-0 -right-20 lg:right-0" />
                        <div className="bg_gradient_gray absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">CVS Pharmacy</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Revolutionizing Retail with Smart Fridge Technology. Proglint's innovative Smart Fridge Management System enhances shopping experiences, streamlines inventory, and offers valuable insights. Witness the future of retail automation.</p>
                            <h2 className="text-white text-lg font-normal underline cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/cvs')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-[#6F1A07] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.humanCoverimg1} alt="cover-pic" className="absolute bottom-12 right-0" />
                        <div className="px-6 cursor-pointer py-10 absolute bottom-0" onClick={() => window.location.reload()}>
                            <Link to={('/contact')}>
                                <div className="flex flex-row gap-1">
                                    <h2 className="text-white text-lg font-normal underline">Say Hi</h2>
                                    <img src={PATH.img.icons.hello} alt="hello" className="w-8 h-8" />
                                </div>
                                <h2 className="text-white text-lg font-normal underline">to Discuss a Project</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3 max-w-xl">Leading iOS App Development Excellence from India</h2>
                    <p className="text-base text-start text-pColor mb-3">Embark on an iOS journey with Proglint, a trailblazer in Swift, Objective-C, and SwiftUI technologies. Our seasoned developers sculpt immersive, secure, and scalable iOS applications with a blend of cutting-edge technologies. Elevate your digital footprint with our proficiency in iOS SDK, ensuring your app stands out. Opt for Proglint's iOS app development excellence, blending innovation with Swift and Objective-C prowess for a distinctive digital presence.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.mobile} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Your Integrity and Transparency are important to us</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our integrity is a cornerstone. Transparent communication fosters trust, ensuring clients are well-informed and confident in our collaborative journey.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.agileIcon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Agile methodology for finest product delivery</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our methodology ensures a dynamic, responsive development process, guaranteeing optimal adaptability and delivering top-notch products at every sprint.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.security} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">We apply the Latest framework and technologies</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">We leverage the latest frameworks and cutting-edge technologies, ensuring your iOS app aligns with the forefront of industry standards.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.bug} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Customer Satisfaction is our first priority</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">At Proglint, customer satisfaction isn't just a goal—it's our commitment. We prioritize your needs, ensuring a seamless and gratifying iOS app development experience.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.User} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Experienced and talented iPhone App Developers</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our iOS development team is a blend of experience and talent. Our skilled iPhone app developers bring expertise and innovation to every project, guaranteeing excellence.</p>
                    </div>
                </div>
            </div >
            <ContentBanner1 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Ios

