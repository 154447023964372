import React, { useState, useEffect, useRef } from "react";
import { PATH } from "../../../globals/app-constants";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import FooterContact from "../../../component/FooterContact";
import InsightsCard from "../../../component/InsightsCard";
import RetailVideo from "../../../asset/video/retail.mp4"


const InRetail = () => {
    const mobileSectionsRefs = {
        visionanalyticsMd: useRef(null),
        LosspreventionMd: useRef(null),
        visionselfcheckoutMd: useRef(null),
        assettrackingMd: useRef(null),
        smartfridgeMd: useRef(null),
        // behavioridentificationMd: useRef(null),
        // cutomersatisfactionMd: useRef(null),
        actionrecognitionMd: useRef(null),
    };
    const webSectionsRefs = {
        visionanalytics: useRef(null),
        Lossprevention: useRef(null),
        visionselfcheckout: useRef(null),
        assettracking: useRef(null),
        smartfridge: useRef(null),
        // behavioridentification: useRef(null),
        // cutomersatisfaction: useRef(null),
        actionrecognition: useRef(null),
    };
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [tab, setTab] = useState(isMobileView ? 'visionanalyticsMd' : 'visionanalytics');

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 3,
        slidesToScroll: 1,
    }
    const [sectionsRefs, setSectionsRefs] = useState(isMobileView ? mobileSectionsRefs : webSectionsRefs)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setIsMobileView(isMobile);
            setSectionsRefs(isMobile ? mobileSectionsRefs : webSectionsRefs);

            // Determine the currently active section
            const currentActiveTab = Object.keys(sectionsRefs).find((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    return top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2;
                }
                return false;
            });

            // Set the tab dynamically based on the active section
            if (currentActiveTab) {
                setTab(isMobile ? `${currentActiveTab}Md` : currentActiveTab.replace('Md', ''));
            } else {
                // Fallback to the first section if no active section is found
                setTab(isMobile ? 'facerecognitionMd' : 'facerecognition');
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Iterate through sections to find the one currently in view
            Object.keys(sectionsRefs).forEach((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                        setTab(key);
                    }
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sectionsRefs]);

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('scroll', handleHorizontalScroll(tab));
        }, 1000);
    }, [tab])

    const handleScrollView = (refName) => {
        const pos = refName.style.position;
        const top = refName.style.top;
        refName.style.position = 'relative';
        refName.style.top = '-100px';
        refName.scrollIntoView({ behavior: 'smooth', block: 'start' });
        refName.style.top = top;
        refName.style.position = pos;
    }
    const handleScrollViewMd = (refName, currentTab) => {
        if (refName) {
            // Adjust the position temporarily for smooth scroll
            const pos = refName.style.position;
            const top = refName.style.top;
            refName.style.position = 'relative';
            refName.style.top = '-170px';

            refName.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });

            // Reset the position after scroll
            refName.style.top = top;
            refName.style.position = pos;

            // Once the vertical scroll is done, trigger the horizontal scroll
            refName.addEventListener('transitionend', () => {
                handleHorizontalScroll(currentTab);
            }, { once: true });
        }
    };
    const handleHorizontalScroll = (currentTab) => {
        const container = document.querySelector('.retailParentTabs-container'); // Adjust the selector to match your container
        const subContainer = document.querySelector(`.retailSubTabsr-${currentTab}`); // Adjust the selector to match your container
        if (container) {
            const subContainerRect = subContainer?.getBoundingClientRect();
            container.scrollBy({
                left: subContainerRect?.x > 0 ? 100 : -200,
                behavior: 'smooth'
            });
        }
    }
    return (
        <div className="mt-24 lg:mt-32">
            <div className="container max-auto">
                <h1 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center capitalize">Transform Retail Stores with Advanced Computer Vision Solutions</h1>
                <p className="text-base fR text-start lg:text-center text-pColor my-1 max-w-3xl mx-auto">Upgrade your retail operations, enhance customer engagement, and drive efficiency with state-of-the-art computer vision solutions.</p>
                <div className="my-6 lg:my-12 flex justify-center ">
                    <video className="lg:max-w-screen-md rounded-md" playsInline autoPlay={true} muted loop>
                        <source src={RetailVideo} type="video/mp4"></source>
                    </video>
                </div>
            </div>
            <div className="container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor fB mb-3 capitalize">Stop Guessing, Start Knowing- Real-Time Insights for Smarter Decisions</h2>
                <p className="text-base fR text-start text-pColor my-1">Insights Made Easy for Every Focus Area.</p>
                <div className="block md:hidden">
                    <div className="d-flex w-full">
                        <div className={`flex w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40 bg-white w-full overflow-x-auto scroll-smooth retailParentTabs-container`}>
                            <button className={`text-sm px-3 py-3 rounded-md retailSubTabsr-visionanalyticsMd ${tab == 'visionanalyticsMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('visionanalyticsMd');
                                    handleScrollViewMd(sectionsRefs.visionanalyticsMd.current, 'visionanalyticsMd')
                                    //sectionsRefs.visionanalyticsMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Vision Analytics</button>
                            <button className={`text-sm px-3 py-3 rounded-md retailSubTabsr-LosspreventionMd ${tab == 'LosspreventionMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('LosspreventionMd');
                                    handleScrollViewMd(sectionsRefs.LosspreventionMd.current, 'LosspreventionMd')
                                    //sectionsRefs.LosspreventionMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Loss Prevention</button>
                            {/* <button className={`text-sm px-3 py-3 rounded-md retailSubTabsr-visionselfcheckoutMd ${tab == 'visionselfcheckoutMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('visionselfcheckoutMd');
                                    handleScrollViewMd(sectionsRefs.visionselfcheckoutMd.current, 'visionselfcheckoutMd')
                                }}
                            >Vision Self Checkout</button> */}
                            <button className={`text-sm px-3 py-3 rounded-md retailSubTabsr-assettrackingMd ${tab == 'assettrackingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('assettrackingMd');
                                    handleScrollViewMd(sectionsRefs.assettrackingMd.current, 'assettrackingMd')
                                    //sectionsRefs.assettrackingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Asset Tracking</button>
                            <button className={`text-sm px-3 py-3 rounded-md retailSubTabsr-smartfridgeMd ${tab == 'smartfridgeMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('smartfridgeMd');
                                    handleScrollViewMd(sectionsRefs.smartfridgeMd.current, 'smartfridgeMd')
                                    //sectionsRefs.smartfridgeMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Smart Fridge</button>
                        </div>
                        {/* <div className=" flex gap-3 overflow-auto py-6 text-nowrap">
                            <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'visionanalytics' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('visionanalytics')}>Vision Analytics</button>
                            <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'Lossprevention' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('Lossprevention')}>Loss Prevention</button>
                            <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'visionselfcheckout' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('visionselfcheckout')}>Vision Self Checkout</button>
                            <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'assettracking' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('assettracking')}>Asset Tracking</button>
                            <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'smartfridge' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('smartfridge')}>Smart Fridge</button>
                        </div> */}
                        <div>
                            <div ref={sectionsRefs.visionanalyticsMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="my-4 lg:my-10">
                                    <div className="w-full">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Vision Analytics</h2>
                                        <p className="text-pColor text-sm py-2 fM">See your store through new eyes with vision analytics. Vision analytics for retail revolutionizes in-store data capture with advanced computer vision sensors, WiFi integration, and powerful analytics tools. Gain unparalleled insights into customer behavior, including precise dwell times, dynamic foot traffic patterns, and detailed demographics.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.easyIntegration}
                                        heading={'Digital Twin of the Building'}
                                        subtext={'Create a dynamic digital representation of your physical store for real-time monitoring, simulation, and optimization.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.scale}
                                        heading={'Performance Metrics'}
                                        subtext={'Gain actionable insights into store performance with metrics like footfall, heatmaps, customer journeys, and sales data.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.dataAnalytics}
                                        heading={'Behavioral Analytics & Profiling'}
                                        subtext={'Unveil customer preferences, behaviors, and patterns through in-depth analysis. Identify opportunities for improvement and personalized experiences.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.predictiveAnalytics}
                                        heading={'Predictive Analysis'}
                                        subtext={'Forecast customer behavior, optimize inventory, and make data-driven decisions to stay ahead of the competition.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.LosspreventionMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="my-4 lg:my-10">
                                    <div className="w-full">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Loss Prevention</h2>
                                        <p className="text-pColor text-sm py-2 fM">Enhance security and optimize your Retail experience with computer vision-powered Loss prevention. Our intelligent system not only prevents fake scanning but also acts as a silent observer, analyzing shopper behavior and traffic patterns. Gain valuable insights into product trends, strategically manage store flow, and create a dynamic, data-driven environment for better customer engagement and satisfaction.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.vision}
                                        heading={'Retail Shrinkage Detection'}
                                        subtext={'Combat retail shrinkage with AI-driven tools that identify and address inventory discrepancies before they affect profits.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.lessTime}
                                        heading={'Suspicious Activity Detection'}
                                        subtext={'Detect suspicious activity instantly with advanced algorithms, preventing unauthorized actions and safeguarding your store.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.dataForcsting}
                                        heading={'Proactive Loss Prevention'}
                                        subtext={'Use smart video analytics to catch theft and fraud early, protecting your store with proactive security measures.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.security}
                                        heading={'Cost-Cutting Intelligence'}
                                        subtext={'Reduce operating costs and boost efficiency with real-time insights and alerts, optimizing your inventory management.'}
                                    />
                                </div>
                            </div>
                            {/* <div ref={sectionsRefs.visionselfcheckoutMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Vision Self Checkout</h2>
                                        <p className="text-pColor text-sm py-2 fM">Effortless, Touchless, and Precise. Transform your retail experience with our cutting-edge Vision Self-Checkout. Streamline transactions with advanced touchless technology, cut down wait times, and boost customer satisfaction. Have a seamless, contactless shopping with real-time item scanning and accurate payment processing.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.shortTdev}
                                        heading={'Self-Checkout Kiosk'}
                                        subtext={'Simplifies the purchasing of food and beverages, cutting down wait times and boosting customer convenience.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Interactive Purchase Navigator'}
                                        subtext={'Leads customers through each step of the buying process with clear, detailed guidance for a smooth transaction.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.product}
                                        heading={'Precision Weighting System'}
                                        subtext={'Accurately weighs items to ensure correct pricing and avoid overcharges, enhancing pricing accuracy.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.costSaving}
                                        heading={'Contactless Payment Solutions'}
                                        subtext={'Enhances hygiene and safety with QR codes and voice/mobile controls, reducing physical contact during transactions.'}
                                    />
                                </div>
                            </div> */}
                            <div ref={sectionsRefs.assettrackingMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Asset Tracking</h2>
                                        <p className="text-pColor text-sm py-2 fM">Retail just got smarter! Forget misplaced inventory and security concerns. Computer vision technology is revolutionizing asset tracking and product identification, ensuring your shelves are always stocked and perfectly aligned with your planograms. Real-time staff availability monitoring empowers your workforce for optimized scheduling, while facial recognition deters unauthorized access in high-security areas. See it all, secure it all with AI!</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.shareReactNative}
                                        heading={'Smarter Inventory Automation'}
                                        subtext={'Streamline your inventory with seamless integration and automated tracking, receiving instant alerts for low stock and expiring items to keep your shelves fully stocked.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.webComputer}
                                        heading={'Dynamic Analytics Dashboard'}
                                        subtext={'Unlock tailored insights with customizable reports on asset usage, movement, and product identification to optimize allocation and boost retail efficiency.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.easyIntegration}
                                        heading={'Precision Product Alignment'}
                                        subtext={'Harness cutting-edge computer vision to ensure products are accurately placed and easily identified according to your planogram, including medical care organization.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.feasibility}
                                        heading={'Live Asset Tracking'}
                                        subtext={'Monitor your assets in real-time with advanced computer vision, offering a centralized dashboard for up-to-the-minute tracking and management of asset movement.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.smartfridgeMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Smart Fridge</h2>
                                        <p className="text-pColor text-sm py-2 fM">Reimagine retail refrigeration with our revolutionary smart fridge solutions. Embedded cameras keep a watchful eye on your inventory, automatically optimizing stock levels and eliminating the frustration of empty shelves.  Embrace the future of retail with smart fridges that not only elevate food management but also delight your customers with consistently fresh products and a seamless shopping experience.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.approach}
                                        heading={'Spot-On Product Tracking'}
                                        subtext={'Our advanced computer vision system delivers precise product identification and real-time inventory updates, ensuring your Smart Fridge is always fresh and fully stocked.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.zeroCost}
                                        heading={'Effortless Payment Experience'}
                                        subtext={'Enjoy secure, contactless transactions through integrated POS or mobile apps, making checkout quick and convenient for every shopper.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.product}
                                        heading={'Tailored Product Suggestions'}
                                        subtext={'Leverage visitor data and purchase history to provide customized product recommendations, enhancing customer satisfaction and boosting sales.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.BusinessService}
                                        heading={'Automatic Restocking Alerts'}
                                        subtext={'Stay ahead of stock shortages with automated low-stock notifications, ensuring your Smart Fridge is always stocked with the latest products.'}
                                    />
                                </div>
                            </div>
                            {/* <div ref={sectionsRefs.behavioridentificationMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Behavior Identification</h2>
                                        <p className="text-pColor text-sm py-2 fM">Secure your retail space with our advanced identification solutions, built to spot and stop suspicious behavior with pinpoint accuracy. Enjoy real-time alerts and proactive intervention thanks to our behavior pattern recognition technology. Transform your shopping environment into a haven, protecting your assets and reputation with our vigilant, centralized monitoring system.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.alert}
                                        heading={'Unauthorized Access Alerts'}
                                        subtext={'Instantly detect and notify security teams of any unauthorized attempts to breach restricted areas, fortifying your security and keeping sensitive zones secure.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.notification}
                                        heading={'Instant Security Notifications'}
                                        subtext={'Immediately alerts staff to suspicious behavior, enabling swift action and preventing potential theft or security issues with real-time updates.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.smartThinking}
                                        heading={'Smart Behavior Detection'}
                                        subtext={'It uses advanced algorithms to pinpoint and analyze suspicious behavior patterns, offering insights for proactive prevention and smarter surveillance.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.mobileNativeapps}
                                        heading={'Unified Security Dashboard'}
                                        subtext={'Centralizes all suspicious behavior alerts and data on a single, intuitive dashboard, providing a comprehensive overview for effective security management and quick responses.'}
                                    />
                                </div>
                            </div> */}
                            {/* <div ref={sectionsRefs.cutomersatisfactionMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Customer Satisfaction</h2>
                                        <p className="text-pColor text-sm py-2 fM">Customer satisfaction in retail is key to repeat business and brand loyalty. Create seamless shopping experiences with efficient checkouts, personalized service, and prompt feedback response. Ensure product availability and a welcoming store environment. Prioritize these elements to keep customers returning and boost your brand's reputation.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.endUser}
                                        heading={'Speed Up Customer Checkout'}
                                        subtext={'AI analyzes customer flow and automatically deploys additional cashiers, keeping lines moving and customers smiling.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.MEANicon1}
                                        heading={'Plan Inventory Smartly'}
                                        subtext={'Utilize sales and foot traffic data to align inventory orders, preventing stockouts and ensuring shelves are always stocked.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.easyIntegration}
                                        heading={'Optimize Store Location'}
                                        subtext={'Leverage customer journey data to select prime store locations within malls, ensuring high visibility and access to significant foot traffic.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.maintainIcon}
                                        heading={'Location Optimization'}
                                        subtext={'Data-driven decisions are key. AI analyzes customer traffic patterns, helping you choose the perfect store location within your stores, and maximizing foot traffic and sales.'}
                                    />
                                </div>
                            </div> */}
                            <div ref={sectionsRefs.actionrecognitionMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Retail</p>
                                        <h2 className="text-hColor text-base fB">Action Recognition</h2>
                                        <p className="text-pColor text-sm py-2 fM">Decoding behavior reveals the blueprint for excellence. Our cutting-edge technology transforms how you track and analyze human activity, offering precise, real-time data that enhances decision-making and operational efficiency across various sectors.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Detect Suspicious Behavior'}
                                        subtext={'Monitor human activity instantly to detect and address suspicious behavior or operational issues as they occur.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.communicationSkils}
                                        heading={'Customer Satisfaction'}
                                        subtext={'Analyze behavior to optimize service interactions and reduce wait times, boosting overall customer satisfaction and loyalty.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.predictiveAnalytics}
                                        heading={'Precise Behavior Analysis'}
                                        subtext={'Utilize advanced algorithms for accurate understanding of patterns and interactions, improving overall tracking and insights.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.safetyverificationicon}
                                        heading={'Safety and Compliance'}
                                        subtext={'Detect risky actions and ensure adherence to safety standards, minimizing accidents and enhancing workplace safety.'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex gap-20">
                    <div>
                        <div className="flex flex-col w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40">
                            <div className={`flex gap-2 items-center py-3 ${tab === 'visionanalytics' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'visionanalytics' ? 'text-primary text-xl transition-all ease-in duration-300 ' : 'text-black transition-all ease-in duration-300 '}`}
                                    onClick={() => {
                                        setTab('visionanalytics');
                                        handleScrollView(sectionsRefs.visionanalytics.current)
                                        //sectionsRefs.visionanalytics.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Vision Analytics
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'visionanalytics' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'visionanalytics' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'Lossprevention' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'Lossprevention' ? 'text-primary text-xl transition-all ease-in duration-300 ' : 'text-black transition-all ease-in duration-300 '}`}
                                    onClick={() => {
                                        setTab('Lossprevention');
                                        handleScrollView(sectionsRefs.Lossprevention.current)
                                        //sectionsRefs.Lossprevention.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Loss Prevention
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'Lossprevention' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'Lossprevention' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                                {/* <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'Lossprevention' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'Lossprevention' ? 'bg-primary visible' : ' invisible'}`}></p></div> */}
                            </div>
                            {/* <div className={`flex gap-2 items-center py-3 ${tab === 'visionselfcheckout' ? 'ml-2' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB text-nowrap ${tab === 'visionselfcheckout' ? 'text-primary text-xl transition-all ease-in duration-300 ' : 'text-black transition-all ease-in duration-300 '}`}
                                    onClick={() => {
                                        setTab('visionselfcheckout');
                                        handleScrollView(sectionsRefs.visionselfcheckout.current)
                                    }}
                                >
                                    Vision Self Checkout
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'visionselfcheckout' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'visionselfcheckout' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div> */}
                            <div className={`flex gap-2 items-center py-3 ${tab === 'assettracking' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB text-nowrap ${tab === 'assettracking' ? 'text-primary text-xl transition-all ease-in duration-300 ' : 'text-black transition-all ease-in duration-300 '}`}
                                    onClick={() => {
                                        setTab('assettracking');
                                        handleScrollView(sectionsRefs.assettracking.current)
                                        //sectionsRefs.assettracking.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Asset Tracking
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p></div>

                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'smartfridge' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB text-nowrap ${tab === 'smartfridge' ? 'text-primary text-xl transition-all ease-in duration-300 ' : 'text-black transition-all ease-in duration-300 '}`}
                                    onClick={() => {
                                        handleScrollView(sectionsRefs.smartfridge.current)
                                        setTab('smartfridge');
                                        //sectionsRefs.smartfridge.current.scrollIntoView({ behavior: 'smooth' });

                                    }}
                                >
                                    Smart Fridge
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'smartfridge' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'smartfridge' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            {/* <div className={`flex gap-2 items-center py-3 ${tab === 'actionrecognition' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB text-nowrap ${tab === 'actionrecognition' ? 'text-primary text-xl transition-all ease-in duration-300 ' : 'text-black transition-all ease-in duration-300 '}`}
                                    onClick={() => {
                                        setTab('actionrecognition');
                                        handleScrollView(sectionsRefs.actionrecognition.current)
                                        //sectionsRefs.actionrecognition.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Action Recognition
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'actionrecognition' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'actionrecognition' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div> */}
                        </div>
                    </div>
                    <div>
                        <div ref={sectionsRefs.visionanalytics} className={`my-4  lg:my-20 border-dashed border-b-2`}>
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl fB underline">Vision Analytics</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/vision-analytics')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /></Link> </button>
                                    </div>
                                    <p className="text-pColor text-sm py-2 fM">See your store through new eyes with vision analytics. Vision analytics for retail revolutionizes in-store data capture with advanced computer vision sensors, WiFi integration, and powerful analytics tools. Gain unparalleled insights into customer behavior, including precise dwell times, dynamic foot traffic patterns, and detailed demographics.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.easyIntegration}
                                        heading={'Digital Twin of the Building'}
                                        subtext={'Create a dynamic digital representation of your physical store for real-time monitoring, simulation, and optimization.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.scale}
                                        heading={'Performance Metrics'}
                                        subtext={'Gain actionable insights into store performance with metrics like footfall, heatmaps, customer journeys, and sales data.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.dataAnalytics}
                                        heading={'Behavioral Analytics & Profiling'}
                                        subtext={'Unveil customer preferences, behaviors, and patterns through in-depth analysis. Identify opportunities for improvement and personalized experiences.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.predictiveAnalytics}
                                        heading={'Predictive Analysis'}
                                        subtext={'Forecast customer behavior, optimize inventory, and make data-driven decisions to stay ahead of the competition.'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div ref={sectionsRefs.Lossprevention} className={`my-4  lg:my-20 border-dashed border-b-2`}>
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl underline fB">Loss Prevention</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/loss-prevention')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Enhance security and optimize your Retail experience with computer vision-powered Loss prevention. Our intelligent system not only prevents fake scanning but also acts as a silent observer, analyzing shopper behavior and traffic patterns. Gain valuable insights into product trends, strategically manage store flow, and create a dynamic, data-driven environment for better customer engagement and satisfaction.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.vision}
                                        heading={'Retail Shrinkage Detection'}
                                        subtext={'Combat retail shrinkage with AI-driven tools that identify and address inventory discrepancies before they affect profits.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.lessTime}
                                        heading={'Suspicious Activity Detection'}
                                        subtext={'Detect suspicious activity instantly with advanced algorithms, preventing unauthorized actions and safeguarding your store.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.dataForcsting}
                                        heading={'Proactive Loss Prevention'}
                                        subtext={'Use smart video analytics to catch theft and fraud early, protecting your store with proactive security measures.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.security}
                                        heading={'Cost-Cutting Intelligence'}
                                        subtext={'Reduce operating costs and boost efficiency with real-time insights and alerts, optimizing your inventory management.'}
                                    />
                                </div>

                            </div>
                        </div>
                        {/* <div ref={sectionsRefs.visionselfcheckout} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl underline fB">Vision Self Checkout</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/vision-selfcheckout')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /></Link> </button>
                                    </div>
                                    <p className="text-pColor text-sm py-2 fM">Effortless, Touchless, and Precise. Transform your retail experience with our cutting-edge Vision Self-Checkout. Streamline transactions with advanced touchless technology, cut down wait times, and boost customer satisfaction. Have a seamless, contactless shopping with real-time item scanning and accurate payment processing.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.shortTdev}
                                        heading={'Self-Checkout Kiosk'}
                                        subtext={'Simplifies the purchasing of food and beverages, cutting down wait times and boosting customer convenience.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Interactive Purchase Navigator'}
                                        subtext={'Leads customers through each step of the buying process with clear, detailed guidance for a smooth transaction.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.product}
                                        heading={'Precision Weighting System'}
                                        subtext={'Accurately weighs items to ensure correct pricing and avoid overcharges, enhancing pricing accuracy.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.costSaving}
                                        heading={'Contactless Payment Solutions'}
                                        subtext={'Enhances hygiene and safety with QR codes and voice/mobile controls, reducing physical contact during transactions.'}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div ref={sectionsRefs.assettracking} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl underline fB">Asset Tracking</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/asset-tracking')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Retail just got smarter! Forget misplaced inventory and security concerns. Computer vision technology is revolutionizing asset tracking and product identification, ensuring your shelves are always stocked and perfectly aligned with your planograms. Real-time staff availability monitoring empowers your workforce for optimized scheduling, while facial recognition deters unauthorized access in high-security areas. See it all, secure it all with AI!</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.shareReactNative}
                                        heading={'Smarter Inventory Automation'}
                                        subtext={'Streamline your inventory with seamless integration and automated tracking, receiving instant alerts for low stock and expiring items to keep your shelves fully stocked.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.webComputer}
                                        heading={'Dynamic Analytics Dashboard'}
                                        subtext={'Unlock tailored insights with customizable reports on asset usage, movement, and product identification to optimize allocation and boost retail efficiency.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.easyIntegration}
                                        heading={'Precision Product Alignment'}
                                        subtext={'Harness cutting-edge computer vision to ensure products are accurately placed and easily identified according to your planogram, including medical care organization.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.feasibility}
                                        heading={'Live Asset Tracking'}
                                        subtext={'Monitor your assets in real-time with advanced computer vision, offering a centralized dashboard for up-to-the-minute tracking and management of asset movement.'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div ref={sectionsRefs.smartfridge} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl underline fB">Smart Fridge</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/smart-fridge')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /></Link> </button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Reimagine retail refrigeration with our revolutionary smart fridge solutions. Embedded cameras keep a watchful eye on your inventory, automatically optimizing stock levels and eliminating the frustration of empty shelves.  Embrace the future of retail with smart fridges that not only elevate food management but also delight your customers with consistently fresh products and a seamless shopping experience.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.approach}
                                        heading={'Spot-On Product Tracking'}
                                        subtext={'Our advanced computer vision system delivers precise product identification and real-time inventory updates, ensuring your Smart Fridge is always fresh and fully stocked.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.zeroCost}
                                        heading={'Effortless Payment Experience'}
                                        subtext={'Enjoy secure, contactless transactions through integrated POS or mobile apps, making checkout quick and convenient for every shopper.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.product}
                                        heading={'Tailored Product Suggestions'}
                                        subtext={'Leverage visitor data and purchase history to provide customized product recommendations, enhancing customer satisfaction and boosting sales.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.BusinessService}
                                        heading={'Automatic Restocking Alerts'}
                                        subtext={'Stay ahead of stock shortages with automated low-stock notifications, ensuring your Smart Fridge is always stocked with the latest products.'}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div ref={sectionsRefs.actionrecognition} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl underline fB">Action Recognition</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/action-recognition')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /></Link> </button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Decoding behavior reveals the blueprint for excellence. Our cutting-edge technology transforms how you track and analyze human activity, offering precise, real-time data that enhances decision-making and operational efficiency across various sectors.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Detect Suspicious Behavior'}
                                        subtext={'Monitor human activity instantly to detect and address suspicious behavior or operational issues as they occur.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.communicationSkils}
                                        heading={'Customer Satisfaction'}
                                        subtext={'Analyze behavior to optimize service interactions and reduce wait times, boosting overall customer satisfaction and loyalty.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.predictiveAnalytics}
                                        heading={'Precise Behavior Analysis'}
                                        subtext={'Utilize advanced algorithms for accurate understanding of patterns and interactions, improving overall tracking and insights.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.safetyverificationicon}
                                        heading={'Safety and Compliance'}
                                        subtext={'Detect risky actions and ensure adherence to safety standards, minimizing accidents and enhancing workplace safety.'}
                                    />
                                </div>
                            </div>
                        </div> */}

                        {/* <div ref={sectionsRefs.behavioridentification} className="my-4 lg:my-20">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl underline fB">Behavior Identification</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /></Link> </button>
                                    </div>
                                    <p className="text-pColor text-sm py-2 fM">Secure your retail space with our advanced identification solutions, built to spot and stop suspicious behavior with pinpoint accuracy. Enjoy real-time alerts and proactive intervention thanks to our behavior pattern recognition technology. Transform your shopping environment into a haven, protecting your assets and reputation with our vigilant, centralized monitoring system.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.alert}
                                        heading={'Unauthorized Access Alerts'}
                                        subtext={'Instantly detect and notify security teams of any unauthorized attempts to breach restricted areas, fortifying your security and keeping sensitive zones secure.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.notification}
                                        heading={'Instant Security Notifications'}
                                        subtext={'Immediately alerts staff to suspicious behavior, enabling swift action and preventing potential theft or security issues with real-time updates.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.smartThinking}
                                        heading={'Smart Behavior Detection'}
                                        subtext={'It uses advanced algorithms to pinpoint and analyze suspicious behavior patterns, offering insights for proactive prevention and smarter surveillance.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.mobileNativeapps}
                                        heading={'Unified Security Dashboard'}
                                        subtext={'Centralizes all suspicious behavior alerts and data on a single, intuitive dashboard, providing a comprehensive overview for effective security management and quick responses.'}
                                    />
                                </div>
                            </div>
                        </div> */}
                        {/* <div ref={sectionsRefs.cutomersatisfaction} className="my-4 lg:my-20">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Retail</p>
                                            <h2 className="text-hColor text-xl fB underline">Customer Satisfaction</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-2 fM">Customer satisfaction in retail is key to repeat business and brand loyalty. Create seamless shopping experiences with efficient checkouts, personalized service, and prompt feedback response. Ensure product availability and a welcoming store environment. Prioritize these elements to keep customers returning and boost your brand's reputation.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.endUser}
                                        heading={'Speed Up Customer Checkout'}
                                        subtext={'AI analyzes customer flow and automatically deploys additional cashiers, keeping lines moving and customers smiling.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.MEANicon1}
                                        heading={'Plan Inventory Smartly'}
                                        subtext={'Utilize sales and foot traffic data to align inventory orders, preventing stockouts and ensuring shelves are always stocked.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.easyIntegration}
                                        heading={'Optimize Store Location'}
                                        subtext={'Leverage customer journey data to select prime store locations within malls, ensuring high visibility and access to significant foot traffic.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.maintainIcon}
                                        heading={'Location Optimization'}
                                        subtext={'Data-driven decisions are key. AI analyzes customer traffic patterns, helping you choose the perfect store location within your stores, and maximizing foot traffic and sales.'}
                                    />
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
            <section className="bg-[#F8F8F8] py-16">
                <div className="container mx-auto flex flex-col gap-12">
                    <div className="title block lg:flex justify-between">
                        <div>
                            <div className="mb-10">
                                <h2 className="text-2xl fB mb-1">We Don’t Just Build, We Elevate Retail</h2>
                                <h2 className="text-3xl fB text-[#C7C7C7] max-w-lg">Seamless Solutions from Concept to Storefront</h2>
                            </div>
                        </div>
                        <p className="fR text-sm max-w-xl mt-6 lg:mt-0">We provide end-to-end solutions and standalone modules for diverse retail businesses, leveraging cutting-edge technologies and industry-leading platforms.</p>
                    </div>
                    <div>
                        <p className="fR mb-5">Research Highlights</p>
                        <div className="section4 bg-background">
                            <div className="w-full gap-3">
                                <Slider {...settings}>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Loss <br /> Prevention </p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/loss-prevention')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col flex-grow gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">See It, Stop It, Save It! Detect basket mismatches, fake-scanning, and abandoned transactions.</p>
                                                <img src={PATH.img.SolutionLossprevention} alt="loss-prevention" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Vision <br /> Analytics</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/vision-analytics')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Turn Every Customer Interaction into Data Point and dissects demographics, emotions, and in-store behavior.</p>
                                                <img src={PATH.img.SolutionVisionAnalytics} alt="vision-analytics" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Vision <br /> Self-Checkout</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/vision-selfcheckout')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Simplify shopping with instant item scanning, swift payments, and a seamless checkout for a fast, hassle-free experience.</p>
                                                <img src={PATH.img.SolutionvisionSOCbanner} alt="SOC" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Asset <br /> Tracking</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/asset-tracking')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Track Your Assets, Inside and Out. Transform Your Inventory with 360° Robot Retail Asset Management.</p>
                                                <img src={PATH.img.SolutionAssettracking} className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        {/* <SolutionCarousel settings={settings} /> */}
                    </div>
                </div>
            </section>
            <FooterContact />
        </div >
    )
}
export default InRetail;