import React, { useState } from "react";
import { PATH } from "../globals/app-constants";
import { useNavigate } from "react-router-dom";
import SideBarIcons from "./SideBarIcon";
import { Link} from "react-router-dom";

const MenuBar = ({ showMenu, setShowMenu }) => {
    const [menu, setMenu] = useState('industry');
    const [showSubMenu, setshowSubMenu] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        setShowMenu(!showMenu);
    };
    const handleNavigate = (naviagteTo) => {
        navigate(naviagteTo);
        setShowMenu(false);
    };
    return (
        <div className="fixed top-0 left-0 z-40 bg-black overflow-auto overflow-x-hidden text-white w-full h-full ">
            <div className="z-50 w-screen absolute top-0">
                <div className="container mx-auto flex justify-between items-center h-20">
                    <button className='invisible'>
                        <img src={PATH.img.logoWhite} alt='logo' className='w-28 ' />
                    </button>
                    <div>
                        <button className='w-8 h-8 bg-slate-600 flex items-center justify-center rounded-full' onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex flex-row'>
                <div className="w-2/3 hidden lg:flex flex-col h-screen px-12 py-4 overflow-auto menubar_scroll">
                    <div className="h-14 flex justify-start items-center me-20">
                        <button className=''>
                            <img src={PATH.img.logoWhite} alt='logo' className='w-28 ' />
                        </button>
                    </div>
                    <div className="flex px-10 mt-6">
                        <div className="w-2/4">
                            <div className="flex flex-col justify-between items-start h-[90%]">
                                <div className="col-span-2 mt-12 lg:mt-0 flex flex-col justify-between">
                                    <ul className='list-none grid gap-5'>
                                        <li>
                                            <button className="flex flex-row items-center justify-between gap-6 group" onClick={() => setMenu('industry')}>
                                                <span className={`text-lg text-start tracking-wider fR group ${menu == 'industry' ? 'text-white' : 'text-[#7F7F7F]'}`}>Industries</span>
                                                <div className="relative "><p className={` ${menu == 'industry' ? 'bg-white w-16 h-0.5' : 'group-hover:w-16 h-0.5 transition-all ease-in duration-300 bg-[#7F7F7F]'}`}></p><p className={`rounded-full absolute -top-1 -right-1 ${menu == 'industry' ? ' bg-white w-2 h-2 ' : 'group-hover:w-2 h-2 bg-[#7F7F7F] transition-all ease-in duration-300'}`}></p></div>
                                            </button>
                                        </li>
                                        <li>
                                            <button className="flex flex-row items-center justify-between gap-6 group" onClick={() => setMenu('features')}>
                                                <span className={`text-lg text-start tracking-wider fR group ${menu == 'features' ? 'text-white' : 'text-[#7F7F7F]'}`}>Features</span>
                                                <div className="relative "><p className={` ${menu == 'features' ? 'bg-white w-16 h-0.5' : 'group-hover:w-16 h-0.5 transition-all ease-in duration-300 bg-[#7F7F7F]'}`}></p><p className={`rounded-full absolute -top-1 -right-1 ${menu == 'features' ? ' bg-white w-2 h-2 ' : 'group-hover:w-2 h-2 bg-[#7F7F7F] transition-all ease-in duration-300'}`}></p></div>
                                            </button>
                                        </li>
                                        <li>
                                            <button className="flex flex-row items-center justify-between gap-6 group" onClick={() => setMenu('research')}>
                                                <span className={`text-lg text-start tracking-wider fR group ${menu == 'research' ? 'text-white' : 'text-[#7F7F7F]'}`}>Research</span>
                                                <div className="relative "><p className={` ${menu == 'research' ? 'bg-white w-16 h-0.5' : 'group-hover:w-16 h-0.5 transition-all ease-in duration-300 bg-[#7F7F7F]'}`}></p><p className={`rounded-full absolute -top-1 -right-1 ${menu == 'research' ? ' bg-white w-2 h-2 ' : 'group-hover:w-2 h-2 bg-[#7F7F7F] transition-all ease-in duration-300'}`}></p></div>
                                            </button>
                                        </li>
                                        <li>
                                            <button className="flex flex-row items-center justify-between gap-6 group" onClick={() => setMenu('services')}>
                                                <span className={`text-lg text-start tracking-wider fR group ${menu == 'services' ? 'text-white' : 'text-[#7F7F7F]'}`}>Services</span>
                                                <div className="relative "><p className={` ${menu == 'services' ? 'bg-white w-16 h-0.5' : 'group-hover:w-16 h-0.5 transition-all ease-in duration-300 bg-[#7F7F7F]'}`}></p><p className={`rounded-full absolute -top-1 -right-1 ${menu == 'services' ? ' bg-white w-2 h-2 ' : 'group-hover:w-2 h-2 bg-[#7F7F7F] transition-all ease-in duration-300'}`}></p></div>
                                            </button>
                                        </li>
                                        <li>
                                            <button className="flex flex-row items-center justify-between gap-6 group" onClick={() => setMenu('technology')}>
                                                <span className={`text-lg text-start tracking-wider fR group ${menu == 'technology' ? 'text-white' : 'text-[#7F7F7F]'}`}>Technology</span>
                                                <div className="relative "><p className={` ${menu == 'technology' ? 'bg-white w-16 h-0.5' : 'group-hover:w-16 h-0.5 transition-all ease-in duration-300 bg-[#7F7F7F]'}`}></p><p className={`rounded-full absolute -top-1 -right-1 ${menu == 'technology' ? ' bg-white w-2 h-2 ' : 'group-hover:w-2 h-2 bg-[#7F7F7F] transition-all ease-in duration-300'}`}></p></div>
                                            </button>
                                        </li>
                                        <li>
                                            <button className="flex flex-row items-center justify-between gap-6 group" onClick={() => setMenu('platforms')}>
                                                <span className={`text-lg text-start tracking-wider fR group ${menu == 'platforms' ? 'text-white' : 'text-[#7F7F7F]'}`}>Platforms</span>
                                                <div className="relative "><p className={` ${menu == 'platforms' ? 'bg-white w-16 h-0.5' : 'group-hover:w-16 h-0.5 transition-all ease-in duration-300 bg-[#7F7F7F]'}`}></p><p className={`rounded-full absolute -top-1 -right-1 ${menu == 'platforms' ? ' bg-white w-2 h-2 ' : 'group-hover:w-2 h-2 bg-[#7F7F7F] transition-all ease-in duration-300'}`}></p></div>
                                            </button>
                                        </li>
                                        <li>
                                            <button className="flex flex-row items-center justify-between gap-6 group" onClick={() => setMenu('portfolio')}>
                                                <span className={`text-lg text-start tracking-wider fR group ${menu == 'portfolio' ? 'text-white' : 'text-[#7F7F7F]'}`}>Portfolio</span>
                                                <div className="relative "><p className={` ${menu == 'portfolio' ? 'bg-white w-16 h-0.5' : 'group-hover:w-16 h-0.5 transition-all ease-in duration-300 bg-[#7F7F7F]'}`}></p><p className={`rounded-full absolute -top-1 -right-1 ${menu == 'portfolio' ? ' bg-white w-2 h-2 ' : 'group-hover:w-2 h-2 bg-[#7F7F7F] transition-all ease-in duration-300'}`}></p></div>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="w-3/4">
                            {menu == 'industry' &&
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/industry/retail')}>Retail</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/industry/education')}>Education</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/industry/manufacturing')}>Manufacturing</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/industry/healthcare')}>Healthcare</Link></p>
                                </div>
                            }
                            {menu == 'features' &&
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/features/facial-recognition')}>Facial Recognition</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/features/distance-calculation')}>Distance calculation</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/features/shape-size-estimation')}>Shape and Size estimation</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/features/person-object-tracking')}>Person / Object Tracking</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/features/speed-calculation')}>Speed calculation</Link></p>
                                </div>
                            }
                            {menu == 'research' &&
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/research/ai-models-to-power-ai-applications')}>AI Models</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/research/NVIDIA_DeepStream')}>NVIDIA DeepStream</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/research/intel_Deep_Learning_Streamer')}>Deep Learning Streamer</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/research/Intel-tiber-edge-platform')}>Tiber Edge Platform</Link></p>
                                </div>
                            }
                            {menu == 'services' &&
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/staff-augmentation-services')}>Staff Augmentation</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/mern-stack-development-services')}>MERN Development</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/mean-stack-development-services')}>MEAN Development</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/java-full-stack-development-services')}>Java Full Stack</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/react-native-app-development-services')}>React Native Development</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/python-development-services')}>Python Development</Link></p>
                                </div>
                            }
                            {menu == 'technology' &&
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/ai-development-services')}>Artificial Intelligence</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/computer-vision-development-services')}>Vision Computing</Link></p>
                                </div>
                            }
                            {menu == 'platforms' &&
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/iOS-app-development-company')}>Ios</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/web-development-services')}>Web Development</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/android-app-development-services')}>Android</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/hybrid-app-development-services')}>Hybrid Platorm</Link></p>
                                </div>
                            }
                            {menu == 'portfolio' &&
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/shopapp-app')}>Shop App</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/jedidahs')}>Jedediah</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/cvs')}>CVS</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/treux-app')}>Treux</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/pauls-fashion')}>Pauls Fashion</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('case-studies/smart-fridge')}>Smart Fridge</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/aladdin-app')}>Aladdin</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/cv-shield')}>CV Shield</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/freshers-all-app')}>Freshers All</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/cosfymart-app')}>Cosfymart</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/tap-and-go')}>Tap & Go</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/equinux')}>Equinux</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/loss-prevention')}>Loss Prevention</Link></p>
                                    <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/qlm-app')}>QLM</Link></p>
                                    {/* <p className="fR text-base text-white py-4 px-4 bg-[#0E0E0E] text-center cursor-pointer" onClick={() => window.location.reload()}><Link to={('/case-studies/product-box-app')}>Product Box</Link></p> */}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/3 lg:bg-[#1C1C1C] px-4 lg:px-10 py-4 lg:h-screen overflow-x-hidden">
                    <div className="h-14 flex justify-between lg:justify-end items-center lg:me-20 menubar-header">
                        <button className='block lg:hidden'>
                            <img src={PATH.img.logoWhite} alt='logo' className='w-28 ' />
                        </button>
                        {/* <button className='w-8 h-8 bg-slate-600 flex items-center justify-center rounded-full menubar-header-content' onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button> */}
                    </div>
                    <div className="flex flex-col justify-between items-start h-[90%]">
                        <div className="col-span-2 mt-4 lg:mt-0 flex flex-col justify-between">
                            <ul className='list-none grid lg:gap-5'>
                                <li><button ><span className='text-lg lg:text-2xl  text-start tracking-wider fR flex items-center group' onClick={() => window.location.reload()}><Link to={('/')}>Home</Link>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg>
                                </span></button></li>
                                 {/* ----- mobile tab */}
                                 <li className="block lg:hidden"><button><span className='text-lg  text-start tracking-wider fR flex items-center group' onClick={() => setshowSubMenu('industry')}>Industries
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                {
                                    showSubMenu == 'industry' &&
                                    <div className="flex flex-col gap-2 py-2 pl-5">
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/industry/retail')}>- Retail</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/industry/education')}>- Education</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/industry/manufacturing')}>- Manufacturing</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/industry/healthcare')}>- Healthcare</Link></p>
                                    </div>
                                }
                                <li className="block lg:hidden"><button><span className='text-lg  text-start tracking-wider fR flex items-center group' onClick={() => setshowSubMenu('features')}>Features
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                {
                                    showSubMenu == 'features' &&
                                    <div className="flex flex-col gap-2 py-2 pl-5">
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/features/facial-recognition')}>- Facial Recognition</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/features/person-object-tracking')}>- Person / Object Tracking</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/features/speed-calculation')}>- Speed calculation</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/features/distance-calculation')}>- Distance calculation</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/features/shape-size-estimation')}>- Shape and Size estimation</Link></p>
                                    </div>
                                }
                                <li className="block lg:hidden"><button><span className='text-lg  text-start tracking-wider fR flex items-center group' onClick={() => setshowSubMenu('research')}>Research
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                {
                                    showSubMenu == 'research' &&
                                    <div className="flex flex-col gap-2 py-2 pl-5">
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/research/ai-models-to-power-ai-applications')}>- AI Models</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/research/NVIDIA_DeepStream')}>- NVIDIA DeepStream</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/research/intel_Deep_Learning_Streamer')}>- Deep Learning Streamer</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/research/Intel-tiber-edge-platform')}>- Tiber Edge Platform</Link></p>
                                    </div>
                                }
                               
                                <li className="block lg:hidden"><button><span className='text-lg  text-start tracking-wider fR flex items-center group' onClick={() => setshowSubMenu('service')}>Services
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                {
                                    showSubMenu == 'service' &&
                                    <div className="flex flex-col gap-2 py-2 pl-5">
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/staff-augmentation-services')}>- Staff Augmentation</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/mern-stack-development-services')}>- MERN Development</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/mean-stack-development-services')}>- MEAN Development</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/java-full-stack-development-services')}>- Java Full Stack</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/react-native-app-development-services')}>- React Native Development</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/python-development-services')}>- Python Development</Link></p>
                                    </div>
                                }
                                <li className="block lg:hidden"><button><span className='text-lg  text-start tracking-wider fR flex items-center group' onClick={() => setshowSubMenu('portfolio')}>Portfolio
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                {
                                    showSubMenu == 'portfolio' &&
                                    <div className="flex flex-col gap-2 py-2 pl-5">
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/shopapp-app')}>- Shop App</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/jedidahs')}>- Jedediah</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/cvs')}>- CVS</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/treux-app')}>- Treux</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/pauls-fashion')}>- Pauls Fashion</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('case-studies/smart-fridge')}>- Smart Fridge</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/aladdin-app')}>- Aladdin</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/cv-shield')}>- CV Shield</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/freshers-all-app')}>- Freshers All</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/cosfymart-app')}>- Cosfymart</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/tap-and-go')}>- Tap & Go</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/equinux')}>- Equinux</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/loss-prevention')}>- Loss Prevention</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/qlm-app')}>- QLM</Link></p>
                                        {/* <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/case-studies/product-box-app')}>- Product Box</Link></p> */}
                                    </div>
                                }
                                <li className="block lg:hidden"><button><span className='text-lg  text-start tracking-wider fR flex items-center group' onClick={() => setshowSubMenu('technology')}>Technology
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                {
                                    showSubMenu == 'technology' &&
                                    <div className="flex flex-col gap-2 py-2 pl-5">
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/ai-development-services')}>Artificial Intelligence</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/computer-vision-development-services')}>Vision Computing</Link></p>
                                    </div>
                                }
                                <li className="block lg:hidden"><button><span className='text-lg  text-start tracking-wider fR flex items-center group' onClick={() => setshowSubMenu('platform')}>Platforms
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                {
                                    showSubMenu == 'platform' &&
                                    <div className="flex flex-col gap-2 py-2 pl-5">
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/iOS-app-development-company')}>Ios</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/web-development-services')}>Web Development</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/android-app-development-services')}>Android</Link></p>
                                        <p className="text-lg fR cursor-pointer inline-block" onClick={() => window.location.reload()}><Link to={('/hybrid-app-development-services')}>Hybrid Platorm</Link></p>
                                    </div>
                                }
                                 <li><button><span className='text-lg lg:text-2xl  text-start tracking-wider fR flex items-center group' onClick={() => window.location.reload()}><Link to={('/company')}>Company</Link>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>


                                <li><button><span className='text-lg lg:text-2xl text-start tracking-wider fR flex items-center group' onClick={() => window.location.reload()}><Link to={('/blog')}>Blog</Link>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                <li><button><span className='text-lg lg:text-2xl  text-start tracking-wider fR flex items-center group' onClick={() => window.location.reload()}><Link to={('/career')}>Careers</Link>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                                <li><button><span className='text-lg lg:text-2xl  text-start tracking-wider fR flex items-center group' onClick={() => window.location.reload()}><Link to={('/contact')}>Contact</Link>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg></span></button></li>
                               
                            </ul>
                        </div>
                        <div className="hidden lg:flex flex-row gap-6 items-center">
                            <div className='flex flex-row gap-4'>
                                <SideBarIcons
                                    IconPath={PATH.img.icons.FdIcon}
                                    MediaName={'Facebook'}
                                />
                                <SideBarIcons
                                    IconPath={PATH.img.icons.TwitterIcon}
                                    MediaName={'Twitter'}
                                />
                                <SideBarIcons
                                    IconPath={PATH.img.icons.InstaIcon}
                                    MediaName={'Instagram'}
                                />
                                <SideBarIcons
                                    IconPath={PATH.img.icons.LinkedInIcon}
                                    MediaName={'LinkedIn'}
                                />
                            </div>
                            <div className="relative ">
                                <div className="w-14 h-0.5 bg-white ">
                                </div>
                                <div className="absolute w-2 h-2 bg-white -top-1 right-0 rounded-full"></div>
                            </div>
                            <h1 className="fSB text-base">Follows us</h1>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MenuBar;