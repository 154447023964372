import React, { useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link, useNavigate } from "react-router-dom";
import Faqs from "../../component/Faqs";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

const SpeedCalculation = () => {
    const [tab, setTab] = useState('Manufacturing');
    const navigate = useNavigate()
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 2,
        slidesToScroll: 1,
    }
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-10 absolute bottom-6 lg:top-[30%]">
                        <h1 className="banner_title fSB text-white w-auto md:w-auto lg:max-w-xl mb-4">Speed Calculation</h1>
                        <p className="text-sm text-white lg:max-w-2xl">Measure speed with precision using Speed Detection from CCTV with OpenCV and Deep Learning, putting accurate speed data right at your fingertips.</p>
                    </div>
                </div>
                <div className="absolute">
                    <img src={PATH.img.FeaturesSpeedcalculation} alt="banner" className="w-screen h-screen lg:h-full object-cover" />
                    <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 left-0 h-full w-full object-cover" />
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-xl">Use cases of Speed Calculation</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3">Turn speed motion into actionable insights with the cutting-edge power of OpenCV and Deep Learning. Our state-of-the-art computer vision system revolutionizes vehicle tracking and speed estimation, delivering real-time analysis, precise timestamps, and accurate MPH/KPH calculations. Transform your CCTV footage into precise speed data and lead the way in vehicle monitoring and analysis.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.dataForcsting} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Traffic Monitoring and Management</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Revolutionize road safety with real-time speed detection, reducing accidents and optimizing traffic flow for smoother commutes and safer streets.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.feasibility} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Advanced Vehicle Tracking</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Continuously detect and track vehicles in real-time, providing comprehensive data on vehicle movements, improving traffic management systems, and reducing congestion with intelligent rerouting solutions.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.security} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Security and Surveillance</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Identify potential threats by analyzing vehicle speed and behavior patterns. Analyze traffic speed data to identify accident-prone areas, allowing for targeted safety measures, infrastructure improvements, and enhanced enforcement to reduce incidents and improve road safety.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.dataAnalytics} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Sports Performance Analytics</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Elevate athletic performance with precise speed measurements during games and training, offering critical insights for coaches and athletes to refine techniques and strategies.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.efficiency} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Industrial Automation and Monitoring</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Ensure peak performance in industrial settings by monitoring the speed of machinery and conveyor belts, preventing malfunctions, and optimizing productivity with real-time data.</p>
                    </div>
                </div>
            </div >
            <div className="bg-black pb-4 lg:pb-20">
                <div className="flex container mx-auto gap-4 flex-row overflow-auto pt-28">
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Manufacturing')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Manufacturing' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.ManufacturingtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Manufacturing' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Manufacturing</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Education')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Education' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.EducationtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Education' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Education</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Healthcare')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Healthcare' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.HealthcaretabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Healthcare' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Healthcare</p>
                    </div>
                    {/* <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Retail')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Retail' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.RetailtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Retail' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Retail</p>
                    </div> */}
                </div>
                {tab == 'Manufacturing' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Monitor assembly line speed for optimal efficiency and reduced bottlenecks.</li>
                                    <li className="text-base fR mb-3 text-white">Detect speed anomalies to maintain high-quality standards.</li>
                                    <li className="text-base fR mb-3 text-white">Predict and prevent machinery breakdowns with speed tracking.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize workflows and enhance productivity using speed data analysis.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">AI for Quality Control</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-for-quality-control')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Education' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Elevate athletic performance with precise speed measurements.</li>
                                    <li className="text-base fR mb-3 text-white">Enhance campus safety by monitoring vehicle speeds.</li>
                                    <li className="text-base fR mb-3 text-white">Promote safer driving with detailed habit analysis.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize racing strategies with vehicle speed data for car and bike training programs.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fB text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4" >
                                    <h2 className="fR text-base text-white">AI Attendance</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-attendance')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Healthcare' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Optimize ambulance speeds for faster emergency response times.</li>
                                    <li className="text-base fR mb-3 text-white">Coordinate the speed of emergency vehicles to prevent accidents.</li>
                                    <li className="text-base fR mb-3 text-white">Monitor outpatient vehicle speeds to streamline patient arrivals and departures.</li>
                                    <li className="text-base fR mb-3 text-white">Track emergency patient arrivals to optimize hospital readiness and resource allocation.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fB text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Medical Image Analysis</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/medical-image-analysis')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {/* {tab == 'Retail' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Key Features</h1>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Track product movement, prevent stockouts, and optimize shelf space.</li>
                                    <li className="text-base fR mb-3 text-white">Detect suspicious activity and theft attempts for improved security.</li>
                                    <li className="text-base fR mb-3 text-white">Monitor customer movements to understand shopping patterns and improve store layout.</li>
                                    <li className="text-base fR mb-3 text-white">Identify areas with high and low product sales or foot traffic to boost productivity.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Proglint AI Solution</h1>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h1 className="fR text-base text-white">Vision Analytics</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions-vision-analytics')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h1 className="fR text-base text-white">Loss Prevention</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions-loss-prevention')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                } */}
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Don't Just Digitize, Be Intelligent</h2>
                <p className="text-base text-start text-pColor my-1">Proglint Makes AI Work for You. Digitize every aspect of your business and uncover opportunities for enhancement at every stage using our advanced AI and computer vision solutions.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.playplugicon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Plug-and-Play Perfection</h2>
                        <p className="text-pColor text-sm py-2">Seamless AI integration with instant, hassle-free setup.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.costSaving} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Smart Savings</h2>
                        <p className="text-pColor text-sm py-2">Slash operating costs by up to 40% with our AI-driven solutions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.security} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Data Security</h2>
                        <p className="text-pColor text-sm py-2">Safeguard your data with top-tier privacy and security protocols.</p>
                    </div>
                </div>
            </div>
            <Faqs
                Question1={`How does AI-powered computer vision measure vehicle speed so precisely?`}
                Answer1={`AI computer vision harnesses sophisticated algorithms to track and analyze vehicle movement from video streams. By capturing precise timestamps and measuring the distance between frames, it delivers pinpoint speed calculations in MPH/KPH.
                `}
                Question2={`Can I integrate AI speed calculation with my current CCTV system?`}
                Answer2={`Yes, integrating AI speed calculation with existing CCTV setups is seamless. This allows you to upgrade to cutting-edge speed detection technology without needing significant hardware changes.`}
                Question3={`What advantages does AI speed calculation bring to traffic management?`}
                Answer3={`AI speed calculation revolutionizes traffic management by providing real-time data, reducing congestion, optimizing flow, and enhancing road safety through ultra-precise speed detection.`}
                Question4={`What are the applications of AI speed calculation beyond traffic management?`}
                Answer4={`AI speed calculation has diverse applications, including sports analytics, industrial automation, and security surveillance. It can be used to analyze athlete performance, monitor machinery speed, and detect potential threats.`}
                Question5={`How does Proglint enhance speed calculation with AI computer vision?`}
                Answer5={`Proglint leverages cutting-edge AI and computer vision technology to deliver unparalleled accuracy in speed calculation. By integrating seamlessly with existing CCTV systems, Proglint provides real-time speed data, helping to improve traffic management, reduce congestion, and enhance road safety. Its advanced algorithms ensure precise measurements in all weather conditions, making it a reliable solution for modern vehicle monitoring needs.`}
            />
            <FooterContact />
        </div>
    )
}
export default SpeedCalculation