import React from "react";
import FooterContact from "../../component/FooterContact";

const TermsOfService = () => {


    return (
        <div>
            <div className="container mx-auto mt-32">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">Terms of Service</h1>
                <p className="text-sm md:text-base text-start text-pColor fR">Welcome to Proglint Software Solutions Private Limited!</p>
                <p className="text-sm md:text-base text-start text-pColor fR pt-5">We’re thrilled to have you on board. Before you start using our Software and services, We need you to read and agree to the following terms of service (“Terms”). The terms are a legally binding contract between you and Proglint Software Solutions Private Limited, so please take the time to read them carefully.</p>
                <p className="text-sm md:text-base text-start text-pColor fR pt-5">By accessing or using our software, websites, and services, you agree to be bound by these terms.</p>
                <h3 className="text-xl fSB pt-5 mb-3">1. Acceptance of Terms:</h3>
                <p className="text-base text-pColor fR">By using our Services, you affirm that you are of legal age and have the legal capacity to enter into these Terms. If you are using the services on behalf of an organization, you are agreeing to these terms for that organization and represent that you have the authority to bind the organization to these Terms.</p>
                <h3 className="text-xl fSB pt-5 mb-3">2. Licence:</h3>
                <p className="text-base text-pColor fR">Subject to your compliance with these Terms, Proglint Software Solutions Private Limited grant you a limited, non-exclusive, and revocable license to use our software and services.</p>
                <h3 className="text-xl fSB pt-5 mb-3">3. User Account:</h3>
                <p className="text-base text-pColor fR">To access certain features of our services, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
                <h3 className="text-xl fSB pt-5 mb-3">4. Payment and Billing</h3>
                <p className="text-base text-pColor fR">If you choose to purchase any of our paid services, you agree to pay all applicable fees. All fees are non-refundable unless otherwise stated in writing by Proglint Software Solutions Private Limited.</p>
                <h3 className="text-xl fSB pt-5 mb-3">5. Privacy:</h3>
                <p className="text-base text-pColor fR">Your privacy is important to us. Please review our Privacy Policy [link to our privacy policy] to understand how we collect, use, and disclose information.</p>
                <h3 className="text-xl fSB pt-5 mb-3">6. Prohibited Activities:</h3>
                <p className="text-base text-pColor fR">You agree not to engage in any prohibited activities, including but not limited to:</p>
                <div className="pl-5">
                    <li className="text-base text-pColor fR">Violating any applicable laws or regulations.</li>
                    <li className="text-base text-pColor fR">Breaching the security of our Services.</li>
                    <li className="text-base text-pColor fR"> Interfering with the proper functioning of the Services.</li>
                </div>
                <h3 className="text-xl fSB pt-5 mb-3">7. Intellectual Property:
                </h3>
                <p className="text-base text-pColor fR ">Proglint Software Solutions retains all rights, title, and interest in and to the Services, including all related intellectual property rights. You may not modify, adapt, translate, or reverse engineer any part of the Services.</p>
                <h3 className="text-xl fSB pt-5 mb-3">8. Termination:</h3>
                <p className="text-base text-pColor fR">We may terminate or suspend your account and access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Services, Proglint Software Solutions Private Limited, or third parties.</p>
                <h3 className="text-xl fSB pt-5 mb-3">Contact Information:</h3>
                <p className="text-base text-pColor fR">If you have any queries or concerns about these Terms, please contact us at</p>
                <div className="pl-5 mb-11">
                    <li className="text-base text-pColor fR">Email: <a className="underline cursor-pointer fSB" href="mailto:info@proglint.com">info@proglint.com</a></li>
                    <li className="text-base text-pColor fR">Phone No: <a className="underline cursor-pointer fSB" href="tel:+919597010401">+91 95970-10401</a></li>
                </div>
            </div>
            <FooterContact />
        </div>

    );
};
export default TermsOfService;