import React from "react";
import FooterContact from "./FooterContact";
import Button from "./Button";
import { PATH } from "../globals/app-constants";
import Faqs from "./Faqs";
import Industries from "./Industries";
import ContentBanner2 from "./ContentBanner2";
const Aiml = () => {
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-10 absolute top-[35%] md:bottom-[25%] md:top-[25%] ">
                        <h1 className="banner_title font-semibold text-white w-auto max-w-sm lg:max-w-xl">AI Development Services</h1>
                    </div>
                </div>
                <div className="absolute right-0 bottom-0 md:h-full">
                    <img src={PATH.img.AimlBanner} alt="banner" className="w-full h-full object-contain" />
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Enabling Tomorrow's World with Advanced Artificial Intelligence Solutions</h2>
                <p className="text-base text-start text-pColor my-1">Unlocking the Potential of Artificial Intelligence: Reshaping Industries, Enhancing Experiences, and Pioneering Future Innovations.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.AiMlIcon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Artificial Intelligence Development</h2>
                        <p className="text-pColor text-sm py-2">Our AI solutions redefine possibilities in app development, propelling innovation and automation.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.Bot} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Chatbot Development Services</h2>
                        <p className="text-pColor text-sm py-2">Elevate user interactions with our chatbot expertise, enhancing engagement and customer support in app development.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.machineLearning} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Machine Learning Development</h2>
                        <p className="text-pColor text-sm py-2">We harness machine learning to create intelligent apps, delivering personalized experiences and predictive solutions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.cvVision} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Computer Vision Technology</h2>
                        <p className="text-pColor text-sm py-2">Our computer vision solutions empower apps with visual understanding, revolutionizing industries and user experiences.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.Annotation} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Annotations</h2>
                        <p className="text-pColor text-sm py-2">Precision is our signature in app development, using annotations to training AI models for unparalleled accuracy.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.deepLearning} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Deep Learning</h2>
                        <p className="text-pColor text-sm py-2">Explore the depths of innovation with deep learning, delivering advanced solutions in app development and AI.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto  my-10 lg:my-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Pioneering the Future with AI and Machine Learning Solutions</h2>
                    <p className="text-base text-start text-pColor mb-3">Our visionary AI and ML solutions drive your business toward boundless possibilities and unparalleled success.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.User} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Business Analytics</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Empower your business with the wisdom of data, guiding your path to informed decisions and growth.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.dataAnalytics} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Data Analytics</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Uncover the potential within your data, letting it be the guiding light to maximize your success.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.predictiveAnalytics} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Predictive Analytics</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Embrace the future with confidence, as our predictive analytics paves the way for visionary insights.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.engine} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Recommendation Engine</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Personalize the journey for your users, offering them tailor-made experiences and discoveries.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.dataForcsting} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Data Forecasting</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Let data be your guiding star, foreseeing trends and leading you to the forefront of innovation.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.imageAnalytics} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Image Analytics</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Transform visuals into valuable wisdom, allowing your vision to shape a brighter future.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.voiceAi} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Voice-based AI</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Your voice, their command; embark on a journey of interactive and intuitive user experiences.</p>
                    </div>
                </div>
            </div >
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start  text-hColor font-bold mb-3">Success Stories</h2>
                    <p className="text-base text-start text-pColor my-1">Unveil the chapters of our AI and ML triumphs, where innovation meets dedication. Explore our journey to excellence.</p>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-14">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-bold mb-2">CV-Shield</h2>
                        <p className="text-sm md:text-base text-start text-pColor mb-6"> Proglient implemented an innovative Face Recognition-based Attendance System, preventing falsified attendance and offering real-time notifications. Our's solution automated tracking, ensuring accuracy through advanced algorithms and robust security measures. The system's efficiency minimized manual efforts and enhanced reliability in attendance recording, prioritizing user privacy and compliance.</p>
                        <div onClick={() => window.location.reload()}>
                            <Button LinkPath={('/case-studies/cv-shield')} value={'View All'} className={'bg-gradient-to-br from-primary from-30% to-secondary to-90% focus:outline-none px-5 text-hColor font-semibold my-4'} />
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 mt-6 lg:mt-0 h-[420px]">
                        <img src={PATH.img.CVshieldWeb} alt="project" className="w-full h-full" />
                    </div>
                </div>
            </div>
            <Industries />
            <ContentBanner2 />
            <Faqs
                Question1={`What industries benefit the most from AI solutions?`}
                Answer1={`AI has wide-reaching applications across various sectors such as healthcare, finance, retail, manufacturing, and more. It aids in optimizing processes, improving efficiency, and making data-driven decisions. 
                Proglint offers AI solutions tailored to diverse industries, leveraging deep expertise to craft custom solutions aligned with specific sector needs.
                `}
                Question2={`How does AI impact privacy and data security?`}
                Answer2={`AI implementation requires data, raising concerns about privacy and security. Robust encryption, anonymization techniques, and stringent access controls are employed to protect sensitive information. At Proglint, we ensure robust AI systems by implementing cutting-edge encryption and security protocols, safeguarding sensitive data in AI-driven solutions.`}
                Question3={`What are the key AI technologies used in solution development?`}
                Answer3={`AI encompasses various technologies including Machine Learning, Natural Language Processing (NLP), Computer Vision, and Deep Learning. We are subject matter experts in these AI technologies. We seamlessly power intelligent systems and drive automation.`}
                Question4={`How does AI improve business operations and decision-making?`}
                Answer4={`AI-driven insights from data analysis facilitate informed decision-making. From predictive analytics to personalized recommendations, Proglint's AI solutions optimize operations by providing data-driven insights, improving processes, and enabling informed strategic decisions.`}
                Question5={`Can AI solutions be customized to specific business needs?`}
                Answer5={`AI solutions are highly adaptable. They can be tailored to meet unique business requirements, whether developing chatbots for customer service or implementing predictive maintenance systems.
                Our experts specialize in tailoring AI solutions, collaborating closely with clients to develop bespoke AI-driven systems meeting unique business demands.`}
            />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Aiml