import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AcademicCapIcon, Bars2Icon, BuildingStorefrontIcon, ChevronDoubleRightIcon, HeartIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import MenuBar from "./MenuBar";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { PATH } from "../globals/app-constants";

const Navbar = () => {
    let Links = [
        { name: 'Home', link: '/' },
        { name: 'Industry', link: '/industry' },
        { name: 'Features', link: '/product-development-company' },
        { name: 'Research', link: '/research' },
    ];


    let Links1 = [
        { name: 'Loss Prevention', link: '/solutions/loss-prevention', icon: PATH.img.icons.losspreventionicon },
        { name: 'Vision Analytics', link: '/solutions/vision-analytics', icon: PATH.img.icons.visionanalyticsicon },
        { name: 'Asset Tracking', link: '/solutions/asset-tracking', icon: PATH.img.icons.assettrackingicon },
        // { name: 'Vision Self checkout', link: '/solutions/vision-selfcheckout', icon: PATH.img.icons.selfcheckouticon },
    ];
    let Links2 = [
        { name: 'AI Attendance', link: '/solutions/ai-attendance', icon: PATH.img.icons.ai },
        { name: 'Safety and Security', link: '/solutions/safety-security', icon: PATH.img.icons.safetysecurityicon },
        { name: 'Asset Tracking', link: '/solutions/asset-tracking', icon: PATH.img.icons.assettrackingicon },
    ];
    let Links3 = [
        { name: 'Safety and Security', link: '/solutions/safety-security', icon: PATH.img.icons.safetysecurityicon },
        { name: 'AI for Quality Control', link: '/solutions/ai-for-quality-control', icon: PATH.img.icons.ai },
        { name: 'Asset Tracking', link: '/solutions/asset-tracking', icon: PATH.img.icons.assettrackingicon },
    ];
    let Links4 = [
        { name: 'Pill Counting', link: '/solutions/pill-counting', icon: PATH.img.icons.pillicon },
        { name: 'Medical Image Analysis', link: '/solutions/medical-image-analysis', icon: PATH.img.icons.medicalimageicon },
        { name: 'Safety and Security', link: '/solutions/safety-security', icon: PATH.img.icons.safetysecurityicon },
    ];
    let industryLink1 = [
        { name: 'Retail', link: '/industry/retail', icon: <BuildingStorefrontIcon />, content: Links1, },
        { name: 'Education', link: '/industry/education', icon: <AcademicCapIcon />, content: Links2, },
        { name: 'Manufacturing', link: '/industry/manufacturing', icon: <WrenchScrewdriverIcon />, content: Links3, },
        { name: 'Healthcare', link: '/industry/healthcare', icon: <HeartIcon />, content: Links4, },
    ];
    let featureLinks1 = [
        { name: 'Face Recognition', link: '/features/facial-recognition', icon: PATH.img.icons.EducationIcon, link1: '/features/facial-recognition', img: PATH.img.Featuresfacialrecognition, heading: 'Facial Recognition', content: 'Transforming Every Glance into a Security Checkpoint - Where Facial Identification Meets Precision.', },
        { name: 'Person and Object Tracking', link: '/features/person-object-tracking', icon: PATH.img.icons.HealthcareIcon, link1: '/features/person-object-tracking', img: PATH.img.FeaturesPersontracking, heading: 'Person/Object Tracking', content: 'Spotlight on Every Move - Precision Tracking That Keeps People and Objects in Sharp Focus.', },
        { name: 'Shape and Size estimation', link: '/features/shape-size-estimation', icon: PATH.img.icons.ManufacturingIcon, link1: '/features/shape-size-estimation', img: PATH.img.FeaturesShapeEstimation, heading: 'Shape and Size Estimation', content: 'Turning Dimensions into Data - Precision Engineering for Accurate Shape and Size Measurement.', },
        { name: 'Distance calculation', link: '/features/distance-calculation', icon: PATH.img.icons.RetailIcon, link1: '/features/distance-calculation', img: PATH.img.FeaturesDistanceCalculation, heading: 'Distance Calculation', content: 'Exact Distances Decoded-Transform Space into Precision Metrics for Superior Navigation', },
        { name: 'Speed Calculation', link: '/features/speed-calculation', icon: PATH.img.icons.RetailIcon, link1: '/features/speed-calculation', img: PATH.img.FeaturesSpeedcalculation, heading: 'Speed Calculation', content: 'Speed Metrics Perfected - Real-Time Vehicle Speed Insights for Enhanced Safety.', },
        { name: 'Action Recognition', link: '/features/action-recognition', icon: PATH.img.icons.RetailIcon, link1: '/features/action-recognition', img: PATH.img.FeaturesActionRecognition, heading: 'Action Recognition', content: 'Every Action Tells a Story. Decipher It for Precise and Insightful Monitoring.', },

    ];

    let featureLinks2 = [
        { name: 'Loss Prevention1', link: '/industry', icon: PATH.img.icons.EducationIcon, },
        { name: 'AI Attendance1', link: '/product-development-company', icon: PATH.img.icons.HealthcareIcon },
        { name: 'Pill counting1', link: '/solutions', icon: PATH.img.icons.ManufacturingIcon },
    ];

    const dropdownRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [isHome, setIsHome] = useState(false);
    const [url, setUrl] = useState('/');
    const [isOpen, setIsOpen] = useState(false);
    const [isResearch, setIsResearch] = useState(false);
    const [isFeatureOpen, setIsFeatureOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [hoveredFeature, setHoveredFeature] = useState(featureLinks1[0]);
    const [hoveredIndustry, setHoveredIndustry] = useState(industryLink1[0]);
    const toggleComponent = () => { setOpen(prevState => !prevState) };
    const [show, setShow] = useState(true);
    // const [showHeader, setShowHeader] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const location = useLocation();
    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    useEffect(() => {
        setUrl(window.location.pathname);
        setIsHome(window.location.pathname === '/');
        return () => {
            setIsHome(false);
        };
    }, []);

    const handleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleNavigate = (navigateTo) => {
        window.location.href = '/';
        navigate(navigateTo);
        setUrl(navigateTo);
        setIsHome(navigateTo === '/');
    };

    const handleNavigationMobile = (navigateURL) => {
        setUrl(navigateURL);
        window.location.reload();
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setIsFeatureOpen(false);
    };

    const toggleFeatureDropdown = () => {
        setIsFeatureOpen(!isFeatureOpen);
        setIsOpen(false);
    };

    const closeDropdown = () => {
        setIsOpen(false);
        setIsFeatureOpen(false);
    };

    useEffect(() => {
        let previousScrollPosition = 0;
        let currentScrollPosition = 0;

        window.addEventListener('scroll', function (e) {
            currentScrollPosition = window.pageYOffset;
            if (currentScrollPosition > 100) {
                setIsHome(false);
            } else {
                setIsHome(true);
            }
            if (previousScrollPosition - currentScrollPosition < 0) {
                setShow(false);
            } else if (previousScrollPosition - currentScrollPosition > 0) {
                setShow(true);
            }
            previousScrollPosition = currentScrollPosition;
        });
    }, []);


    useEffect(() => {
        setUrl(window.location.pathname);
        if (window.location.pathname === '/') {
            setIsHome(true);
        }
    }, []);

    const checkRoute = (link) => {
        let isValid = false;
        if (link === '/') {
            isValid = location.pathname === '/';
        } else {
            isValid = location.pathname.includes(link);
        }
        return isValid;
    }
    const checkOverallRoute = (link) => {
        closeDropdown()
        if (link === '/research') {
            setIsResearch(true)
        } else {
            setIsHome(true);
        }
    }

    const handleRoute = (isFeature) => {
        let routeUrl = ''
        if (isFeature) {
            routeUrl = hoveredFeature.link
        } else {
            routeUrl = hoveredIndustry.link
        }
        navigate(routeUrl)
        closeDropdown()
    }

    return (
        <nav className={`${isHome && window.location.pathname === '/' ? 'bg-transparent ' : 'bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]'} w-full z-50 h-20 flex items-center fixed top-0`}>
            <div className="container mx-auto">
                <div className="flex items-center justify-between relative">
                    <div className="hidden lg:block">
                        <ul className="md:flex pl-9 md:pl-0 md:items-center pb-12 md:pb-0 absolute md:static md:z-auto z-[2] font-semibold left-0 w-full transition-all md:duration-0 duration-500 ease-in" ref={containerRef}>
                            {Links.map(obj => (
                                <li
                                    key={obj.name} className={`my-7 md:my-0 md:mr-5 fR ${!isOpen && !isFeatureOpen && (location.pathname === '/') && (!location.pathname.includes('research')) ? 'text-secondary' : (isHome && window.location.pathname === '/') ? 'text-white' : 'text-hColor'}`}>
                                    {obj.name === 'Industry' ? (
                                        <div>
                                            <span className={` cursor-pointer ${checkRoute('industry') ? 'text-secondary' : (isHome && window.location.pathname === '/') ? 'text-white' : 'text-hColor'} ${isOpen ? 'navColor' : ''}`} onClick={toggleDropdown}>{obj.name}</span>
                                            {isOpen && (
                                                <div className="absolute bg-white text-black mt-8 py-8 rounded-lg shadow-lg px-10  container left-0">
                                                    <div className="w-full grid grid-cols-2">
                                                        <div>
                                                            {industryLink1.map((l, index) => (
                                                                <div
                                                                    onClick={() => {
                                                                        closeDropdown();
                                                                        window.location.href = l.link; // Navigate and reload the page
                                                                    }}
                                                                    key={l.name}
                                                                    className={`cursor-pointer text-sm ${hoveredIndustry.name === l.name ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-[#565252] transition-all ease-in duration-300'}`}
                                                                >
                                                                    <div className="py-3 gap-2 mb-1 flex items-center" onMouseEnter={() => setHoveredIndustry(l)}>
                                                                        <ChevronDoubleRightIcon className={`w-6 h-6 text-primary ${hoveredIndustry.name === l.name ? 'group-hover:block' : 'hidden'}`} />
                                                                        <p>{l.name}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {hoveredIndustry && (
                                                            <Link to={hoveredIndustry?.content[0]?.link}>
                                                                <div className="bg-[#f9f9f9] rounded-md">
                                                                    <div className="px-4 py-4">
                                                                        <h1 className="text-base mb-2">Use Cases</h1>
                                                                        <p className="text-sm">Devise, activate, and propel with All-In-One AI Vision Systems</p>
                                                                    </div>
                                                                    {hoveredIndustry?.content.map((l1) => (
                                                                        <div key={l1.name} className="cursor-pointer py-3 px-6 mb-2 flex gap-4 text-[#565252] items-center">
                                                                            <div className="w-6 h-6">
                                                                                <img src={l1.icon} alt={l1.name} />
                                                                            </div>
                                                                            <div
                                                                                onClick={() => {
                                                                                    closeDropdown();
                                                                                    window.location.href = l1.link; // Navigate and reload the page
                                                                                }}
                                                                                key={l1.name}
                                                                                className="text-sm hover:underline">{l1.name} </div>
                                                                        </div>
                                                                    ))}
                                                                    <div className="text-black hover:text-primary">
                                                                        <button className="text-sm px-4 py-4 flex gap-2" onClick={() => handleRoute(false)}><Link className="flex gap-2 " to={('')}>View More <ArrowUpRightIcon className="w-5 h-5" /> </Link></button>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )}
                                                    </div>
                                                    <div className="w-6 h-6 bg-white absolute -top-3 rotate-45 left-24"></div>
                                                </div>
                                            )}
                                        </div>
                                    ) : obj.name === 'Features' ? (
                                        <div>
                                            <span className={` cursor-pointer ${checkRoute('features') ? 'text-secondary' : (isHome && window.location.pathname === '/') ? 'text-white' : 'text-hColor'} ${isFeatureOpen ? 'navColor' : ''}`} onClick={toggleFeatureDropdown}>{obj.name}</span>
                                            {isFeatureOpen && (
                                                <div className="absolute bg-white text-black mt-8 py-8 rounded-lg shadow-lg px-10  container left-0 ">
                                                    <div className="w-full grid grid-cols-2">
                                                        <div>
                                                            {featureLinks1.map((l, index) => (
                                                                <div onClick={() => {
                                                                    closeDropdown();
                                                                    window.location.href = l.link; // Navigate and reload the page
                                                                }}
                                                                    key={l.name}
                                                                    className={`cursor-pointer text-sm ${hoveredFeature.name === l.name ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-[#565252] transition-all ease-in duration-300'}`} >
                                                                    <div className="py-3 mb-2 flex gap-4 items-center" onMouseEnter={() => setHoveredFeature(l)}>
                                                                        <ChevronDoubleRightIcon className={` w-6 h-6 text-primary ${hoveredFeature.name === l.name ? ' group-hover:block transition  ease-in duration-300' : 'hidden'} `} />
                                                                        <p>{l.name}</p>
                                                                    </div>
                                                                </div>
                                                                // <Link to={l.link} onClick={closeDropdown} key={l.name}
                                                                //  className={`cursor-pointer text-sm ${hoveredFeature.name === l.name ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-[#565252] transition-all ease-in duration-300'}`} >
                                                                //     <div className="py-3 mb-2 flex gap-4 items-center" onMouseEnter={() => setHoveredFeature(l)}>
                                                                //         <ChevronDoubleRightIcon className={` w-6 h-6 text-primary ${hoveredFeature.name === l.name ? ' group-hover:block transition  ease-in duration-300' : 'hidden'} `} />
                                                                //         <p>{l.name}</p>
                                                                //     </div>
                                                                // </Link>
                                                            ))}

                                                        </div>

                                                        {hoveredFeature && (
                                                            <Link to={hoveredFeature.link1}>
                                                                <div className="transition-all ease-in-out duration-500 ">
                                                                    <img src={hoveredFeature.img} className="h-60 w-full object-cover rounded-md" alt={hoveredFeature.name} />
                                                                    <p className="text-sm fSB py-2">{hoveredFeature.heading}</p>
                                                                    <p className="text-sm fR text-gray-600 nav-item-contact-text">{hoveredFeature.content}</p>
                                                                    <div className="text-black hover:text-primary">
                                                                        <button className="text-sm py-4 flex gap-2" onClick={() => handleRoute(true)}><Link className="flex gap-2 " to={('')}>View More <ArrowUpRightIcon className="w-5 h-5" /> </Link></button>
                                                                    </div>
                                                                </div>
                                                            </Link>

                                                        )}

                                                    </div>
                                                    <div className="w-6 h-6 bg-white absolute -top-3 rotate-45 left-48"></div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <Link to={obj.link}
                                            onClick={() => {
                                                closeDropdown();
                                                window.location.href = obj.link; // Navigate and reload the page
                                            }}
                                            className={` cursor-pointer ${checkRoute(obj?.link)
                                                ? 'text-secondary' : (isHome && window.location.pathname === '/')
                                                    ? 'text-white' : 'text-hColor'}`}><a>{obj.name}</a></Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <img
                        src={isHome && window.location.pathname === '/' ? PATH.img.logoWhite : PATH.img.logoBlack}
                        alt="logo"
                        className="w-32 hidden lg:block absolute lg:left-0 lg:right-0 right-0 mx-auto cursor-pointer"
                        onClick={() => handleNavigate('/')}
                    />
                    <img
                        src={isHome && window.location.pathname === '/' ? PATH.img.logoWhite : PATH.img.logoBlack}
                        alt="logo"
                        className="w-32 block lg:hidden cursor-pointer"
                        onClick={() => handleNavigate('/')}
                    />
                    <div>
                        <Bars2Icon onClick={handleMenu} className={`cursor-pointer ${isHome && window.location.pathname === '/' ? 'w-8 h-8 text-white' : 'w-8 h-8 text-black'}`} />
                    </div>
                </div>
            </div>
            {showMenu && <MenuBar showMenu={showMenu} setShowMenu={setShowMenu} />}
        </nav >
    );
};

export default Navbar;
