import React, { useState } from "react";
import { PATH } from "../globals/app-constants";

const Visioncomputing = () => {
    const [tab, setTab] = useState(1)
    const AnalyticsArray = [
        {
            img: PATH.img.icons.collectIcon,
            text: "Collect"
        },
        {
            img: PATH.img.icons.annotateIcon,
            text: "Annotate"
        },
        {
            img: PATH.img.icons.trainingIcon,
            text: "Train"
        },
        {
            img: PATH.img.icons.rocket_icon,
            text: "Develop"
        },
        {
            img: PATH.img.icons.deployIcon,
            text: "Deploy"
        },
        {
            img: PATH.img.icons.connectIcon,
            text: "Connect"
        },
        {
            img: PATH.img.icons.monitorIcon,
            text: "Monitor"
        },
        {
            img: PATH.img.icons.maintainIcon,
            text: "Maintain"
        },
        {
            img: PATH.img.icons.security,
            text: "Secure"
        },
    ]
    const AnalyticsArrayContent = [
        {
            title: "Collect",
            content: "Gather diverse, high-quality data from various sources, ensuring a comprehensive representation for effective model training and analysis. Curate datasets meticulously, considering relevance and diversity to enhance model accuracy.",
            point1: "Diverse Data Curation",
            point2: "Precision in Diversity",
            point3: "Comprehensive Dataset Assembly",
            img: PATH.img.collectVC,
        },
        {
            title: "Annotate",
            content: "Methodically label and annotate collected data, enabling machine learning algorithms to recognize patterns and correlations accurately. Precise annotation ensures the model's ability to comprehend complex visual elements.",
            point1: "Precision Labeling Precision",
            point2: "Accurate Data Annotations",
            point3: "Detailed Labeling Precision",
            img: PATH.img.annotateVC,
        },
        {
            title: "Train",
            content: "Utilize sophisticated algorithms to train models, refining their accuracy iteratively. This involves processing massive datasets, adjusting parameters, and fine-tuning the model for optimal performance.",
            point1: "Iterative Model Refinement",
            point2: "Algorithmic Iterations",
            point3: "Optimal Model Training",
            img: PATH.img.trinVC,
        },
        {
            title: "Develop",
            content: "Create tailored solutions aligned with business objectives, utilizing trained models to develop applications that solve specific problems, from object detection to image recognition and beyond.",
            point1: "Tailored AI Solutions",
            point2: "Customized Model Applications",
            point3: "Precision in Solutions",
            img: PATH.img.developVC,
        },
        {
            title: "Deploy",
            content: "Seamlessly integrate Computer Vision & AI solutions into existing systems and workflows, ensuring smooth interaction and functionality across different platforms or applications.",
            point1: "Seamless System Integration",
            point2: "Smooth Interoperability",
            point3: "Effortless Integration",
            img: PATH.img.deployVC,
        },
        {
            title: "Connect",
            content: "Seamlessly integrate Computer Vision & AI solutions into existing systems and workflows, ensuring smooth interaction and functionality across different platforms or applications.",
            point1: "Seamless System Integration",
            point2: "Smooth Interoperability",
            point3: "Effortless Integration",
            img: PATH.img.connectVC,
        },
        {
            title: "Monitor",
            content: "Implement robust monitoring mechanisms to continuously assess model performance, identify anomalies, and make necessary adjustments, ensuring sustained accuracy and relevance.",
            point1: "Continuous Performance Evaluation",
            point2: "Real-time Model Assessment",
            point3: "Ongoing Accuracy Monitoring",
            img: PATH.img.monitorVC,
        },
        {
            title: "Maintain",
            content: "Develop strategies for ongoing maintenance, including periodic updates, enhancements, and refinements, to ensure continued optimal performance and adaptability.",
            point1: "Persistent Model Enhancement",
            point2: "Continuous System Refinement",
            point3: "Ongoing System Optimization",
            img: PATH.img.maintainVC,
        },
        {
            title: "Secure",
            content: "Implement stringent security protocols to protect sensitive data and models from potential threats, ensuring confidentiality, integrity, and compliance with industry standards. Regular audits and updates fortify the system against vulnerabilities.",
            point1: "Robust Data Protection",
            point2: "Fortified Model Security",
            point3: "Rigorous Security Measures",
            img: PATH.img.secureVC,
        },
    ]
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-[2] absolute lg:bottom-6 top-[35%] lg:top-[30%]">
                        <h1 className="banner_title font-semibold text-white w-auto lg:max-w-xl">Unveiling the Power of Computer Vision & AI</h1>
                        {/* <p className="my-4 font-normal text-white text-base lg:text-lg w-auto lg:max-w-2xl">Our expertise in Computer Vision & AI enables transformative solutions, redefining industries and shaping the future of technology.</p> */}
                    </div>
                </div>
                <div className="absolute right-0 w-full lg:w-4/6">
                    <img src={PATH.img.VisioncomputingBanner} alt="banner" className="w-full h-auto lg:h-full object-contain" />
                </div>
            </div>
            <div className="service_section container mx-auto  my-10 lg:my-16 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Experience Our Advanced Product Strategy with Computer Vision & AI</h2>
                    <p className="text-base text-start text-pColor my-3">Embark on a transformative journey with our advanced Product Strategy and Discovery Process, integrating the power of Computer Vision & AI. Experience accelerated value, cost-efficiency, and inherent scalability, redefining possibilities and shaping the future of your business through cutting-edge technology.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.onTime} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Accelerated Value Creation:</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">10x faster deployment of impactful solutions, leveraging the agility and precision of Computer Vision & AI. Experience rapid implementation and immediate impact on business outcomes.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.costSaving} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Cost-Efficient Solutions:</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Optimized processes lead to substantial savings, maximizing the benefits of cutting-edge technology. Enjoy cost-effective solutions without compromising quality.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.scalling} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Inherently Scalable:</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5"> Engineered with scalability in mind, our solutions evolve seamlessly, growing with your business demands leveraging Computer Vision & AI. Scale effortlessly as your needs expand, ensuring sustained efficiency.</p>
                    </div>
                </div>
            </div >
            <div className="hidden lg:block work_process container mx-auto">
                <div className="grid grid-cols-9 gap-8">
                    {
                        AnalyticsArray.map((obj, i) => (
                            <div className={`col-span-1 p-3 cursor-pointer ${i + 1 === tab ? "bg-primary" : "bg-white"} rounded-xl flex justify-center shadow-lg`} onClick={() => setTab(i + 1)}>
                                <div className="flex flex-col items-center">
                                    <img src={obj.img} alt="collect_icon" className="w-14 h-14" />
                                    <p className="text-base text-hColor font-medium mt-1 flex-grow">{obj.text}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    AnalyticsArrayContent.map((obj, i) => (
                        <div className={i + 1 === tab ? "" : "hidden"}>
                            <div className="grid grid-cols-1 md:grid-cols-2 my-16 md:gap-12 mb-16">
                                <div>
                                    <h2 className="text-2xl font-bold text-hColor mb-3">{obj.title}</h2>
                                    <p className="text-base font-normal text-pColor pb-5">{obj.content}</p>
                                    <ul className="list-disc list-inside">
                                        <li className="text-base text-pColor mb-2 uppercase">{obj.point1}</li>
                                        <li className="text-base text-pColor mb-2 uppercase">{obj.point2}</li>
                                        <li className="text-base text-pColor mb-2 uppercase">{obj.point3}</li>
                                    </ul>
                                </div>
                                <div>
                                    <img src={obj.img} alt="business_goal_img" className="w-full h-full object-cover" />
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
            <div className="container mx-auto mt-8 mb-6 lg:mt-20 lg:mb-16">
                <h2 className="text-xl md:text-3xl font-bold text-center text-hColor mb-3">Driving Growth with Scalable Computer Vision Innovations</h2>
                <p className="text-base mb-2 text-center text-pColor">Pioneering the frontier of innovation, our expertise lies in mastering deep visual learning for on-device computer vision solutions. We empower real-time insights, driving transformative advancements in visual intelligence</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 lg:gap-20 mt-8 md:mt-16">
                    <div>
                        <div className="shadow-lg p-4 flex justify-center rounded-xl">
                            <img src={PATH.img.objectDectionIcon} alt="object-dection" className="w-44 h-44 object-contain" />
                        </div>
                        <div className="my-6">
                            <h2 className="text-lg text-hColor font-semibold mb-3">Object Detection</h2>
                            <p className="text-sm text-pColor">Detects and identifies multiple objects within images or videos with pinpoint accuracy, enabling precise recognition and classification. Employs advanced algorithms to locate and categorize objects, facilitating tasks like automated inventory management and security surveillance.</p>
                        </div>
                    </div>
                    <div>
                        <div className="shadow-lg p-4 flex justify-center rounded-xl">
                            <img src={PATH.img.poseEstimationIcon} alt="object-dection" className="w-44 h-44 object-contain" />
                        </div>
                        <div className="my-6">
                            <h2 className="text-lg text-hColor font-semibold mb-3">Pose Estimation</h2>
                            <p className="text-sm text-pColor">Precisely identifies and tracks human body positions and movements within images or videos, offering insights into posture analysis and activity recognition. Utilizes sophisticated models to estimate body joint positions, assisting in applications like sports analytics and physical therapy assessments.</p>
                        </div>
                    </div>
                    <div>
                        <div className="shadow-lg p-4 flex justify-center rounded-xl">
                            <img src={PATH.img.ImagesegmentationIcon} alt="object-dection" className="w-44 h-44 object-contain" />
                        </div>
                        <div className="my-6">
                            <h2 className="text-lg text-hColor font-semibold mb-3">Image Segmentation</h2>
                            <p className="text-sm text-pColor">Divides images into distinct segments or regions, enabling precise understanding of object boundaries and composition within images. Utilizes semantic understanding to categorize and differentiate specific areas within images, supporting medical imaging and autonomous vehicle navigation.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="vision mt-5 lg:mt-10 container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 my-3 md:gap-12 mb-6 md:mb-12 lg:mb-16">
                    <div>
                        <img src={PATH.img.ImageClassification} alt="business_goal_img" className="w-full h-full object-cover" />
                    </div>
                    <div className="mt-4 md:mt-0">
                        <h2 className="text-sm font-normal text-hColor uppercase mb-2">Face Detection</h2>
                        <h2 className="text-2xl font-bold text-hColor mb-3">Facial Intelligence Integration</h2>
                        <p className="text-base font-normal text-pColor pb-5">Proglint's mastery in Face Detection and Facial Attribute Analysis transcends mere technology; it's a gateway to transformative software applications. By integrating our expertise, clients unlock a new dimension of user-centric experiences. Our solutions enable personalized interactions, enhanced security measures, and invaluable insights into user behavior. Whether optimizing customer engagement or refining security protocols, Proglint's proficiency in this realm reshapes software applications, driving innovation and elevating user experiences to unprecedented levels.</p>
                        <ul className="list-disc list-inside">
                            <li className="text-sm text-pColor mb-2 uppercase">Enhanced Automation</li>
                            <li className="text-sm text-pColor mb-2 uppercase">Precise Analysis and Insights</li>
                            <li className="text-sm text-pColor mb-2 uppercase">Revolutionized User Experiences</li>
                        </ul>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 my-6 md:gap-12 mb-6 md:mb-12 lg:mb-16">
                    <div className="block md:hidden">
                        <img src={PATH.img.ObjectDection} alt="business_goal_img" className="w-full h-full object-cover" />
                    </div>
                    <div className="mt-4 md:mt-0">
                        <h2 className="text-sm font-normal text-hColor uppercase mb-2">Object Detection</h2>
                        <h2 className="text-2xl font-bold text-hColor mb-3">Real-time Object Detection</h2>
                        <p className="text-base font-normal text-pColor pb-5">Proglint's fusion of AI-driven Real-time Object Detection revolutionizes software applications by swiftly identifying and tracking objects or people. Through advanced AI algorithms, our solutions empower applications to analyze and respond to real-world entities instantaneously. This transformative capability enhances sectors like surveillance, retail analytics, and autonomous systems, enabling proactive decision-making and bolstering safety measures. Proglint's AI-integrated Real-time Object Detection transforms software applications, creating an ecosystem where efficiency, security, and responsiveness converge, delivering unparalleled user experiences.</p>
                        <ul className="list-disc list-inside">
                            <li className="text-sm text-pColor mb-2 uppercase">Instantaneous Recognition</li>
                            <li className="text-sm text-pColor mb-2 uppercase">AI-Powered Precision</li>
                            <li className="text-sm text-pColor mb-2 uppercase">Enhanced Safety Measures</li>
                            {/* <li className="text-sm text-pColor mb-2 uppercase">FACE DETECTION</li> */}
                        </ul>
                    </div>
                    <div className="hidden md:block">
                        <img src={PATH.img.ObjectDection} alt="business_goal_img" className="w-full h-full object-cover" />
                    </div>

                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 my-6 md:gap-12 mb-6 md:mb-12 lg:mb-16">
                    <div>
                        <img src={PATH.img.poseEstimation} alt="business_goal_img" className="w-full h-full object-cover" />
                    </div>
                    <div className="mt-4 md:mt-0">
                        <h2 className="text-sm font-normal text-hColor uppercase mb-2">Pose Estimation</h2>
                        <h2 className="text-2xl font-bold text-hColor mb-3">AI-Powered Pose Estimation Solutions</h2>
                        <p className="text-base font-normal text-pColor pb-5">Pose Estimation ventures beyond conventional boundaries, harnessing the power of AI to decode human movement intricacies in real-time. Our proficiency enables software applications to comprehend and interpret body posture, gestures, and actions. By seamlessly integrating Pose Estimation capabilities, our solutions bring unparalleled interactivity and precision to software applications. This innovative technology not only revolutionizes fitness tracking and sports analytics but also augments immersive experiences in virtual environments and facilitates advanced healthcare diagnostics. Proglint's AI-driven approach in Pose Estimation transforms software applications, ushering in a new era of intuitive and dynamic user interactions.</p>
                        <ul className="list-disc list-inside">
                            <li className="text-sm text-pColor mb-2 uppercase">Precision in Motion Analysis</li>
                            <li className="text-sm text-pColor mb-2 uppercase">Real-time Interaction Enhancement</li>
                            <li className="text-sm text-pColor mb-2 uppercase">Adaptive Applications for Varied Sectors</li>
                            {/* <li className="text-sm text-pColor mb-2 uppercase">GAIT ANALYSIS</li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Visioncomputing;