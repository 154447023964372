import { fetchBaseQuery,createApi } from "@reduxjs/toolkit/query/react";
import API_BASE_URL from '../../service/Endpoints';

export const productsApi = createApi({
    reducerPath: "productsApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL}),
    endpoints: (builder) => ({
        products: builder.query({
            query: () => '/products'
        })
    })
})

export const { useProductsQuery } = productsApi;