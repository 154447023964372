import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const ProductBox = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Role-Based Access: The application offers distinct roles - admin, approver, and trainer, each with tailored permissions for efficient workflow management.",
        point2: "Enhanced Product Data Handling: Users can capture product images with cropping functionality, perform barcode and weight scans, and capture dimensions via RealSense camera technology.",
        point3: "OCR Integration: Optical Character Recognition extracts details like nutrition and ingredients from images, streamlining data collection and minimizing manual entry errors."
    },
    {
        title: 'Business challenge',
        challengeDescription: "Balancing role-based access controls and integrating diverse data input methods without compromising accuracy posed initial challenges, necessitating efficient user role definitions."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Role-Driven Access: Defined role hierarchies ensured controlled access, enabling different functionalities based on user roles, thereby enhancing data security and integrity.",
        point2: "Seamless Data Extraction: Integrated OCR technology simplified data extraction from images, enhancing accuracy while reducing manual data entry errors."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Product Box, equipped with role-based access controls and innovative data input features, revolutionizes product information management. Its intuitive interface, coupled with OCR capabilities, ensures accurate and updated product data management, optimizing operational efficiency."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.porductBoxBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Streamlined Product Information Management'}
                domainText={'Product Management, Data Input, User Roles'}
                durationText={'5 months, 14 man-months'}
                technologyText={'RealSense Depth Camera, Optical Character Recognition (OCR), Image Processing (OpenCV), Barcode Scanning API, Database Management (SQL)'}
                overviewText={'Product Box stands as an intuitive application designed to facilitate efficient product information management. It boasts a user-centric interface enabling hassle-free input and updates of crucial data such as images, weight, barcode, and dimensions.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.LeelaKitchenThumbnail} RetailHeading={'Food & Catering technology'} SubHead={'Leela-kitchen'} ParaContent={`Experience a culinary transformation with Proglint's innovative solution tailored for Leela Kitchen. Our efficient ordering system redefines the customer journey, offering seamless dish selection and smart inventory management. Enhance your service with our cost-effective, tech-driven food ordering approach.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/leela-kitchen')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.PaulFashionThumbnail} RetailHeading={'Fashion'} SubHead={`Paul's Fashion`} ParaContent={`Paul's Fashion, a longstanding tailor shop in Thailand, embraced innovation by launching an e-commerce app. Proglint facilitated this transition, delivering a seamless platform where customers effortlessly customize garments. The app's user-friendly interface enhanced the shopping experience, enabling personalized clothing selections with ease.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/pauls-fashion')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.losspreventionBanner} RetailHeading={'Retail'} SubHead={'Loss-Prevention'} ParaContent={'Implemented a robust loss-prevention algorithm for self-checkout kiosks in supermarkets. Prevents barcode mismatches, fake scans, and alerts on suspicious activities, enhancing security without the need for extensive product training. Deployed successfully in labs.'} profileContent={
                            <>
                                <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/loss-prevention')}>Read More</Link>
                            </>
                        } />
                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default ProductBox;