import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import ContentBanner2 from "../../../component/ContentBanner2";

const Java = () => {
   
    return (
        <div>
            <div className="white_banner relative overflow-hidden bg-cover bg-center ">
                <div className="container mx-auto">
                    <div className="absolute lg-top-[35%] md:bottom-[25%] md:top-[25%] z-10 ">
                        <h1 className="white_banner_title font-semibold text-hColor w-full lg:w-1/2 lg-mb-6 lg:mb-0">Loss Prevention</h1>
                        <p className="text-base text-start text-pColor my-1 w-full lg:w-1/2">Loss Prevention solution is your store's best defense against theft risks like basket mismatches and fake-scanning. Real-time alerts ensure quick action, keeping your store safe and constantly improving.</p>
                    </div>
                </div>
                <div className="absolute right-0 bottom-0 md:h-full">
                    <img src={PATH.img.LP_solution_detail} alt="banner" className="w-full h-full object-contain" />
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Harness Automation for Precise and Actionable Data</h2>
                <p className="text-base text-start text-pColor my-1">Unveil comprehensive insights into store traffic patterns and shopper behavior, empowering businesses to make data-driven decisions and optimize retail strategies with precision.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon1} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Retail Volume</h2>
                        <p className="text-pColor text-sm py-2">Access unmatched insights with our comparative data, presenting a dynamic view of shopper volume, in-store footfall, and store dynamics.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon2} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold">Retail Trends</h2>
                        <p className="text-pColor text-sm py-2">Unveil Dynamic Shopper Insights via Data on Conversion Rates, Cart Desertion, Dwell Time, Basket Sizes, and Purchase Lengths - Empowering Thoughtful Decision-Making.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.LP_Solution_icon3} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base font-semibold ">Inventory Insights</h2>
                        <p className="text-pColor text-sm py-2">Acquire insights into Product Performance, revealing purchasing trends, items considered, and alternatives- Enhancing your understanding of Shopper preferences.</p>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-2 max-w-2xl">AI-Driven Video Surveillance for Safe Point-of-Sale and Self-Checkout Procedures</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3">Experience heightened security at point-of-sale and self-checkout processes with our AI-enhanced video analysis, providing unparalleled protection against fraudulent activities in real time.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon1} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Stealthy Product Identification</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Streamlined Approach Boosts Training, Fueling Enhanced Product Recognition for Tomorrow's Advancements.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon2} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Supreme Accuracy in Product Insights</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our cutting-edge technology surpasses expectations by providing precise product details and reliable quantity estimations, breaking free from traditional limitations.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon3} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Enhance Product Training Efficiency</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Introducing Advanced Anonymous Product Recognition, transforming precision in product identification through Simplified Training Methods.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.LP_Solution_detail_line_icon4} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Bespoke Security Services</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Our Tailor-Made approach significantly mitigates theft and fraud, ensuring a smooth, trustworthy experience throughout the self-checkout process.</p>
                    </div>
                </div>
            </div >
            <div className="project container mx-auto">
                <div>
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start  text-hColor font-bold mb-3">Proglint's Innovation Redefining Global Retail Security</h2>
                    <p className="text-base text-start text-pColor my-1">Proglint's cutting-edge innovation reshapes global retail security, providing bespoke solutions for businesses. Effortlessly maintain and manage self-checkout operations, ensuring unparalleled security from a centralized platform.</p>
                </div>
                <div className="lg:flex lg:gap-10 my-6 lg:my-14">
                    <div className="w-full lg:w-2/5">
                        <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor font-bold mb-3">Algorithm</h2>
                        <p className="text-base text-start text-pColor mb-6">Engineered for Self-Checkout KIOSKs, our Advanced Algorithm Redefines Retail Security. Say Goodbye to Fraud Concerns as our Reliable System Guarantees a Secure Shopping Experience. Shop with Confidence, Sophistication, and Unmatched Security, Where Innovation Meets Peace of Mind.</p>
                    </div>
                    <div className="w-full lg:w-3/5 mt-10 lg:mt-0 h-[420px]">
                        <img src={PATH.img.shopAppThumbnail} alt="project" className="w-full h-full rounded-2xl object-cover" />
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10 pb-4 md:pb-8 relative z-10">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Maximize Profits, Reduce Expenses, and Enhance Customer Journey</h2>
                <p className="text-sm md:text-base text-start text-pColor mb-3">Proglint offers a holistic solution for business enhancement. Drive revenue growth and enrich customer experiences through discreet product recognition, precise insights, and affordable implementation.</p>
                <div className="grid grid-cols-1 md:grid-cols-3 my-10 md:my-14 gap-8 lg:gap-16">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Tackling Retail Theft</h2>
                        <p className="border-t-2 border-primary text-pColor pt-2 text-base flex-grow">Detecting Accurate Scans and Notifying on Incomplete Transactions for Enhanced Vigilance</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Streamlined Surveillance</h2>
                        <p className="border-t-2 border-[#26557D] text-pColor pt-2 text-base flex-grow">Guarantee Smooth Product Scanning with Our Monitoring Expertise</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Enhanced Accuracy in Self-Checkout</h2>
                        <p className="border-t-2 border-secondary text-pColor pt-2 text-base flex-grow">Unveiling Product Insights, Revealing Considered Items, and Exploring Alternatives</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 my-10 md:my-14 gap-8 lg:gap-16">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Anomaly Detection</h2>
                        <p className="border-t-2 border-primary text-pColor pt-2 text-base flex-grow">Spotting Inconsistencies and Promptly Notifying Customer Support for Swift Resolution</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Affordable Efficiency</h2>
                        <p className="border-t-2 border-[#26557D] text-pColor pt-2 text-base flex-grow">Achieve Greater Results with Budget-Friendly Implementation</p>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-hColor pb-4">Hassle-Free Installation</h2>
                        <p className="border-t-2 border-secondary text-pColor pt-2 text-base flex-grow">Enhance Your Self-Checkout Experience Effortlessly - Simply Integrate a Top Camera and Edge Compute for Seamless Precision</p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10 pb-4 md:pb-8 ">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3">Leading the Retail Revolution: Proglint's Vision for Efficiency</h2>
                <p className="text-sm md:text-base text-start text-pColor mb-3">Proglint revolutionizes surveillance at point-of-sale and self-checkout through ambient computing and AI analytics, delivering real-time insights into customer behavior. Our advanced analytics not only bolster security measures but also provide invaluable insights for streamlining operations and enriching the customer journey. </p> 
                <p className="text-sm md:text-base text-start text-pColor mb-3">With Proglint, retailers can remain ahead of the curve in today's dynamic retail landscape. We don't just prevent theft, we redefine retail operations, fostering a smarter, more secure ecosystem.</p>
                <div>
                    <img src={PATH.img.LP_solution_map} alt="map" className="w-full h-full mt-10"/>
                </div>
            </div>
            <ContentBanner2 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Java