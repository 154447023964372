import React from "react";
import { PATH } from "../globals/app-constants";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
const SolutionCarousel = ({ settings }) => {

    return (
        <div className="section4 bg-background">
            <div className="w-full gap-3">
                <Slider {...settings}>
                <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Vision <br /> Analytics</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/vision-analytics')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">Turn Every Customer Interaction into Data Point and dissects demographics, emotions, and in-store behavior.</p>
                                <img src={PATH.img.SolutionVisionAnalytics} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Loss <br/> Prevention</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/loss-prevention')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col flex-grow gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">See It, Stop It, Save It! Detect basket mismatches, fake-scanning, and abandoned transactions.</p>
                                <img src={PATH.img.SolutionLossprevention} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Pill <br /> Counting</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/pill-counting')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">No More Second Guessing. Precision in every pill count, experience the future of pharmacy.</p>
                                <img src={PATH.img.IndustryPillcount} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">AI <br /> Attendance</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/ai-attendance')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">Simplify attendance tracking with facial recognition for fast, accurate recording. Your face is your pass- just walk in, verify, and move on.</p>
                                <img src={PATH.img.AiattendanceImg} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div>
                    <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Asset <br /> Tracking</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/asset-tracking')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">Track Your Assets, Inside and Out. Transform Your Inventory with 360° Robot Retail Asset Management.</p>
                                <img src={PATH.img.SolutionAssettracking} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Vision<br /> Self Checkout</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/vision-selfcheckout')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">Simplify shopping with instant item scanning, swift payments, and a seamless checkout for a fast, hassle-free experience.</p>
                                <img src={PATH.img.SolutionvisionSOCbanner} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Face<br />Recognition</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solution-Ai-attandance')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">From retail to education, facial recognition is transitioning from sci-fi to reality, verifying identities by matching faces from images or videos against extensive databases.</p>
                                <img src={PATH.img.AiattendanceImg} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Safety and <br /> Security</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/safety-security')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">A Proven Solution for Rapid, contactless screening with AI-Driven, Contactless Weapon Detection and Real-Time Monitoring for Superior Safety.</p>
                                <img src={PATH.img.SolutionSafetyandsecurity} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Loss Prevention <br /> In Healthcare</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions-loss-prevention')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col flex-grow gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">AI Secures Healthcare Inventory. Enhance healthcare security with our AI-powered system, halting fraud effectively. No more errors or delays - just trust, verify, and ensure peace of mind for patients and staff.</p>
                                <img src={PATH.img.solution_healthcare} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div> */}
                    
                    {/* <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">AI for <br /> Quality Control</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/ai-for-quality-control')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">Automated inspections deliver instantaneous, precise evaluations, dramatically reducing manual tasks and eliminating human errors.</p>
                                <img src={PATH.img.SolutionQualityControl} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div> */}
                    
                    {/* <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Medical<br /> Image Analysis</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/medical-image-analysis')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">Facilitates rapid diagnoses, individualized treatment plans, precise tracking of medication use, and predictive health analytics.</p>
                                <img src={PATH.img.SolutionImageAalyis} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="lg:pr-6">
                        <div className="card bg-white rounded-3xl p-6">
                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                <p className="fR text-bace">Smart<br /> Fridge</p>
                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/smart-fridge')}>
                                    <ArrowUpRightIcon className="w-5 h-5" />
                                </Link>
                                </button>
                            </div>
                            <div className="flex flex-col gap-4 mt-6">
                                <p className="fR text-sm text-gray-500 solution-card-text">AI Vision Transforms Retail - Let Customers Grab and Go with Ease.</p>
                                <img src={PATH.img.smartfridgeimg1} className="w-full h-60 object-cover rounded-xl" />
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </div>
        </div>
    );
};
export default SolutionCarousel;