import React, { useState } from "react";
import { PATH } from "../../../globals/app-constants";
import FooterContact from "../../../component/FooterContact";
import { TagIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
const Vision_SOC = () => {


    const [showAccordion, setShowAccordion] = useState({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
    const handleAccordion = (accordion) => {

        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false })
                break;
            case 6:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6 })
                break;
        }

    }
    return (
        <div>
            <div className="home_banner relative">
                <div className="w-full h-full">
                    <img src={PATH.img.SolutionvisionSOCbanner} alt="banner" className="h-full w-full object-cover slide active" />
                </div>
                <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                <div className="w-full z-[2] absolute top-[30%] lg:top-[40%]">
                    <div className=" container mx-auto">
                        <div className="mb-14">
                            <div className="flex gap-1 items-center mb-2">
                                <TagIcon className="text-[#7A7A7A] w-5 h-5" />
                                <p className="text-xs fB text-[#7A7A7A]">Self Checkout</p>
                            </div>
                            <h1 className="text-2xl lg:text-5xl fR text-white">Vision Self-Checkout</h1>
                            <p className="text-base max-w-2xl fR text-white mt-4">Simplify shopping with instant item scanning, swift payments, and a seamless checkout for a fast, hassle-free experience.</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <h2 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center">Touchless AI Self-Checkout for seamless shopping and effortless transactions.</h2>
                <p className="text-sm fR text-start lg:text-center text-pColor my-1 mx-auto">Upgrade your shopping routine with our innovative checkout platform, designed to simplify purchases of groceries and bistro products. Benefit from seamless self-checkout and advanced touchless technology, ensuring a smooth experience for all. Provide effortless transactions with our advanced digital kiosk.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Benefits</h2>
                        <p className="fR text-sm my-3">Reimagine retail with touchless, data-driven solutions. Our AI delivers a smooth, contactless shopping experience while providing valuable insights to optimize your business.</p>
                        <div className="my-6">
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Enhanced Customer Experience</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(1)}>
                                        {showAccordion.accordion1 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">A smooth and effortless shopping journey, where convenience and satisfaction are top priorities, ensuring a delightful experience every time.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Reduced Wait times</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(2)}>
                                        {showAccordion.accordion2 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Reduce wait times at checkout and streamline staff allocation, making your operations more efficient and responsive to customer needs.</p>
                                        <p className="fR text-sm p-4">Improved Accuracy: Reduce errors in item scanning and payment processing for flawless transactions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Data-Driven Insights</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(3)}>
                                        {showAccordion.accordion3 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">Collect and analyze valuable customer behavior data to optimize your business strategies and enhance decision-making for better results.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b mb-4 border-gray-200 md:border-none md:bg-transparent">
                                <div className="accordion-hedder p-4 flex justify-between bg-[#F5F5F5] items-center" >
                                    <span className="text-base fB text-black">Contactless Convenience</span>
                                    <div className="w-5 h-5 cursor-pointer" onClick={() => handleAccordion(4)}>
                                        {showAccordion.accordion4 ? <MinusIcon className="text-black" /> : <PlusIcon style={{ color: "#000" }} />}
                                    </div>
                                </div>
                                <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                                    <div className="bg-[#F5F5F5]">
                                        <p className="fR text-sm p-4">A safer and more hygienic shopping environment with touchless payment options, reducing physical contact and enhancing overall safety.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={PATH.img.SolutionvisionSOC1} alt="visionsoc1-img" />
                    </div>
                </div>
            </section>
            <div className="solution-industry-banner">
                <div className="container mx-auto grid grid-cols-2 lg:grid-cols-3 py-20">
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.IndustryIcon} alt="industry-icon" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Industry</h2>
                            <p className="fR text-xs text-white">Manufacturing</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.DeliveryModalIcon} alt="delivery-icon" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Resource Allocation</h2>
                            <p className="fR text-xs text-white">Checkout Cameras, Integration Software</p>
                        </div>
                    </div>
                    <div className="flex gap-4 mb-24">
                        <img src={PATH.img.icons.TechnologyIcon} alt="technology-icon" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Tech Frameworks</h2>
                            <p className="fR text-xs text-white">Computer Vision, AI/ML</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.DomainbannerIcon} alt="domain-icon" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Support</h2>
                            <p className="fR text-xs text-white">Multi-Channel Support</p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <img src={PATH.img.icons.EffortIcon} alt="effort-icon" className="w-10 h-10" />
                        <div>
                            <h2 className="fR text-lg lg:text-2xl mb-1 text-white">Project Duration</h2>
                            <p className="fR text-xs text-white">4-8 Weeks, Scalable</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container mx-auto py-6 lg:py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 pt-14 pb-8 gap-8">
                    <div>
                        <img src={PATH.img.SolutionvisionSOC2} alt="visionsoc2-img" />
                    </div>
                    <div>
                        <h2 className="fB text-lg lg:text-2xl text-black">Features</h2>
                        {/* <p className="text-sm mt-2 fR">AI securely guards a world where patients whiz through checkout and medications. Upgrade your healthcare checkout process with our advanced computer vision-powered self-checkout, ensuring top-notch security and effortless patient transactions.</p> */}
                        <div className="relative">
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base"> Automated Checkout KIOSK</h2>
                                    <p className="text-sm fR mt-1">Provide a quick and user-friendly way to purchase food and beverages, reducing wait times and enhancing customer convenience.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Guided Purchase Display</h2>
                                    <p className="text-sm fR mt-1">Guides customers through the purchasing process by showing detailed steps and available items, making the transaction seamless and intuitive.</p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFE3D1] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Smart Weighting Tray</h2>
                                    <p className="text-sm fR mt-1">Supports items and calculates their weight precisely, ensuring accurate pricing and preventing overcharges.
                                    </p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">High-Tech Image Analysis</h2>
                                    <p className="text-sm fR mt-1">Identify and process items efficiently at the point of sale, reducing manual input and errors.
                                    </p>
                                </div>
                            </div>
                            <div className="my-6 flex gap-3 items-start time-line">
                                <div className="z-10">
                                    <div className="w-5 h-5 bg-[#FFC6BC] rounded-full"></div>
                                </div>
                                <div>
                                    <h2 className="fSB text-base">Touch-Free Transactions</h2>
                                    <p className="text-sm fR mt-1">Offers a hygienic payment experience through QR codes and voice/mobile controls, minimizing physical contact and enhancing safety.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Challenge</h2>
                    <div>
                        <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Deploying computer vision for self-checkout presents a maze of technical challenges. Merging cutting-edge image recognition technology with existing systems demands meticulous planning and substantial investment, making integration a complex and potentially disruptive endeavor.</p>
                        <p className="text-sm fR text-start text-pColor my-1 mx-auto mb-4">Preventing theft and fraud requires robust security measures. Ensuring a seamless user experience for customers of all ages and technical abilities is crucial. Additionally, maintaining system reliability and uptime is essential for business continuity.</p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-12">
                <div className="lg:flex gap-8">
                    <h2 className="w-2/3 text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start">Success</h2>
                   <div>
                   <p className="text-sm fR text-start text-pColor my-1 mx-auto">Proglint addresses these challenges with advanced computer vision technology. Our system excels in accurate product identification, even in challenging conditions. Our solution boasts ironclad security to thwart theft and fraud. User-centric design ensures a seamless and intuitive experience for customers of all ages and technical abilities and with a focus on reliability and uptime, our solution delivers exceptional performance.</p>
                    <p className="text-sm fR text-start text-pColor my-2 mx-auto">With its sophisticated algorithms and high-definition imaging, Proglint transforms self-checkout by drastically reducing errors and accelerating transaction times.</p>
                   </div>
                </div>
            </div>
            <FooterContact />
        </div>
    )
}
export default Vision_SOC;