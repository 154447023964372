import React from "react";
import Btn from "../component/Btn";
import { ArrowLongLeftIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
const Job1 = () => {
    return (
        <div className="container mx-auto mt-20">
            <div className="flex gap-1 items-center my-10" onClick={() => window.location.reload()}>
                <Link to={('/career')} className="flex gap-1 items-center">
                    <ArrowLongLeftIcon className="w-6 h-6 text-secondary cursor-pointer" />
                    <p className="text-secondary text-base fSB cursor-pointer">Back to Career</p>
                </Link>
            </div>
            <div className="mt-10 mb-6 lg:mb-10">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center  text-hColor fB mb-3">Java Developer</h1>
                {/* <p className="text-sm text-center text-pColor my-1 mb-3 px-2 lg:px-28">September 20, 2023</p> */}
                {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 w-32 text-center text-sm px-6 block m-auto bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                <div className="flex justify-center">
                    <a href="mailto:hr@proglint.com?subject=Applying for Java Developer">
                        {/* <button className='block mx-auto py-3 rounded-full my-4 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'> Apply Now</button> */}
                        <Btn value={'Apply Now'} bgColor={'white'} />
                    </a>
                </div>
            </div>
            <div className="md:flex my-14">
                <div className="w-full md:w-2/6 lg:w-1/6">
                    <h2 className="text-hColor text-base fSB">Job Information</h2>
                    <ul>
                        <li className="text-sm text-pColor my-3 fR">Any Graduate</li>
                        <li className="text-sm text-pColor my-3 fR">1 Year</li>
                        <li className="text-sm text-pColor my-3 fR"> Thiruvanmiyur, Chennai</li>
                        <li className="text-sm text-pColor my-3 fR">Full Time</li>
                    </ul>
                </div>
                <div className="w-full md:w-4/6 lg:w-5/6">
                    <div className="description">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Profile</h2>
                        <p className="text-pColor fR text-sm my-3">Proglint is looking for skilled programmers with experience building web applications in Java.
                            Java Developers are responsible for analyzing user requirements and business objectives,
                            determining application features and functionality, and recommending changes to existing
                            Java-based applications, among other duties.</p>

                    </div>
                    <div className="description mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Description</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Designing and implementing Java-based applications.</li>
                            <li className="text-pColor fR text-sm my-3"> Analyzing user requirements to inform application design.</li>
                            <li className="text-pColor fR text-sm my-3"> Defining application objectives and functionality.</li>
                            <li className="text-pColor fR text-sm my-3"> Aligning application design with business goals.</li>
                            <li className="text-pColor fR text-sm my-3"> Developing and testing software.</li>
                            <li className="text-pColor fR text-sm my-3"> Debugging and resolving technical problems that arise.</li>
                            <li className="text-pColor fR text-sm my-3"> Producing detailed design documentation.</li>
                            <li className="text-pColor fR text-sm my-3"> Recommending changes to existing Java infrastructure.</li>
                            <li className="text-pColor fR text-sm my-3"> Developing multimedia applications.</li>
                            <li className="text-pColor fR text-sm my-3">Developing documentation to assist users.</li>
                            <li className="text-pColor fR text-sm my-3"> Ensuring continuous professional self-development.</li>
                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Skills Required</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Object oriented analysis and design using common design patterns.</li>
                            <li className="text-pColor fR text-sm my-3">Good knowledge in Java 8 & Java 11, Spring Boot, JPA, Micro services and SQL.</li>
                            <li className="text-pColor fR text-sm my-3">Experience with user interface design, database structures, and statistical analyses.</li>
                            <li className="text-pColor fR text-sm my-3">Analytical mindset and good problem solving skills.</li>
                            <li className="text-pColor fR text-sm my-3">Ability to work as a team player.</li>
                            <li className="text-pColor fR text-sm my-3">Rigid adherence to schedule and delivering solutions on time.</li>
                            <li className="text-pColor fR text-sm my-3">Excellent communication skills (verbal and written).</li>
                        </ol>
                    </div>
                    <h2 className="text-sm fSB text-hColor my-4">Please note that we have requirements for this role in Chennai.</h2>
                </div>
            </div>
        </div >
    );
};
export default Job1