import React from "react";
const IndustryCard = ({ ImgPath, Title, Subtext, ProfileContent,}) => {

    return (
        <div className="flex flex-col lg:flex-row items-start gap-8">
            <img src={ImgPath} alt="Industy-img" className="w-24 h-24 object-cover rounded-md border border-[#696969]" />
            <div>
                <h1 className="fSB text-xl text-white">{Title}</h1>
                <p className="fR text-sm text-[#CFCFCF] mt-2">{Subtext}</p>
                {ProfileContent}
            </div>
        </div>

    );
}
export default IndustryCard