import React, { useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link } from "react-router-dom";
import Faqs from "../../component/Faqs";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

const ActionRecognition = () => {
    const [tab, setTab] = useState('Manufacturing');
    return (
        <div>
             <div className="home_banner relative">
                <div className="w-full h-full">
                    <img src={PATH.img.FeaturesActionRecognition} alt="banner" className="h-full w-full object-cover slide active" />
                </div>
                <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 h-full w-full object-cover active" />
                <div className="w-full z-[2] absolute top-[30%] lg:top-[40%]">
                    <div className=" container mx-auto">
                        <div className="mb-14">
                            <h1 className="text-2xl lg:text-5xl fR text-white">Action Recognition with Behavior Identification</h1>
                            <p className="text-base max-w-2xl fR text-white mt-4">Revolutionize Behavior Monitoring with AI-Powered Recognition. Get precise, real-time analysis of human activity, transforming how you understand and track their behavior.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-xl">Advanced Computer Vision for Human Behavior Analysis</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3">To know your customer is to master the market.  Behavior analysis reveals deep insights into buying patterns, preferences, and interactions, enabling businesses to tailor their strategies, improve engagement, and drive success across diverse sectors.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.stockicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Retail Theft Prevention</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Detect and analyze suspicious behavior to prevent theft, utilizing real-time surveillance data. Enhance security measures by identifying potential shoplifters and reducing inventory shrinkage.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.communicationSkils} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Customer Satisfication</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Measure queue times and customer interactions to optimize staffing and service efficiency. Reduce wait times and enhance the overall customer service experience, increasing satisfaction and loyalty.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.safetyverificationicon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Industrial Safety Monitoring</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Track employee movements in hazardous areas to detect and prevent accidents or injuries. Enhance workplace safety and compliance, reducing the likelihood of industrial accidents.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.efficiency} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Healthcare Patient Monitoring</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Monitor patient movements to detect falls or unusual activity patterns, ensuring timely intervention. Improve patient safety and response times, leading to better healthcare outcomes.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.healthcare} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Patient Monitoring</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Track patient status through behavior analysis even in the absence of doctors. This ensures timely interventions and continuous care, enhancing patient outcomes.</p>
                    </div>
                </div>
            </div >
            <div className="bg-black pb-4 lg:pb-20">
                <div className="flex container mx-auto gap-4 flex-row overflow-auto pt-28">
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Manufacturing')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Manufacturing' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.ManufacturingtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Manufacturing' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Manufacturing</p>
                    </div>
                    {/* <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Education')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Education' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.EducationtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Education' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Education</p>
                    </div> */}
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Healthcare')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Healthcare' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.HealthcaretabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Healthcare' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Healthcare</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Retail')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Retail' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.RetailtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Retail' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Retail</p>
                    </div>
                </div>
                {tab == 'Manufacturing' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <p className="text-lg fM text-white">Key Features</p>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Identify and address risky actions to minimize workplace accidents.</li>
                                    <li className="text-base fR mb-3 text-white">Enhance efficiency by refining worker movements and equipment use.</li>
                                    <li className="text-base fR mb-3 text-white">Assess worker fatigue and enthusiasm to improve performance in key areas.</li>
                                    {/* <li className="text-base fR mb-3 text-white">Improve space utilization and logistics with precise distance tracking between items.</li> */}
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Ai for Quality control</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-for-quality-control')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {/* {tab == 'Education' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Key Features</h1>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Elevate athletic performance with precise speed measurements.</li>
                                    <li className="text-base fR mb-3 text-white">Enhance campus safety by monitoring vehicle speeds.</li>
                                    <li className="text-base fR mb-3 text-white">Promote safer driving with detailed habit analysis.</li>
                                    <li className="text-base fR mb-3 text-white">Optimize racing strategies with vehicle speed data for car and bike training programs.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h1 className="text-lg text-white">Proglint AI Solution</h1>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4" >
                                    <h1 className="fR text-base text-white">AI Attendance</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h1 className="fR text-base text-white">Safety and Security</h1>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions-safety-security')}>
                                            <p className="text-sm text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                } */}
                {tab == 'Healthcare' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Identify early warning signs of health deterioration through behavior analysis.</li>
                                    <li className="text-base fR mb-3 text-white">Track and assess patient status for timely interventions.</li>
                                    <li className="text-base fR mb-3 text-white">Customize care plans based on individual behavior and engagement data.</li>
                                    <li className="text-base fR mb-3 text-white">Allocate healthcare resources more effectively based on insights.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Pill Counting</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/pill-counting')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Medical Image Analysis</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/medical-image-analysis')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Retail' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Detect and analyze suspicious behavior using real-time surveillance to prevent shoplifting.</li>
                                    <li className="text-base fR mb-3 text-white">Identify customer dissatisfaction or enthusiasm in specific departments.</li>
                                    <li className="text-base fR mb-3 text-white">Measure queue times and customer interactions to improve staffing and service efficiency.</li>
                                    <li className="text-base fR mb-3 text-white">Improve customer satisfaction by reducing wait times and enhancing service.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Vision Analytics</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/vision-analytics')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Loss Prevention</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/loss-prevention')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Don't Just Digitize, Be Intelligent</h2>
                <p className="text-base text-start text-pColor my-1">Proglint Makes AI Work for You. Digitize every aspect of your business and uncover opportunities for enhancement at every stage using our advanced AI and computer vision solutions.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.playplugicon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Plug-and-Play Perfection</h2>
                        <p className="text-pColor text-sm py-2">Seamless AI integration with instant, hassle-free setup.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.costSaving} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Smart Savings</h2>
                        <p className="text-pColor text-sm py-2">Slash operating costs by up to 40% with our AI-driven solutions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.security} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Data Security</h2>
                        <p className="text-pColor text-sm py-2">Safeguard your data with top-tier privacy and security protocols.</p>
                    </div>
                </div>
            </div>
            <Faqs
                Question1={`What is Customer Behavior Analysis and why is it important?`}
                Answer1={`Customer Behavior Analysis is the process of understanding how customers interact with a business. It involves studying customer actions, preferences, and patterns to gain insights that can be used to improve products, services, and overall customer experience. This analysis is crucial for businesses to stay competitive, increase sales, and build customer loyalty.`}
                Question2={`How does customer behavior analysis benefit different industries?`}
                Answer2={`Customer behavior analysis helps various industries by improving efficiency, enhancing security, personalizing services, and increasing overall satisfaction. It provides insights that drive better decision-making and operational improvements.`}
                Question3={`How can behavior analysis improve manufacturing processes?`}
                Answer3={`Behavior analysis in manufacturing identifies inefficiencies, enhances product quality, and optimizes workflows by analyzing worker interactions and equipment usage, leading to higher productivity and reduced downtime.`}
                Question4={`How does behavior analysis enhance both theft prevention and customer service in retail?`}
                Answer4={`Behavior analysis leverages real-time surveillance to detect and address suspicious behavior, preventing theft and reducing inventory shrinkage. Additionally, it measures queue times and analyzes customer interactions to optimize staffing and minimize wait times, thereby enhancing customer service and satisfaction.`}
                Question5={`How does behavior analysis support patient monitoring and adherence to treatment plans?`}
                Answer5={`Behavior analysis facilitates remote tracking of patient status through behavioral patterns, allowing for timely interventions and continuous care even when doctors are not present. It also identifies factors influencing patient compliance, addressing barriers to improve adherence to treatment plans and overall health outcomes.`}
            />
            <FooterContact />
        </div>
    )
}
export default ActionRecognition