import React, { useState, useEffect, useRef } from "react";
import { PATH } from "../../../globals/app-constants";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import FooterContact from "../../../component/FooterContact";
import InsightsCard from "../../../component/InsightsCard";
import HealthcareVideo from "../../../asset/video/healthcare.mp4";


const Healthcare = () => {
    const mobileSectionsRefs = {
        healthcareoptimizationMd: useRef(null),
        pillcountingMd: useRef(null),
        selfcheckoutMd: useRef(null),
        assettrackingMd: useRef(null),
        employeetrackingMd: useRef(null),
    };
    const webSectionsRefs = {
        healthcareoptimization: useRef(null),
        pillcounting: useRef(null),
        selfcheckout: useRef(null),
        assettracking: useRef(null),
        employeetracking: useRef(null),
    };
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [tab, setTab] = useState(isMobileView ? 'healthcareoptimizationMd' : 'healthcareoptimization');
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 3,
        slidesToScroll: 1,
    }
    const [sectionsRefs, setSectionsRefs] = useState(isMobileView ? mobileSectionsRefs : webSectionsRefs)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setIsMobileView(isMobile);
            setSectionsRefs(isMobile ? mobileSectionsRefs : webSectionsRefs);

            // Determine the currently active section
            const currentActiveTab = Object.keys(sectionsRefs).find((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    return top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2;
                }
                return false;
            });

            // Set the tab dynamically based on the active section
            if (currentActiveTab) {
                setTab(isMobile ? `${currentActiveTab}Md` : currentActiveTab.replace('Md', ''));
            } else {
                // Fallback to the first section if no active section is found
                setTab(isMobile ? 'healthcareoptimizationMd' : 'healthcareoptimization');
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Iterate through sections to find the one currently in view
            Object.keys(sectionsRefs).forEach((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                        setTab(key);
                    }
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sectionsRefs]);

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('scroll', handleHorizontalScroll(tab));
        }, 1000);
    }, [tab])

    const handleScrollView = (refName) => {
        const pos = refName.style.position;
        const top = refName.style.top;
        refName.style.position = 'relative';
        refName.style.top = '-100px';
        refName.scrollIntoView({ behavior: 'smooth', block: 'start' });
        refName.style.top = top;
        refName.style.position = pos;
    }
    const handleScrollViewMd = (refName, currentTab) => {
        if (refName) {
            // Adjust the position temporarily for smooth scroll
            const pos = refName.style.position;
            const top = refName.style.top;
            refName.style.position = 'relative';
            refName.style.top = '-170px';

            refName.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });

            // Reset the position after scroll
            refName.style.top = top;
            refName.style.position = pos;

            // Once the vertical scroll is done, trigger the horizontal scroll
            refName.addEventListener('transitionend', () => {
                handleHorizontalScroll(currentTab);
            }, { once: true });
        }
    };
    const handleHorizontalScroll = (currentTab) => {
        const container = document.querySelector('.healthParentTabs-container'); // Adjust the selector to match your container
        const subContainer = document.querySelector(`.healthSubTabsr-${currentTab}`); // Adjust the selector to match your container
        if (container) {
            const subContainerRect = subContainer?.getBoundingClientRect();
            container.scrollBy({
                left: subContainerRect?.x > 0 ? 100 : -200,
                behavior: 'smooth'
            });
        }
    }
    return (
        <div className="mt-24 lg:mt-32">
            <div className="container max-auto">
                <h1 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center capitalize">Artificial Intelligence in Healthcare- Transforming Patient Care with Smart Data</h1>
                <p className="text-base fR text-start lg:text-center text-pColor my-1 max-w-3xl mx-auto">Pioneering advancements in patient care and operational excellence, we provide top-tier Artificial intelligence solutions for the healthcare industry.</p>
                <div className="my-6 lg:my-12 flex justify-center ">
                    <video className="lg:max-w-screen-md rounded-md" playsInline autoPlay={true} muted loop>
                        <source src={HealthcareVideo} type="video/mp4"></source>
                    </video>
                </div>
            </div>
            <div className="container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor fB mb-3 capitalize">Now is the time for healthcare to embrace Digital innovation</h2>
                <p className="text-base fR text-start text-pColor my-1">Transform Care with Clarity - Real Time Data for Smarter Healthcare.</p>
                <div className="block md:hidden">
                    <div className="d-flex w-full">
                        <div className={`flex w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40 bg-white w-full overflow-x-auto scroll-smooth healthParentTabs-container`}>
                            <button className={`text-sm px-3 py-3 rounded-md healthSubTabsr-healthcareoptimizationMd ${tab == 'healthcareoptimizationMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('healthcareoptimizationMd');
                                    handleScrollViewMd(sectionsRefs.healthcareoptimizationMd.current, 'healthcareoptimizationMd')
                                    //sectionsRefs.healthcareoptimizationMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Healthcare Optimization</button>
                            <button className={`text-sm px-3 py-3 rounded-md healthSubTabsr-pillcountingMd ${tab == 'pillcountingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('pillcountingMd');
                                    handleScrollViewMd(sectionsRefs.pillcountingMd.current, 'pillcountingMd')
                                    //sectionsRefs.pillcountingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Pill Counting</button>
                            {/* <button className={`text-sm px-3 py-3 rounded-md healthSubTabsr-selfcheckoutMd ${tab == 'selfcheckoutMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('selfcheckoutMd');
                                    handleScrollViewMd(sectionsRefs.selfcheckoutMd.current, 'selfcheckoutMd')
                                }}
                            >Self-Checkout</button> */}
                            <button className={`text-sm px-3 py-3 rounded-md healthSubTabsr-assettrackingMd ${tab == 'assettrackingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('assettrackingMd');
                                    handleScrollViewMd(sectionsRefs.assettrackingMd.current, 'assettrackingMd')
                                    //sectionsRefs.assettrackingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Asset Tracking</button>
                            <button className={`text-sm px-3 py-3 rounded-md healthSubTabsr-employeetrackingMd ${tab == 'employeetrackingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('employeetrackingMd');
                                    handleScrollViewMd(sectionsRefs.employeetrackingMd.current, 'employeetrackingMd')
                                    //sectionsRefs.employeetrackingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Employee Tracking</button>
                            {/* <div className=" flex gap-3 overflow-auto py-6 text-nowrap">
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'healthcareoptimization' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('healthcareoptimization')}>Healthcare Optimization</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'pillcounting' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('pillcounting')}>Pill Counting</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'selfcheckout' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('selfcheckout')}>Self-Checkout</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'assettracking' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('assettracking')}>Asset Tracking</button>
                                <button className={`text-sm  px-3 py-3 rounded-md ${tab == 'employeetracking' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`} onClick={() => setTab('employeetracking')}>Employee Tracking</button>
                            </div> */}
                        </div>
                        <div>
                            <div ref={sectionsRefs.healthcareoptimizationMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Healthcare</p>
                                        <h2 className="text-hColor text-base fB">Healthcare Optimization</h2>
                                        <p className="text-pColor text-sm py-2 fM">Healthcare is evolving into a symphony of efficiency and care, conducted by data-driven Healthcare Optimization. Advanced monitoring even identifies patient discomfort, fostering greater satisfaction. Beyond this, smart staffing ensures ideal ratios and streamlined workflows, while in-depth analytics pinpoint resource waste, enhancing productivity and setting new benchmarks for healthcare excellence.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.medicalanalysis}
                                        heading={'Advanced Medical Image Analysis'}
                                        subtext={'Revolutionizes diagnostic precision, supports better clinical decisions, and enhances patient care.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.securityLaptop}
                                        heading={'Patient Monitoring and Safety'}
                                        subtext={'Monitors patient movement, prevents safety issues like falls, and gauges comfort through advanced analysis of body language and facial expressions.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.dataForcsting}
                                        heading={'Smart Staffing Solutions'}
                                        subtext={'Optimizes patient care through precise staffing ratios, efficient task tracking, and resource management, all powered by data insights.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.healthcare}
                                        heading={'Smart Pharmacy Solutions'}
                                        subtext={'Reduces customer wait times at the pharmacy counter with unmanned delivery systems using a smart cabinet with a sensor lock and a smartphone app.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.pillcountingMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Healthcare</p>
                                        <h2 className="text-hColor text-base fB">Pill Counting</h2>
                                        <p className="text-pColor text-sm py-2 fM">Level up your pharmacy with the future of pill counting! Gone are the days of manual counting - this innovative system guarantees flawless pill identification and counting, effortlessly syncing with kiosks, cloud services, and edge devices for instant updates. Elevate your automation and redefine precision with unparalleled efficiency.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.shareReactNative}
                                        heading={'Visual Confidence'}
                                        subtext={'Witness every pill drop on a crystal-clear kiosk screen, ensuring complete transparency throughout the process.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.aiicon}
                                        heading={'AI-Powered Precision'}
                                        subtext={'OpenCV technology meticulously analyzes pill shapes, guaranteeing automatic and highly accurate counting.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.integrity}
                                        heading={'Effortless Integration'}
                                        subtext={'Introducing new pills? No sweat! Our system easily adapts with minimal training for seamless detection.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.streamline}
                                        heading={'Intuitive Control'}
                                        subtext={'Our user-friendly interface empowers you to effortlessly monitor and manage the counting process with ease.'}
                                    />
                                </div>
                            </div>
                            {/* <div ref={sectionsRefs.selfcheckoutMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Healthcare</p>
                                        <h2 className="text-hColor text-base fB">Self-Checkout</h2>
                                        <p className="text-pColor text-sm py-2 fM">Tired of long checkout lines leaving patients waiting? Our revolutionary self-checkout powered by computer vision is here to transform the healthcare experience. With smart cameras managing secure, swift payments—no cashiers needed—this innovative system boosts efficiency for providers and offers a smooth, stress-free experience for patients. It’s a win-win, more time for patient care and a better overall healthcare experience!</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.efficiency}
                                        heading={'Swift Checkout Revolution'}
                                        subtext={'Cut checkout times with precise scanning and fraud detection, transforming patient satisfaction with seamless transactions.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.securitymeasureicon}
                                        heading={'Robust Security Measures'}
                                        subtext={'Protect patient data and transactions with biometric facial recognition and real-time monitoring that flags any irregularities.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.product}
                                        heading={'Smart Operational Boost'}
                                        subtext={'Automate restocking and streamline workflows with real-time inventory tracking and adaptable interfaces for peak efficiency.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.BusinessService}
                                        heading={'Proactive Loss Prevention'}
                                        subtext={'Spot and resolve discrepancies instantly with advanced detection, protecting against theft and ensuring accurate records.'}
                                    />
                                </div>
                            </div> */}
                            <div ref={sectionsRefs.assettrackingMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Healthcare</p>
                                        <h2 className="text-hColor text-base fB">Asset Tracking</h2>
                                        <p className="text-pColor text-sm py-2 fM">The dynamic world of healthcare demands a system that optimizes workflows while safeguarding valuable assets. Our Asset tracking solution enhances workforce productivity and secures everything from small tools to major equipment through real-time monitoring and facial recognition. Beyond safeguarding high-security areas, our advanced technology ensures comprehensive protection and streamlined management of your hospital’s assets, revolutionizing your operational landscape.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Smart Tablet Tracking'}
                                        subtext={'Leverages cutting-edge computer vision to verify and locate tablets based on a set planogram, ensuring perfect placement and quick identification in medical carts and designated areas.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.shortTdev}
                                        heading={'Live Asset Monitoring'}
                                        subtext={'Utilizes computer vision for real-time tracking of medical assets, providing a centralized dashboard for instant monitoring of asset location and availability.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.notification}
                                        heading={'Automated Inventory Alerts'}
                                        subtext={'Integrates seamlessly with inventory systems to automate asset tracking, generating proactive alerts for low stock levels and expiring items to ensure timely restocking.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.mobileNativeapps}
                                        heading={'Dynamic Reporting and Insights'}
                                        subtext={'Offers customizable reports on asset use and movement, delivering valuable insights for better asset allocation and improved workflow efficiency.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.employeetrackingMd} className="my-4 lg:my-20 border-dashed border-b-2">

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Healthcare</p>
                                        <h2 className="text-hColor text-base fB">Employee Tracking</h2>
                                        <p className="text-pColor text-sm py-2 fM">Struggling to balance staff efficiency with ironclad security? Our innovative computer vision solution has you covered. Utilizing smart cameras, it tracks staff locations in real-time, ensuring the right people are available when needed. But that's not all! This technology also enhances security through facial recognition, acting as a vigilant guardian to prevent unauthorized access in high-security areas.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.endUser}
                                        heading={'Automated Attendance Tracking'}
                                        subtext={'Employs facial recognition for seamless attendance tracking, eliminating manual timekeeping and reducing administrative tasks.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.MEANicon1}
                                        heading={'Unauthorized Access Prevention'}
                                        subtext={'Uses facial recognition algorithms to block unauthorized entry to secure areas, sending instant alerts to security teams.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.alert}
                                        heading={'Mask Detection'}
                                        subtext={'Ensure heightened safety and compliance by monitoring staff, doctors, and patients to confirm proper mask usage.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.maintainIcon}
                                        heading={'Task Management'}
                                        subtext={'Integrates with task management systems to assign and route tasks based on staff location and skills, improving prioritization and response times.'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex gap-20">
                    <div className="">
                        <div className="flex flex-col w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40">
                            <div className={`flex gap-2 items-center text-start text-nowrap py-3 ${tab === 'healthcareoptimization' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB text-nowrap ${tab === 'healthcareoptimization' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('healthcareoptimization');
                                        //sectionsRefs.healthcareoptimization.current.scrollIntoView({ behavior: 'smooth' });
                                        handleScrollView(sectionsRefs.healthcareoptimization.current)

                                    }}
                                >
                                    Health Care Optimization
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'healthcareoptimization' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'healthcareoptimization' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'pillcounting' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'pillcounting' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('pillcounting');
                                        //sectionsRefs.pillcounting.current.scrollIntoView({ behavior: 'smooth' });
                                        handleScrollView(sectionsRefs.pillcounting.current)

                                    }}
                                >
                                    Pill Counting
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'pillcounting' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'pillcounting' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            {/* <div className={`flex gap-2 items-center py-3 ${tab === 'selfcheckout' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'selfcheckout' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('selfcheckout');
                                        //sectionsRefs.selfcheckout.current.scrollIntoView({ behavior: 'smooth' });
                                        handleScrollView(sectionsRefs.selfcheckout.current)

                                    }}
                                >
                                    Self Checkout
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'selfcheckout' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'selfcheckout' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div> */}
                            <div className={`flex gap-2 items-center py-3 ${tab === 'assettracking' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'assettracking' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('assettracking');
                                        //sectionsRefs.assettracking.current.scrollIntoView({ behavior: 'smooth' });
                                        handleScrollView(sectionsRefs.assettracking.current)

                                    }}
                                >
                                    Asset Tracking
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'employeetracking' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'employeetracking' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('employeetracking');
                                        //sectionsRefs.employeetracking.current.scrollIntoView({ behavior: 'smooth' });
                                        handleScrollView(sectionsRefs.employeetracking.current)

                                    }}
                                >
                                    Employee Tracking
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'employeetracking' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'employeetracking' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>


                        </div>
                    </div>
                    <div>
                        <div ref={sectionsRefs.healthcareoptimization} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Healthcare</p>
                                            <h2 className="text-hColor text-xl underline fB">Healthcare Optimization</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/medical-image-analysis')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Healthcare is evolving into a symphony of efficiency and care, conducted by data-driven Healthcare Optimization. Advanced monitoring even identifies patient discomfort, fostering greater satisfaction. Beyond this, smart staffing ensures ideal ratios and streamlined workflows, while in-depth analytics pinpoint resource waste and enhance productivity. By monitoring staff, doctors, and patients wearing masks, Proglint sets new benchmarks for healthcare excellence, ensuring not only efficient operations but also heightened safety and compliance.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.medicalanalysis}
                                        heading={'Advanced Medical Image Analysis'}
                                        subtext={'Revolutionizes diagnostic precision, supports better clinical decisions, and enhances patient care.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.securityLaptop}
                                        heading={'Patient Monitoring and Safety'}
                                        subtext={'Monitors patient movement, prevents safety issues like falls, and gauges comfort through advanced analysis of body language and facial expressions.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.dataForcsting}
                                        heading={'Smart Staffing Solutions'}
                                        subtext={'Optimizes patient care through precise staffing ratios, efficient task tracking, and resource management, all powered by data insights.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.healthcare}
                                        heading={'Smart Pharmacy Solutions'}
                                        subtext={'Reduces customer wait times at the pharmacy counter with unmanned delivery systems using a smart cabinet with a sensor lock and a smartphone app.'}
                                    />
                                </div>

                            </div>
                        </div>
                        <div ref={sectionsRefs.pillcounting} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Healthcare</p>
                                            <h2 className="text-hColor text-xl underline fB">Pill Counting</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/pill-counting')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Level up your pharmacy with the future of pill counting! Gone are the days of manual counting - this innovative system guarantees flawless pill identification and counting, effortlessly syncing with kiosks, cloud services, and edge devices for instant updates. Elevate your automation and redefine precision with unparalleled efficiency.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.shareReactNative}
                                        heading={'Visual Confidence'}
                                        subtext={'Witness every pill drop on a crystal-clear kiosk screen, ensuring complete transparency throughout the process.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.aiicon}
                                        heading={'AI-Powered Precision'}
                                        subtext={'OpenCV technology meticulously analyzes pill shapes, guaranteeing automatic and highly accurate counting.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.integrity}
                                        heading={'Effortless Integration'}
                                        subtext={'Introducing new pills? No sweat! Our system easily adapts with minimal training for seamless detection.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.streamline}
                                        heading={'Intuitive Control'}
                                        subtext={'Our user-friendly interface empowers you to effortlessly monitor and manage the counting process with ease.'}
                                    />
                                </div>

                            </div>
                        </div>
                        {/* <div ref={sectionsRefs.selfcheckout} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Healthcare</p>
                                            <h2 className="text-hColor text-xl underline fB">Self-Checkout</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/vision-selfcheckout')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Tired of long checkout lines leaving patients waiting? Our revolutionary self-checkout powered by computer vision is here to transform the healthcare experience. With smart cameras managing secure, swift payments—no cashiers needed—this innovative system boosts efficiency for providers and offers a smooth, stress-free experience for patients. It’s a win-win, more time for patient care and a better overall healthcare experience!</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.efficiency}
                                        heading={'Swift Checkout Revolution'}
                                        subtext={'Cut checkout times with precise scanning and fraud detection, transforming patient satisfaction with seamless transactions.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.securitymeasureicon}
                                        heading={'Robust Security Measures'}
                                        subtext={'Protect patient data and transactions with biometric facial recognition and real-time monitoring that flags any irregularities.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.product}
                                        heading={'Smart Operational Boost'}
                                        subtext={'Automate restocking and streamline workflows with real-time inventory tracking and adaptable interfaces for peak efficiency.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.BusinessService}
                                        heading={'Proactive Loss Prevention'}
                                        subtext={'Spot and resolve discrepancies instantly with advanced detection, protecting against theft and ensuring accurate records.'}
                                    />
                                </div>

                            </div>
                        </div> */}
                        <div ref={sectionsRefs.assettracking} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Healthcare</p>
                                            <h2 className="text-hColor text-xl underline fB">Asset Tracking</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/asset-tracking')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">The dynamic world of healthcare demands a system that optimizes workflows while safeguarding valuable assets. Our Asset tracking solution enhances workforce productivity and secures everything from small tools to major equipment through real-time monitoring and facial recognition. Beyond safeguarding high-security areas, our advanced technology ensures comprehensive protection and streamlined management of your hospital’s assets, revolutionizing your operational landscape.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.trackingicon}
                                        heading={'Smart Tablet Tracking'}
                                        subtext={'Leverages cutting-edge computer vision to verify and locate tablets based on a set planogram, ensuring perfect placement and quick identification in medical carts and designated areas.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.shortTdev}
                                        heading={'Live Asset Monitoring'}
                                        subtext={'Utilizes computer vision for real-time tracking of medical assets, providing a centralized dashboard for instant monitoring of asset location and availability.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.notification}
                                        heading={'Automated Inventory Alerts'}
                                        subtext={'Integrates seamlessly with inventory systems to automate asset tracking, generating proactive alerts for low stock levels and expiring items to ensure timely restocking.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.mobileNativeapps}
                                        heading={'Dynamic Reporting and Insights'}
                                        subtext={'Offers customizable reports on asset use and movement, delivering valuable insights for better asset allocation and improved workflow efficiency.'}
                                    />
                                </div>

                            </div>
                        </div>
                        <div ref={sectionsRefs.employeetracking} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Healthcare</p>
                                            <h2 className="text-hColor text-xl underline fB">Employee Tracking</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/ai-attendance')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Struggling to balance staff efficiency with ironclad security? Our innovative computer vision solution has you covered. Utilizing smart cameras, it tracks staff locations in real-time, ensuring the right people are available when needed. But that's not all! This technology also enhances security through facial recognition, acting as a vigilant guardian to prevent unauthorized access in high-security areas.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.endUser}
                                        heading={'Automated Attendance Tracking'}
                                        subtext={'Employs facial recognition for seamless attendance tracking, eliminating manual timekeeping and reducing administrative tasks.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.MEANicon1}
                                        heading={'Unauthorized Access Prevention'}
                                        subtext={'Uses facial recognition algorithms to block unauthorized entry to secure areas, sending instant alerts to security teams.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.alert}
                                        heading={'Mask Detection'}
                                        subtext={'Ensure heightened safety and compliance by monitoring staff, doctors, and patients to confirm proper mask usage.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.maintainIcon}
                                        heading={'Task Management'}
                                        subtext={'Integrates with task management systems to assign and route tasks based on staff location and skills, improving prioritization and response times.'}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-[#F8F8F8] py-16">
                <div className="container mx-auto flex flex-col gap-12">
                    <div className="title block lg:flex justify-between">
                        <div>
                            <div className="mb-10">
                                <h2 className="text-2xl fB mb-1">Tomorrow’s Healthcare, Realized Today!</h2>
                                <h2 className="text-3xl fB text-[#C7C7C7] max-w-lg">Shape a future where technology elevates healthcare for all.</h2>
                            </div>
                        </div>
                        <p className="fR text-sm max-w-xl mt-6 lg:mt-0">We design and scale breakthrough computer vision and deep learning solutions for healthcare. Elevate efficiency, spark innovation, and transform medical service quality with our advanced vision technology.</p>
                    </div>
                    <div>
                        <p className="fR mb-5">Research Highlights</p>
                        <div className="section4 bg-background">
                            <div className="w-full gap-3">
                                <Slider {...settings}>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Medical <br /> Image Analysis</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/medical-image-analysis')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col flex-grow gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Redefine the New Era of Healthcare from diagnosing fractures in X-rays to detecting life-threatening.</p>
                                                <img src={PATH.img.SolutionImageAalyis} alt="medical-image-analysis" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Pill <br /> Counting</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/pill-counting')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">No More Second Guessing. Precision in every pill count, experience the future of pharmacy.</p>
                                                <img src={PATH.img.IndustryPillcount} alt="pill-count" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Safety and <br /> Security</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/safety-security')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">A Proven Solution for Rapid, contactless screening with AI-Driven, Contactless Weapon Detection and Real-Time Monitoring for Superior Safety.</p>
                                                <img src={PATH.img.SolutionSafetyandsecurity} alt="safety-and-security" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                        </div>
                        {/* <SolutionCarousel settings={settings} /> */}
                    </div>
                </div>
            </section>
            <FooterContact />
        </div>
    )
}
export default Healthcare;