import React, { useState } from "react"
import { ArrowLongLeftIcon, } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import { toast } from "react-toastify";
const CareerForm = () => {
    const navigate = useNavigate()
    const [careerDetails, setCareerDetails] = useState({
        name: "",
        email: "",
        contact: "",
        graduationYear: "",
        gender: "",
        experience: "",
        ctc: "",
        expectedCTC: "",
        noticePeriod: "",
        vacancy: ""
    })
    const handleChange = (e) => {
        setCareerDetails(p => {
            return {
                ...p, [e.target.name]: e.target.value
            }
        })
    }
    const handleSubmit = () => {
        let bool = Object.values(careerDetails)?.every(o => o)
        if (!bool) {
            toast.error("Please enter all the details")
        } else {
            navigate('/career-form')
        }
    }
    return (
        <div className="container mx-auto mt-20">
            <div className="flex gap-1 items-center my-4" onClick={() => window.location.reload()}>
                <Link to={('/career')} className="flex gap-1 items-center">
                    <ArrowLongLeftIcon className="w-6 h-6 text-secondary cursor-pointer" />
                    <p className="text-secondary text-base font-normal cursor-pointer">Back to Career</p>
                </Link>
            </div>
            <div className="my-10">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center  text-hColor font-bold mb-3">Software Developers</h1>
                <p className="text-base text-center text-pColor my-1 mb-4 px-4 lg:px-28">September 20, 2023</p>
            </div>
            <div className="px-0 lg:px-20 mb-8 lg:mb-16 mt-4">
                <div className="flex gap-1 text-secondary text-xl px-4 my-4">
                    <h2>Personal Details -</h2><button>clear</button>
                </div>
                <form className="grid grid-cols-1 md:grid-cols-2">
                    <div className="mb-4 px-4 ">
                        <label for="name" className="block mb-2 text-sm font-normal text-pColor">Your Name*</label>
                        <input type="text" name="name" id="name" value={careerDetails?.name} onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="email" className="block mb-2 text-sm font-normal text-pColor">Email Address*</label>
                        <input type="email" name="email" id="email" value={careerDetails?.email} onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="Contact" className="block mb-2 text-sm font-normal text-pColor">Contact*</label>
                        <input type="text" name="contact" id="Contact" value={careerDetails?.contact} onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="Year of Graduation" className="block mb-2 text-sm font-normal text-pColor">Year of Graduation*</label>
                        <input type="text" name="graduationYear" value={careerDetails?.graduationYear} id="Year of Graduation" onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="Gender" className="block mb-2 text-sm font-normal text-pColor">Gender*</label>
                        <input type="text" name="gender" value={careerDetails?.gender} id="Gender" onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="Experience In Years" className="block mb-2 text-sm font-normal text-pColor">Experience In Years</label>
                        <input type="text" name="experience" value={careerDetails?.experience} id="Experience In Years" onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="ctc" className="block mb-2 text-sm font-normal text-pColor">Current CTC (In Lakhs Per Annum)*</label>
                        <input type="text" name="ctc" id="ctc" value={careerDetails?.ctc} onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="Expected CTC (In Lakhs Per Annum)" className="block mb-2 text-sm font-normal text-pColor">Expected CTC (In Lakhs Per Annum)*</label>
                        <input type="text" name="expectedCTC" value={careerDetails?.expectedCTC} id="ctc" onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="Notice Period" className="block mb-2 text-sm font-normal text-pColor">Notice Period*</label>
                        <input type="text" name="noticePeriod" value={careerDetails?.noticePeriod} onChange={(e) => handleChange(e)} id="Notice Period" className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                    <div className="mb-4 px-4 ">
                        <label for="vacancy" className="block mb-2 text-sm font-normal text-pColor">How did you come across this vacancy? *</label>
                        <input type="text" name="vacancy" value={careerDetails?.vacancy} id="vacancy" onChange={(e) => handleChange(e)} className="bg-white border border-[#CFCFCF] text-pColor text-sm rounded-lg outline-0 block w-full p-2.5" required />
                    </div>
                </form>
                <h2 className="flex gap-1 text-secondary text-xl px-4 my-4">Upload Your Resume</h2>
                <div className="px-4">
                    <p className="text-base text-pColor font-normal">Resume*</p>
                    <button type="file" className="py-2 px-16 my-4 text-[#707070] bg-[#F7F7F7] font-semibold border-dashed border-2 ">Browser</button>
                </div>
                <div className="flex gap-6 justify-center">
                    <div onClick={() => window.location.reload()}>
                        <Button LinkPath={('/career-form')} value={'Cancel'} className={'text-sm px-6 py-2 bg-gradient-to-br font-semibold bg-[#E5E5E5]'} />
                    </div>
                    <Button onClick={() => handleSubmit()} value={'Submit Application'} className={'text-sm px-6 py-2 bg-gradient-to-br font-semibold from-primary from-30% to-secondary to-90% focus:outline-none'} />
                </div>
            </div>
        </div>
    )
}
export default CareerForm