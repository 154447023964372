import React, { useEffect, useRef, useState } from "react";
import { PATH } from "../globals/app-constants";
import { CheckIcon } from "@heroicons/react/24/solid";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const ResearchContent = ({ title, Date, BannerImage, sideMenu, sideMenu2, sideMenu3, sideMenu4,sideMenu5, heading,paragraph,paragraph1, sideMenuContent, sideMenuContent2, sideMenuContent3, sideMenuContent4, sideMenuContent5, sideMenuContent6,sideMenuContent7 }) => {
    const [tab, setTab] = useState('content1');
    const sectionsRefs = {
        content1: useRef(null),
        content2: useRef(null),
        content3: useRef(null),
        content4: useRef(null),
        content5: useRef(null),
        content6: useRef(null),
        content7: useRef(null)
    };
    // if(sideMenuContent5) {
    //     sectionsRefs["content5"] = useRef(null)
    // }
    // if(sideMenuContent6){
    //     sectionsRefs["content6"] = useRef(null)
    // }


    useEffect(() => {
        const handleScroll = () => {
            // Iterate through sections to find the one currently in view
            Object.keys(sectionsRefs).forEach((key) => {
                const ref = sectionsRefs[key].current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                        setTab(key);
                    }
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sectionsRefs]);

    const getRef = (key) => {
        if (key === 'content5' && !sideMenuContent5) return null;
        if (key === 'content6' && !sideMenuContent6) return null;
        return sectionsRefs[key];
    };
    const handleScrollView = (refName) => {
        const pos = refName.style.position;
        const top = refName.style.top;
        refName.style.position = 'relative';
        refName.style.top = '-70px';
        refName.scrollIntoView({ behavior: 'smooth', block: 'start' });
        refName.style.top = top;
        refName.style.position = pos;
    }
    return (
        <div className="container mx-auto mt-28">
            <div onClick={() => window.location.reload()}>
                <button className="text-secondary fM text-sm mb-3" ><Link className="flex gap-2 items-center" to={('/research')}><ArrowLeftIcon className="w-6" /><p>Back to  Research</p></Link></button>
            </div>
            <div className="md:flex gap-12 pt-6">
                <div className="w-full lg:w-5/8">
                    <div className="mx-auto max-w-xl">
                        <h1 className="text-hColor text-xl lg:text-4xl font-bold">{title}</h1>
                        <p className="text-pColor text-sm font-normal my-4">{Date}</p>
                        <div className="flex flex-row gap-4 mb-3">
                            <img src={PATH.img.icons.InstagramIcon} className='w-6' alt="icon" />
                            <img src={PATH.img.icons.LinkedinIcon} className='w-6' alt="icon" />
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-3/8">
                    <div>
                        <img src={BannerImage} alt="blogImg" className="w-full h-[350px] object-cover" />
                    </div>
                </div>
            </div>
            <div className="md:flex my-4 lg:my-8 gap-10">
                <div className="hidden md:block w-full md:w-2/6 lg:w-1/4">
                    <div className="bg-[#F6F6F6] text-black rounded-lg p-4 mb-8">
                        <h1 className="fSB text-lg mb-3">Build, deploy, operate computer vision at scale</h1>
                        <div className="flex gap-3 items-center mb-3">
                            <CheckIcon className="w-5 text-green-500" />
                            <p className="fR text-sm">One platform for all use cases</p>
                        </div>
                        <div className="flex gap-3 items-center mb-3">
                            <CheckIcon className="w-5 text-green-500" />
                            <p className="fR text-sm">Scale on robust infrastructure</p>
                        </div>
                        <div className="flex gap-3 items-center mb-3">
                            <CheckIcon className="w-5 text-green-500" />
                            <p className="fR text-sm">Enterprise security</p>
                        </div>
                    </div>
                    <div className="sticky top-20">
                        <h2 className="text-hColor text-base lg:text-lg text-start font-semibold uppercase">TABLE OF CONTENT</h2>
                        <div className={`flex gap-2 items-center text-start py-1 ${tab === 'content1' ? 'ml-0' : ' ml-0'} `}>
                            <button
                                className={`fs-12 fSB text-start ${tab === 'content1' ? 'text-primary transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                onClick={() => {
                                    setTab('content1');
                                    handleScrollView(sectionsRefs.content1.current)
                                    // sectionsRefs.content1.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                {sideMenu}
                            </button>
                            <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'content1' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'content1' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                        </div>
                        <div className={`flex gap-2 items-center text-start py-1 ${tab === 'content2' ? 'ml-0' : ' ml-0'} `}>
                            <button
                                className={`fs-12 fSB text-start ${tab === 'content2' ? 'text-primary transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                onClick={() => {
                                    setTab('content2');
                                    handleScrollView(sectionsRefs.content2.current)
                                    // sectionsRefs.content2.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                {sideMenu2}
                            </button>
                            <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'content2' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'content2' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                        </div>
                        <div className={`flex gap-2 items-center text-start py-1 ${tab === 'content3' ? 'ml-0' : ' ml-0'} `}>
                            <button
                                className={`fs-12 fSB text-start ${tab === 'content3' ? 'text-primary transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                onClick={() => {
                                    setTab('content3');
                                    handleScrollView(sectionsRefs.content3.current)
                                    // sectionsRefs.content3.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                {sideMenu3}
                            </button>
                            <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'content3' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'content3' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                        </div>
                        <div className={`flex gap-2 items-center text-start py-1 ${tab === 'content4' ? 'ml-0' : ' ml-0'} `}>
                            <button
                                className={`fs-12 fSB text-start ${tab === 'content4' ? 'text-primary transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                onClick={() => {
                                    setTab('content4');
                                    handleScrollView(sectionsRefs.content4.current)
                                    // sectionsRefs.content4.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                {sideMenu4}
                            </button>
                            <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'content4' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'content4' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                        </div>
                        <div className={`flex gap-2 items-center text-start py-1 ${tab === 'content5' ? 'ml-0' : ' ml-0'} `}>
                            <button
                                className={`fs-12 fSB text-start ${tab === 'content5' ? 'text-primary transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                onClick={() => {
                                    setTab('content5');
                                    handleScrollView(sectionsRefs.content5.current)
                                    // sectionsRefs.content4.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                {sideMenu5}
                            </button>
                            <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'content5' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'content5' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                        </div>
                        {/* <ol className="list-decimal px-4">
                            {blog.map((content) =>
                                <li className={`text-base text-pColor my-3 ${activeSection === content.id ? 'navColor' : ''}`}>{content.keyHeading}</li>
                            )}

                        </ol> */}

                    </div>
                </div>
                <div className="w-full md:w-4/6 lg:w-3/4">
                    {/* {contents} */}
                    <div>

                        <h1 className="pt-5 font-bold text-2xl mb-3">{heading}</h1>
                        <p className="text-base">{paragraph}</p>
                        <p className="text-base">{paragraph1}</p>
                        <div ref={sectionsRefs.content1}>{sideMenuContent}</div>
                        <div ref={sectionsRefs.content2}>{sideMenuContent2}</div>
                        <div ref={sectionsRefs.content3}>{sideMenuContent3}</div>
                        <div ref={sectionsRefs.content4}>{sideMenuContent4}</div>
                        <div ref={sectionsRefs.content5}>{sideMenuContent5}</div>
                        <div ref={sectionsRefs.content6}>{sideMenuContent6}</div>
                        <div ref={sectionsRefs.content7}>{sideMenuContent7}</div>
                        {/* {sideMenuContent5 && <div ref={sectionsRefs.content5}>{sideMenuContent5}</div>}
                        {sideMenuContent6 && <div ref={sectionsRefs.content6}>{sideMenuContent6}</div>} */}
                    </div>
                    {/* {contents} */}
                </div>
            </div>
        </div>

    );
};
export default ResearchContent;