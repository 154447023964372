import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const JedediahVisionCheckout = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Diverse Checkout Options: Jedediah offers web, mobile, and vision interfaces alongside manned checkout counters, ensuring flexible and convenient shopping.",
        point2: "Payment Flexibility: Customers enjoy multiple payment modes, including card and cash, and access exclusive promotions and discounts.",
        point3: "Hardware Health Integration: Advanced technology enables constant monitoring of vision and manned checkout devices, ensuring optimal functionality.",
        point4: "Dual-Screen Setup: Manned checkout facilitates efficient and synchronized operations via a dual-screen setup on the electron application, enhancing user convenience."
    },
    {
        title: 'Business challenge',
        challengeDescription: "Ensuring seamless integration of diverse checkout options and hardware health monitoring posed initial challenges. Proglint aimed to streamline these multifaceted functionalities while maintaining operational efficiency.",

    },
    {
        title: 'Overcoming Challenges',
        point1: "Integrating Vision and Manned Checkout: Proglint successfully integrated various checkout interfaces, ensuring smooth operations and customer satisfaction.",
        point2: "Hardware Health Optimization: Through meticulous monitoring and maintenance, Proglint ensured continuous optimal functionality of all checkout devices."
        // point3: "Customized Security Measures: The tailored solution significantly minimized the risk of theft or fraudulent activities, ensuring a secure and reliable self-checkout process."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "The Jedediah E-commerce application, developed and deployed by Proglint, revolutionized online shopping experiences by providing versatile checkout options and seamless payment modes. This comprehensive platform assures efficient operations and enhanced user satisfaction.",
        // deliveredParagraph2: "The solution, designed to prevent pilferage and guarantee seamless transactions, significantly enhanced overall retail efficiency and security, setting new industry standards in automated checkout systems.",
        // deliveredParagraph3: "A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores, where the items picked up by a customer are detected using computer vision technology and the items presented.A loss prevention solution for stores,",
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.JedediahBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Advanced E-commerce Checkout Solution'}
                domainText={'E-commerce, Retail, Technology'}
                durationText={'5 months, 18 man-months'}
                technologyText={'Web Development, Mobile App Development, Computer Vision'}
                overviewText={'Proglint pioneered the development of Jedediah, an innovative E-commerce application offering diverse checkout options to enrich user experiences. With versatile interfaces - web, mobile, manned, and vision-based checkouts - Jedediah provides a holistic solution for online shopping, catering to groceries and bistro products. This comprehensive platform ensures seamless shopping experiences for both guests and registered users.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.EquionxThumbnail} RetailHeading={'Business Solutions'} SubHead={'Equinox'} ParaContent={'Explore Equinox, a revolutionary employee management app by Proglint. Streamline HR services, approval processes, and learning opportunities in one user-friendly platform. Witness enhanced efficiency and quick-turnaround solutions for your workforce. Delivered successfully to QICG Tawasul in Qatar.'}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/equinux')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.CVSThumbnail} RetailHeading={'Inventory Management'} SubHead={'CVS'} ParaContent={`Revolutionizing Retail with Smart Fridge Technology. Proglint's innovative Smart Fridge Management System enhances shopping experiences, streamlines inventory, and offers valuable insights. Witness the future of retail automation.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cvs')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.aladdinThumbnail} RetailHeading={'Home Maintenance'} SubHead={'Aladdin'} ParaContent={'Enhancing home services, our mobile-based solution adeptly connects users with service professionals. With dedicated apps for customers and administrators, our platform assures an efficient experience. The Aladdin app stands as a testament to our expertise at Proglint in crafting user-centric home service solutions.'}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/aladdin-app')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default JedediahVisionCheckout;