import React from "react";
import { PATH } from "../globals/app-constants";
import { Link } from "react-router-dom";
const Industries = () => {
    return (
        <div>
            <div className="industries container mx-auto my-10 lg:my-24">
                <div className="my-4">
                    <h1 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3 max-w-xl">Proglint Industry Expertise</h1>
                    <p className="text-base text-start text-pColor mb-3 fR">Tailored technology solutions designed to conquer industry-specific challenges, adding substantial value to diverse businesses.</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-10 my-4 lg:my-10">
                    <div className="relative">
                        <img src={PATH.img.Iretail} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/cosfymart-app')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IretailIcon} alt="retail" />
                                    <p className="text-2xl fSB text-white hover:underline mt-3 cursor-pointer">Retail & Shopping</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ihealth} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/treux-app')}>
                                <main className="absolute bottom-8 px-6 w-52 ">
                                    <img src={PATH.img.icons.IhealthIcon} alt="retail" />
                                    <p className="text-2xl fSB text-white hover:underline mt-3 cursor-pointer">Health Care & Wellness</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ieduction} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/cv-shield')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IeducationIcon} alt="retail" />
                                    <p className="text-2xl fSB  cursor-pointer text-white hover:underline mt-3">Education & e-Learning</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={PATH.img.Ifinancial} alt="retail" className="w-full h-full" />
                        <div className="w-full h-full cursor-pointer flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900" onClick={() => window.location.reload()}>
                            <Link to={('/case-studies/qlm-app')}>
                                <main className="absolute bottom-8 px-6 w-52">
                                    <img src={PATH.img.icons.IfinancialIcon} alt="retail" />
                                    <p className="text-2xl cursor-pointer fSB text-white hover:underline mt-3">Financial & Technology</p>
                                </main>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Industries