import React from "react";
import { PATH } from "../globals/app-constants";
import FooterContact from "./FooterContact";
import { Link } from "react-router-dom";
import ContentBanner2 from "./ContentBanner2";

const Java = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <h1 className="white_banner_title fSB text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">Java Spring Boot Development Services</h1>
                    <div className="w-full h-1/2 lg:h-auto lg:flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.javaLogo} alt="logo" className="block mx-auto w-60 lg:w-auto object-contain" />
                    </div>
                </div>
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Java Spring Boot Development Services</h2>
                <p className="text-base text-start text-pColor my-1 fR">Experience seamless, scalable, and secure applications with our Java Spring Boot expertise. From microservices to legacy migration, unlock innovation with us.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.webLaptop} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Spring Boot Web Application</h2>
                        <p className="text-pColor text-sm py-2 fR">Crafting robust and scalable web applications leveraging Spring Boot's powerful features for seamless functionality.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.springBoot_logo} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Spring Boot Microservices</h2>
                        <p className="text-pColor text-sm py-2 fR">Implementing microservices architecture with Spring Boot for agility and scalability in complex system development.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.API} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSBw">Spring Boot API Integration</h2>
                        <p className="text-pColor text-sm py-2 fR">Seamlessly integrating APIs into Spring Boot applications for enhanced functionality and interoperability.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.legacy_icon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Legacy Application Migration</h2>
                        <p className="text-pColor text-sm py-2 fR">Modernizing legacy systems through Spring Boot for improved efficiency and performance.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.securityLaptop} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Spring Boot Security Solutions</h2>
                        <p className="text-pColor text-sm py-2 fR">Ensuring robust security implementations within Spring Boot applications for data protection.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.maintenance} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB ">Support & Maintenance</h2>
                        <p className="text-pColor text-sm py-2 fR">Providing comprehensive support and maintenance services for ongoing optimization and reliability.</p>
                    </div>
                </div>
            </div>
            <div className="service_projects container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-6 lg:mb-12 max-w-md">Building Better Tomorrows Together</h2>
                <div className="lg:flex gap-10">
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-primary  relative overflow-hidden rounded-xl">
                        <img src={PATH.img.cvsCoverimg} alt="cover-pic" className="absolute object-cover w-full h-full lg:w-auto lg:h-auto lg:top-0 -right-32 lg:right-0" />
                        <div className="bg_gradient_orange absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl fB mb-3">CVS Pharmacy</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10 fR">Revolutionizing Retail with Smart Fridge Technology. Proglint's innovative Smart Fridge Management System enhances shopping experiences, streamlines inventory, and offers valuable insights. Witness the future of retail automation.</p>
                            <h2 className="text-white text-lg  cursor-pointer font-normal underline fR" onClick={() => window.location.reload()}><Link to={('/case-studies/cvs')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-2/3 bg-[#26557D] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.Canteenweb} alt="cover-pic" className="absolute top-0 -right-48 lg:right-0" />
                        <div className="bg_gradient_blue absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl fB mb-3">Canteen</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10 fR">Transforming canteen experiences, our Tap & Go app simplifies billing with QR technology. Seamlessly register cards, preauthorize amounts, and refund balances effortlessly. Elevate canteen convenience with our innovative mobile solution.</p>
                            <h2 className="text-white cursor-pointer text-lg font-normal underline fR" onClick={() => window.location.reload()}><Link to={('/case-studies/canteen-app')}>Case Study</Link></h2>
                        </div>
                    </div>
                </div>
                <div className="lg:flex lg:gap-10 lg:mt-10">
                    <div className="w-full mb-6 lg:w-2/3 bg-secondary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.shopAppWeb} alt="cover-pic" className="absolute top-0 -right-48 lg:right-0" />
                        <div className="bg_gradient_red absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl fB mb-3">Shop App</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10 fR">Optimize shop operations with our innovative inventory management system. Proglint's tech-driven collaboration brings precision to stock control and customer interactions, ensuring efficient store management and lasting cost-effectiveness.</p>
                            <h2 className="text-white  text-lg font-normal cursor-pointer underline fR" onClick={() => window.location.reload()}><Link to={('/case-studies/shopapp-app')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-[#6F1A07] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.humanCoverimg1} alt="cover-pic" className="absolute bottom-12 right-0" />
                        <div className="px-6 cursor-pointer py-10 absolute bottom-0" onClick={() => window.location.reload()}>
                            <Link to={('/contact')} >
                                <div className="flex flex-row gap-1">
                                    <h2 className="text-white text-lg font-normal underline fR">Say Hi</h2>
                                    <img src={PATH.img.icons.hello} alt="hello" className="w-8 h-8" />
                                </div>
                                <h2 className="text-white text-lg font-normal underline fR">to Discuss a Project</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-xl">Hiring Models</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3 fR">Choose flexible hiring models tailored to create exceptional user experiences while minimizing costs:</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.maintenance} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Proglint Managed Team</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Leverage our dedicated team of experts, managed and directed by Proglint, ensuring streamlined development and top-notch deliverables.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.User} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Client Managed Team</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Drive the development process while utilizing our talented pool of professionals. Take charge of project management and strategic decision-making.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.product} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Hybrid Model</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5 fR">Enjoy the best of both worlds with a collaborative approach. Combine the prowess of our dedicated team with your in-house resources for optimal results.</p>
                    </div>
                </div>
            </div >
            <ContentBanner2 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Java