import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const NourishBloom = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Advanced Inventory Control: Customized web and mobile inventory systems for efficient stock management.",
        point2: "Customer E-commerce Solution: Tailored apps for seamless product and bistro dish ordering.",
        point3: "Role-specific Store Manager App: Admin, store manager, and kitchen roles for efficient order handling.",
        point4: "Configuration & Display: Customized bistro products and real-time stock-based dish display.",
        point5: "Insightful Reports: Detailed sales, popular products, and inventory reports for vendor insights."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Complex Inventory Logic: Implementing robust logics for product onboarding and detailed inventory control.",
        point2: "Handling Promotions: Rigorous testing ensured accurate cost calculations for diverse promotion types.",
        point3: "Seamless Integration: Extensive testing for system integration between inventory and other applications.",
        point4: "Cost-Effective Implementation: Emphasizing the one-time investment benefit for long-term savings."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Streamlined Inventory Logic: Advanced algorithms ensured precise control and seamless reporting.",
        point2: "Thorough Promotion Handling: Rigorous testing assured accurate cost calculations for all promotions.",
        point3: "Smooth System Integration: Extensive testing ensured seamless integration among various systems."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Successfully implemented at Nourish & Bloom, Proglint's solutions revolutionized inventory and e-commerce management, optimizing operations and offering a unified, cost-effective platform. Proglint played a pivotal role in developing, testing, and implementing comprehensive solutions, ensuring seamless integration and delivering a user-friendly, cost-effective platform for Nourish & Bloom."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.NNBBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Optimized Inventory Management & E-commerce Solutions'}
                domainText={'Retail, Inventory Management, E-commerce'}
                durationText={'6 months, 24 man-months'}
                technologyText={'MySQL, MongoDB, SAP ERP Integration,React Native,React.js, Node.js'}
                overviewText={'Proglint spearheaded the development of an advanced Inventory Management System for Nourish & Bloom, integrating with SAP ERP for seamless control over ordering, stocking, and tracking inventory across multiple sections. Additionally, bespoke Customer E-commerce and Store Manager Applications were designed for streamlined operations.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.IntelThumbnail} RetailHeading={'Payment Processing'} SubHead={'Intel Vision Checkout'} ParaContent={`Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={'/case-studies/intel-vision-checkout'}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.cosfymartThumbnail} RetailHeading={'E-commerce'} SubHead={`Cosfymart`} ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={'/case-studies/cosfymart-app'}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={'/case-studies/jedidahs'}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default NourishBloom;