import React, { useState } from "react";
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid'

const Faqs = ({ Question1, Question2, Question3, Question4, Question5, Answer1, Answer2, Answer3, Answer4, Answer5 }) => {
    const [showAccordion, setShowAccordion] = useState({ accordion1: true, accordion2: false, accordion3: false, accordion4: false, accordion5: false })
    const handleAccordion = (accordion) => {
        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5 })
                break;
        }
    }
    return (
        <div className="FAQ mb-14 mt-6 lg:mt-14 container mx-auto">
            <div>
                <h1 className="text-xl md:px-0 lg:text-3xl text-center text-hColor fB mb-3">FAQs</h1>
                <p className="text-base text-center text-pColor fR">In case, you aren’t able to find your answer, do write to us through our contact page.</p>
            </div>
            <div className="my-6 lg:my-12 px-0 lg:px-28">
                <div className="border-b-2 border-t-2 border-gray-200 cursor-pointer">
                    <div className="accordion-hedder py-4 flex justify-between gap-2 items-center" onClick={() => handleAccordion(1)}>
                        <span className={`fSB text-base cursor-pointer ${showAccordion.accordion1 ? 'text-secondary' : 'text-pColor'}`}>{Question1}</span>
                        <div className="w-5 h-5 cursor-pointer">
                            {showAccordion.accordion1 ? <MinusIcon className="cursor-pointer w-6 h-6" /> : <PlusIcon className="cursor-pointer w-6 h-6" />}
                        </div>
                    </div>
                    <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion1 && "hidden"} `}>
                        <div>
                            <ul>
                                <li className="text-sm font-normal text-pColor py-2 fR">{Answer1}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-b-2 border-gray-200 cursor-pointer">
                    <div className="accordion-hedder py-4 flex justify-between gap-2 items-center" onClick={() => handleAccordion(2)}>
                        <span className={`fSB text-base cursor-pointer ${showAccordion.accordion2 ? 'text-secondary' : 'text-pColor'}`}>{Question2}</span>
                        <div className="w-5 h-5 cursor-pointer">
                            {showAccordion.accordion2 ? <MinusIcon className="cursor-pointer w-6 h-6" /> : <PlusIcon className="cursor-pointer w-6 h-6" />}
                        </div>
                    </div>
                    <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion2 && "hidden"} `}>
                        <div>
                            <ul>
                                <li className="text-sm font-normal text-pColor py-2 fR">{Answer2}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-b-2 border-gray-200 cursor-pointer">
                    <div className="accordion-hedder py-4 flex justify-between gap-2 items-center" onClick={() => handleAccordion(3)}>
                        <span className={`fSB text-base cursor-pointer ${showAccordion.accordion3 ? 'text-secondary' : 'text-pColor'}`}>{Question3}</span>
                        <div className="w-5 h-5 cursor-pointer">
                            {showAccordion.accordion3 ? <MinusIcon className="cursor-pointer w-6 h-6" /> : <PlusIcon className="cursor-pointer w-6 h-6" />}
                        </div>
                    </div>
                    <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion3 && "hidden"} `}>
                        <div>
                            <ul>
                                <li className="text-sm font-normal text-pColor py-2 fR">{Answer3}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-b-2 border-gray-200 cursor-pointer">
                    <div className="accordion-hedder py-4 flex justify-between gap-2 items-center" onClick={() => handleAccordion(4)}>
                        <span className={`fSB text-base cursor-pointer ${showAccordion.accordion4 ? 'text-secondary' : 'text-pColor'}`}>{Question4}</span>
                        <div className="w-5 h-5 cursor-pointer">
                            {showAccordion.accordion4 ? <MinusIcon className="cursor-pointer w-6 h-6" /> : <PlusIcon className="cursor-pointer w-6 h-6" />}
                        </div>
                    </div>
                    <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion4 && "hidden"} `}>
                        <div>
                            <ul>
                                <li className="text-sm font-normal text-pColor py-2 fR">{Answer4}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-b-2 border-gray-200 cursor-pointer">
                    <div className="accordion-hedder py-4 flex justify-between gap-2 items-center" onClick={() => handleAccordion(5)}>
                        <span className={`fSB text-base cursor-pointer ${showAccordion.accordion5 ? 'text-secondary' : 'text-pColor'}`}>{Question5}</span>
                        <div className="w-5 h-5 cursor-pointer">
                            {showAccordion.accordion5 ? <MinusIcon className="cursor-pointer w-6 h-6" /> : <PlusIcon className="cursor-pointer w-6 h-6" />}
                        </div>
                    </div>
                    <div className={`accordion-body transition-all duration-500 ease-out ${!showAccordion.accordion5 && "hidden"} `}>
                        <div>
                            <ul>
                                <li className="text-sm font-normal text-pColor py-2 fR">{Answer5}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqs