import React, { useState, useEffect, useRef } from "react";
import { PATH } from "../../../globals/app-constants";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import FooterContact from "../../../component/FooterContact";
import InsightsCard from "../../../component/InsightsCard";
import EducationVideo from "../../../asset/video/education.mp4"


const Education = () => {
    const mobileSectionsRefs = {
        facerecognitionMd: useRef(null),
        smartAIMd: useRef(null),
        assettrackingMd: useRef(null),
    };
    const webSectionsRefs = {
        facerecognition: useRef(null),
        smartAI: useRef(null),
        assettracking: useRef(null),
    };

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [tab, setTab] = useState(isMobileView ? 'facerecognitionMd' : 'facerecognition');
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobileView ? 1 : 3,
        slidesToScroll: 1,
    }
    const [sectionsRefs, setSectionsRefs] = useState(isMobileView ? mobileSectionsRefs : webSectionsRefs)
    
    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setIsMobileView(isMobile);
            setSectionsRefs(isMobile ? mobileSectionsRefs : webSectionsRefs);

            // Determine the currently active section
            const currentActiveTab = Object.keys(sectionsRefs).find((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    return top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2;
                }
                return false;
            });

            // Set the tab dynamically based on the active section
            if (currentActiveTab) {
                setTab(isMobile ? `${currentActiveTab}Md` : currentActiveTab.replace('Md', ''));
            } else {
                // Fallback to the first section if no active section is found
                setTab(isMobile ? 'facerecognitionMd' : 'facerecognition');
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Iterate through sections to find the one currently in view
            Object.keys(sectionsRefs).forEach((key) => {
                const ref = sectionsRefs[key]?.current;
                if (ref) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                        setTab(key);
                    }
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sectionsRefs]);

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('scroll', handleHorizontalScroll(tab));
        }, 1000);
    }, [tab])

    const handleScrollView = (refName) => {
        const pos = refName.style.position;
        const top = refName.style.top;
        refName.style.position = 'relative';
        refName.style.top = '-100px';
        refName.scrollIntoView({ behavior: 'smooth', block: 'start' });
        refName.style.top = top;
        refName.style.position = pos;
    }
    const handleScrollViewMd = (refName, currentTab) => {
        if (refName) {
            // Adjust the position temporarily for smooth scroll
            const pos = refName.style.position;
            const top = refName.style.top;
            refName.style.position = 'relative';
            refName.style.top = '-170px';

            refName.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });

            // Reset the position after scroll
            refName.style.top = top;
            refName.style.position = pos;

            // Once the vertical scroll is done, trigger the horizontal scroll
            refName.addEventListener('transitionend', () => {
                handleHorizontalScroll(currentTab);
            }, { once: true });
        }
    };
    const handleHorizontalScroll = (currentTab) => {
        const container = document.querySelector('.educationParentTabs-container'); // Adjust the selector to match your container
        const subContainer = document.querySelector(`.educationSubTabsr-${currentTab}`); // Adjust the selector to match your container
        if (container) {
            const subContainerRect = subContainer?.getBoundingClientRect();
            container.scrollBy({
                left: subContainerRect?.x > 0 ? 100 : -200,
                behavior: 'smooth'
            });
        }
    }
    return (
        <div className="mt-24 lg:mt-32">
            <div className="container max-auto">
                <h1 className="text-xl md:px-0 lg:text-2xl text-hColor fB mb-3 text-start lg:text-center capitalize">AI Solutions in Education pioneer pathways to inclusive learning</h1>
                <p className="text-base fR text-start lg:text-center text-pColor my-1 max-w-3xl mx-auto">Redefining education with Advanced AI/ML solutions that spark innovation, enrich learning, and optimize operations.</p>
                <div className="my-6 lg:my-12 flex justify-center ">
                    <video className="lg:max-w-screen-md rounded-md" playsInline autoPlay={true} muted loop>
                        <source src={EducationVideo} type="video/mp4"></source>
                    </video>
                </div>
            </div>
            <div className="container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-2xl text-start text-hColor fB mb-3 capitalize">Connect the Dots and Bridge the Gaps in Education with AI</h2>
                <p className="text-base fR text-start text-pColor my-1">Tech solutions that push education ahead.</p>
                <div className="block md:hidden">
                    <div className="d-flex w-full">
                        <div className={`flex w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40 bg-white w-full overflow-x-auto scroll-smooth educationParentTabs-container`}>
                            <button className={`text-sm px-3 py-3 rounded-md educationSubTabsr-facerecognitionMd ${tab == 'facerecognitionMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('facerecognitionMd');
                                    handleScrollViewMd(sectionsRefs.facerecognitionMd.current, 'facerecognitionMd')
                                    //sectionsRefs.facerecognitionMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Face Recognition Attendance System</button>
                            <button className={`text-sm px-3 py-3 rounded-md educationSubTabsr-smartAIMd ${tab == 'smartAIMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`}
                                onClick={() => {
                                    setTab('smartAIMd');
                                    handleScrollViewMd(sectionsRefs.smartAIMd.current, 'smartAIMd')
                                    //sectionsRefs.smartAIMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Smart AI</button>
                            <button className={`text-sm px-3 py-3 rounded-md educationSubTabsr-assettrackingMd ${tab == 'assettrackingMd' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500'}`}
                                onClick={() => {
                                    setTab('assettrackingMd');
                                    handleScrollViewMd(sectionsRefs.assettrackingMd.current, 'assettrackingMd')
                                    //sectionsRefs.assettrackingMd.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Asset Tracking</button>
                        </div>
                        <div>
                            <div ref={sectionsRefs.facerecognitionMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 mt-4 mb-5 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Education</p>
                                        <h2 className="text-hColor text-base fB">Face Recognition Attendance System</h2>
                                        <p className="text-pColor text-sm py-2 fM">Move beyond time-consuming roll calls. Embrace a smarter attendance tracking system with advanced facial recognition technology for precise and efficient presence recording. Simply walk in, get verified, and save valuable time for both students and staff.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.securityLaptop}
                                        heading={'Privacy first'}
                                        subtext={'Privacy concerns are addressed with robust security measures. Encryption techniques and access controls safeguard bio-metric data, guaranteeing the privacy and confidentiality of individuals facial features.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.BusinessService}
                                        heading={'Efficient tracking'}
                                        subtext={'Streamline attendance with automated tracking, cutting out manual data entry and freeing up valuable time for educators and administrators to focus on what truly matters.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.facerecognition}
                                        heading={'Precision Face-matching'}
                                        subtext={'Featuring advanced algorithms like OpenCV and ArcFace, the solution ensures real-time face detection and precise recognition for accurate attendance recording.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.healthcare}
                                        heading={'Reliable Results'}
                                        subtext={'Achieve unmatched precision in attendance recording through continuous algorithm enhancements, minimizing errors, and delivering trustworthy records.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.smartAIMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Education</p>
                                        <h2 className="text-hColor text-base fB">Smart AI Security</h2>
                                        <p className="text-pColor text-sm py-2 fM">Schools can now equip themselves with tireless AI Security! Our sophisticated systems scan and analyze video feeds to detect suspicious activities—such as loitering and unauthorized access—before they escalate into threats. Plus, they keep an eye on traffic flow and parking lot congestion, streamlining campus design for top-notch safety and efficiency.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.dataAnalytics}
                                        heading={'Threat Detection'}
                                        subtext={'Utilizes high-resolution cameras and sophisticated image recognition technology to accurately identify and classify potential threats, including weapons and suspicious items.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.identityverficationicon}
                                        heading={'Identity Check'}
                                        subtext={'Employs advanced facial recognition systems to verify individuals, capturing additional details like mask or helmet usage to ensure accurate identification.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.vision}
                                        heading={'Incident Records'}
                                        subtext={'Captures exact timestamps and records of threats and incidents, storing proof videos and essential metadata for comprehensive documentation.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.webAndapp}
                                        heading={'Survillence Interface'}
                                        subtext={'Provides an interactive web interface for instant alerts, historical insights, and detailed reports, featuring weapon types, identification data, and video evidence for a complete overview.'}
                                    />
                                </div>
                            </div>
                            <div ref={sectionsRefs.assettrackingMd} className="my-4 lg:my-20 border-dashed border-b-2">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                    <div className="lg:col-span-2 flex-grow">
                                        <p className="fR text-xs mb-1">Education</p>
                                        <h2 className="text-hColor text-base fB">Asset Tracking</h2>
                                        <p className="text-pColor text-sm py-2 fM">Streamline the oversight of school supplies, textbooks, and valuable resources with our intuitive Asset tracking system. Ensure every item is impeccably organized, effortlessly monitored, and precisely accounted for, elevating efficiency and eliminating loss.</p>
                                    </div>
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Real-Time Tracking'}
                                        subtext={'Ensure impeccable asset tracking with real-time updates for enhanced control over educational resources.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relativeflex-grow'
                                        image={PATH.img.icons.streamline}
                                        heading={'Automated School Inventory'}
                                        subtext={'Automate the management of school inventories, boosting efficiency and cutting down on manual tasks.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.imageAnalytics}
                                        heading={'Smart Insights'}
                                        subtext={'Leverage detailed analytics to optimize asset distribution, improving restocking and resource placement decisions.'}
                                    />
                                    <InsightsCard
                                        image={PATH.img.icons.pythonAPI}
                                        heading={'Advanced Tech Fusion'}
                                        subtext={'Incorporate advanced technologies like Python and neural networks to keep your asset management system at the forefront of innovation.'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 
                <div className="hidden md:flex gap-20">
                    <div className="">
                        <div className="flex flex-col w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40">
                            <button
                                className={`text-sm px-3 py-3 rounded-md ${tab === 'facerecognition' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`}
                                onClick={() => {
                                    setTab('facerecognition');
                                    sectionsRefs.facerecognition.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                Face Recognition
                            </button>
                            <button
                                className={`text-sm px-3 py-3 rounded-md ${tab === 'smartAI' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`}
                                onClick={() => {
                                    setTab('smartAI');
                                    sectionsRefs.smartAI.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                SmartAI
                            </button>
                            <button
                                className={`text-sm px-3 py-3 rounded-md ${tab === 'assettracking' ? 'fSB bg-primary text-white' : 'fR bg-[#F0F0F0] text-gray-500 '}`}
                                onClick={() => {
                                    setTab('assettracking');
                                    sectionsRefs.assettracking.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                Asset Tracking
                            </button>
                        </div>
                    </div>
                    <div>
                        <div ref={sectionsRefs.facerecognition} className="my-4 lg:my-20">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <p className="fR text-xs mb-1">Education</p>
                                    <h2 className="text-hColor text-base fB">Face Recognition Attendance System</h2>
                                    <p className="text-pColor text-sm py-2 fM">Move beyond time-consuming roll calls. Embrace a smarter attendance tracking system with advanced facial recognition technology for precise and efficient presence recording. Simply walk in, get verified, and save valuable time for both students and staff.</p>
                                </div>
                                <InsightsCard
                                    image={PATH.img.icons.securityLaptop}
                                    heading={'Enhanced Security'}
                                    subtext={'Privacy concerns are addressed with robust security measures. Encryption techniques and access controls safeguard bio-metric data, guaranteeing the privacy and confidentiality of individuals facial features.'}
                                />
                                <InsightsCard
                                    className='overflow-hidden relativeflex-grow'
                                    image={PATH.img.icons.BusinessService}
                                    heading={'Effortless Efficiency'}
                                    subtext={'Streamline attendance with automated tracking, cutting out manual data entry and freeing up valuable time for educators and administrators to focus on what truly matters.'}
                                />
                                <InsightsCard
                                    image={PATH.img.icons.facerecognition}
                                    heading={'Next-Gen Face Recognition'}
                                    subtext={'Featuring advanced algorithms like OpenCV and ArcFace, the solution ensures real-time face detection and precise recognition for accurate attendance recording.'}
                                />
                                <InsightsCard
                                    image={PATH.img.icons.healthcare}
                                    heading={'Precision and Dependability'}
                                    subtext={'Achieve unmatched precision in attendance recording through continuous algorithm enhancements, minimizing errors, and delivering trustworthy records.'}
                                />
                            </div>
                        </div>
                        <div ref={sectionsRefs.smartAI} className="my-4 lg:my-20">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <p className="fR text-xs mb-1">Education</p>
                                    <h2 className="text-hColor text-base fB">Smart AI Security</h2>
                                    <p className="text-pColor text-sm py-2 fM">Schools can now equip themselves with tireless AI Security! Our sophisticated systems scan and analyze video feeds to detect suspicious activities—such as loitering and unauthorized access—before they escalate into threats. Plus, they keep an eye on traffic flow and parking lot congestion, streamlining campus design for top-notch safety and efficiency.</p>
                                </div>
                                <InsightsCard
                                    image={PATH.img.icons.dataAnalytics}
                                    heading={'Threat Detection and Analysis'}
                                    subtext={'Utilizes high-resolution cameras and sophisticated image recognition technology to accurately identify and classify potential threats, including weapons and suspicious items.'}
                                />
                                <InsightsCard
                                    className='overflow-hidden relativeflex-grow'
                                    image={PATH.img.icons.identityverficationicon}
                                    heading={'Identity Verification'}
                                    subtext={'Employs advanced facial recognition systems to verify individuals, capturing additional details like mask or helmet usage to ensure accurate identification.'}
                                />
                                <InsightsCard
                                    image={PATH.img.icons.vision}
                                    heading={'Incident Tracking'}
                                    subtext={'Captures exact timestamps and records of threats and incidents, storing proof videos and essential metadata for comprehensive documentation.'}
                                />
                                <InsightsCard
                                    image={PATH.img.icons.webAndapp}
                                    heading={'Surveillance Dashboard'}
                                    subtext={'Provides an interactive web interface for instant alerts, historical insights, and detailed reports, featuring weapon types, identification data, and video evidence for a complete overview.'}
                                />
                            </div>
                        </div>
                        <div ref={sectionsRefs.assettracking} className="my-4 lg:my-20">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 my-4 lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <p className="fR text-xs mb-1">Education</p>
                                    <h2 className="text-hColor text-base fB">Asset Tracking</h2>
                                    <p className="text-pColor text-sm py-2 fM">Streamline the oversight of school supplies, textbooks, and valuable resources with our intuitive Asset tracking system. Ensure every item is impeccably organized, effortlessly monitored, and precisely accounted for, elevating efficiency and eliminating loss.</p>
                                </div>
                                <InsightsCard
                                    image={PATH.img.icons.trendIcon}
                                    heading={'Real-Time Accuracy'}
                                    subtext={'Ensure impeccable asset tracking with real-time updates for enhanced control over educational resources.'}
                                />
                                <InsightsCard
                                    className='overflow-hidden relativeflex-grow'
                                    image={PATH.img.icons.streamline}
                                    heading={'Streamlined Automation'}
                                    subtext={'Automate the management of school inventories, boosting efficiency and cutting down on manual tasks.'}
                                />
                                <InsightsCard
                                    image={PATH.img.icons.imageAnalytics}
                                    heading={'Smart Insights'}
                                    subtext={'Leverage detailed analytics to optimize asset distribution, improving restocking and resource placement decisions.'}
                                />
                                <InsightsCard
                                    image={PATH.img.icons.pythonAPI}
                                    heading={'Advanced Tech Fusion'}
                                    subtext={'Incorporate advanced technologies like Python and neural networks to keep your asset management system at the forefront of innovation.'}
                                />
                            </div>
                        </div>

                    </div>
                </div> */}
                <div className="hidden md:flex gap-20">
                    <div className="">
                        <div className="flex flex-col w-[300px] gap-3 py-6 text-nowrap sticky top-20 z-40">

                            <div className={`flex gap-2 items-center py-3 ${tab === 'facerecognition' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'facerecognition' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('facerecognition');
                                        handleScrollView(sectionsRefs.facerecognition.current)
                                        //sectionsRefs.facerecognition.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Face Recognition
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'facerecognition' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'facerecognition' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'smartAI' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'smartAI' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('smartAI');
                                        handleScrollView(sectionsRefs.smartAI.current)
                                        //sectionsRefs.smartAI.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Smart AI Security
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'smartAI' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'smartAI' ? 'bg-primary visible' : ' invisible'}`}></p></div>
                            </div>
                            <div className={`flex gap-2 items-center py-3 ${tab === 'assettracking' ? 'ml-6' : ' ml-0'} `}>
                                <button
                                    className={`text-sm fSB ${tab === 'assettracking' ? 'text-primary text-xl transition-all ease-in duration-300' : 'text-black transition-all ease-in duration-300'}`}
                                    onClick={() => {
                                        setTab('assettracking');
                                        handleScrollView(sectionsRefs.assettracking.current)
                                        //sectionsRefs.assettracking.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Asset Tracking
                                </button>
                                <div className="relative "><p className={` w-16 h-0.5 transition-all ease-in duration-300  ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p><p className={`rounded-full absolute -top-1 -right-1 w-2 h-2 transition-all ease-in duration-300 ${tab === 'assettracking' ? 'bg-primary visible' : ' invisible'}`}></p></div>

                            </div>
                        </div>
                    </div>
                    <div>
                        <div ref={sectionsRefs.facerecognition} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Education</p>
                                            <h2 className="text-hColor text-xl underline fB">Face Recognition Attendance System</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/ai-attendance')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Move beyond time-consuming roll calls. Embrace a smarter attendance tracking system with advanced facial recognition technology for precise and efficient presence recording. Simply walk in, get verified, and save valuable time for both students and staff.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.securityLaptop}
                                        heading={'Privacy first'}
                                        subtext={'Privacy concerns are addressed with robust security measures. Encryption techniques and access controls safeguard bio-metric data, guaranteeing the privacy and confidentiality of individuals facial features.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.BusinessService}
                                        heading={'Efficient tracking'}
                                        subtext={'Streamline attendance with automated tracking, cutting out manual data entry and freeing up valuable time for educators and administrators to focus on what truly matters.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.facerecognition}
                                        heading={'Precision Face-matching'}
                                        subtext={'Featuring advanced algorithms like OpenCV and ArcFace, the solution ensures real-time face detection and precise recognition for accurate attendance recording.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.healthcare}
                                        heading={'Reliable Results'}
                                        subtext={'Achieve unmatched precision in attendance recording through continuous algorithm enhancements, minimizing errors, and delivering trustworthy records.'}
                                    />
                                </div>

                            </div>
                        </div>
                        <div ref={sectionsRefs.smartAI} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Education</p>
                                            <h2 className="text-hColor text-xl underline fB">Smart AI Security</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/safety-security')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /></Link> </button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Schools can now equip themselves with tireless AI Security! Our sophisticated systems scan and analyze video feeds to detect suspicious activities—such as loitering and unauthorized access—before they escalate into threats. Plus, they keep an eye on traffic flow and parking lot congestion, streamlining campus design for top-notch safety and efficiency.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.dataAnalytics}
                                        heading={'Threat Detection'}
                                        subtext={'Utilizes high-resolution cameras and sophisticated image recognition technology to accurately identify and classify potential threats, including weapons and suspicious items.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.identityverficationicon}
                                        heading={'Identity Check'}
                                        subtext={'Employs advanced facial recognition systems to verify individuals, capturing additional details like mask or helmet usage to ensure accurate identification.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.vision}
                                        heading={'Incident Records'}
                                        subtext={'Captures exact timestamps and records of threats and incidents, storing proof videos and essential metadata for comprehensive documentation.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.webAndapp}
                                        heading={'Survillence Interface'}
                                        subtext={'Provides an interactive web interface for instant alerts, historical insights, and detailed reports, featuring weapon types, identification data, and video evidence for a complete overview.'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div ref={sectionsRefs.assettracking} className="my-4 lg:my-20 border-dashed border-b-2">
                            <div className="lg:my-10">
                                <div className="lg:col-span-2 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="fR text-sm mb-1">Education</p>
                                            <h2 className="text-hColor text-xl underline fB">Asset Tracking</h2>
                                        </div>
                                        <button className="text-sm flex gap-2 text-primary" onClick={() => window.location.reload()}><Link className="flex gap-2 " to={('/solutions/asset-tracking')}>Read More <ArrowUpRightIcon className="w-5 h-5 text-primary" /> </Link></button>
                                    </div>
                                    <p className="text-pColor text-sm py-4 fM">Streamline the oversight of school supplies, textbooks, and valuable resources with our intuitive Asset tracking system. Ensure every item is impeccably organized, effortlessly monitored, and precisely accounted for, elevating efficiency and eliminating loss.</p>
                                </div>
                                <div className="grid grid-cols-2 mt-4">
                                    <InsightsCard
                                        image={PATH.img.icons.trendIcon}
                                        heading={'Real time Tracking'}
                                        subtext={'Ensure impeccable asset tracking with real-time updates for enhanced control over educational resources.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 '
                                        image={PATH.img.icons.streamline}
                                        heading={'Automated School Inventory'}
                                        subtext={'Automate the management of school inventories, boosting efficiency and cutting down on manual tasks.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-t-2 '
                                        image={PATH.img.icons.imageAnalytics}
                                        heading={'Smart Insights'}
                                        subtext={'Leverage detailed analytics to optimize asset distribution, improving restocking and resource placement decisions.'}
                                    />
                                    <InsightsCard
                                        className='overflow-hidden relative flex-grow border-l-2 border-t-2'
                                        image={PATH.img.icons.pythonAPI}
                                        heading={'Advanced Tech Fusion'}
                                        subtext={'Incorporate advanced technologies like Python and neural networks to keep your asset management system at the forefront of innovation.'}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <section className="bg-[#F8F8F8] py-16">
                <div className="container mx-auto flex flex-col gap-12">
                    <div className="title block lg:flex justify-between">
                        <div>
                            <div className="mb-10">
                                <h1 className="text-2xl fB mb-1">Build a Better Future in Education</h1>
                                <h2 className="text-3xl fB text-[#C7C7C7] max-w-lg">Partner with us to create a safer and more engaging learning environment.</h2>
                            </div>
                        </div>
                        <p className="fR text-sm max-w-xl mt-6 lg:mt-0">Leading the charge, Proglint transforms educational services and strengthens safety and security in schools and academic institutions.</p>
                    </div>
                    <div>
                        <p className="fR mb-5">Research Highlights</p>
                        <div className="section4 bg-background">
                            <div className="w-full gap-3">
                                <Slider {...settings}>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">AI <br /> Attendance</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/ai-attendance')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col flex-grow gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Simplify attendance tracking with facial recognition for fast, accurate recording. Your face is your pass- just walk in, verify, and move on.</p>
                                                <img src={PATH.img.Featuresfacialrecognition} className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Pill <br /> Counting</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions-pill-counting')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">No More Second Guessing, Get the Right Pill, Every Time with Automated Dispensing. Our sleek, space-saving system records the entire fill process, ensuring precise pill counts with unmatched accuracy, safety, and compliance.</p>
                                                <img src={PATH.img.IndustryPillcount} className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Face<br />Recognition</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solution-Ai-attandance')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">From retail to education, facial recognition is transitioning from sci-fi to reality, verifying identities by matching faces from images or videos against extensive databases.</p>
                                                <img src={PATH.img.AiattendanceImg} className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Vision <br /> Analytics</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions-vision-analytics')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Vision Analytics for Real-Time Stores uses AI to transform your store into a customer insights powerhouse.</p>
                                                <img src={PATH.img.SolutionVisionAnalytics} className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Asset <br /> Tracking</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/asset-tracking')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">Track Your Assets, Inside and Out. Transform Your Inventory with 360° Robot Retail Asset Management.</p>
                                                <img src={PATH.img.SolutionAssettracking} alt="Asset tracking" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:pr-6">
                                        <div className="card bg-white rounded-3xl p-6">
                                            <div className="flex justify-between align-middle items-center border-b border-gray-300 pb-3">
                                                <p className="fR text-bace">Safety and <br /> Security</p>
                                                <button className="hover:bg-primary p-2 rounded-full flex justify-center cursor-pointer transition-all" onClick={() => window.location.reload()}><Link to={('/solutions/safety-security')}>
                                                    <ArrowUpRightIcon className="w-5 h-5" />
                                                </Link>
                                                </button>
                                            </div>
                                            <div className="flex flex-col gap-4 mt-6">
                                                <p className="fR text-sm text-gray-500 solution-card-text">A Proven Solution for Rapid, contactless screening with AI-Driven, Contactless Weapon Detection and Real-Time Monitoring for Superior Safety.</p>
                                                <img src={PATH.img.SolutionSafetyandsecurity} alt="safety and security" className="w-full h-60 object-cover rounded-xl" />
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        {/* <SolutionCarousel settings={settings} /> */}
                    </div>
                </div>
            </section>
            <FooterContact />
        </div>
    )
}
export default Education;