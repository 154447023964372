import React from "react";
import Btn from "../component/Btn";
import { ArrowLongLeftIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
const Job3 = () => {
    return (
        <div className="container mx-auto mt-20">
            <div className="flex gap-1 items-center my-10" onClick={() => window.location.reload()}>
                <Link to={('/career')} className="flex gap-1 items-center">
                    <ArrowLongLeftIcon className="w-6 h-6 text-secondary cursor-pointer" />
                    <p className="text-secondary text-base fSB cursor-pointer">Back to Career</p>
                </Link>
            </div>
            <div className="mt-10 mb-6 lg:mb-10">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center  text-hColor fB mb-3">DevOps Engineer</h1>
                {/* <p className="text-sm text-center text-pColor my-1 mb-3 px-2 lg:px-28">September 20, 2023</p> */}
                {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 w-32 text-center text-sm px-6 block m-auto bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                <div className="flex justify-center">
                    <a href="mailto:hr@proglint.com?subject=Applying for DevOps Engineer">
                        <Btn value={'Apply Now'} bgColor={'white'} />
                        {/* <button className='block mx-auto py-3 rounded-full my-4 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'> Apply Now</button> */}
                    </a>
                </div>
            </div>
            <div className="md:flex my-14">
                <div className="w-full md:w-2/6 lg:w-1/6">
                    <h2 className="text-hColor text-base fSB">Job Information</h2>
                    <ul>
                        <li className="text-sm text-pColor my-3 fR">2 - 3 years</li>
                        <li className="text-sm text-pColor my-3 fR">Bachelor’s degree in computer science or equivalent</li>
                        <li className="text-sm text-pColor my-3 fR"> Thiruvanmiyur, Chennai</li>
                        <li className="text-sm text-pColor my-3 fR"> Full-time</li>
                    </ul>
                </div>
                <div className="w-full md:w-4/6 lg:w-5/6">
                    <div className="description">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Profile</h2>
                        <p className="text-pColor fR  text-sm my-3">We’re looking for a DevOps engineer who can be responsible for deploying
                            product updates, identifying production issues, and implementing integrations that meet our
                            customers needs.</p>

                    </div>
                    <div className="description mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Description</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Building and implementing new development tools and infrastructure.</li>
                            <li className="text-pColor fR text-sm my-3">  Understanding the needs of stakeholders and conveying them to developers.</li>
                            <li className="text-pColor fR text-sm my-3"> Working on ways to automate and improve development and release processes.</li>
                            <li className="text-pColor fR text-sm my-3"> Testing and examining code written by others and analyzing results.</li>
                            <li className="text-pColor fR text-sm my-3"> Ensuring that systems are safe and secure against cyber security threats.</li>
                            <li className="text-pColor fR text-sm my-3"> Identifying technical problems and developing software updates and fixes.</li>
                            <li className="text-pColor fR text-sm my-3"> Working with software developers and software engineers to ensure that development
                                follows established processes and works as intended.</li>
                            <li className="text-pColor fR text-sm my-3"> Planning projects and being involved in project management decisions.</li>
                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Technical Skills</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Very good knowledge specifically in Azure and AWS.</li>
                            <li className="text-pColor fR text-sm my-3">Proficiency with Git and GitHub workflows</li>
                            <li className="text-pColor fR text-sm my-3">Good knowledge of Ruby or Python</li>
                            <li className="text-pColor fR text-sm my-3">Working knowledge of databases and SQL</li>
                            <li className="text-pColor fR text-sm my-3">Problem-solving attitude</li>
                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Soft Skills</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Excellent interpersonal communication skills</li>
                            <li className="text-pColor fR text-sm my-3">Fluent in English</li>
                            <li className="text-pColor fR text-sm my-3">Rigid adherence to schedule and delivering solutions on time</li>
                            <li className="text-pColor fR text-sm my-3">Collaborative team spirit</li>
                        </ol>
                    </div>
                    <h2 className="text-sm fSB text-hColor my-4">Please note that we have requirements for this role in Chennai.</h2>
                    {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                </div>
            </div>
        </div>
    );
};
export default Job3