import React from "react";
import Btn from "../component/Btn";
import { ArrowLongLeftIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
const Job5 = () => {
    return (
        <div className="container mx-auto mt-20">
            <div className="flex gap-1 items-center my-10" onClick={() => window.location.reload()}>
                <Link to={('/career')} className="flex gap-1 items-center">
                    <ArrowLongLeftIcon className="w-6 h-6 text-secondary cursor-pointer" />
                    <p className="text-secondary text-base fSB cursor-pointer">Back to Career</p>
                </Link>
            </div>
            <div className="mt-10 mb-6 lg:mb-10">
                <h1 className="text-xl md:px-0 lg:text-3xl text-center  text-hColor fB mb-2">JavaScript Developer Fresher</h1>
                {/* <p className="text-sm text-center text-pColor my-1 mb-3 px-2 lg:px-28">September 20, 2023</p> */}
                {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 w-32 text-center text-sm px-6 block m-auto bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                <div className="flex justify-center">
                    <a href="mailto:hr@proglint.com?subject=Applying for JavaScript Developer Fresher">
                        <Btn value={'Apply Now'} bgColor={'white'} />
                        {/* <button className='block mx-auto py-3 rounded-full my-4 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'> Apply Now</button> */}
                    </a>
                </div>
            </div>
            <div className="md:flex my-14">
                <div className="w-full md:w-2/6 lg:w-1/6">
                    <h2 className="text-hColor text-base fSB">Job Information</h2>
                    <ul>
                        <li className="text-sm text-pColor my-3 fR">0 to 1 Year</li>
                        <li className="text-sm text-pColor my-3 fR"> Thiruvanmiyur, Chennai</li>
                        <li className="text-sm text-pColor my-3 fR">Full Time</li>
                    </ul>
                </div>
                <div className="w-full md:w-4/6 lg:w-5/6">
                    <div className="description">
                        <h2 className="text-hColor fSB text-xl mb-3">Job Profile</h2>
                        <p className="text-pColor fR text-sm my-3">Proglint is seeking enthusiastic and talented individuals who are passionate about
                            JavaScript development to join our team as Junior JavaScript Developers. This is an excellent
                            opportunity for recent graduates or individuals with limited professional experience to kick-start
                            their career in web development.
                        </p>

                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Learn and Grow</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Participate in training programs to build a solid foundation in JavaScript development</li>
                            <li className="text-pColor fR text-sm my-3"> Stay up to date with the latest trends and best practices in web development</li>

                        </ol>
                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Front-end Development</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Work closely with senior developers to develop and implement user interfaces using
                                HTML, CSS, JavaScript and React.
                            </li>
                            <li className="text-pColor fR text-sm my-3"> Collaborate with UI/UX designers to bring designs to life on the web</li>

                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Coding and Debugging</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Write clean and efficient code following industry standards.</li>
                            <li className="text-pColor fR text-sm my-3">Debug and troubleshoot issues to ensure the functionality of web applications.
                            </li>


                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Collaboration</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Collaborate with cross-functional teams to understand project requirements.</li>
                            <li className="text-pColor fR text-sm my-3">Communicate effectively with team members to ensure successful project delivery.</li>

                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Documentation</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Create and maintain documentation for code and development processes.
                            </li>
                            <li className="text-pColor fR text-sm my-3">Ensure documentation is kept up to date with any changes to the codebase.
                            </li>

                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Basic Understanding of</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">HTML, CSS, and JavaScript
                            </li>
                            <li className="text-pColor fR text-sm my-3">Web development concepts and principles</li>

                        </ol>
                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Problem-Solving Skills</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Ability to analyze problems and propose solutions</li>
                        </ol>
                    </div>
                    <div className="description mt-6">

                        <h2 className="text-hColor fSB text-xl mb-3">Communication Skills</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Strong verbal and written communication skills</li>
                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Team Player
                        </h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> Ability to work collaboratively in a team environment.</li>


                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Passion for Learning
                        </h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3"> AEagerness to learn and adapt to new technologies</li>


                        </ol>
                    </div>
                    <div className="responsibilities mt-6">
                        <h2 className="text-hColor fSB text-xl mb-3">Nice to Have</h2>
                        <ol className="list-disc pl-12">
                            <li className="text-pColor fR text-sm my-3">Exposure to any JavaScript frameworks or libraries (e.g., React, Angular)</li>
                            <li className="text-pColor fR text-sm my-3">Familiarity with version control systems, preferably Gi</li>
                            <li className="text-pColor fR text-sm my-3">Any relevant internships or personal projects demonstrating practical experience</li>
                        </ol>
                    </div>
                    <h2 className="text-sm fSB text-hColor my-4">Please note that we have requirements for this role in Chennai.</h2>
                    {/* <Button LinkPath={('/career-form')} value={'Apply Now'} className={'my-6 text-sm px-6 bg-gradient-to-br fSB from-primary from-30% to-secondary to-90% focus:outline-none'} /> */}
                </div>
            </div>
        </div>
    );
};
export default Job5