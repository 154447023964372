import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const Equinox = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "HR Services Accessibility: Provides access to essential HR services like Payslip, Timesheet, Handbook, Newsletter, Staff Offers, and Holidays.",
        point2: "Streamlined Requests: Enables employees to send requests to HR and Admin effortlessly.",
        point3: "Approval Management: Facilitates approval for Business Trips, IT Requests, Leave/Joining requests, enhancing operational efficiency.",
        point4: "Learning & Development: Displays upcoming training sessions, promoting continuous employee development."
    },
    {
        title: 'Business challenge',
        // challengeDescription: "Integrating diverse checkout options and implementing real-time hardware monitoring while maintaining a seamless shopping experience posed initial challenges. Proglint aimed to optimize these functionalities without compromising user experience.",
        point1: "Manager-Employee Approval Tracking: Ensuring smooth tracking of approvals between managers and employees.",
        point2: "Request Management: Efficiently managing employee requests directed to HR and Admin."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Enhanced Workflow Tracking: Implemented a robust tracking mechanism to monitor approvals and requests between managers and employees.",
        point2: "Streamlined Request Processing: Developed a seamless process for handling employee requests, ensuring swift HR and Admin actions."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Proglint successfully deployed the Equinox app, transforming employee management for QICG Tawasul in Qatar. This comprehensive solution redefined workflows, empowering users with a unified platform for efficient daily operations.",
        deliveredParagraph2: "Proglint played a pivotal role in the design and deployment of the Equinox app, focusing on user-centric functionalities and robust backend integration for seamless employee management."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.equinoxBanner}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Streamlined Employee Management App'}
                domainText={'HR Services, Employee Management, Business Solutions'}
                durationText={'3 months, 10 man-months'}
                technologyText={'React Native, Firebase, RESTful API'}
                overviewText={'The Equinox app redefines employee management, offering a centralized platform for quick and efficient daily operations. Simplifying tasks with its user-friendly interface, it allows seamless tracking of requests, approvals, and HR services, empowering users with on-the-go actions.'}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>
                        {/* <div className="flex gap-3">
                            <ArrowLongLeftIcon className="w-12 text-gray-400" />
                            <ArrowLongRightIcon className="w-12" />
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.IntelThumbnail} RetailHeading={'Payment Processing'} SubHead={'Intel Vision Checkout'} ParaContent={`Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/intel-vision-checkout')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.cosfymartThumbnail} RetailHeading={'E-commerce'} SubHead={`Cosfymart`} ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cosfymart-app')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/jedidahs')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default Equinox;