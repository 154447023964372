import React, { useState } from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link } from "react-router-dom";
import Faqs from "../../component/Faqs";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

const FacialRecognition = () => {
    const [tab, setTab] = useState('Manufacturing');
    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-10 absolute bottom-6 lg:top-[30%]">
                        <h1 className="banner_title fSB text-white w-auto md:w-auto lg:max-w-xl mb-4">Face Recognition</h1>
                        <p className="text-sm text-white lg:max-w-2xl">Embrace a smarter, more secure future with Face Recognition. Advanced algorithms deliver instant facial capture and verification, while rock-solid encryption and top-notch access controls shield your biometric data and guarantee privacy.</p>
                    </div>
                </div>
                <div className="absolute">
                    <img src={PATH.img.Featuresfacialrecognition} alt="banner" className="w-screen h-screen lg:h-full object-cover" />
                    <img src={PATH.img.overlay} alt="overlay" className="absolute top-0 left-0 h-full w-full object-cover" />
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-2 max-w-xl">Applications of Face Recognition</h2>
                    <p className="text-sm md:text-base text-start text-pColor my-3">Businesses, regardless of size or sector, can rely on our AI Face Recognition Solution for superior adaptability and rigorous security, thanks to sophisticated algorithms and solid hardware. Streamline your security management, retail analysis, and educational operations with this indispensable tool.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.User} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Secure Access Control</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Facial recognition grants secure access with a simple scan of your face. This technology streamlines entry by verifying identities quickly and accurately, ensuring only authorized personnel gain access.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.trendIcon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Time and Attendance Tracking</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Revolutionize attendance management with facial recognition. This system automates clock-ins and clock-outs, providing accurate time tracking and reducing administrative workload.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.security} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Security and Surveillance</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Boost security measures with facial recognition, which monitors and identifies individuals in real time. It enhances surveillance systems by providing precise, actionable insights into who is present.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.healthcare} alt="icon" className="w-8 h-8" />
                            <span className="text-xl fSB">Health and Safety Monitoring</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Implement facial recognition to boost health and safety standards. This includes ensuring that safety gear is worn correctly and detecting potential health risks in environments like workplaces or hospitals.</p>
                    </div>
                </div>
            </div >
            <div className="bg-black pb-4 lg:pb-20">
                <div className="flex container mx-auto gap-4 flex-row overflow-auto pt-28">
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Manufacturing')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Manufacturing' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.ManufacturingtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Manufacturing' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Manufacturing</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Education')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Education' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.EducationtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Education' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Education</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Healthcare')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Healthcare' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.HealthcaretabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Healthcare' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Healthcare</p>
                    </div>
                    <div className="flex-auto relative cursor-pointer" onClick={() => setTab('Retail')}>
                        <div className={`w-16 h-16 lg:w-28 lg:h-28 object-cover rounded-full bg-slate-400 absolute -top-10 lg:-top-16 left-0 right-0 m-auto  ${tab == 'Retail' ? 'border-4 lg:border-8  border-white ' : 'border-4 lg:border-8 border-[#2A2A2A]'}`} >
                            <img src={PATH.img.RetailtabImg} className="w-full h-full object-cover rounded-full" alt="Feature-icon" />
                        </div>
                        <p className={`text-sm lg:text-lg pt-8 lg:pt-14 pb-4 text-center w-40 md:w-full  ${tab == 'Retail' ? 'text-primary bg-white fB' : 'text-white bg-[#2A2A2A]'}`}>Retail</p>
                    </div>
                </div>
                {tab == 'Manufacturing' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Automates shift tracking, eliminating manual punch-ins and cutting admin work.</li>
                                    <li className="text-base fR mb-3 text-white">Alerts security and blocks unauthorized access to restricted zones instantly.</li>
                                    <li className="text-base fR mb-3 text-white">Ensures all staff wear necessary safety gear, like helmets and glasses.</li>
                                    <li className="text-base fR mb-3 text-white">Smartly routes tasks based on staff location and skills for peak efficiency.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">AI Attendance</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-attendance')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Education' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Automated attendance tracking eliminates manual entry, freeing up educators.</li>
                                    <li className="text-base fR mb-3 text-white">Stops students from falsifying attendance.</li>
                                    <li className="text-base fR mb-3 text-white">Real-time notifications keep students updated on their attendance.</li>
                                    <li className="text-base fR mb-3 text-white">Robust encryption and access controls secure biometric data.</li>
                                    <li className="text-base fR mb-3 text-white">Continuous updates ensure error-free, reliable records.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">AI Attendance</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-attendance')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4" >
                                    <h2 className="fR text-base text-white">Asset Tracking</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/asset-tracking')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Healthcare' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Streamlines patient and staff attendance, eliminating manual logs.</li>
                                    <li className="text-base fR mb-3 text-white">Tracks healthcare staff and patient movement for optimal coordination and safety.</li>
                                    <li className="text-base fR mb-3 text-white">Prevents unauthorized access to sensitive medical areas with instant alerts.</li>
                                    <li className="text-base fR mb-3 text-white">Enables smart tasking in medical settings with real-time location data.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fR text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">AI Attendance</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/ai-attendance')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {tab == 'Retail' &&
                    <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-10 mt-8">
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Key Features</h2>
                            </div>
                            <div className="p-6">
                                <ul className="list-disc">
                                    <li className="text-base fR mb-3 text-white">Instant Age Verification and ensure you get all the real customers.</li>
                                    <li className="text-base fR mb-3 text-white">Simplify staff check-ins with precise attendance tracking.</li>
                                    <li className="text-base fR mb-3 text-white">Instantly spot unauthorized access with real-time security alerts.</li>
                                    <li className="text-base fR mb-3 text-white">Receive immediate notifications on suspicious behavior to prevent theft.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="border-b border-gray-50 py-6 px-4">
                                <h2 className="text-lg fM text-white">Proglint AI Solution</h2>
                            </div>
                            <ul className="p-6">
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Vision Analytics</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/vision-analytics')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Loss Prevention</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/loss-prevention')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="flex justify-between items-center py-4">
                                    <h2 className="fR text-base text-white">Safety and Security</h2>
                                    <div className="flex justify-between items-center gap-3" onClick={() => window.location.reload()}>
                                        <Link className="flex gap-3 justify-between items-center" to={('/solutions/safety-security')}>
                                            <p className="text-sm fR text-white">Read More</p>
                                            <ArrowUpRightIcon className="text-white w-4 h-4" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className="services container mx-auto my-6 lg:my-14">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor fB mb-3">Don't Just Digitize, Be Intelligent</h2>
                <p className="text-base text-start text-pColor my-1">Proglint Makes AI Work for You. Digitize every aspect of your business and uncover opportunities for enhancement at every stage using our advanced AI and computer vision solutions.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10 my-4 lg:my-10">
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.playplugicon} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Plug-and-Play Perfection</h2>
                        <p className="text-pColor text-sm py-2">Seamless AI integration with instant, hassle-free setup.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.costSaving} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Smart Savings</h2>
                        <p className="text-pColor text-sm py-2">Slash operating costs by up to 40% with our AI-driven solutions.</p>
                    </div>
                    <div className="flex flex-col">
                        <img src={PATH.img.icons.security} alt="icon" className="my-3 w-14 h-14" />
                        <h2 className="text-hColor text-base fSB">Data Security</h2>
                        <p className="text-pColor text-sm py-2">Safeguard your data with top-tier privacy and security protocols.</p>
                    </div>
                </div>
            </div>
            <Faqs
                Question1={`What is Face Recognition Technology and How Does it Work?`}
                Answer1={`Face recognition technology uses advanced algorithms to capture, analyze, and verify the facial features of individuals. It works by comparing the unique patterns in an individual’s facial features to a stored database for identification or authentication purposes.
                `}
                Question2={`How Secure is Face Recognition for Access Control?`}
                Answer2={`Face recognition technology is highly secure, incorporating robust encryption techniques and stringent access controls to safeguard biometric data. This ensures that personal information is protected against unauthorized access and breaches.`}
                Question3={`Can facial recognition be fooled?`}
                Answer3={`While early facial recognition systems were susceptible to spoofing, advancements in technology have significantly improved accuracy. Modern systems can detect and reject fake images or videos, making them more reliable.`}
                Question4={`What Are the Main Applications of Face Recognition in the Workplace?`}
                Answer4={`Face recognition technology is used for various applications in the workplace, including automated attendance tracking, access control to secure areas, real-time monitoring of employee movements, and enhancing overall security protocols.`}
                Question5={`Is Face Recognition Technology Compliant with Privacy Regulations?`}
                Answer5={`Yes, face recognition technology can be designed to comply with stringent privacy regulations. It incorporates data encryption, anonymization techniques, and access controls to ensure that biometric data is handled responsibly and in accordance with legal standards.`}
            />
            <FooterContact />
        </div>
    )
}
export default FacialRecognition