import React from "react";
import FooterContact from "./FooterContact";
import { PATH } from "../globals/app-constants";
import ContentBanner1 from "./ContentBanner1";
import { Link } from "react-router-dom";
const Hybrid = () => {
    return (
        <div>
            <div className="white_banner bg-cover bg-center">
                <div className="w-full h-full lg:flex items-center container mx-auto py-6">
                    <p className="white_banner_title font-semibold text-hColor w-full lg:w-1/2 mb-6 lg:mb-0">Hybrid App
                        Development Company</p>
                    <div className="w-full h-1/2 lg:h-auto flex align-middle justify-center items-center lg:w-1/2">
                        <img src={PATH.img.icons.hybridLogo} alt="logo" className="block mx-auto w-52 lg:w-auto" />
                    </div>
                </div>
            </div>
            <div className="device my-5 lg:my-10 container mx-auto">
                <div>
                    <h1 className="text-xl md:px-0 lg:text-3xl text-center text-hColor font-bold mb-3">Empower your business with Proglint's Hybrid App Development</h1>
                    <p className="text-base text-center text-pColor mb-1">Seamless, Scalable, and Future-Ready Solutions</p>
                </div>
                <img src={PATH.img.hybridDesvice} alt="device-img" className="block m-auto py-6 lg:py-12" />
            </div>
            <div className="service_section container mx-auto  mt-10 mb-10 lg:mt-14 lg:mb-20 ">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3 max-w-xl">Unlock the potential of React NativeApp Development with Proglint</h2>
                    <p className="text-base text-start text-pColor mb-3">Where rapid development meets cross-platform excellence. Seamlessly launch your mobile app on both Android and iOS, delivering a superior user experience with our creative UI/UX design. Experience the efficiency of React Native and elevate your app to surpass native counterparts, all within a minimal timeframe.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.trendIcon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Cross-Platform Adaptability</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Empower your app to thrive across ecosystems with Proglint's hybrid app development. Utilizing frameworks like Ionic and Flutter, we ensure seamless adaptation on both Android and iOS, optimizing your app's reach.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.shortTdev} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Shorter Time Development</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Accelerate your app's journey to the market with Proglint's hybrid development expertise. Leveraging tools like Xamarin, we cut development time, providing a faster route from ideation to deployment.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.thirdParty} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Third-party Integrations</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Enhance your app's functionality with seamless third-party integrations. Proglint's hybrid app development leverages frameworks like PhoneGap, ensuring smooth collaboration with external services for an enriched user experience.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.dartBoard} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Live Reload</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Experience real-time changes and quick iterations during development with Proglint's hybrid app approach. Through features like Hot Reload in frameworks like Flutter, witness instant updates and efficient debugging for a smoother development process.</p>
                    </div>
                </div>
            </div >
            <div className="service_projects container mx-auto">
                <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-6 lg:mb-12 max-w-md">Building Relationships</h2>
                <div className="lg:flex gap-10">
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-[#6F1A07]  relative overflow-hidden rounded-xl">
                        <img src={PATH.img.cvsCoverimg} alt="cover-pic" className="absolute object-cover w-full h-full lg:w-auto lg:h-auto lg:top-0 -right-32 lg:right-0" />
                        <div className="bg_gradient_brown absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">CVS Pharmacy</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Revolutionizing Retail with Smart Fridge Technology. Proglint's innovative Smart Fridge Management System enhances shopping experiences, streamlines inventory, and offers valuable insights. Witness the future of retail automation.</p>
                            <h2 className="text-white cursor-pointer text-lg font-normal underline" onClick={() => window.location.reload()}><Link to={('/case-studies/cvs')}>Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-2/3 bg-[#646E78] relative overflow-hidden rounded-xl">
                        <img src={PATH.img.cosfyCoverimg} alt="cover-pic" className="absolute top-0 -right-48 lg:right-0" />
                        <div className="bg_gradient_gray absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">Cosfymart</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.</p>
                            <h2 className="text-white cursor-pointer text-lg font-normal underline" onClick={() => window.location.reload()}><Link to={('/case-studies/cosfymart-app')}>Case Study</Link></h2>
                        </div>
                    </div>
                </div>
                <div className="lg:flex lg:gap-10 lg:mt-10">
                    <div className="w-full mb-6 lg:w-2/3 bg-secondary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.treuxCoverimg} alt="cover-pic" className="absolute top-0 -right-48 lg:right-0" />
                        <div className="bg_gradient_red absolute w-full h-full"></div>
                        <div className="px-6 py-6 relative z-30">
                            <h2 className="text-white text-xl font-bold mb-3">Treux</h2>
                            <p className="text-white text-sm w-60 mb-3 line-clamp-10">Treux simplifies medical records for patients and doctors. Our user-friendly app enables patients to manage their medical history, while doctors access records securely with patient consent. Proglint ensured a seamless, secure platform, enhancing patient-doctor interactions and delivering an intuitive app for efficient medical record management.</p>
                            <h2 className="text-white cursor-pointer text-lg font-normal underline" onClick={() => window.location.reload()}><Link to={('/case-studies/treux-app')} >Case Study</Link></h2>
                        </div>
                    </div>
                    <div className="w-full mb-6 lg:w-3/5 h-80 bg-primary relative overflow-hidden rounded-xl">
                        <img src={PATH.img.humanCoverimg1} alt="cover-pic" className="absolute bottom-12 right-0" />
                        <div className="px-6 cursor-pointer py-10 absolute bottom-0" onClick={() => window.location.reload()}>
                            <Link to={('/contact')}>
                                <div className="flex flex-row gap-1">
                                    <h2 className="text-white text-lg font-normal underline">Say Hi</h2>
                                    <img src={PATH.img.icons.hello} alt="hello" className="w-8 h-8" />
                                </div>
                                <h2 className="text-white text-lg font-normal underline">to Discuss a Project</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_section container mx-auto mt-6 mb-10 lg:mt-16 lg:mb-20">
                <div className="service_item_title mb-8 lg:mb-12">
                    <h2 className="text-xl md:px-0 lg:text-3xl text-start text-hColor font-bold mb-3 max-w-xl">Future of mobile app development with Proglint's Flutter services</h2>
                    <p className="text-base text-start text-pColor my-3">Embracing Google's powerhouse, Flutter, we bring forth a dynamic framework that crafts cutting-edge Android and iOS apps with a unified codebase, setting a new standard for speed and efficiency. Our Flutter application development breaks barriers, delivering a seamless native experience on diverse platforms. Whether on mobile, desktop, or wearables, we harness Flutter's versatility to create visually stunning, high-performance apps. Join us in exploring the myriad benefits of Flutter as we redefine digital excellence.</p>
                </div>
                <div className="service_item">
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.webLaptop} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Single-Code Base Awesomeness</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Elevate your app development game with Proglint's mastery in harnessing the power of a unified codebase, leveraging cutting-edge frameworks like Flutter for seamless cross-platform excellence.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.easyIntegration} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Easy Integration & Fast App Development:</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Experience the efficiency of seamless integrations and accelerated app development as Proglint expertly navigates the complexities of Flutter, ensuring rapid deployment and optimal performance.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.mobileNativeapps} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Native Performance & Fluent Experience</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Immerse your users in a world of native-level performance and a smooth user experience, achieved through Proglint's expertise in optimizing Flutter for unparalleled responsiveness.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.trendIcon} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Future Ready & Trend-Setter</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Stay ahead of the curve with Proglint's forward-looking approach, utilizing Flutter's future-ready features and adopting emerging trends for an app that stands out in the digital landscape.</p>
                    </div>
                    <div className="service_item_box">
                        <h3 className="service_item_content">
                            <img src={PATH.img.icons.meterialDesign} alt="icon" className="w-8 h-8" />
                            <span className="text-xl font-semibold">Material Design & Cupertino</span>
                        </h3>
                        <p className="text-base text-pColor max-w-4xl my-5">Craft visually stunning interfaces that seamlessly blend Material Design and Cupertino aesthetics, creating an engaging user experience that caters to both Android and iOS platforms.</p>
                    </div>
                </div>
            </div >
            <ContentBanner1 />
            {/* <BlogCarousel settings={settings} /> */}
            <FooterContact />
        </div>
    )
}
export default Hybrid