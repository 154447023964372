import React from "react";
const InsightsCard = ({ heading, subtext, image , className}) => {
    return (
        <div>

            <div className={`${className} flex flex-col h-full bg-white p-4`}>
                <span className="flex justify-center items-center align-middle bg-[#EEEEEE] rounded-full w-12 h-12 mb-3">
                    {/* {icon} */}
                    <img src={image} alt="icon" className="w-7 h-7" />
                    {/* <ArrowRightStartOnRectangleIcon className="w-8 h-8" /> */}
                </span>
                <h2 className="text-hColor text-base fB">{heading}</h2>
                <p className="text-pColor text-sm py-2 fM">{subtext}</p>
            </div>
        </div>
    )
}
export default InsightsCard;