import React from "react";
import FooterContact from "../../component/FooterContact";
import { PATH } from "../../globals/app-constants";
import PortfolioDetail from "../../component/PortfolioDetail";
import SliderCards from "../../component/SliderCards";
import { Link } from "react-router-dom";

const Aladdin = () => {
    const Portfoliodetail = [{
        title: 'Case highlights',
        point1: "Effortless Service Requests: Empowers users to request diverse home services through a unified mobile app interface, including air conditioning, cleaning, washing, and plumbing.",
        point2: "Dynamic Service Assignment: Efficiently routes and assigns customer requests to the most suitable service providers based on real-time demand analysis."
    },
    {
        title: 'Business challenge',
        point1: "Architecting a multifaceted ecosystem for various user roles within home service management.",
        point2: "Developing distinct mobile applications for Customers, Aladdins, Service Providers, and Admin, each with unique functionalities.",
        point3: "Establishing an intuitive interface for different user personas while ensuring coherence across the service chain.",
        point4: "Creating a robust backend system to manage service requests, task assignments, and real-time interactions among users.",
        point5: "Ensuring secure and swift communication channels between all stakeholders.",
        point6: "Aligning numerous components of the service management ecosystem for a seamless user experience."
    },
    {
        title: 'Overcoming Challenges',
        point1: "Simplified the design of different apps, making them user-friendly for everyone.",
        point2: "Conducted thorough testing to ensure all the apps worked smoothly together.",
        point3: "Built a robust system to manage requests efficiently between users and service providers.",
        point4: "Implemented secure and efficient communication channels for all parties involved.",
        point5: "Ensured seamless integration of all components for a cohesive and hassle-free experience."
    },
    {
        title: 'Delivered solution',
        deliveredParagraph1: "Proglint successfully developed and deployed a comprehensive mobile-based solution catering to home service needs. The solution included distinct applications for customers and administrators, ensuring a user-friendly experience and seamless management. By conducting detailed testing across the platform, Proglint guaranteed the smooth functioning of all interconnected systems.",
        deliveredParagraph2: "The Aladdin app, specifically tailored for the client, stands as a testament to Proglint's commitment to delivering efficient, reliable, and user-centric home service solutions."
    }
    ]

    return (
        <div>
            <PortfolioDetail
                imgSrc={PATH.img.AladdinDetail}
                bannerSubtitle={'Success Story'}
                bannerTitle={'Home Service Management Solution'}
                domainText={'Service Industry, Home Maintenance, On-demand Services'}
                durationText={'6 months, 20 man-months'}
                technologyText={'React Native, Node.js, OAuth 2.0, Firebase'}
                overviewText={`Proglint's Home Service project introduces a mobile-based solution connecting customers with proficient home service professionals. The app offers tailored service requests, managed by 'Aladdins' who match requests to the right 'Service Providers.' Admin oversees operations.`}
                Portfoliodetails={Portfoliodetail}

            />
            <div className="related_project bg-background">
                <div className="container mx-auto py-5">
                    <div className="flex justify-between items-end">
                        <h1 className="text-hColor font-bold text-2xl capitalize mb-3">Related projects</h1>

                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 py-8 ">
                        <SliderCards ImgPath={PATH.img.IntelThumbnail} RetailHeading={'Payment Processing'} SubHead={'Intel Vision Checkout'} ParaContent={`Proglint crafted Intel Vision Checkout, an intuitive app amplifying Intel's product accessibility and seamless transactions. Integrating secure payment options, real-time data sync, and flexible refund mechanisms, the app enriched customer experiences, optimizing Intel's business operations.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/intel-vision-checkout')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.cosfymartThumbnail} RetailHeading={'E-commerce'} SubHead={`Cosfymart`} ParaContent={`Cosfymart's mobile and web solutions have revolutionized retail interactions, offering a dynamic interface for customers and vendors alike. Proglint's strategic implementation ensured a seamless, cost-effective platform, overcoming training and cost challenges efficiently. The harmonized system enables effortless transactions and management, setting a new standard in the E-commerce realm.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/cosfymart-app')}>Read more</Link>
                                </>
                            } />
                        <SliderCards ImgPath={PATH.img.JedediahsThumbnail} RetailHeading={'Retail'} SubHead={'Jedediah Vision Checkout'} ParaContent={`Proglint empowered Jedediah, an advanced E-commerce app, with diverse checkout options, optimizing user experiences across web, mobile, and vision interfaces. The solution's seamless shopping experience, inclusive of self-checkout and manned counters, enhanced flexibility and convenience.`}
                            profileContent={
                                <>
                                    <Link className="my-5 underline text-xs font-bold text-secondary text-start" to={('/case-studies/jedidahs')}>Read more</Link>
                                </>
                            } />

                    </div>
                </div>
            </div>
            <FooterContact />
        </div>
    );
};
export default Aladdin;