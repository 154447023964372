import React from "react";
import { PATH } from "../../globals/app-constants";
import FooterContact from "../../component/FooterContact";
import { Link } from "react-router-dom";
import { useState } from "react";

const SolutionLanding = () => {
    const [hideLoadMore, setHideLoadMore] = useState(false);
    const [initialImagesCount, setInitialImagesCount] = useState(6);
    const card = [
        {
            img: PATH.img.SolutionLossprevention,
            title: 'Loss Prevention',
            subText: 'See It, Stop It, Save It! Redefine Retail security with our AI-driven Self-checkout, halting fraud in its tracks. No more lines, no more delays - just grab, scan, and let the customers go with total peace of mind',
            fontSize: 'large',
            title_path: '/solutions/loss-prevention'
        },
        {
            img: PATH.img.SolutionPillcount,
            title: 'Pill counting',
            subText: `No More Second Guessing, Get the Right Pill, Every Time with Automated Dispensing. Our sleek, space-saving system records the entire fill process, ensuring precise pill counts with unmatched accuracy, safety, and compliance.`,
            fontSize: 'large',
            title_path: '/solutions/pill-counting'

        },
        {
            img: PATH.img.SolutionVisionAnalytics,
            title: 'Vision Analytics',
            subText: `Vision Analytics for Real-Time Stores uses AI to transform your store into a customer insights powerhouse.`,
            fontSize: 'large',
            title_path: '/solutions/vision-analytics'

        },
        {
            img: PATH.img.SolutionAssettracking,
            title: 'Asset Tracking',
            subText: `Track Your Assets, Inside and Out. Transform Your Inventory with 360° Robot Retail Asset Management.`,
            fontSize: 'large',
            title_path: '/solutions/asset-tracking'
        },
        {
            img: PATH.img.SolutionSafetyandsecurity,
            title: 'Safety and Security',
            subText: `A Proven Solution for Rapid, contactless screening with AI-Driven, Contactless Weapon Detection and Real-Time Monitoring for Superior Safety.`,
            fontSize: 'large',
            title_path: '/solutions/safety-security',

        },
        {
            img: PATH.img.AiattendanceImg,
            title: 'AI Attendance',
            subText: `Simplify attendance tracking with facial recognition for fast, accurate recording. Your face is your pass- just walk in, verify, and move on.`,
            fontSize: 'large',
            title_path: '/solutions/ai-attendance'

        },
        {
            img: PATH.img.SolutionQualityControl,
            title: 'AI for Quality Control',
            subText: `Automated inspections deliver instantaneous, precise evaluations, dramatically reducing manual tasks and eliminating human errors.`,
            fontSize: 'large',
            title_path: '/solutions/ai-for-quality-control'

        },
        {
            img: PATH.img.SolutionvisionSOCbanner,
            title: 'Vision Self-Checkout',
            subText: `Simplify shopping with instant item scanning, swift payments, and a seamless checkout for a fast, hassle-free experience.`,
            fontSize: 'large',
            title_path: '/solutions/vision-selfcheckout'

        },
        {
            img: PATH.img.SolutionImageAalyis,
            title: 'Medical Image Analysis',
            subText: `Facilitates rapid diagnoses, individualized treatment plans, precise tracking of medication use, and predictive health analytics.`,
            fontSize: 'large',
            title_path: '/solutions/medical-image-analysis'

        },
        {
            img: PATH.img.smartfridgeimg1,
            title: 'Smart Fridge',
            subText: `Meet the future of shopping with the Smart Fridge—a cutting-edge cabinet that uses advanced computer vision technology to automate purchases, create virtual shopping carts, and manage inventory seamlessly.`,
            fontSize: 'large',
            title_path: '/solutions/smart-fridge'

        },
        {
            img: PATH.img.solution_action_recognition_img1,
            title: 'Action Recognition',
            subText: `AI-Enhanced Behavior Recognition for Real-Time Insights. Transform your approach to tracking and understanding human behavior with precise, immediate analysis.`,
            fontSize: 'large',
            title_path: '/solutions/action-recognition'

        },

    ]
    const [portfolioCard, setPortfolioCard] = useState(card.slice(0, initialImagesCount))


    const loadMoreOnClick = () => {
        const currentCount = portfolioCard.length;
        const newCount = currentCount + initialImagesCount;
        let remainingImages = card?.slice(currentCount, newCount);
        let remaining = card?.length - newCount
        if (remaining < 8) {
            // initialImagesCount = remainingImages?.length
            setInitialImagesCount(remaining)
        }
        // debugger
        if ((newCount - 1) === card?.length) {
            setHideLoadMore(true)
        }
        setPortfolioCard([...portfolioCard, ...remainingImages]);
    };

    return (
        <div>
            <div className="service_banner_h relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="z-10 absolute bottom-6 lg:top-[30%]">
                        <h1 className="banner_title fSB text-white w-auto md:w-auto lg:max-w-xl">Proglint Ai Solution</h1>
                    </div>
                </div>
                <div className="">
                    <img src={PATH.img.SolutionLandingBanner} alt="banner" className="w-screen h-screen object-cover" />
                </div>
            </div>

            <div className="container mx-auto">


                <div className="portfolio grid grid-cols-12 gap-4 lg:gap-10 my-4 lg:my-10">
                    {portfolioCard?.map((card) => {
                        return (
                            <>
                                {card.fontSize === 'large' &&
                                    <>
                                        <div className="portfolio_card group w-full col-span-12 lg:col-span-6 lg:mb-0 relative overflow-hidden">
                                            <img src={card.img} alt="retail" className="w-full h-full object-cover bg-no-repeat" />
                                            <div className="w-full h-full cursor-pointer lg:flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900 lg:group-hover:bg-gradient-to-b lg:group-hover:from-[#D9D9D9] lg:group-hover:to-[#D9D9D9]">
                                                <div className="flex flex-col justify-between lg:hidden w-full h-full px-3 py-3">
                                                    <span className="tags flex flex-row gap-2 flex-wrap lg:hidden">
                                                        {
                                                            card.tag?.map(v => <div className="py-1 px-2 rounded-sm bg-white text-black text-xs w-auto">{v}</div>)
                                                        }
                                                    </span>
                                                    <h2 className="portfolio_title text-xl md:text-3xl fB text-white cursor-pointer" onClick={() => window.location.reload()}><Link to={(card.title_path)}>{card.title}</Link></h2>
                                                </div>
                                                <div className="relative lg:group-hover:translate-y-0 transition-all ease-out duration-300 px-6 w-full h-full">
                                                    <div className="tags hidden lg:group-hover:flex flex-wrap flex-row gap-8 group-hover:top-0 py-6">
                                                        {
                                                            card.tag?.map(v => <div className="py-1 px-2 rounded-sm bg-white text-black text-xs w-auto">{v}</div>)
                                                        }
                                                    </div>
                                                    <h2 className="portfolio_title absolute bottom-10 group-hover:hidden text-xl md:text-3xl lg:text-5xl fB text-white group-hover:mb-5 lg:group-hover:text-hColor lg:group-hover:underline decoration-2 mt-3 cursor-pointer">{card.title}</h2>
                                                    <div className="invisible group-hover:visible">
                                                        <h2 className="portfolio_title text-xl md:text-3xl lg:text-5xl fB text-white mb-16 group-hover:mb-5 lg:group-hover:text-hColor lg:group-hover:underline decoration-2 mt-3 cursor-pointer" onClick={() => window.location.reload()}><Link to={(card.title_path)}>{card.title}</Link></h2>
                                                        <p className="hidden lg:block text-white fR text-lg group-hover:text-pColor">{card.subText}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="block col-span-12 w-full lg:hidden fR text-sm mb-4 z-30 text-pColor">{card.subText}</p>
                                    </>
                                }
                                {card.fontSize === 'small' &&
                                    <>
                                        <div className="portfolio_card group w-full col-span-12 lg:col-span-6 mb-3 lg:mb-0 relative overflow-hidden">
                                            <img src={card.img} alt="retail" className="w-full h-full object-cover bg-no-repeat" />
                                            <div className="w-full h-full cursor-pointer lg:flex absolute top-0 bg-gradient-to-b from-transparent to-gray-900 lg:group-hover:bg-gradient-to-b lg:group-hover:from-[#D9D9D9] lg:group-hover:to-[#D9D9D9]">
                                                <div className="flex flex-col justify-between lg:hidden w-full h-full px-3 py-3">
                                                    <span className="tags flex flex-row gap-2 flex-wrap lg:hidden">
                                                        {
                                                            card.tag?.map(v => <div className="py-1 px-2 rounded-sm bg-white text-black text-xs w-auto">{v}</div>)
                                                        }
                                                    </span>
                                                    <h2 className="portfolio_title text-xl md:text-3xl fB text-white cursor-pointer" onClick={() => window.location.reload()}><Link to={(card.title_path)}>{card.title}</Link></h2>
                                                </div>
                                                <div className="relative lg:group-hover:translate-y-0 transition-all ease-out duration-300 px-6 w-full h-full">
                                                    <div className="tags hidden group-hover:flex flex-wrap flex-row gap-3 group-hover:top-0 py-6">
                                                        {
                                                            card.tag?.map(v => <div className="py-1 px-2 rounded-sm bg-white text-black text-xs w-auto">{v}</div>)
                                                        }
                                                    </div>
                                                    <h2 className="portfolio_title absolute bottom-10 group-hover:hidden text-4xl fB text-white group-hover:mb-5 lg:group-hover:text-hColor lg:group-hover:underline decoration-2 mt-3 cursor-pointer" onClick={() => window.location.reload()}><Link to={(card.title_path)}>{card.title}</Link></h2>
                                                    <div className="invisible group-hover:visible">
                                                        <h2 className="portfolio_title text-3xl fB text-white group-hover:mb-5 lg:group-hover:text-hColor lg:group-hover:underline decoration-2 mt-3 cursor-pointer" onClick={() => window.location.reload()}><Link to={(card.title_path)}>{card.title}</Link></h2>
                                                        <p className="text-white text-sm fR group-hover:text-pColor">{card.subText}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="block col-span-12 lg:hidden fR text-sm mb-4 z-30 text-pColor">{card.subText}</p>
                                    </>}
                            </>
                        )
                    })}
                </div>
                {!hideLoadMore && <button onClick={() => loadMoreOnClick()} className="fR text-xs border rounded-full px-6 py-2  text-center border-gray-950 text-[#F26D55] block m-auto mb-16">Load More...</button>}
            </div>
            <FooterContact />
        </div>
    );
};
export default SolutionLanding;